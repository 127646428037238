import { connect } from "react-redux";
import CreateUserForm from "./CreateUserForm.component";
import { actions as act } from "../../../store/actions/index";

const mapDispatchToProps = {
  createUser: act.sagas.createUser,
  setPage: act.general.setPage,
};

const CreateUserFormContainer = connect(
  null,
  mapDispatchToProps
)(CreateUserForm);

export default CreateUserFormContainer;
