import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

export const useKeyboardShortcut = (
  specialKeys,
  keys,
  callback,
  node = null
) => {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = useCallback(
    (event) => {
      let together = true;
      let allSpecial = ["ctrlKey", "shiftKey", "altKey"];
      for (let specialKey of allSpecial) {
        if (specialKeys.includes(specialKey) && event[specialKey] === false) {
          together = false;
        }
        if (!specialKeys.includes(specialKey) && event[specialKey] === true) {
          together = false;
        }
      }
      if (
        keys.every(
          (key) => event.key !== key && event.key.toLowerCase() !== key
        )
      ) {
        together = false;
      }
      if (together) {
        callbackRef.current(event);
      }
    },
    [specialKeys, keys]
  );

  useEffect(() => {
    const targetNode = node ?? document;

    targetNode && targetNode.addEventListener("keydown", handleKeyPress);

    return () =>
      targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node]);
};

export default useKeyboardShortcut;
