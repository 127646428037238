import { actionChannel, call, take, put, race } from "redux-saga/effects";
import * as actions from "XmarsStore/actions";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";

export function* audioTrackControl(track) {
  const channel = yield actionChannel(actions.localAudioTrackController);
  try {
    while (true) {
      const { action, endLocalAudioTrack } = yield race({
        action: take(channel),
        endLocalAudioTrack: take(actions.endLocalAudioTrack),
      });
      if (endLocalAudioTrack) {
        channel.close();
      } else {
        const { payload } = action;
        yield call(audioControlHandler, { track, payload });
      }
    }
  } catch (error) {
    yield handleSagaError(error);
  }
}

function* audioControlHandler(obj) {
  const { track, payload } = obj;
  const { command } = payload;
  if (command === "mute") {
    track.mute();
    yield put(actions.setLocalAudioIsMuted(true));
  }
  if (command === "unmute") {
    track.unmute();
    yield put(actions.setLocalAudioIsMuted(false));
  }
  if (command === "toggle") {
    if (track.isMuted()) {
      track.unmute();
      yield put(actions.setLocalAudioIsMuted(false));
    } else {
      track.mute();
      yield put(actions.setLocalAudioIsMuted(true));
    }
  }
  if (command === "kill") {
    yield put(actions.endLocalAudioTrack());
  }

  if (command === "status") {
    console.log("muted", track.isMuted());
    console.log("ended", track.isEnded());
  }
}
