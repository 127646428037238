import { createAction } from "@reduxjs/toolkit";

export const getMessages = createAction("saga/getMessages");
export const getMessagesUpToDate = createAction("saga/getMessagesUpToDate");
export const sendBotMessage = createAction("saga/sendBotMessage");
export const answerBotMessage = createAction("saga/answerBotMessage");
export const sendFiles = createAction("saga/sendFiles");
export const getFile = createAction("saga/getFile");

export const sendTextMessage = createAction("saga/sendTextMessage");
export const sendScribbleMessage = createAction("saga/sendScribbleMessage");
export const editMessage = createAction("saga/editMessage");
export const editReply = createAction("saga/editReply");
export const replyMessage = createAction("saga/replyMessage");
export const readMessage = createAction("saga/readMessage");
export const deleteMessage = createAction("saga/deleteMessage");
export const deleteReply = createAction("saga/deleteReply");
