import { updateItem } from "XmarsStore/utils";
import jitsiInitialState from "./initialState";

export const resetRemoteParticipants = (state) => {
  state.remoteParticipants = jitsiInitialState.remoteParticipants;
};

export const setRemoteParticipants = (state, { payload }) => {
  state.remoteParticipants = payload;
};

export const addRemoteParticipant = (state, { payload }) => {
  state.remoteParticipants = [...state.remoteParticipants, payload];
};

export const removeRemoteParticipant = (state, { payload }) => {
  state.remoteParticipants = state.remoteParticipants.filter(
    (item) => item.id !== payload.id
  );
};

export const updateRemoteParticipant = (state, { payload }) => {
  state.remoteParticipants = updateItem(state.remoteParticipants, payload);
};

export const mapRemoteParticipants = (state, { payload }) => {
  state.remoteParticipants = state.remoteParticipants.map(payload);
};

export const addRemoteTrack = (state, { payload }) => {
  const id = payload.getParticipantId();
  const participant = { id };
  const props = { [payload.getType()]: payload };
  state.remoteParticipants = updateItem(state.remoteParticipants, {
    item: participant,
    props,
  });
};

export const removeRemoteTrack = (state, { payload }) => {
  const id = payload.getParticipantId();
  const item = { id };
  const props = { [payload.getType()]: null };
  state.remoteParticipants = updateItem(state.remoteParticipants, {
    item,
    props,
  });
};

export const addParticipantMessage = (state, { payload }) => {
  const { id, message } = payload;
  const item = state.remoteParticipants.find(
    (participant) => participant.id === id
  );
  if (!item) {
    return state;
  }
  const messages = [...item.messages, message];
  const props = { messages };
  state.remoteParticipants = updateItem(state.remoteParticipants, {
    item,
    props,
  });
};

export const removeParticipantMessage = (state, { payload }) => {
  const { id, message } = payload;
  const item = state.remoteParticipants.find(
    (participant) => participant.id === id
  );
  if (!item) {
    return state;
  }
  const messages = item.messages.filter(
    (itemMessage) => itemMessage !== message
  );
  const props = { messages };
  state.remoteParticipants = updateItem(state.remoteParticipants, {
    item,
    props,
  });
};
