import React, { useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import { Alert } from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";

import { Tooltip } from "../../prestyledComponents";

import useStyles from "./Recording.styles";

export const Recording = function ({ recording, ...props }) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handlePlay = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!recording.thumbnail)
    return <Alert severity="info">{recording.size}</Alert>;
  return (
    <>
      <Mui.ListItem {...props} divider={true}>
        <img
          alt="thumbnail"
          className={classes.thumbnail}
          src={recording.thumbnail}
        />
        <Mui.ListItemText
          primary={format(recording.createdAt, "PPp")}
          secondary={recording.size}
          classes={{
            primary: classes.date,
          }}
        />
        <Mui.ListItemSecondaryAction>
          <Tooltip title="Play">
            <Mui.IconButton color="primary" onClick={handlePlay}>
              <Icons.PlayCircle />
            </Mui.IconButton>
          </Tooltip>
        </Mui.ListItemSecondaryAction>
      </Mui.ListItem>
      <Mui.Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        classes={{
          paperFullScreen: classes.screen,
        }}
      >
        <Mui.DialogContent>
          <video
            controls
            className={classes.video}
            src={`/video/${recording.sessionID}`}
            autoPlay="1"
          />
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Tooltip classes={{}} title="Return">
            <Mui.Fab color="primary" onClick={handleClose}>
              <Icons.X />
            </Mui.Fab>
          </Tooltip>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default Recording;

Recording.propTypes = {
  recording: PropTypes.object.isRequired,
};
