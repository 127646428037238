import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  avatar: {
    marginRight: "-20px",
  },
  contactContainer: {
    display: "flex",
    marginBottom: "5px",
  },
  textLoading: {},
}));
