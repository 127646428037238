import * as electron from "../../../electron";

import api from "../../../dataApi";
import {
  registerRoom,
  unregisterRoom,
  unregisterWorkpace,
  registerWorkpace,
} from "../../sagas/chat/utils";

import * as actions from "../../actions";
import { handleSagaError } from "../sagas.utils";

import { tryOrLog } from "../../../utils";
import { eventChannel } from "redux-saga";
import { delay, put, select, take, call, fork, race } from "redux-saga/effects";
import { getWorkspaces } from "../chat/workspaces";
import env from "../../../env";

function workspaceChannel(workspace) {
  const cleanedSubscription = (emitter) => {
    const subscription = api.subscribeToWorkspace(workspace, {
      next: emitter,
      error: handleSagaError,
    });
    // This weird trick removes a this is undefined error:
    const unsubscribe = subscription.unsubscribe;
    return unsubscribe.bind(subscription);
  };
  return eventChannel(cleanedSubscription);
}

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
function* processSignal(payload) {
  const { workspace, signal } = payload;
  try {
    // console.log("Ws Subscription debug:")
    // console.log(signal.signalType)
    switch (signal.signalType) {
      case "roomMessage": {
        // const { title, admin } = signal.room;
        // yield put(
        //   actions.setRoomProperties({
        //     room,
        //     properties: {
        //       title,
        //       admin,
        //     },
        //   })
        // );
        // yield put(actions.updateRooms());
        // break;
        break;
      }
      case "workspaceUpdated": {
        const chat = yield select((state) => state.chat);
        if (
          chat.currentWorkspace &&
          chat.currentWorkspace.id === workspace.id
        ) {
          yield put(actions.replaceWorkspaceForUpdated({ id: workspace.id }));
        }
        break;
      }
      case "setWorkspaceAdmin": {
        const chat = yield select((state) => state.chat);
        if (
          chat.currentWorkspace &&
          chat.currentWorkspace.id === workspace.id
        ) {
          yield put(actions.setCurrentWorkspace(workspace));
        }
        if (signal.target === chat.user.id) {
          const snackbar = {
            severity: "info",
            message: `You have been added as admin of ${workspace.title}`,
          };
          yield put(actions.addSnackbar(snackbar));
        } else if (signal.sender === chat.user.id) {
          const snackbar = {
            severity: "info",
            message: `User added as admin of ${workspace.title} sucessfully`,
          };
          yield put(actions.addSnackbar(snackbar));
        }
        break;
      }
      case "unsetWorkspaceAdmin": {
        const chat = yield select((state) => state.chat);
        if (
          chat.currentWorkspace &&
          chat.currentWorkspace.id === workspace.id
        ) {
          yield put(actions.setCurrentWorkspace(workspace));
        }
        if (signal.target === chat.user.id) {
          const snackbar = {
            severity: "info",
            message: `You have been removed as admin of ${workspace.title}`,
          };
          yield put(actions.addSnackbar(snackbar));
        } else if (signal.sender === chat.user.id) {
          const snackbar = {
            severity: "info",
            message: `User removed as admin of ${workspace.title} sucessfully`,
          };
          yield put(actions.addSnackbar(snackbar));
        }
        break;
      }
      case "workspaceDeleted": {
        const chat = yield select((state) => state.chat);
        if (chat.currentWorkspace.id === workspace.id) {
          yield put(actions.setCategories([]));

          yield put(actions.setWorkspace(null));
        }
        yield put(actions.removeWorkspace(workspace.id));
        yield unregisterWorkpace(workspace);
        const snackbar = {
          severity: "info",
          message: `The workspace: ${workspace.title} has been deleted by an admin`,
        };
        yield put(actions.addSnackbar(snackbar));
        break;
      }
      case "categoryAdded": {
        const chat = yield select((state) => state.chat);
        if (chat.currentWorkspace.id === workspace.id) {
          yield put(actions.getCategories({ workspaceId: workspace.id }));
        }
        break;
      }
      case "categoryUpdated": {
        const chat = yield select((state) => state.chat);
        if (
          (signal.sender === chat.user.id || signal.target === chat.user.id) &&
          chat.currentWorkspace.id === workspace.id
        ) {
          // if (chat.currentWorkspace.id === workspace.id) {
          yield put(actions.getCategories({ workspaceId: workspace.id }));
          // }
        }
        break;
      }
      case "call": {
        const { room, sender } = signal;
        const userId = yield select((state) => state.chat.user.id);
        const userIsInRoom = room.users.some((user) => user.id === userId);
        if (!userIsInRoom || userId === sender) return;
        const ongoingCall = yield select((state) => state.video.ongoingCall);
        if (ongoingCall) return;
        const workspaces = yield select((state) => state.chat.workspaces);
        const currentWorkspace = workspaces.find(
          (ws) => ws.id === workspace.id
        );
        yield put(actions.setCurrentWorkspace(currentWorkspace));
        const incomingCall = {
          meetingCode: room.id,
          channel: room,
        };
        yield put(actions.setIncomingCall(incomingCall));
        yield put(actions.setOngoingCall(incomingCall));
        if (electron.isSupported) {
          const notification = {
            title: room.title,
            body: "Incoming call",
          };
          tryOrLog(() => {
            electron.showNotification(notification);
          });
        }
        break;
      }
      case "invitedToCall": {
        const { target, room } = signal;
        const userId = yield select((state) => state.chat.user.id);
        if (userId !== target) return;
        const workspaces = yield select((state) => state.chat.workspaces);
        const currentWorkspace = workspaces.find(
          (ws) => ws.id === workspace.id
        );
        yield put(actions.setCurrentWorkspace(currentWorkspace));
        const incomingCall = {
          meetingCode: room.id,
          channel: room,
        };
        yield put(actions.setIncomingCall(incomingCall));
        yield put(actions.setOngoingCall(incomingCall));
        if (electron.isSupported) {
          const notification = {
            title: room.title,
            body: "Incoming call",
          };
          tryOrLog(() => {
            electron.showNotification(notification);
          });
        }
        break;
      }
      case "endOngoingCall": {
        const { room } = signal;
        const ongoingCall = yield select((state) => state.video.ongoingCall);
        if (ongoingCall?.meetingCode !== room.id) return;
        yield put(actions.setOngoingCall(null));
        yield put(actions.setIncomingCall(null));
        yield put(actions.setPage("home"));
        break;
      }
      case "sharingScreen": {
        const { sender } = signal;
        const id = sender.replace(env.BEARER_SECRET, "");
        yield put(
          actions.setSharing({
            status: true,
            id,
          })
        );
        break;
      }
      case "stopSharingScreen": {
        const { sender } = signal;
        yield put(
          actions.setSharing({
            status: false,
            id: "",
          })
        );
        break;
      }
      case "categoryDeleted": {
        const chat = yield select((state) => state.chat);
        if (chat.currentWorkspace.id === workspace.id) {
          yield put(actions.getCategories({ workspaceId: workspace.id }));
        }
        break;
      }
      case "workspaceMembersUpdate": {
        const chat = yield select((state) => state.chat);

        if (chat.user.id === signal.removedUserId) {
          const workspaces = chat.workspaces.filter((ws) => {
            return ws.id !== workspace.id;
          });
          yield put(actions.updateWorkspaces(workspaces));
          const snackbar = {
            severity: "info",
            message: `You have been removed from ${workspace.title}`,
          };
          yield put(actions.addSnackbar(snackbar));
        } else {
          if (chat.currentWorkspace.id === signal.workspace.id) {
            const workspaceUpdated = yield call(
              api.getWorkspace,
              signal.workspace.id
            );
            yield put(actions.setCurrentWorkspace(workspaceUpdated));
          } else {
            yield put(actions.getWorkspaces());
          }

          if (signal.removedUserId != null) {
            const removedUser = workspace.users.find(
              (user) => user.id === signal.removedUserId
            );
            const snackbar = {
              severity: "info",
              message: `User: ${removedUser.username} has been removed from ${workspace.title}`,
            };
            yield put(actions.addSnackbar(snackbar));
          }
        }
        break;
      }
      case "onlineStatus": {
        const chat = yield select((state) => state.chat);
        if (chat.currentWorkspace) {
          if (chat.currentWorkspace.id === signal.workspace.id) {
            const workspaceUpdated = yield call(
              api.getWorkspace,
              signal.workspace.id
            );
            yield put(actions.setCurrentWorkspace(workspaceUpdated));
            // yield put(
            //     actions.setCurrentWorkspace(workspaceUpdated)
            // );
          }
          if (chat.room) {
            yield put(actions.getRoom({ id: chat.room.id }));
          }
        } else {
          const workspaces = yield call(api.getWorkspaces);
          yield put(actions.setWorkspaces(workspaces));
        }
        break;
      }
      default: {
        return console.log("unhandled case", signal);
      }
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}

export const startWorkpaceSubscription = function* ({ payload }) {
  const workspace = payload;

  const channel = yield call(workspaceChannel, workspace);
  try {
    while (true) {
      const { signal, cancel } = yield race({
        signal: take(channel),
        cancel: take(actions.cancelWorkspaceSubscription),
      });

      if (cancel) {
        if (cancel.payload.id === workspace.id) {
          channel.close();
        }
      } else {
        yield fork(processSignal, { workspace, signal });
      }
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
};
