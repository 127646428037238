import React, { useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import { DoneAll as MuiDoubleCheck } from "@mui/icons-material";
import PropTypes from "prop-types";

import { CircularProgressIcon } from "../../prestyledComponents";
import { stringAvatar } from "../../../utils/defaultAvatar/defaultAvatar";
import useStyles from "./MessageStatusIcon.styles";

export const MessageStatusIcon = function ({ message, inReplies }) {
  const [anchorEl, setAnchorEl] = useState(false);

  const classes = useStyles();

  const handleClick = function (event) {
    event.stopPropagation();
    setAnchorEl(event.target);
  };

  const handleClose = () => setAnchorEl(null);

  const renderMessageStatusIcon = function () {
    switch (message.status) {
      case "sending":
        return <CircularProgressIcon />;
      case "sent":
        return <Icons.Check />;
      case "read":
        return <MuiDoubleCheck onClick={handleClick} color="primary" />;
      case "error":
        return <Icons.AlertCircle color="secondary" />;
      default:
        return <Icons.WifiOff style={{ color: "rgba(76, 175, 80, 0.0)" }} />;
    }
  };

  const messageType = message.messageType;

  return (
    <Mui.Box
      className={
        inReplies
          ? classes.statusIconReplies
          : messageType === "file"
          ? classes.fileStatusIcon
          : classes.statusIcon
      }
    >
      {renderMessageStatusIcon()}
      <Mui.Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        <Mui.MenuItem
          disableRipple
          style={{
            backgroundColor: "transparent",
          }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "center", horizontal: "left" }}
          transformOrigin={{ vertical: "center", horizontal: "left" }}
        >
          <Mui.ListItemText secondary="Read by:" />
        </Mui.MenuItem>
        {!message.readers
          ? null
          : message.readers.map((reader, index) => (
              <Mui.MenuItem key={index}>
                <Mui.ListItemAvatar>
                  <Mui.Avatar
                    src={reader.avatar}
                    {...stringAvatar(reader.username)}
                  />
                </Mui.ListItemAvatar>
                <Mui.ListItemText primary={reader.username} />
              </Mui.MenuItem>
            ))}
      </Mui.Menu>
    </Mui.Box>
  );
};

export default MessageStatusIcon;

MessageStatusIcon.propTypes = {
  message: PropTypes.object,
};
