import { eventChannel } from "redux-saga";

import JitsiMeetJS from "XmarsJitsiMeetJS/jitsi";

export function screenSharingChannel(jitsiConnection) {
  const jitsiSubscription = (emitter) => {
    jitsiConnection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      () => {
        emitter({
          connectionStatus: "CONNECTED",
        });
      }
    );
    jitsiConnection.connect();

    return () => {
      jitsiConnection.disconnect();
    };
  };
  return eventChannel(jitsiSubscription);
}
