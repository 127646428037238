import { put } from "redux-saga/effects";
import { logError } from "../../../utils";
import { actions as act } from "../../actions";

/** @param {Error} error */
export const handleSagaError = function* (error) {
  logError(error);
  yield put(
    act.snackbars.addSnackbar({
      severity: "error",
      message: error.message,
    })
  );
};

export default handleSagaError;
