import React, { useState } from "react";
import Box from "@mui/material/Box";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { TooltipIconButton } from "../../prestyledComponents";
import { Recordings } from "../../collections";

export const RecordingsButton = function ({ user, getRecordings, ...props }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(!open);

  const handleRefresh = () => getRecordings();

  return !user ? null : (
    <>
      <TooltipIconButton {...props} title="recordings" onClick={handleClick}>
        <Icons.Video />
      </TooltipIconButton>
      <Mui.Drawer open={open} variant="persistent" anchor="right">
        <Box>
          <TooltipIconButton
            title="Refresh"
            color="primary"
            onClick={handleRefresh}
          >
            <Icons.RefreshCw />
          </TooltipIconButton>
          <TooltipIconButton
            title="Close"
            color="primary"
            onClick={handleClick}
          >
            <Icons.ChevronRight />
          </TooltipIconButton>
        </Box>
        <Recordings />
      </Mui.Drawer>
    </>
  );
};

export default RecordingsButton;

RecordingsButton.propTypes = {
  user: PropTypes.object.isRequired,
};
