import { gql } from "../../../utils/apollo";

export const endOngoingCall = gql`
  mutation EndOngoingCall($channelId: ID, $workspaceId: ID) {
    endOngoingCall(
      input: { channelId: $channelId, workspaceId: $workspaceId }
    ) {
      null
    }
  }
`;

export default endOngoingCall;
