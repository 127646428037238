/**
 * @callback boundAction
 */

import { logError } from "XmarsUtils/errors";

/**
 * @param {boundAction} boundAction
 * @deprecated This function is no longer needed, as the old promise system has been replaced by sagas
 */
export const getBoundActionPromise = function (boundAction, argsObj = {}) {
  logError(
    "`getBoundActionPromise` function is deprecated. You should no longer need to use it as we're using sagas to replace the promise pattern"
  );
  return new Promise((resolve, reject) => {
    boundAction({
      ...argsObj,
      promise: { resolve, reject },
    });
  });
};

export default getBoundActionPromise;
