import { put, call, select } from "redux-saga/effects";
import * as actions from "../../actions";
import api from "../../../dataApi";
import { handleSagaError } from "../sagas.utils";

export const searchPageAndSection = function* ({ payload }) {
  try {
    const { section } = payload;
    const results = yield call(api.searchPageAndSection, payload);

    if (section.includes("Messages")) {
      yield put(actions.setFoundMessages(results.messages));
    }
    if (section.includes("Groups")) {
      yield put(actions.setFoundGroups(results.groups));
    }
    if (section.includes("Contacts")) {
      const { friends, user, status } = yield select((state) => state.chat);
      const foundIds = results.contacts.map((foundContact) => foundContact.id);
      const foundFriends = friends.filter((friend) =>
        foundIds.includes(friend.id)
      );
      if (foundIds.includes(user.id)) {
        user.online = status;
        foundFriends.unshift(user);
      }
      yield put(actions.setFoundContacts(foundFriends));
    }
    if (section.includes("Files")) {
      yield put(actions.setFoundFiles(results.files));
    }
  } catch (error) {
    handleSagaError(error);
  }
};
