import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Snackbar from "./Snackbar.component";

import { actions as act } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeSnackbar: act.snackbars.removeSnackbar,
    },
    dispatch
  );

const SnackbarContainer = connect(null, mapDispatchToProps)(Snackbar);

export default SnackbarContainer;
