export const getImageDataFromBackgroundPredictions = function (
  predictions,
  { width = 640, height = 480 } = {}
) {
  const imageData = new ImageData(width, height);
  for (let i = 0; i < predictions.length; i++) {
    const prediction = predictions[i];
    if (prediction > 160) {
      imageData.data[4 * i + 0] = prediction;
      imageData.data[4 * i + 1] = prediction;
      imageData.data[4 * i + 2] = prediction;
      imageData.data[4 * i + 3] = prediction;
    }
  }
  return imageData;
};

export default getImageDataFromBackgroundPredictions;
