import client from "../../../client";
import mutation from "./sendPushNotification.mutation";

/**
 * @param {string} endpoint
 * @param {string} expirationTime
 * @param {string} p256dh
 * @param {string} auth
 */
export const sendPushNotification = async function (
  endpoint,
  expirationTime,
  p256dh,
  auth
) {
  if (expirationTime === undefined) expirationTime = null;
  await client.mutate({
    mutation,
    variables: {
      endpoint,
      expirationTime,
      p256dh,
      auth,
    },
  });
};

export default sendPushNotification;
