// import { subscriptionClient } from "../client/link";

const state = {
  log: false,
  mock: false,
  onReconnectedRemover: null,
  onDisconnectedRemover: null,
};
export default state;

/**
 * If true, sets the api to log the info of all queries (default: false)
 * @param {boolean} value - Boolean to indicate if all the info of the queries is logged
 */
export const logs = function (value) {
  state.log = !!value;
};

/**
 * If true, sets the api to mock the response of all queries (default: false)
 * @param {boolean} value - Boolean to indicate if all responses are mocked
 */
export const mock = function (value) {
  state.mock = !!value;
};
//TODO update the way subscriptions are handled
export const onReconnected = function (onReconnectedCallback) {
  if (state.onReconnectedRemover) state.onReconnectedRemover();
  // state.onReconnectedRemover = subscriptionClient.onReconnected(onReconnectedCallback);
};
export const onDisconnected = function (onDisconnectedCallback) {
  if (state.onDisconnectedRemover) state.onDisconnectedRemover();
  // state.onDisconnectedRemover = subscriptionClient.onDisconnected(onDisconnectedCallback);
};
