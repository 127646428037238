import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Typography from "@mui/material/Typography";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { stringAvatar } from "../../utils/defaultAvatar/defaultAvatar";
import { VerticalScrollbars } from "../prestyledComponents";

import useStyles from "./AddUserToRooms.styles";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const AddUserToRooms = function ({
  user,
  rooms,
  checked,
  handleToggle,
  currentRoom,
  filter,
}) {
  const classes = useStyles();

  /**
   * Separates the groups with and without the current user.
   * @parameter {array} groups
   */
  function separateGroups(groups) {
    const groupsWithoutUser = groups.filter((room) => {
      let roomParticipants = room.users?.map((user) => user.id);
      return !roomParticipants?.includes(currentRoom.friend.id);
    });
    const groupsWithUser = groups.filter((room) => {
      let roomParticipants = room.users?.map((user) => user.id);
      return roomParticipants?.includes(currentRoom.friend.id);
    });
    return [groupsWithUser, groupsWithoutUser];
  }

  const renderGroups = () => {
    if (rooms.length) {
      const uncaseFilter = filter.toLowerCase();
      let shownGroups = [...rooms];
      if (filter) {
        shownGroups = shownGroups.filter((group) =>
          group.title.toLowerCase().includes(uncaseFilter)
        );
      }

      let [groupsWithUser, groupsWithoutUser] = separateGroups(shownGroups);

      if (shownGroups.length)
        return (
          <>
            {groupsWithoutUser.map((group) => {
              const toggleHandler = handleToggle(group.id);
              return (
                <ListItem
                  key={group.id}
                  button
                  selected={false}
                  className={classes.item}
                  classes={{
                    root: classes.listItemRoot,
                    selected: classes.listItemSelected,
                  }}
                  onClick={toggleHandler}
                >
                  <ListItemAvatar>
                    <Avatar src={group.avatar} {...stringAvatar(group.title)} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={group.title}
                    primaryTypographyProps={{
                      className: classes.primaryText,
                    }}
                    className={classes.itemText}
                  />
                  <ListItemSecondaryAction
                    classes={{ root: classes.secondaryAction }}
                  >
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={toggleHandler}
                      checked={checked.indexOf(group.id) !== -1}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
            {groupsWithUser.map((group) => (
              <ListItem
                key={group.id}
                button
                disabled
                selected={false}
                className={classes.item}
                classes={{
                  root: classes.listItemRoot,
                  selected: classes.listItemSelected,
                }}
              >
                <ListItemAvatar>
                  <Avatar src={group.avatar} {...stringAvatar(group.title)} />
                </ListItemAvatar>
                <ListItemText
                  primary={group.title}
                  primaryTypographyProps={{
                    className: classes.primaryText,
                  }}
                  className={classes.itemText}
                />
                <ListItemSecondaryAction
                  classes={{ root: classes.secondaryAction }}
                >
                  <Typography
                    variant="subtitle2"
                    className={classes.alreadyMember}
                  >
                    already member
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </>
        );
      return (
        <ListItem>
          <ListItemIcon>
            <Icons.Search />
          </ListItemIcon>
          <ListItemText primary={`No match for the search "${filter}"`} />
        </ListItem>
      );
    }

    if (user.role === "guest") return null;
    return (
      <ListItem>
        <ListItemText primary={"You are not admin of any group"} />
      </ListItem>
    );
  };

  return (
    <Box className={classes.usersContainer}>
      <VerticalScrollbars style={{ height: 300 }} alwaysVisible={true}>
        <List
          style={{ paddingTop: 0, paddingBottom: 30 }}
          className={classes.content}
        >
          {renderGroups()}
        </List>
      </VerticalScrollbars>
    </Box>
  );
};

export default AddUserToRooms;

AddUserToRooms.propTypes = {
  user: PropTypes.object,
};
