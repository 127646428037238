import { groupLog } from "XmarsJitsiMeetJS/loggers";
import JitsiMeetJS from "XmarsJitsiMeetJS/index";
import getEventName from "./getEventName";
// eslint-disable-next-line
import {
  JitsiConference,
  JitsiConnection,
  JitsiMediaDevices,
  JitsiTrack,
} from "../typeDefs";

/**
 * Function to add event handlers to JitsiMeetJS instances
 * @param {"conference"|"connection"|"detection"|"track"|"mediaDevices"|"connectionQuality"|"e2eping"} type
 * @param {JitsiConference|JitsiConnection|JitsiTrack|JitsiMediaDevices} instance - JitsiMeetJS object instance
 * @param {Object.<string,(event) => void>} handlers - Object with handlers to add to the instance
 */
export const registerEventHandlers = function (type, instance, handlers) {
  const events = JitsiMeetJS.events[type];
  if (!events) throw new Error(`"${type}" is not a valid type`);
  groupLog(`Registering ${type} event handlers`, () => {
    console.log("instance", instance);
    console.log("handlers", handlers);
  });
  for (const [handlerName, handler] of Object.entries(handlers)) {
    const eventName = getEventName(handlerName);
    instance.addEventListener(events[eventName], handler);
  }
};

export default registerEventHandlers;
