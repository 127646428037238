import { gql } from "../../../utils/apollo";

export const rejectNotification = gql`
  mutation RejectNotification($id: ID) {
    rejectNotification(input: { id: $id }) {
      null
    }
  }
`;

export default rejectNotification;
