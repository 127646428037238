import client from "../../../client";
import mutation from "./createMedicalRoom.mutation";

/**
 * @param {{
 *  email: string,
 *  username: string,
 * }} pacient
 * @param {Date} date
 * @param {number} duration
 * @returns {Promise<{
 *  id: string,
 *  group: boolean,
 *  title: string,
 *  admin: boolean,
 *  meetingCode: string,
 *  kind: string,
 * }>}
 */
export const createMedicalRoom = async function (pacient, date, duration) {
  const { data } = await client.mutate({
    mutation,
    variables: {
      pacient,
      date,
      duration,
    },
  });
  return data.createMedicalRoom;
};

export default createMedicalRoom;
