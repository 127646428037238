import { byCreatedAt } from "../../stateManagement.utils/sorters";
import messagesInitialState from "./initialState";

export const resetMessages = (state) => {
  return messagesInitialState;
};

export const setMessages = (state, { payload }) => {
  return payload;
};

export const addMessage = (state, { payload }) => {
  return [...state, payload].sort(byCreatedAt);
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const addToMessageList = (state, { payload }) => {
  const { temporalMessages, room } = payload;
  const roomId = payload.roomId || room.id;

  const currentIds = state.map((item) => item?.id);
  const newMessages = temporalMessages.filter(
    (msg) => !currentIds.includes(msg.id) && msg.room.id === roomId
  );

  return [...state, ...newMessages].sort(byCreatedAt);
};

export const removeMessage = (state, { payload }) => {
  return state.filter((item) => item.id !== payload.id);
};

export const updateMessage = (state, { payload }) => {
  const { message, properties } = payload;
  const index = state.findIndex((element) => element.id === message.id);
  if (-1 < index) {
    state[index] = { ...state[index], ...properties };
  }
};
