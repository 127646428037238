import { gql } from "../../../utils/apollo";

export const deleteReply = gql`
  mutation deleteReply($replyId: ID, $repliedMessageId: ID) {
    deleteReply(
      input: { replyId: $replyId, repliedMessageId: $repliedMessageId }
    ) {
      null
    }
  }
`;

export default deleteReply;
