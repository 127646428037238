import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import * as reducers from "./reducers";

const slice = createSlice({
  name: "video",
  initialState,
  reducers,
});

const { reducer, actions } = slice;

export default reducer;
export const {
  setActiveChannel,
  setMeetingCode,
  setDevices,
  setMicId,
  setVideoId,
  setSpeakerId,
  setIncomingCall,
  setOnlyAudio,
  setRecordings,
  updateSmallVideoOpen,

  resetCallStatus,
  setCallStatus,
  updateCallStatus,
  setOngoingCall,
  setSharing,
} = actions;
