export const getEventName = function (handlerName = "") {
  const first = handlerName[2];
  handlerName = handlerName
    .substring(3)
    .replace(/([A-Z])/g, "_$1")
    .toUpperCase();
  return `${first}${handlerName}`;
};

export default getEventName;
