import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import env from "../../../env";

import RecordingButton from "./RecordingButton.component";

import conferenceMock from "./conferenceMock";

import { startRecording, stopRecording } from "../../../store/actions/index";

const mapStateToProps = ({ conference }) => ({
  conference: env.DEVELOPMENT ? conferenceMock : conference,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startRecording,
      stopRecording,
    },
    dispatch
  );

const RecordingButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordingButton);

export default RecordingButtonContainer;
