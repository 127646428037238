import { makeStyles } from "@mui/styles";

import { colors } from "../../../style";

export default makeStyles(() => ({
  thumbnail: {
    width: "6em",
    maxHeight: "4em",
    borderRadius: "0.5em",
    marginRight: "0.5em",
  },
  date: {
    fontSize: "small",
  },
  screen: {
    backgroundColor: colors.dark,
  },
  video: {
    height: "95%",
    width: "95%",
    backgroundColor: colors.dark,
  },
}));
