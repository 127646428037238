import { gql } from "../../../utils/apollo";

export const addRecording = gql`
  mutation AddRecording($sessionID: ID) {
    addRecording(input: { sessionID: $sessionID }) {
      null
    }
  }
`;

export default addRecording;
