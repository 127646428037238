import { gql } from "../../../utils/apollo";

export const getClientSecret = gql`
  query GetClientSecret($id: ID) {
    getClientSecret(input: { id: $id }) {
      clientSecret
    }
  }
`;

export default getClientSecret;
