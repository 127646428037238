import React from "react";
import PropTypes from "prop-types";
import useStyles from "./SideWorkspacesButtons.style";
import * as Buttons from "../../buttons";

export const SideWorkspacesButtons = function ({ workspaces }) {
  const classes = useStyles();
  const renderItems = function () {
    return (
      <>
        {workspaces.length > 0
          ? workspaces.map((ws) => (
              <Buttons.WorkspacesButton
                className={classes.iconButton}
                TooltipProps={{ placement: "right" }}
                isInNavBar={true}
                wsData={ws}
              />
            ))
          : null}
      </>
    );
  };

  return <>{renderItems()}</>;
};

export default SideWorkspacesButtons;

SideWorkspacesButtons.propTypes = {
  workspaces: PropTypes.array.isRequired,
};
