import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import {
  CircularProgressIcon,
  LinearSubmitButton,
  TooltipIconButton,
} from "../../prestyledComponents";
import { useJitsiEffect, useMounted } from "../../../hooks/index";
import { FilesInput } from "../../inputs";

import env from "../../../env";
import { effects } from "../../../JitsiMeetJS";
import { pdfFileToUrls } from "../../../utils/converters/pdfFileToUrls";
import { tryOrLog } from "../../../utils/errors/tryOrLog";

// eslint-disable-next-line
import { Props } from "./HandtrackButton.propTypes";

const slides = [
  `${env.PUBLIC_URL}/images/background0.jpg`,
  `${env.PUBLIC_URL}/images/background1.jpg`,
  `${env.PUBLIC_URL}/images/background2.jpg`,
  `${env.PUBLIC_URL}/images/background3.jpg`,
  `${env.PUBLIC_URL}/images/background4.jpg`,
  `${env.PUBLIC_URL}/images/background5.jpg`,
  `${env.PUBLIC_URL}/images/background6.jpg`,
  `${env.PUBLIC_URL}/images/background7.jpg`,
  `${env.PUBLIC_URL}/images/background8.jpg`,
  `${env.PUBLIC_URL}/images/background9.jpg`,
];

/**
 * @param {Props} props
 */
export const HandtrackButton = function ({
  disabled,
  onClick,
  localParticipant,
  videoEffects,
  addSnackbar,
  updateVideoEffects,
  ...props
}) {
  const handtrackEffect = useMemo(
    () => new effects.SlidesEffect({ slides }),
    []
  );

  const [handtrackStatus, handtrackError] = useJitsiEffect(handtrackEffect, {
    flipHorizontal: false,
  });

  const [factor, setFactor] = useState(handtrackEffect.factor * 100);

  const [open, setOpen] = useState(false);
  const [badge, setBadge] = useState(false);
  const [uploading, setUploading] = useState(false);

  const mounted = useMounted();

  useEffect(() => {
    if (handtrackEffect) handtrackEffect.factor = factor / 100;
    // eslint-disable-next-line
  }, [factor]);

  useEffect(() => {
    if (handtrackStatus === "ready") {
      updateVideoEffects({ handtrackEffect });
    }
    // eslint-disable-next-line
  }, [handtrackStatus]);

  useEffect(() => {
    const track = localParticipant.video;
    if (track) {
      tryOrLog(async () => {
        if (videoEffects.handtrackActive) {
          updateVideoEffects({ backgroundActive: false });
          await track.setEffect(handtrackEffect);
        } else if (!videoEffects.backgroundActive) {
          await track.setEffect();
        }
      }, true);
    }
    // eslint-disable-next-line
  }, [videoEffects.handtrackActive]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleMouseEnter = () => setBadge(true);
  const handleMouseLeave = () => setBadge(false);

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const handleSizeChange = function (event) {
    const value = parseInt(event.target.value);
    setFactor(value);
  };

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const handleFileChange = async function (event) {
    const file = event.target.files[0];
    if (!file) return;
    setUploading(true);
    try {
      const urls = await pdfFileToUrls(file);
      if (mounted.current) handtrackEffect.slides = urls;
    } catch (error) {
      addSnackbar({
        severity: "error",
        message: error.message,
      });
    } finally {
      if (mounted.current) setUploading(false);
    }
  };

  const handleClick = function (event) {
    updateVideoEffects({ handtrackActive: !videoEffects.handtrackActive });
    if (onClick) onClick(event);
  };

  const renderBadgeContent = function () {
    switch (handtrackStatus) {
      case "loading":
        return (
          <Mui.Icon>
            <CircularProgressIcon />
          </Mui.Icon>
        );
      case "error":
        return (
          <Mui.Tooltip arrow placement="top" title={`${handtrackError}`}>
            <Mui.Icon color="secondary">
              <Icons.AlertCircle />
            </Mui.Icon>
          </Mui.Tooltip>
        );
      case "ready":
        return !badge ? (
          0
        ) : (
          <TooltipIconButton
            size="small"
            title="Slides settings"
            TooltipProps={{ placement: "top" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleOpen}
          >
            <Icons.Settings />
          </TooltipIconButton>
        );
      default:
        return 0;
    }
  };

  return (
    <>
      <Mui.Badge overlap="circle" badgeContent={renderBadgeContent()}>
        <TooltipIconButton
          {...props}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          TooltipProps={{ placement: "top" }}
          title={
            handtrackStatus === "error"
              ? ""
              : `Track${videoEffects.handtrackActive ? "ing" : ""} slideshow`
          }
          disabled={
            disabled || handtrackStatus !== "ready" || !localParticipant.video
          }
          // color={videoEffects.handtrackActive ? "secondary" : undefined}
          onClick={handleClick}
        >
          {videoEffects.handtrackActive ? (
            <Icons.Star style={{ fill: "currentColor" }} />
          ) : (
            <Icons.Star />
          )}
        </TooltipIconButton>
      </Mui.Badge>
      <Mui.Dialog open={open} onClose={handleClose}>
        <Mui.DialogTitle>Slides Settings</Mui.DialogTitle>
        <Mui.DialogContent>
          <Box display="flex" flexDirection="column">
            <Box margin="0.5em">
              <Mui.TextField
                label="Size"
                placeholder="0 - 100"
                type="number"
                value={factor}
                onChange={handleSizeChange}
                inputProps={{
                  min: 0,
                  max: 100,
                }}
              />
            </Box>
            <Box margin="0.5em">
              <FilesInput
                inputProps={{
                  onChange: handleFileChange,
                  accept: "application/pdf",
                }}
                component={LinearSubmitButton}
                fullWidth
                type="button"
                color="primary"
                loading={uploading}
                children={`Load${uploading ? "ing" : ""} slides`}
              />
            </Box>
          </Box>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button
            variant="contained"
            color="secondary"
            children="Exit"
            onClick={handleClose}
          />
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default HandtrackButton;

HandtrackButton.propTypes = {
  videoEffects: PropTypes.object.isRequired,
  updateVideoEffects: PropTypes.func.isRequired,
};
