import { connect } from "react-redux";

import Snackbars from "./Snackbars.component";

const mapStateToProps = (state) => {
  const { snackbars } = state;

  return { snackbars };
};

const SnackbarsContainer = connect(mapStateToProps, null)(Snackbars);

export default SnackbarsContainer;
