import { Grid } from "@mui/material";

import Settings from "../../Settings";
import { User } from "../../standaloneItems";

export const SettingsPage = function () {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <User />
      </Grid>
      <Grid item>
        <Settings />
      </Grid>
    </Grid>
  );
};

export default SettingsPage;
