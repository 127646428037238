import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TestCall from "./TestCall.component";

import {
  addLocalTrack,
  addSnackbar,
  cleanConferenceRequest,
} from "../../store/actions/index";

const mapStateToProps = (state) => {
  const { localParticipant } = state.jitsi;
  return {
    localParticipant,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addLocalTrack,
      addSnackbar,
      cleanConferenceRequest,
    },
    dispatch
  );

const TestCallContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TestCall);

export default TestCallContainer;
