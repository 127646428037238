import jitsiInitialState from "./initialState";

export const resetVideoEffects = (state) => {
  state.videoEffects = jitsiInitialState.videoEffects;
};

export const setVideoEffects = (state, { payload }) => {
  state.videoEffects = payload;
};

export const updateVideoEffects = (state, { payload }) => {
  state.videoEffects = { ...state.videoEffects, ...payload };
};
