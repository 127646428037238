import client from "../../../client";
import { addBearer } from "../../../utils";
import query from "./getAdmin.query";

/**
 * @param {string} workspace_id
 * @returns {Promise<{
 *  id: string,
 *  title: string,
 *  createdAt: date,
 *  admin: boolean
 *  lastUpdate: date,
 *
 * }>}
 */
export const getAdmin = async function (workspaceId) {
  workspaceId = addBearer(workspaceId);
  const { data } = await client.query({
    query,
    variables: {
      input: {
        workspace_id: workspaceId,
      },
    },
  });
  return data.getAdmin;
};

export default getAdmin;
