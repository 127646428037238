import React, { useState } from "react";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { useJsonParsed } from "../../../hooks/index";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const MessageFormContent = function ({
  message,
  room,
  answerBotMessage,
}) {
  const form = useJsonParsed(message.form);

  if (form.answer)
    return <Mui.Chip color="primary" label={form.answer.toUpperCase()} />;
  return (
    <Mui.ButtonGroup orientation="vertical" variant="contained">
      {form.options.map((option, index) => {
        const MyButton = function (props) {
          const [color, setColor] = useState("default");

          const handleClick = function () {
            answerBotMessage(message, room, option);
          };

          const handleMouseEnter = () => setColor("primary");
          const handleMouseLeave = () => setColor("default");

          return (
            <Mui.Button
              {...props}
              color={color}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleClick}
            >
              {option}
            </Mui.Button>
          );
        };
        return <MyButton key={index} />;
      })}
    </Mui.ButtonGroup>
  );
};

export default MessageFormContent;

MessageFormContent.propTypes = {
  message: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  answerBotMessage: PropTypes.func.isRequired,
};
