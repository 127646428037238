import { gql } from "../../../utils/apollo";

export const inviteUsersToCall = gql`
  mutation InviteUsersToCall(
    $usersIds: [ID]
    $meetingCode: String
    $channelId: ID
    $workspaceId: ID
  ) {
    inviteUsersToCall(
      input: {
        usersIds: $usersIds
        meetingCode: $meetingCode
        channelId: $channelId
        workspaceId: $workspaceId
      }
    ) {
      null
    }
  }
`;

export default inviteUsersToCall;
