const neglect = function (name) {
  const url =
    "https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-ljm-api#jitsitrack";
  console.error(
    `JitsiTrackEffect "${name}" method not implemented. Check ${url}`
  );
};

export class JitsiTrackEffect {
  startEffect(stream) {
    neglect("startEffect");
    return stream;
  }

  stopEffect() {
    neglect("stopEffect");
  }

  isEnabled() {
    neglect("isEnabled");
    return false;
  }
}

export default JitsiTrackEffect;
