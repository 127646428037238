import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Icons from "react-feather";
import { File } from "../../collectionItems";

import useStyles from "./Files.styles";
import { FilesInput } from "../../inputs";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const Files = function ({
  files: newFiles,
  setFiles: setNewFiles,
  classes: newClasses,
  className,
  isDrawer,
  room,
  deleteFile,
  sendFiles,
  sendingFiles,
  setSendingFiles,
  ...props
}) {
  let fileList = newFiles;

  // eslint-disable-next-line
  const [progress, setProgress] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    if (sendingFiles === true) {
      handleSend();
      setSendingFiles(false);
    }
    // eslint-disable-next-line
  }, [newFiles, sendingFiles]);

  const renderFiles = function () {
    const render = [];
    let index = fileList.length;
    while (index--) {
      const file = fileList[index];
      render.push(
        <Grid item key={index}>
          <File file={file} isDrawer={isDrawer} />
        </Grid>
      );
    }
    return render;
  };

  const clearFiles = function () {
    let i = fileList.length;
    while (i > 0) deleteFile(fileList[--i]);
  };

  const handleSend = function () {
    setProgress(1);
    sendFiles({ room, fileList, setProgress });
    clearFiles();
  };

  const handleCancel = function () {
    clearFiles();
  };

  return (
    <Grid
      {...props}
      container
      className={clsx(
        classes.root,
        fileList.length ? newClasses.root : null,
        className
      )}
    >
      {/*!progress ? null :
                <>
                    <Grid item xs={12}
                        className={classes.progress}>
                        <Typography>Uploading files...</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress
                            variant="determinate"
                            value={progress} />
                    </Grid>
                </>
            */}
      {!fileList.length ? null : (
        <Grid item xs={12}>
          <Mui.IconButton onClick={handleCancel}>
            <Icons.X />
          </Mui.IconButton>
        </Grid>
      )}
      <Grid
        item
        container
        className={
          fileList.length ? clsx(classes.files, newClasses.files) : null
        }
      >
        {renderFiles()}
        {fileList.length ? (
          <FilesInput
            disabled={false}
            color="primary"
            inputProps={{
              multiple: true,
              onChange: (event) => {
                const { files } = event.target;
                setNewFiles(files);
                event.target.value = "";
              },
            }}
            component={Grid}
          >
            <Mui.Button
              variant="contained"
              color="primary"
              className={classes.uploadButton}
            >
              <Icons.Plus />
            </Mui.Button>
          </FilesInput>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Files;

Files.propTypes = {
  files: function (props, propName, componentName) {
    const isIterable = function (obj) {
      if (!obj) return false;
      return typeof obj[Symbol.iterator] === "function";
    };
    if (!isIterable(props[propName])) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected iterable object.`
      );
    }
  },
  classes: PropTypes.object,
  className: PropTypes.string,
  room: PropTypes.object,
  sendFiles: PropTypes.func.isRequired,
  setFiles: PropTypes.func.isRequired,
};
