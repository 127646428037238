import { createSlice } from "@reduxjs/toolkit";
import messagesInitialState from "./initialState";
import * as reducers from "./reducers";

const slice = createSlice({
  name: "messages",
  initialState: messagesInitialState,
  reducers,
});

const { reducer, actions } = slice;

export default reducer;
export const {
  resetMessages,
  setMessages,
  addMessage,
  addToMessageList,
  removeMessage,
  updateMessage,
} = actions;
