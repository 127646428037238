import { put, call, race, take, delay } from "redux-saga/effects";
import * as actions from "XmarsStore/actions";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";

export const jitsiHangSaga = function* () {
  try {
    yield put(actions.endLocalVideoTrack());
    yield put(actions.endLocalAudioTrack());
    const { timeout } = yield race({
      audioTrackEnded: take(actions.localAudioTrackRemoved),
      timeout: delay(5000),
    });
    if (timeout) {
      console.log("Dispose local audio track timed out");
    }
    yield put(actions.jitsiEndConference());
    yield put(actions.cleanConferenceRequest());
    yield call(console.log, "finished jitsi cleanup");
  } catch (error) {
    yield call(handleSagaError, error);
  }
};
