import * as gql from "../gql";

import { downloadBlob, customFetch, wrappedFetch } from "../../utils";

// Mutations
// ------------------------------------------------------------------------------------------------------------------------
/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const sendFiles = async function (files, room_id, setProgress) {
  try {
    // Send files to storage
    setProgress(1);
    const formData = new FormData();
    for (const file of files) formData.append("files", file);
    const response = await customFetch("/files", {
      method: "post",
      body: formData,
      onUploadProgress({ loaded, total }) {
        const progress = Math.floor((100 * loaded) / total);
        setProgress(progress);
      },
    });
    const json = JSON.parse(response);
    // Send file links to room
    const messages = [];
    for (const file of json) {
      const message = await gql.sendMessage(
        "file",
        JSON.stringify(file),
        room_id
      );
      messages.push(message);
    }
    setProgress(0);
    return messages;
  } catch (error) {
    setProgress(0);
    throw error;
  }
};
// ------------------------------------------------------------------------------------------------------------------------
export const getFile = async function (file, setProgress) {
  try {
    setProgress(1);
    const response = await wrappedFetch(`/files/${file.fileName}`);
    const size = response.headers.get("Content-Length") || 0;
    const reader = response.body.getReader();
    const blobParts = [];
    let readSize = 0;
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      blobParts.push(value);
      readSize += value.byteLength;
      const progress = Math.floor((100 * readSize) / size);
      setProgress(progress);
    }
    const blob = new Blob(blobParts.flat());
    downloadBlob(blob, file.name);
    setTimeout(setProgress, 1000, 0);
  } catch (error) {
    setTimeout(setProgress, 500, 0);
    throw error;
  }
};
