import client from "../../../client";
import { addBearer } from "../../../utils";
import mutation from "./createFriendRoom.mutation";

/**
 * @param {string} userId
 * @param {string} categoryId
 * @returns {Promise<{
 *  id: string,
 *  group: boolean,
 *  title: string,
 *  admin: boolean,
 *  meetingCode: string,
 *  kind: string,
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 * }>}
 */
export const createFriendRoom = async function (userId, categoryId) {
  userId = addBearer(userId);
  categoryId = addBearer(categoryId);
  const { data } = await client.mutate({
    mutation,
    variables: {
      input: {
        userId,
        categoryId,
      },
    },
  });
  return data.createFriendRoom;
};

export default createFriendRoom;
