import { gql } from "../../../utils/apollo";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const searchPageAndSection = gql`
  query SearchPageAndSection(
    $searchValue: String
    $page: Int
    $section: [String]
  ) {
    searchFromPanel(
      input: { searchValue: $searchValue, page: $page, section: $section }
    ) {
      messages {
        id
        text
        createdAt
        messageType
        edited
        owner {
          avatar
          id
          username
        }
        room {
          id
          title
          group
        }
        replyingTo {
          id
          createdAt
        }
      }
      files {
        id
        text
        createdAt
        messageType
        owner {
          avatar
          id
          username
        }
        room {
          id
          title
          group
        }
        replyingTo {
          id
          createdAt
        }
      }
      groups {
        id
        title
        users {
          id
        }
      }
      contacts {
        id
      }
    }
  }
`;

export default searchPageAndSection;
