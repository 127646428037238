import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import MessageEditForm from "./MessageEditForm.component";

import {
  editMessage,
  setIsEditingMessage,
  setDraft,
  setEditingMessage,
  saveDraft,
} from "../../../store/actions/index";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const { editingMessage, draft } = state.chat;
  return {
    message: editingMessage,
    value: draft,
    room,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editMessage,
      setIsEditing: setIsEditingMessage,
      setValue: setDraft,
      setEditingMessage,
      saveDraft,
    },
    dispatch
  );

const MessageEditFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageEditForm);

export default MessageEditFormContainer;
