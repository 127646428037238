import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import GuestAuthForm from "./GuestAuthForm.component";

import { guestSignIn } from "../../../store/actions/index";

const mapStateToProps = ({ general }) => ({ data: general.generic });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      guestSignIn,
    },
    dispatch
  );

const GuestAuthFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestAuthForm);

export default GuestAuthFormContainer;
