import { put, call } from "redux-saga/effects";

import * as actions from "XmarsStore/actions";

import { handleSagaError } from "XmarsStore/sagas/sagas.utils/handleSagaError";
import { startJitsiCallConference } from "../startJitsiCallConference";

import * as connectionStatus from "./statusConstants";

function* sagaOnConnected(payload) {
  try {
    yield put(actions.setConnectionStatus("active"));
    yield startJitsiCallConference(payload);
  } catch (error) {
    yield call(handleSagaError, error);
  }
}

export function* processConnectionSignal(payload) {
  const { signal, jitsiConnection, meetingCode } = payload;
  try {
    if (signal.connectionStatus === connectionStatus.CONNECTED) {
      yield sagaOnConnected({ jitsiConnection, meetingCode });
    }
    if (signal.connectionStatus === connectionStatus.FAILED) {
      yield put(
        actions.addSnackbar({
          severity: "error",
          message: `Connection ${signal.connectionStatus}: ${signal.message}`,
        })
      );
      yield put(actions.jitsiCallHang());
    }
    if (signal.connectionStatus === connectionStatus.DISCONNECTED) {
      yield put(actions.jitsiCallHang());
    }
    if (signal.connectionStatus === connectionStatus.WRONG_STATE) {
      yield put(
        actions.addSnackbar({
          severity: "info",
          message: `Connection ${signal.connectionStatus}: ${signal.message}`,
        })
      );
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}
