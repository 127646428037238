import { useState } from "react";
import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";

import { PasswordTextField, EmailTextField } from "../../inputs";
import { LinearSubmitButton, Divider } from "../../prestyledComponents";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";

import useStyles from "./LoginUserForm.styles";

import logo from "../../../assets/images/logo-sb.png";
import { useForm } from "../../../hooks/useForm";

const formName = Symbol("loginUser");
export const LoginUserForm = function ({ login, setPage, onSubmit }) {
  //TODO Change this to read from local state
  const [rememberCheck, setRememberCheck] = useState(true);
  const { submitting } = useForm(formName);
  const classes = useStyles();

  const handleSubmit = formSubmitHandler(async ({ email, password }, event) => {
    login({ email, password, formName });
    if (onSubmit) onSubmit(event);
  });

  const handleRegister = () => setPage("register");
  const handleForgot = () => setPage("requestRecovery");

  const handleRememberChange = () => setRememberCheck((check) => !check);

  return (
    <Mui.Paper variant="outlined" elevation={0} className={classes.formBox}>
      <Mui.Box className={classes.logo}>
        <img src={logo} alt="Sibatel logo" className={classes.logoImg} />
      </Mui.Box>
      <Mui.Typography
        variant="h5"
        align="center"
        gutterBottom
        className={classes.title}
      >
        Sign in
      </Mui.Typography>

      <Grid
        container
        direction="column"
        spacing={3}
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid item>
          <EmailTextField
            placeholder="Username or email"
            disabled={submitting}
            autoComplete="on"
          />
        </Grid>
        <Grid item>
          <PasswordTextField disabled={submitting} />
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={clsx(classes.removeVPadding, classes.checkboxRow)}
        >
          <Grid
            item
            component={Mui.FormControlLabel}
            label="Remember me"
            control={
              <Mui.Checkbox
                sx={{ pl: 0 }}
                checked={rememberCheck}
                onChange={handleRememberChange}
                color="primary"
              />
            }
            classes={{
              root: classes.checkboxLabelRoot,
              label: classes.checkboxLabel,
            }}
          ></Grid>

          <Grid
            component={Mui.Link}
            item
            alignSelf="center"
            color="inherit"
            variant="body1"
            onClick={handleForgot}
            className={clsx(classes.linkBox, classes.setHeight25, classes.link)}
          >
            Reset password
          </Grid>
        </Grid>
        <Grid item className={classes.removeVPadding}>
          <LinearSubmitButton
            loading={submitting}
            children="Sign in"
            className={classes.actionButton}
          />
        </Grid>
        <Grid item className={classes.removeVPadding}>
          <Divider />
        </Grid>
        <Grid
          item
          className={clsx(
            classes.linkBox,
            classes.removeVPadding,
            classes.addMarginBottom
          )}
        >
          <Mui.Typography>{"Don't have an account?"}</Mui.Typography>
        </Grid>
        <Grid item className={clsx(classes.linkBox, classes.removeTopPadding)}>
          <Mui.Link
            onClick={handleRegister}
            variant="button"
            className={classes.routeButton}
          >
            Register now!
          </Mui.Link>
        </Grid>
      </Grid>
    </Mui.Paper>
  );
};

export default LoginUserForm;

LoginUserForm.propTypes = {
  login: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};
