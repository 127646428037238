import React, { forwardRef, useRef } from "react";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import useStyles from "./MultilineInput.styles";

export const MultilineInput = forwardRef(function (
  { onKeyDown, autoFocus, inputRef, startComponent, ...props },
  externalRef
) {
  const classes = useStyles();

  const ref = useRef(null);

  const handleKeyDown = function (event) {
    if (!event.shiftKey && event.key === "Enter") {
      event.preventDefault();
      ref.current.click();
    }
    if (onKeyDown) onKeyDown(event);
  };

  return (
    <>
      <Mui.TextField
        {...props}
        autoFocus={autoFocus}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )
        }
        multiline
        rowsMax={4}
        onKeyDown={handleKeyDown}
        inputRef={externalRef}
        InputProps={{
          className: classes.input,
          notched: false,
          classes: {
            root: classes.inputRoot,
            focused: classes.inputFocused,
            notchedOutline: classes.inputNotchedOutline,
          },
          startAdornment: (
            <Mui.InputAdornment
              position="start"
              classes={{ root: classes.adornment }}
            >
              {startComponent}
            </Mui.InputAdornment>
          ),
        }}
        inputProps={{ className: classes.inputBase, ref: inputRef }}
      />
      <input hidden ref={ref} type="submit" />
    </>
  );
});

export default MultilineInput;

MultilineInput.propTypes = {
  onKeyDown: PropTypes.func,
};

MultilineInput.defaultProps = {
  name: "multiline",
  placeholder: "Multiline",
  rowsMax: 2,
  size: "small",
  variant: "outlined",
  required: true,
  fullWidth: true,
  autoComplete: "off",
};
