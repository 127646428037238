import client from "../../../client";
import query from "./subscribeToFriend.subscription";
import { addBearer } from "../../../utils";

/**
 * @typedef {Object} FriendSubscriptionEvent
 * @property {string} signalType
 * @property {string} online
 * @property {{
 *  id: string,
 *  group: string,
 *  title: string,
 *  meetingCode: string,
 * }} room
 * @property {{
 *  username: string,
 *  avatar: string,
 * }} user
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */

/**
 * @param {Object} args
 * @param {string} args.id
 * @param {Object} subcription
 * @param {(event: FriendSubscriptionEvent) => void} subcription.next
 * @param {(error: Error) => void} [subcription.error]
 */
export const subscribeToFriend = function (
  { id },
  { next, error = console.error }
) {
  id = addBearer(id);
  const observable = client.subscribe({
    query,
    variables: {
      id,
    },
  });
  return observable.subscribe({
    next({ data }) {
      next(data.subscribeToUser);
    },
    error,
  });
};

export default subscribeToFriend;
