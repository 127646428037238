import HandtrackModel from "./HandtrackModel";
import { tryOrLog } from "../../utils";

export class HandTracker {
  constructor(onPredict) {
    this.model = null;
    this.running = false;
    this.onPredict = onPredict;
  }

  // public
  async init(modelParams = {}) {
    const model = new HandtrackModel();
    await model.init(modelParams);
    this.model = model;
  }

  // public
  dispose() {
    this.stop();
    if (this.model) {
      this.model.dispose();
      this.model = null;
    }
    this.onPredict = null;
  }

  // public
  start(video) {
    this.running = true;
    const { height, width } = video.srcObject.getTracks()[0].getSettings();
    video.height = height;
    video.width = width;
    const predict = async () => {
      const { model } = this;
      if (!model) return;
      await tryOrLog(async () => {
        const predictions = await model.predict(video);
        this._handlePredictions(predictions, video);
      }, true);
    };
    const continuousPredict = async () => {
      if (!this.running) return;
      const predictedPromise = predict();
      const fps = (await this.model.getFPS()) || 1;
      if (fps)
        setTimeout(() => {
          predictedPromise.then(continuousPredict);
        }, 1000 / fps);
    };
    continuousPredict();
  }

  // public
  stop() {
    this.running = false;
  }

  // * private
  _handlePredictions(predictions, video) {
    if (!this.running) return;
    if (this.onPredict) {
      const hands = {
        open: [],
        closed: [],
        pinch: [],
        point: [],
        media: video,
      };
      for (const prediction of predictions) {
        switch (prediction.class) {
          case 1:
            hands.open.push(prediction);
            break;
          case 2:
            hands.closed.push(prediction);
            break;
          case 3:
            hands.pinch.push(prediction);
            break;
          case 4:
            hands.point.push(prediction);
            break;
          default:
            break;
        }
      }
      this.onPredict(hands);
    } else {
      console.warn("onPredict missing", predictions);
    }
  }
}

export default HandTracker;
