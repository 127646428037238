interface FormState {
  submitting: boolean;
  error: Object | null;
}
interface FormMap {
  [name: string]: FormState;
}

export const formInitialState: FormState = {
  submitting: false,
  error: null,
};

export default {} as FormMap;
