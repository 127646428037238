import client from "../../../client";
import mutation from "./login.mutation";

/**
 * @param {string} email
 * @param {string} password
 */
export const login = async function (email, password) {
  await client.mutate({
    mutation,
    variables: {
      email,
      password,
    },
  });
};

export default login;
