import React, { useRef, useState, useMemo, useEffect } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";

import { format } from "date-fns";
import clsx from "clsx";

import { stringAvatar } from "../../../utils/defaultAvatar/defaultAvatar";
import { MessageFileContent, MessageStatusIcon } from "../../pseudoItems";
import { useOnScreen, useMounted } from "../../../hooks/index";
import useStyles from "./Reply.styles";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

const MessageMenu = React.memo(function ({ contextMenu, onClose, children }) {
  return (
    <Mui.Menu
      open={contextMenu !== null}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      {children}
    </Mui.Menu>
  );
});

/**
 * Individual reply.
 * @component
 */
export const Reply = function ({
  containerRef,
  deleteReply,
  user,
  message,
  position,
  header,
  readMessage,
  repliedMessage,
  setIsEditingReply,
  setEditingReply,
  setReplyToJumpTo,
  shouldScrollIntoView,
  ...props
}) {
  const classes = useStyles();
  const mounted = useMounted(false);
  const ref = useRef(false);
  const onScreen = useOnScreen(ref);

  const [showingDotsButton, setShowingDotsButton] = useState(false);
  const [readTimeout, setReadTimeout] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [highlighted, setHighlighted] = useState(false);

  const username = message?.owner?.username;
  const own = username === "You";

  const shouldRead = useMemo(
    () =>
      message.owner.username !== "You" &&
      (message.status !== "read" ||
        (message.status === "read" &&
          !message.readers.some((reader) => reader.id === user.id))),
    // eslint-disable-next-line
    [message]
  );

  useEffect(() => {
    if (shouldRead) {
      if (!readTimeout) {
        if (onScreen) {
          const timeout = setTimeout(() => {
            if (mounted.current)
              readMessage({ reply: message, message: repliedMessage });
          }, 8);
          setReadTimeout(timeout);
        }
      } else {
        if (!onScreen) {
          clearTimeout(readTimeout);
          setReadTimeout(null);
        }
      }
    }
    // eslint-disable-next-line
  }, [onScreen]);

  useEffect(() => {
    if (shouldScrollIntoView) {
      if (!onScreen) {
        containerRef.current.scrollTop = ref.current.offsetTop || 10;
      }
      setReplyToJumpTo(null);
      setHighlighted(true);
      setTimeout(() => {
        if (mounted.current) setHighlighted(false);
      }, 3000);
    }
  }, [shouldScrollIntoView, onScreen]);

  let dateTime;
  if (message && message.createdAt) {
    dateTime = format(message.createdAt, "hh:mm a");
  }
  /**
   * Organize all the classes that correspond to the current message.
   * @returns {string} The classes corresponding to the current message.
   */
  const getContentClassName = function () {
    const clsxParams = [classes.item];
    if (message.messageType !== "scribble") {
      if (own) {
        clsxParams.push(classes.ownText);
      } else {
        clsxParams.push(classes.otherText);
      }

      if (header) clsxParams.push(classes.headerReply);
    }

    return clsx(...clsxParams);
  };

  const handleMouseMove = () => {
    if (header) return;
    setShowingDotsButton(true);
  };

  const handleMouseLeave = () => setShowingDotsButton(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    if (header) return;
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleOpenEditing = function () {
    if (message.owner.username !== "You") return;

    setIsEditingReply(true);
    setEditingReply(message);
    setContextMenu(null);
    setShowingDotsButton(false);
  };

  /**
   * Renders the contend depending on the message type.
   * @returns {React.ReactElement} - The content
   */
  const renderContent = function () {
    switch (message.messageType) {
      case "scribble":
        return (
          <Mui.List classes={{ padding: classes.messageContentPadding }}>
            <Mui.ListItem className={getContentClassName()}>
              <img
                alt="scribble"
                src={message.scribble}
                className={classes.scribble}
              />
              <div style={{ minWidth: "1em" }} />
            </Mui.ListItem>
            {showingDotsButton && (
              <Mui.IconButton
                className={classes.scribbleButton}
                onClick={handleContextMenu}
              >
                <Icons.MoreVertical />
              </Mui.IconButton>
            )}
          </Mui.List>
        );
      case "file":
        return <MessageFileContent message={message} header={header} />;
      default:
        const messageText = (
          <>
            {message.text}
            {"  "}
            {message.edited && (
              <span className={classes.editedLabel}>(edited)</span>
            )}
          </>
        );
        return (
          <Mui.List classes={{ padding: classes.messageContentPadding }}>
            <Mui.ListItem className={getContentClassName()}>
              <Mui.ListItemText
                primary={messageText}
                primaryTypographyProps={{ className: classes.text }}
                className={classes.textMessageBody}
                onDoubleClick={handleOpenEditing}
              />
              {showingDotsButton && (
                <Mui.IconButton
                  className={classes.messageMenu}
                  onClick={handleContextMenu}
                >
                  <Icons.MoreVertical
                  // classes={{ root: classes.messageMenuIcon }}
                  />
                </Mui.IconButton>
              )}
              {(position === "end" || position === "unique") && !header
                ? renderMessageStatusIcon()
                : null}
              {messageMenu}
            </Mui.ListItem>
          </Mui.List>
        );
    }
  };

  /**
   * Return the status icon. The status can be send and received.
   * @returns {React.ReactElement|null} Icon that shows the status.
   */
  const renderMessageStatusIcon = function () {
    return !own ? null : <MessageStatusIcon message={message} inReplies />;
  };

  const handleDelete = async function () {
    if (message.owner.username !== "You") return;

    try {
      await getBoundActionPromise(deleteReply, {
        reply: message,
        repliedMessage,
      });
    } catch (error) {
      logError(error);
    } finally {
      if (mounted.current) {
        setContextMenu(null);
        setShowingDotsButton(false);
      }
    }
  };

  const handleClose = () => {
    setContextMenu(null);
    setShowingDotsButton(false);
  };

  const messageMenu = (
    <MessageMenu contextMenu={contextMenu} onClose={handleClose}>
      {message.owner.username !== "You" && (
        <Mui.MenuItem classes={{ root: classes.conversationMenuOptionItem }}>
          <Mui.ListItemIcon
            classes={{ root: classes.conversationMenuOptionIcon }}
          >
            <Icons.Check />
          </Mui.ListItemIcon>
          <Mui.ListItemText
            primary="Mark as Unread"
            disableTypography
            className={classes.conversationMenuOptionText}
          />
        </Mui.MenuItem>
      )}
      {message.owner.username === "You" && (
        <Mui.MenuItem
          onClick={handleOpenEditing}
          classes={{ root: classes.conversationMenuOptionItem }}
        >
          <Mui.ListItemIcon
            classes={{ root: classes.conversationMenuOptionIcon }}
          >
            <Icons.Tag />
          </Mui.ListItemIcon>
          <Mui.ListItemText
            primary="Edit"
            disableTypography
            className={classes.conversationMenuOptionText}
          />
        </Mui.MenuItem>
      )}

      <Mui.MenuItem classes={{ root: classes.conversationMenuOptionItem }}>
        <Mui.ListItemIcon
          classes={{ root: classes.conversationMenuOptionIcon }}
        >
          <Icons.Share />
        </Mui.ListItemIcon>
        <Mui.ListItemText
          primary="Forward Message"
          disableTypography
          className={classes.conversationMenuOptionText}
        />
      </Mui.MenuItem>
      <Mui.MenuItem classes={{ root: classes.conversationMenuOptionItem }}>
        <Mui.ListItemIcon
          classes={{ root: classes.conversationMenuOptionIcon }}
        >
          <Icons.Flag />
        </Mui.ListItemIcon>
        <Mui.ListItemText
          primary="Pin Message"
          disableTypography
          className={classes.conversationMenuOptionText}
        />
      </Mui.MenuItem>
      <Mui.MenuItem classes={{ root: classes.conversationMenuOptionItem }}>
        <Mui.ListItemIcon
          classes={{ root: classes.conversationMenuOptionIcon }}
        >
          <Icons.File />
        </Mui.ListItemIcon>
        <Mui.ListItemText
          primary="Message Reminder"
          disableTypography
          className={classes.conversationMenuOptionText}
        />
      </Mui.MenuItem>
      {message.owner.username === "You" && (
        <Mui.MenuItem
          onClick={handleDelete}
          classes={{ root: classes.conversationMenuOptionItem }}
        >
          <Mui.ListItemIcon
            classes={{ root: classes.conversationMenuOptionIcon }}
          >
            <Icons.Trash />
          </Mui.ListItemIcon>
          <Mui.ListItemText
            primary="Delete Message"
            disableTypography
            className={classes.conversationMenuOptionText}
          />
        </Mui.MenuItem>
      )}
    </MessageMenu>
  );

  return (
    <Mui.ListItem
      className={own ? classes.ownMessage : classes.otherMessage}
      ref={ref}
      // innerRef={ref}
      {...props}
    >
      <Mui.List
        className={classes.content}
        onMouseEnter={handleMouseMove}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onContextMenu={handleContextMenu}
      >
        <Mui.ListItem
          className={clsx(
            classes.item,
            own ? classes.ownMessageItem : null,
            classes.messageInfo,
            position === "middle" && classes.middle,
            position === "start" && classes.start,
            position === "end" && classes.end,
            position === "unique" && classes.unique
          )}
        >
          <Mui.ListItemAvatar
            classes={{
              root: clsx(
                classes.avatar,
                position !== "start" && position !== "unique" && classes.hidden
              ),
            }}
          >
            <Mui.Avatar
              src={message?.owner?.avatar}
              classes={{ root: classes.avatar }}
              {...stringAvatar(own ? user.username : username)}
            />
          </Mui.ListItemAvatar>

          <Mui.Box>
            {(position === "start" || position === "unique") && (
              <Mui.Box className={classes.messageHeader}>
                {own ? user.username : username},{" "}
                <span className={classes.HeaderDate}>{dateTime}</span>
              </Mui.Box>
            )}
            <Mui.Box
              className={clsx(
                classes.secondLevel,
                highlighted ? classes.messageHighlighted : null
              )}
            >
              {renderContent()}
            </Mui.Box>
          </Mui.Box>
        </Mui.ListItem>
      </Mui.List>
    </Mui.ListItem>
  );
};

export default Reply;
