import client from "../../../client";
import mutation from "./startSharingScreen.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 */
export const startSharingScreen = async function (
  channelId,
  workspaceId,
  sharingUserId
) {
  channelId = addBearer(channelId);
  workspaceId = addBearer(workspaceId);
  await client.mutate({
    mutation,
    variables: {
      channelId,
      workspaceId,
      sharingUserId,
    },
  });
};

export default startSharingScreen;
