import client from "../../../client";
import mutation from "./replyMessage.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {String} message_id
 * @param {String} text
 */
export const replyMessage = async function (message_id, text) {
  message_id = addBearer(message_id);
  await client.mutate({
    mutation,
    variables: {
      message_id,
      text,
    },
  });
};

export default replyMessage;
