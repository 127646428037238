import * as Mui from "@mui/material";

import useStyles from "./InstantMeetingHeaderButton.styles";

export const InstantMeetingHeaderButton = () => {
  const classes = useStyles();

  return (
    <Mui.Button variant="outlined" className={classes.instMeetButton}>
      Instant Meeting
    </Mui.Button>
  );
};

export default InstantMeetingHeaderButton;
