import React, { useEffect, useRef, useState } from "react";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";
import { VerticalScrollbars } from "../../prestyledComponents";

import useStyles from "./Messages.styles";
//import { useScroll } from "@react-hooks-library/core";

import {
  useAutoScroll,
  useDependency,
  useOnLoadImages,
} from "../../../hooks/index";

import { renderMessages } from "./renderMessages";

/**
 *  Messages of the current chat.
 *  @component
 *  @param {Object} room - Contains the information of the current room.
 *  @param {array} messages - Contains the obj. messages or Symbol(loading): true.
 *  @param {function} getMessages - Action to obtain and the messages of the current room.
 *  @param {function} setMessages - Action to set the messages of the current room, here it is used to clean messages from the previous room.
 *  @param {Object} props - Style properties that will be sent to the VerticalScrollbars component.
 *  @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 *  Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 *  still useful. If this function is still relevant, rename its identifiers using the
 *  proper entities and remove this deprecation warning
 */
export const Messages = function ({
  isDrawer = false,
  room,
  activeChatRoomId,
  messages,
  getMessages,
  getMessagesUpToDate,
  setMessages,
  isLoadingMessages,
  saveDraft,
  setIsLoadingMessages,
  getDraft,
  user,
  setIsEditing,
  isEditing,
  editingMessage,
  messageToJumpTo,
  setMessageToJumpTo,
  ...props
}) {
  const ref = useRef(null);
  const [handleTopScroll, onTopScroll] = useDependency();
  const { scrollToBottom, autoScroll, scrollToAlmostTop } = useAutoScroll(ref, {
    onTopScroll: handleTopScroll,
  });

  const imagesLoaded = useOnLoadImages(ref);

  const [pagination, setPagination] = useState(10);
  const [oldestUnreadDate, setOldestUnreadDate] = useState(
    room.dateOldestUnreadMessage
  );

  const classes = useStyles();

  useEffect(() => {
    const emptyMessages = [];
    emptyMessages[Symbol.for("loading")] = true;

    setMessages(emptyMessages);
    //setPagination(10);
    getMessages({
      roomId: activeChatRoomId,
      pagination: 0,
      gettingUnread: true,
    });

    // setOldestUnreadDate(room.dateOldestUnreadMessage);

    scrollToBottom();
    return () => {
      saveDraft({ roomId: activeChatRoomId });
      setIsEditing(false);
    };
    // eslint-disable-next-line
  }, [activeChatRoomId]);

  useEffect(() => {
    setPagination(messages.length);
  }, [messages.length]);

  useEffect(() => {
    if (onTopScroll) {
      getMessages({ roomId: activeChatRoomId, pagination });
      //setPagination(pagination + 10);

      setIsLoadingMessages(true);
    }

    // eslint-disable-next-line
  }, [activeChatRoomId, onTopScroll]);

  useEffect(() => {
    if (imagesLoaded) {
      const lastMessage = messages[messages.length - 1];
      const ownLastMessage =
        lastMessage &&
        lastMessage.owner &&
        lastMessage.owner.username === "You" &&
        lastMessage.createdAt > Date.now() - 1000;

      if (ownLastMessage) {
        scrollToBottom();
      } else if (
        oldestUnreadDate &&
        room.dateOldestUnreadMessage &&
        oldestUnreadDate >= room.dateOldestUnreadMessage
      ) {
        scrollToAlmostTop();
      } else {
        autoScroll();
      }
    }

    // eslint-disable-next-line
  }, [messages, ref?.current?.scrollHeight, imagesLoaded]);
  //});

  useEffect(() => {
    if (messageToJumpTo) {
      if (messageToJumpTo.beforeFetch) {
        if (!messages.some((m) => m.id === messageToJumpTo.id)) {
          getMessagesUpToDate({
            room,
            pagination,
            message: {
              id: messageToJumpTo.id,
              messageDate: new Date(messageToJumpTo.messageDate),
            },
          });
          setIsLoadingMessages(true);
        } else {
          setMessageToJumpTo({
            beforeFetch: false,
            id: messageToJumpTo.id,
            count: 0,
          });
        }
      } else {
        //setPagination((p) => p + messageToJumpTo.count);
      }
    }
  }, [messageToJumpTo]);

  return (
    <Mui.Box>
      {(isLoadingMessages || messages[Symbol.for("loading")]) && (
        <Mui.LinearProgress classes={{ root: classes.linearProgress }} />
      )}
      <VerticalScrollbars
        viewStyle={{ padding: "30px" }}
        {...props}
        ref={(s) => (ref.current = s && s.view)}
      >
        <Mui.List className={classes.messagesContainer}>
          {renderMessages({ messages, classes, ref, isDrawer })}
        </Mui.List>
      </VerticalScrollbars>
    </Mui.Box>
  );
};

export default Messages;

Messages.propTypes = {
  room: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  setMessages: PropTypes.func.isRequired,
  getMessages: PropTypes.func.isRequired,
};
