import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Typography from "@mui/material/Typography";
import * as Icons from "react-feather";

import PropTypes from "prop-types";

import { stringAvatar } from "../../utils/defaultAvatar/defaultAvatar";
import { VerticalScrollbars } from "../prestyledComponents";

import style from "./style";

import useStyles from "./AddUsersToRoom.styles";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const AddUsersToRoom = function ({
  rooms,
  checked,
  handleToggle,
  currentRoom,
  filter,
}) {
  const classes = useStyles();

  /**
   * Separates the contacts that already belong to the group.
   * @parameter {array} contacts
   */
  function separateGroups(contacts) {
    const groupUsers = !currentRoom
      ? []
      : currentRoom.users?.map((user) => user.id);

    const contactsInTheGroup = contacts.filter((contact) => {
      return !groupUsers?.includes(contact.id);
    });
    const contactsNotInTheGroup = contacts.filter((contact) => {
      return groupUsers?.includes(contact.id);
    });
    return [contactsInTheGroup, contactsNotInTheGroup];
  }

  const renderGroups = () => {
    if (rooms.length) {
      //const regex = RegExp(filter, "i");
      const uncaseFilter = filter.toLowerCase();
      let shownGroups = [...rooms];
      if (filter) {
        //shownGroups = shownGroups.filter((group) => regex.test(group.username));
        shownGroups = shownGroups.filter((group) =>
          group.username.toLowerCase().includes(uncaseFilter)
        );
      }

      let [contactsInTheGroup, contactsNotInTheGroup] =
        separateGroups(shownGroups);

      if (shownGroups.length)
        return (
          <>
            {contactsInTheGroup.map((group) => {
              const toggleHandler = handleToggle(group.id);
              return (
                <ListItem
                  key={group.id}
                  button
                  selected={false}
                  sx={style.item}
                  classes={{
                    root: classes.listItemRoot,
                    selected: classes.listItemSelected,
                  }}
                  onClick={toggleHandler}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={group.avatar}
                      {...stringAvatar(group.username)}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={group.username}
                    primaryTypographyProps={{
                      className: classes.primaryText,
                    }}
                    sx={style.itemText}
                  />
                  <ListItemSecondaryAction
                    classes={{ root: classes.secondaryAction }}
                  >
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={toggleHandler}
                      checked={checked.indexOf(group.id) !== -1}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
            {contactsNotInTheGroup.map((group) => (
              <ListItem
                key={group.id}
                button
                disabled
                selected={false}
                sx={style.item}
                classes={{
                  root: classes.listItemRoot,
                  selected: classes.listItemSelected,
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={group.avatar}
                    {...stringAvatar(group.username)}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={group.username}
                  primaryTypographyProps={{
                    className: classes.primaryText,
                  }}
                  sx={style.itemText}
                />
                <ListItemSecondaryAction
                  classes={{ root: classes.secondaryAction }}
                >
                  <Typography variant="subtitle2" sx={style.alreadyMember}>
                    already in the group
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </>
        );
      return (
        <ListItem>
          <ListItemIcon>
            <Icons.Search />
          </ListItemIcon>
          <ListItemText primary={`No match for the search "${filter}"`} />
        </ListItem>
      );
    }

    return (
      <ListItem>
        <ListItemText primary={"You don't have any contact yet"} />
      </ListItem>
    );
  };

  return (
    <Box sx={style.usersContainer}>
      <VerticalScrollbars style={{ height: 300 }} alwaysVisible={true}>
        <List style={{ paddingTop: 0, paddingBottom: 30 }} sx={style.content}>
          {renderGroups()}
        </List>
      </VerticalScrollbars>
    </Box>
  );
};

export default AddUsersToRoom;

AddUsersToRoom.propTypes = {
  user: PropTypes.object,
};
