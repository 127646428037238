// eslint-disable-next-line
import { BoundingBoxType, bboxType } from "./handtracking.typeDefs";

/**
 * @param {bboxType} bbox
 * @returns {BoundingBoxType}
 */
export const parseBoundingBox = function (bbox) {
  const [left, top, width, height] = bbox;
  const right = left + width;
  const bottom = top + height;
  const x = left + width * 0.5;
  const y = top + height * 0.5;
  return {
    x,
    y,
    width,
    height,
    left,
    right,
    top,
    bottom,
  };
};

export default parseBoundingBox;
