import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { SearchedFriend } from "../../collectionItems";

export const SearchedFriends = function ({ friends, resetSearchedUsers }) {
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    resetSearchedUsers();
    // eslint-disable-next-line
  }, []);

  const getOnClickHandler = (index) => () => setSelected(index);

  return (
    <Mui.List>
      {friends.length ? (
        friends.map((friend, index) => (
          <SearchedFriend
            key={index}
            friend={friend}
            selected={index === selected}
            onClick={getOnClickHandler(index)}
          />
        ))
      ) : (
        <Mui.ListItem>
          <Mui.ListItemText primary={"No match"} />
        </Mui.ListItem>
      )}
    </Mui.List>
  );
};

export default SearchedFriends;

SearchedFriends.propTypes = {
  friends: PropTypes.array.isRequired,
  resetSearchedUsers: PropTypes.func.isRequired,
};
