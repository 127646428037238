import { connect } from "react-redux";

import ChatsPanelButton from "./ChatsPanelButton.component";

const mapStateToProps = (state) => {
  const { rooms } = state.chat;
  const totalUnreadMessages = rooms.reduce((prevValue, currRoom) => {
    const currValue = currRoom.unreadMessages?.total || 0;
    return prevValue + currValue;
  }, 0);
  return { totalUnreadMessages };
};
const mapDispatchToProps = () => ({});

const ChatsPanelButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatsPanelButton);

export default ChatsPanelButtonContainer;
