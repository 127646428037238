import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import * as reducers from "./reducers";

const slice = createSlice({
  name: "jitsi",
  initialState,
  reducers,
});

const { reducer, actions } = slice;

export default reducer;
export const {
  resetLocalParticipant,
  setLocalParticipant,
  updateLocalParticipant,
  addLocalTrack,
  removeLocalTrack,

  resetRemoteParticipants,
  setRemoteParticipants,
  addRemoteParticipant,
  removeRemoteParticipant,
  updateRemoteParticipant,
  mapRemoteParticipants,
  addRemoteTrack,
  removeRemoteTrack,
  addParticipantMessage,
  removeParticipantMessage,

  getConference,
  setConference,
  setConferenceStatus,
  setConnection,
  setConnectionStatus,

  resetVideoEffects,
  setVideoEffects,
  updateVideoEffects,

  resetVideoGrid,
  setVideoGrid,

  setSharedScreen,
  setScreenConnection,
  setScreenTrack,

  setLocalAudioVolume,
  setLocalVideoTrackReady,
  setLocalAudioTrackReady,
  setLocalAudioIsMuted,
  setLocalVideoIsMuted,
} = actions;
