import {
  registerEventHandlers,
  unregisterEventHandlers,
} from "XmarsJitsiMeetJS/index";

import { groupLog } from "XmarsUtils/groupLog";
import { eventChannel } from "redux-saga";
import { createConferenceHandlers } from "./createConferenceHandlers";

export function jitsiConferenceChannel(jitsiConference) {
  const jitsiConferenceSubscription = (emitter) => {
    const conferenceHandlers = createConferenceHandlers(emitter);
    registerEventHandlers("conference", jitsiConference, conferenceHandlers);

    return async () => {
      groupLog("Ending conference channel");
      unregisterEventHandlers(
        "conference",
        jitsiConference,
        conferenceHandlers
      );
      try {
        if (jitsiConference.getParticipants().length !== 0) {
          await jitsiConference.leave();
        }
      } catch (error) {
        console.log(error);
      }
    };
  };
  return eventChannel(jitsiConferenceSubscription);
}
