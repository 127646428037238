import jitsiInitialState from "./initialState";

export const resetLocalParticipant = (state) => {
  state.localParticipant = jitsiInitialState.localParticipant;
};

export const setLocalParticipant = (state, { payload }) => {
  state.localParticipant = payload;
};

export const updateLocalParticipant = (state, { payload }) => {
  state.localParticipant = { ...state.localParticipant, ...payload };
};

export const addLocalTrack = (state, { payload }) => {
  state.localParticipant[payload.getType()] = payload;
};

export const removeLocalTrack = (state, { payload }) => {
  state.localParticipant[payload.getType()] = null;
};
