import React, { useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";
import AddUsersToGroupButton from "../AddUsersToGroupButton";

import { TooltipIconButton } from "../../prestyledComponents";
import { RoomInfoButton, LeaveRoomButton } from "../";
import useStyles from "./MoreHeaderButton.styles";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const MoreHeaderButton = function ({
  room,
  group,
  openProfile,
  spanProps,
  setRepliesPanelOpen,
  setUnreadRepliesPanelOpen,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClose = () => setAnchorEl(null);

  const handleUnreadReplies = () => {
    console.log("SEE ALL THREADS OPTION");
    handleClose();
    setRepliesPanelOpen(false);
    setUnreadRepliesPanelOpen(true);
  };

  const handleClick = (event) => setAnchorEl(event.target);

  const handleShowProfile = () => {
    openProfile(room.friend);
    handleClose();
  };
  const handleBlock = () => {};
  return (
    <span {...spanProps}>
      <TooltipIconButton
        {...props}
        title="More"
        className={classes.iconButton}
        onClick={handleClick}
        addMarginTop={true}
      >
        <Icons.MoreHorizontal />
      </TooltipIconButton>
      <Mui.Menu
        keepMounted
        id="simple-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {group
          ? [
              <RoomInfoButton
                key="1"
                color="primary"
                afterAction={handleClose}
              />,
              //room.admin ? <InviteToRoomButton key="2" color="primary" afterAction={handleClose} /> : null,
              <Mui.Divider key="3" />,
              <LeaveRoomButton
                key="4"
                color="secondary"
                afterAction={handleClose}
              />,
            ]
          : [
              //             <Mui.ListItem key="1" button>
              //                 {/* <Mui.ListItemIcon>
              //   <Mui.IconButton color="primary">
              //     <Icons.Slash />
              //   </Mui.IconButton>
              // </Mui.ListItemIcon> */}

              //                 {/* <Mui.ListItemText
              //     primary={"Add Participants"}
              //     primaryTypographyProps={{ color: "secondary" }}
              //   /> */}
              //             </Mui.ListItem>,
              <Mui.ListItem key="1">
                <AddUsersToGroupButton isInList="true" />
              </Mui.ListItem>,
              <Mui.ListItem key="1" button>
                <Mui.ListItemText primary={"See All Participants"} />
              </Mui.ListItem>,
              <Mui.ListItem key="1" button>
                <Mui.ListItemText
                  primary={"See All Threads"}
                  onClick={handleUnreadReplies}
                />
              </Mui.ListItem>,
              <Mui.ListItem key="1" button>
                <Mui.ListItemText primary={"See All Pinned Messages"} />
              </Mui.ListItem>,
              <Mui.ListItem key="1" button>
                <Mui.ListItemText primary={"Mute Channel"} />
              </Mui.ListItem>,
              <Mui.ListItem key="1" button>
                <Mui.ListItemText primary={"Hide Channel"} />
              </Mui.ListItem>,
              <Mui.ListItem key="1" button>
                <Mui.ListItemText primary={"Archive Channel"} />
              </Mui.ListItem>,
              <Mui.ListItem key="1" button>
                <Mui.ListItemText primary={"Leave Channel"} />
              </Mui.ListItem>,
              <Mui.ListItem key="1" button>
                <Mui.ListItemText primary={"Delete Channel"} />
              </Mui.ListItem>,
              <Mui.ListItem key="1" button>
                <Mui.ListItemText primary={"Edit Channel"} />
              </Mui.ListItem>,
            ]}
      </Mui.Menu>
    </span>
  );
};

export default MoreHeaderButton;

MoreHeaderButton.propTypes = {
  room: PropTypes.object,
};
