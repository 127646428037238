import client from "../../../client";
import { addBearer } from "../../../utils";
import mutation from "./createCustomRoom.mutation";

/**
 * @param {string} title
 * @param {string} categoryId
 * @param {boolean}  isPublic
 * @returns {Promise<{
 *  id: string,
 *  group: boolean,
 *  title: string,
 *  admin: boolean,
 *  meetingCode: string,
 *  kind: string,
 * }>}
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const createCustomRoom = async function (title, categoryId, isPublic) {
  categoryId = addBearer(categoryId);
  // console.log("cutomRoom mutation on fronend")
  const { data } = await client.mutate({
    mutation,
    variables: {
      input: {
        title,
        categoryId,
        isPublic,
      },
    },
  });
  return data.createCustomRoom;
};

export default createCustomRoom;
