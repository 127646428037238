import React, { useRef, useState, useMemo, useEffect } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import { CornerLeftUp as FeatherCornerLeftUp } from "react-feather";

import { format } from "date-fns";
import clsx from "clsx";

import { stringAvatar } from "../../../utils/defaultAvatar/defaultAvatar";
import { MessageStatusIcon } from "../../pseudoItems";
import { useOnScreen, useMounted } from "../../../hooks/index";
import { NumberOfRepliesButton } from "../../buttons";
import useStyles from "./MessageWithReplies.styles";
import UnreadReplies from "../../UnreadReplies/UnreadReplies.component";

/**
 * Menu to reply a message.
 * @component
 */
const MessageMenu = React.memo(function ({ contextMenu, onClose, children }) {
  return (
    <Mui.Menu
      open={contextMenu !== null}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      {children}
    </Mui.Menu>
  );
});

/**
 * Individual message with unread replies.
 * @component
 */
export const MessageWithReplies = function ({
  user,
  message,
  position,
  header,
  readMessage,
  setMessageToJumpTo,
  openReplies,
  ...props
}) {
  const classes = useStyles();
  const mounted = useMounted(false);
  const ref = useRef(false);
  const onScreen = useOnScreen(ref);
  const [hasReplies, setHasReplies] = useState(false);

  const [readTimeout, setReadTimeout] = useState(false);
  const [showingDotsButton, setShowingDotsButton] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const username = message?.owner?.username;
  const own = username === "You";

  useEffect(() => {
    if (message.replies && message.replies.length > 0) {
      setHasReplies(true);
    } else {
      setHasReplies(false);
    }
  }, [message?.replies]);

  const shouldRead = useMemo(
    () =>
      message.owner.username !== "You" &&
      (message.status !== "read" ||
        (message.status === "read" &&
          !message.readers.some((reader) => reader.id === user.id))),
    // eslint-disable-next-line
    [message]
  );

  useEffect(() => {
    if (shouldRead) {
      if (!readTimeout) {
        if (onScreen) {
          const timeout = setTimeout(() => {
            if (mounted.current) readMessage({ message });
          }, 2000);
          setReadTimeout(timeout);
        }
      } else {
        if (!onScreen) {
          clearTimeout(readTimeout);
          setReadTimeout(null);
        }
      }
    }
    // eslint-disable-next-line
  }, [onScreen]);

  let dateTime;
  if (message && message.createdAt) {
    dateTime = format(message.createdAt, "hh:mm a");
  }

  const handleMouseEnter = () => {
    setShowingDotsButton(true);
  };
  const handleMouseLeave = () => setShowingDotsButton(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const jumpToMessage = () => {
    setContextMenu(null);
    setMessageToJumpTo({
      beforeFetch: true,
      id: message.id,
      messageDate: message.createdAt,
    });
  };

  const handleDoubleClick = (e) => {
    e.preventDefault();
    jumpToMessage();
  };

  /**
   * Organize all the classes that correspond to the current message.
   * @returns {string} The classes corresponding to the current message.
   */
  const getContentClassName = function () {
    const clsxParams = [classes.item];
    if (message.messageType !== "scribble") {
      if (own) {
        clsxParams.push(classes.ownText);
      } else {
        clsxParams.push(classes.otherText);
      }

      clsxParams.push(classes.headerReply);
    }

    return clsx(...clsxParams);
  };

  /**
   * Renders the contend depending on the message type.
   * @returns {React.ReactElement} - The content
   */
  const renderContent = function () {
    switch (message.messageType) {
      case "scribble":
        return (
          <Mui.List classes={{ padding: classes.messageContentPadding }}>
            <Mui.ListItem className={getContentClassName()}>
              <img
                alt="scribble"
                src={message.scribble}
                className={classes.scribble}
                onDoubleClick={handleDoubleClick}
              />
              <div style={{ minWidth: "1em" }} />
            </Mui.ListItem>
            {showingDotsButton && (
              <Mui.IconButton
                className={classes.messageMenu}
                onClick={handleContextMenu}
              >
                <Icons.MoreVertical
                  classes={{ root: classes.messageMenuIcon }}
                />
              </Mui.IconButton>
            )}
          </Mui.List>
        );
      default:
        const messageText = message.text;
        return (
          <Mui.List classes={{ padding: classes.messageContentPadding }}>
            <Mui.ListItem className={getContentClassName()}>
              <Mui.ListItemText
                primary={messageText}
                primaryTypographyProps={{
                  className: classes.text,
                }}
                className={classes.textMessageBody}
                onDoubleClick={handleDoubleClick}
              />
              {showingDotsButton && (
                <Mui.IconButton
                  className={classes.messageMenu}
                  onClick={handleContextMenu}
                >
                  <Icons.MoreVertical
                    classes={{ root: classes.messageMenuIcon }}
                  />
                </Mui.IconButton>
              )}
              {(position === "end" || position === "unique") && !header
                ? renderMessageStatusIcon()
                : null}
            </Mui.ListItem>
          </Mui.List>
        );
    }
  };

  /**
   * Return the status icon. The status can be send and received.
   * @returns {React.ReactElement|null} Icon that shows the status.
   */
  const renderMessageStatusIcon = function () {
    return !own ? null : <MessageStatusIcon message={message} inReplies />;
  };

  return (
    <Mui.ListItem
      className={own ? classes.ownMessage : classes.otherMessage}
      innerRef={ref}
      {...props}
    >
      <Mui.List
        className={classes.content}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onContextMenu={handleContextMenu}
      >
        <Mui.ListItem
          className={clsx(
            classes.item,
            own ? classes.ownMessageItem : null,
            classes.messageInfo,
            position === "middle" && classes.middle,
            position === "start" && classes.start,
            position === "end" && classes.end,
            position === "unique" && classes.unique
          )}
        >
          <Mui.ListItemAvatar
            classes={{
              root: clsx(
                classes.avatar,
                position !== "start" && position !== "unique" && classes.hidden
              ),
            }}
          >
            <Mui.Avatar
              src={message?.owner?.avatar}
              classes={{ root: classes.avatar }}
              {...stringAvatar(own ? user.username : username)}
            />
          </Mui.ListItemAvatar>

          <Mui.Box>
            {(position === "start" || position === "unique") && (
              <Mui.Box className={classes.messageHeader}>
                {own ? user.username : username},{" "}
                <span className={classes.HeaderDate}>{dateTime}</span>
              </Mui.Box>
            )}
            <Mui.Box
              className={clsx(
                classes.secondLevel,
                message.unreadReplies && classes.withUnread
              )}
            >
              {renderContent()}
              {hasReplies && (
                <NumberOfRepliesButton
                  message={message}
                  own={own}
                  handleOpenReplies={openReplies}
                  position={position}
                  style={{ right: "auto", position: "relative" }}
                  unread={true}
                />
              )}
            </Mui.Box>
          </Mui.Box>
        </Mui.ListItem>
        <MessageMenu contextMenu={contextMenu} onClose={handleClose}>
          <Mui.MenuItem
            onClick={jumpToMessage}
            classes={{ root: classes.conversationMenuOptionItem }}
          >
            <Mui.ListItemIcon
              classes={{ root: classes.conversationMenuOptionIcon }}
            >
              <FeatherCornerLeftUp className="svgIcon" />
            </Mui.ListItemIcon>
            <Mui.ListItemText
              primary="Jump to Message"
              disableTypography
              className={classes.conversationMenuOptionText}
            />
          </Mui.MenuItem>
        </MessageMenu>
      </Mui.List>
    </Mui.ListItem>
  );
};

export default MessageWithReplies;
