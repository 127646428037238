import { gql } from "../../../utils/apollo";

export const createMedicalRoom = gql`
  mutation CreateMedicalRoom(
    $pacient: pacientInput
    $date: Date
    $duration: Int
  ) {
    createMedicalRoom(
      input: { pacient: $pacient, date: $date, duration: $duration }
    ) {
      id
      group
      title
      admin
      meetingCode
      kind
    }
  }
`;

export default createMedicalRoom;
