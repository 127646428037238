import ctxSaveExecRestore from "./ctxSaveExecRestore";

/**
 * @callback configCallback
 * @param {CanvasRenderingContext2D} ctx
 */

/**
 * @param {CanvasRenderingContext2D} ctx
 * @param {Path2D} path
 * @param {Object} [options]
 * @param {boolean} [options.full]
 * @param {boolean} [options.strike]
 * @param {configCallback} [options.configCallback]
 */
export const ctxDrawPath = function (
  ctx,
  path,
  { full = false, strike = true, configCallback } = {}
) {
  ctxSaveExecRestore(ctx, () => {
    if (configCallback) configCallback(ctx);
    if (strike) ctx.stroke(path);
    if (full) ctx.fill(path);
  });
};

export default ctxDrawPath;
