import * as Mui from "@mui/material";
import { memo } from "react";

/**
 * Menu to reply a message.
 * @component
 */
export const MessageMenu = memo(function ({ contextMenu, onClose, children }) {
  return (
    <Mui.Menu
      open={contextMenu !== null}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      {children}
    </Mui.Menu>
  );
});
