import * as Mui from "@mui/material";
import clsx from "clsx";
import useStyles from "./NumberOfRepliesButton.styles";

export const NumberOfRepliesButton = function ({
  handleOpenReplies,
  own,
  message,
  position,
  style = {},
  unread = false,
}) {
  const classes = useStyles();
  return (
    <>
      <Mui.Button
        onClick={handleOpenReplies}
        classes={{
          root: clsx(
            classes.numRepliesButton
            //own && classes.ownNumRepliesButton
          ),
        }}
        style={style}
      >
        {message.replies.length < 100 ? message.replies.length : "99+"}{" "}
        {/*unread ? "unread" : ""*/} repl
        {message.replies.length === 1 ? "y" : "ies"}
      </Mui.Button>
      {message.unreadReplies ? (
        <Mui.Box
          className={clsx(
            classes.numberUnreadText,
            own && !unread
              ? classes.unreadRepliesOwn
              : position === "unique" || position === "start"
              ? classes.unreadRepliesOther
              : classes.unreadRepliesOtherBody,
            position === "end"
              ? classes.unreadOfLastPlace
              : position === "middle"
              ? classes.unreadOfMiddle
              : classes.unreadOfFirstPlace,
            unread && classes.unreadInThreads
          )}
        >
          <Mui.Typography variant="body2">
            {`(${
              message.unreadReplies < 100 ? message.unreadReplies : "99+"
            } unread)`}
          </Mui.Typography>
        </Mui.Box>
      ) : null}
    </>
  );
};

export default NumberOfRepliesButton;
