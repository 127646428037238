import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import * as reducers from "./reducers";

const slice = createSlice({
  name: "general",
  initialState,
  reducers,
});

const { reducer, actions } = slice;

export default reducer;
export const {
  setPage,
  setConnected,
  setGeneric,
  setRepliesPanelOpen,
  setUnreadRepliesPanelOpen,
  setProfileOpen,
  setIsOneToOneChatsOpen,
  setIsGroupChatsOpen,
  updateListItem,
  setFiles,
  deleteFile,
  setSendingFiles,
  addTemporalMessge,
  removeTemporalMessage,
  setReplyToJumpTo,
} = actions;
