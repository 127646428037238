import * as Mui from "@mui/material";
import PropTypes from "prop-types";

// eslint-disable-next-line
import { Props } from "./LinearSubmitButton.propTypes";

/**
 * @param {Props} props
 */
export const LinearSubmitButton = function ({
  disabled,
  loading,
  color,
  LinearProgressProps,
  ...props
}) {
  return (
    <>
      <Mui.Button
        {...props}
        fullWidth
        color={color}
        disabled={disabled || loading}
        disableElevation
      />
      <Mui.LinearProgress
        {...LinearProgressProps}
        sx={{ visibility: loading ? "unset" : "hidden" }}
        color={color}
      />
    </>
  );
};

export default LinearSubmitButton;

LinearSubmitButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(["submit", "reset", "button"]),
  LinearProgressProps: PropTypes.object,
};

LinearSubmitButton.defaultProps = {
  disabled: false,
  loading: false,
  type: "submit",
  LinearProgressProps: {},
  color: "primary",
  variant: "contained",
  children: "Submit",
};
