import { gql } from "../../../utils/apollo";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const subscribeToWorkspace = gql`
  subscription SubscribeToWorkspace($id: ID) {
    subscribeToWorkspace(input: { id: $id }) {
      signalType
      workspace {
        id
      }
      category {
        id
      }
      room {
        category {
          id
        }
        id
        title
        admin
        lastUpdate
        lastMessage
        group
        dateOldestUnreadMessage
        messagesWithReplies {
          id
          messageType
          scribble
          owner {
            id
            username
            avatar
          }
          text
          createdAt
          lastReplyDate
          status
          replies {
            id
            messageType
            scribble
            owner {
              id
              username
              avatar
            }
            text
            createdAt
            status
            readers {
              id
              avatar
              username
            }
          }
          readers {
            id
            avatar
            username
          }
          unreadReplies
        }
        unreadMessages {
          total
          replies
        }
        users {
          id
        }
        draft {
          room
          message
          text
        }
      }
      sender
      removedUserId
      target
    }
  }
`;

export default subscribeToWorkspace;
