import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getRecordings } from "../../../store/actions/index";

import Recordings from "./Recordings.component";

const mapStateToProps = (state) => {
  const { recordings } = state.jitsi;
  return { recordings };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRecordings,
    },
    dispatch
  );

export const RecordingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Recordings);

export default RecordingsContainer;
