import { createSelector } from "@reduxjs/toolkit";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
const selectActiveChannelId = (state) => state.chat.room?.id;

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
const selectAllChannels = (state) =>
  state.chat.categories.reduce(
    (accumulator, category) => [...accumulator, ...category.rooms],
    []
  );

export const selectActiveChannel = createSelector(
  selectActiveChannelId,
  selectAllChannels,
  (channelId, allChannels) =>
    allChannels.find((channel) => channel.id === channelId)
);
