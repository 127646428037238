import client from "../../../client";
import query from "./getFriends.query";

/**
 * @returns {Promise<Array<{
 *  id: string,
 *  username: string,
 *  avatar: string,
 * }>>}
 */
export const getFriends = async function () {
  const { data } = await client.query({ query });
  return data.getUser.friends;
};

export default getFriends;
