import { getUid } from "../../../utils";

const lorem =
  "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur tempora soluta vero illo, voluptates, fuga vitae repellat debitis eum, ea libero id! Tenetur quod distinctio libero neque reprehenderit, a commodi.".split(
    " "
  );
const getLorem = () =>
  lorem.slice(0, Math.floor(Math.random() * lorem.length) || 1).join(" ");

const fn = () => {};

const Subscription = function () {
  this.unsubscribe = fn;
};

const Mock = class {
  constructor(props = {}) {
    for (const [key, value] of Object.entries(props)) {
      this[key] = value;
    }
  }
};

export const Observable = function () {
  this.subscribe = () => new Subscription();
};

export const Void = function () {
  this[undefined] = undefined;
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const User = function (
  username = "Batman",
  avatar = null,
  roomAdmin = false,
  id = getUid()
) {
  this.id = id;
  this.username = username;
  this.avatar = avatar;
  this.roomAdmin = roomAdmin;
  this.role = "user";

  // this.role = "guest";
  // this.role = "admin";
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const Room = function (title = "Group", group = false, admin = false) {
  this.id = `${title}_room_id`;
  this.meetingCode = "test_code_mine";
  this.group = group;
  this.title = title;
  this.admin = admin;
};

// let createdAt = new Date().getTime();
// const getCreatedAt = function () {
//     createdAt -= 60000;
//     return createdAt;
// };
export class Message extends Mock {
  constructor({
    id = getUid(),
    messageType = "text",
    text = getLorem(),
    scribble = "",
    form = null,
    createdAt = new Date().getTime(),
    edited = false,
    status = "sending",
    //
    username = "Batman",
    //
    avatar = null,
    userId,
    owner = new User(username, avatar, false, userId),
  } = {}) {
    super({
      id,
      messageType,
      text,
      scribble,
      form,
      createdAt,
      edited,
      status,
      owner,
    });
  }
}

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const Notification = function (notificationType = "friendRequest") {
  this.id = `${notificationType}_notification_id`;
  this.notificationType = notificationType;
  this.sender = new User();
  this.room = new Room("Unjustice", true);
};

export const Response = class {
  constructor(queryValues = {}) {
    this.data = {
      ...queryValues,
    };
  }
  mockNull() {
    return new Response();
  }
  mockError(message) {
    return getErrors("ERROR", message);
  }
  mockWarning(message) {
    return getErrors("WARNING", message);
  }
};

const getErrors = (code, message = "Mock error") => ({
  errors: [
    {
      message,
      extensions: {
        code,
      },
    },
  ],
});
