import JitsiMeetJS from "../jitsi";
import { groupLog } from "../loggers";
import { getHandlerName } from "./getHandlerName";

export const logAvailableHandlers = function () {
  const eventTypes = JitsiMeetJS.events;
  const availableHandlers = {};
  for (const eventType of Object.keys(eventTypes)) {
    const handlers = {};
    const events = eventTypes[eventType];
    for (const event of Object.keys(events)) {
      const handler = getHandlerName(event);
      handlers[event] = handler;
    }
    availableHandlers[eventType] = handlers;
  }
  groupLog("JitsiMeetJS Available Handlers", () =>
    console.log(availableHandlers)
  );
};

export default logAvailableHandlers;
