import client from "../../../client";
import query from "./getGroupRooms.query";

/**
 * @returns {Promise<Array<{
 *  id: string,
 *  group: boolean,
 *  title: string,
 *  admin: boolean,
 *  meetingCode: string,
 *  kind: string,
 * }>>}
 */
export const getGroupRooms = async function () {
  const { data } = await client.query({ query });
  return data.getGroupRooms;
};

export default getGroupRooms;
