import client from "../../../client";
import mutation from "./recoverPassword.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 * @param {string} params
 * @param {string} password
 */
export const recoverPassword = async function (id, params, password) {
  id = addBearer(id);
  await client.mutate({
    mutation,
    variables: {
      id,
      params,
      password,
    },
  });
};

export default recoverPassword;
