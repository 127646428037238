import JitsiMeetJS from "./jitsi";
import * as effects from "./effects";

export default JitsiMeetJS;

export { effects };
export * from "./eventHandlers";
export * from "./recording";

export * from "./createLocalTracks";
export * from "./getJitsiConference";
export * from "./getJitsiConnection";
export * from "./getMediaDevices";
export * from "./jitsi";
export * from "./JitsiMessage";
