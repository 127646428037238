import client from "../../../client";
import query from "./getRecordings.query";

/**
 * @returns {Promise<Array<{
 *  sessionID: string,
 *  thumbnail: string,
 *  createdAt: number,
 *  size: string,
 * }>>}
 */
export const getRecordings = async function () {
  const { data } = await client.query({ query });
  return data.getUser.recordings;
};

export default getRecordings;
