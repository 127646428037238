import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FoundFiles from "./FoundFiles.component";
import { searchPageAndSection } from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { foundFiles, searchedValue, totalResults } = state.search;
  return {
    files: foundFiles,
    searchedValue,
    totalFiles: totalResults.files,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ searchPageAndSection }, dispatch);

const FoundFilesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FoundFiles);

export default FoundFilesContainer;
