export const setPage = function (state, { payload }) {
  state.page = payload;
};

export const setConnected = function (state, { payload }) {
  state.connected = payload;
};

export const setGeneric = function (state, { payload }) {
  state.generic = payload;
};

export const setRepliesPanelOpen = function (state, { payload }) {
  state.repliesPanelOpen = payload;
};

export const setUnreadRepliesPanelOpen = function (state, { payload }) {
  state.unreadRepliesPanelOpen = payload;
};

export const setProfileOpen = function (state, { payload }) {
  state.profilaOpen = payload;
};

export const setIsOneToOneChatsOpen = function (state, { payload }) {
  state.isOneToOneChatsOpen = payload;
};

export const setIsGroupChatsOpen = function (state, { payload }) {
  state.isGroupChatsOpen = payload;
};

export const updateListItem = function (state, { payload } = {}) {
  const { list, item, props = {} } = payload;
  if (!list) {
    throw new Error(`"list" argument is required`);
  }
  if (!item) {
    throw new Error(`"item" argument is required`);
  }
  const stateList = state[list];
  if (!Array.isArray(stateList)) {
    throw new Error("List name doesn't point to an array in state");
  }
  const index = stateList.findIndex((i) => i === item);
  if (index < 0) {
    throw new Error(`State list "${list}" dont hold given item`);
  }

  state[list][index] = { ...item, ...props };
};

export const setFiles = function (state, { payload }) {
  const uniqueFiles = [...payload].filter(
    (file) =>
      !state.files.some((f) => {
        return (
          f.name === file.name &&
          f.size === file.size &&
          f.type === file.type &&
          f.lastModified === file.lastModified
        );
      })
  );
  for (let file of uniqueFiles) {
    state.files.push(file);
  }
};

export const deleteFile = function (state, { payload }) {
  state.files = state.files.filter((f) => f !== payload);
};

export const setSendingFiles = function (state, { payload }) {
  state.sendingFiles = payload;
};

export const addTemporalMessge = function (state, { payload }) {
  state.temporalMessages.push(payload);
};

export const removeTemporalMessage = function (state, { payload }) {
  state.temporalMessages = state.temporalMessages.filter((m) => m !== payload);
};

export const setReplyToJumpTo = function (state, { payload }) {
  state.replyToJumpTo = payload;
};
