import React, { useRef } from "react";
import PropTypes from "prop-types";

import PasswordTextField from "../PasswordTextField";

export const DoublePassword = function ({
  Wrapper,
  WrapperProps,
  name1,
  name2,
  label1,
  label2,
  placeholder1,
  placeholder2,
  ...props
}) {
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const handleChange = function (event) {
    const password1 = ref1.current;
    const password2 = ref2.current;
    if (password1.value === password2.value) {
      password2.setCustomValidity("");
      password1.setCustomValidity("");
      return;
    }
    const validity = "Both passwords must match";
    const current = event.target;
    if (current === password1) {
      password1.setCustomValidity(validity);
      password2.setCustomValidity("");
      return;
    }
    password2.setCustomValidity(validity);
    password1.setCustomValidity("");
  };

  const Password1 = (
    <PasswordTextField
      {...props}
      name={name1}
      placeholder={placeholder1}
      inputRef={ref1}
      onChange={handleChange}
    />
  );

  const Password2 = (
    <PasswordTextField
      {...props}
      name={name2}
      placeholder={placeholder2}
      inputRef={ref2}
      onChange={handleChange}
    />
  );

  if (!Wrapper)
    return (
      <>
        {Password1}
        {Password2}
      </>
    );

  return (
    <>
      <Wrapper {...WrapperProps}>{Password1}</Wrapper>
      <Wrapper {...WrapperProps}>{Password2}</Wrapper>
    </>
  );
};

export default DoublePassword;

DoublePassword.propTypes = {
  /**
   * Wrapper for each password TextField (ej: div, Grid, Box, etc)
   */
  Wrapper: PropTypes.elementType,
  /**
   * Props for the given Wrapper
   */
  WrapperProps: PropTypes.object,
  /**
   * name for the main password TextField
   */
  name1: PropTypes.string,
  /**
   * label for the main password TextField
   */
  label1: PropTypes.string,
  /**
   * placeholder for the main password TextField
   */
  placeholder1: PropTypes.string,
  /**
   * name for the confirmation password TextField
   */
  name2: PropTypes.string,
  /**
   * label for the confirmation password TextField
   */
  label2: PropTypes.string,
  /**
   * placeholder for the confirmation password TextField
   */
  placeholder2: PropTypes.string,
};

DoublePassword.defaultProps = {
  name2: "password2",
  label2: "Confirm Password",
  placeholder2: "This must match the password",
};
