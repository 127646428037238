import client from "../../../client";
import mutation from "./inviteUsersToCall.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {Array<string>} usersIds
 * @param {string} meetingCode
 */
export const inviteUsersToCall = async function (
  usersIds,
  meetingCode,
  channelId,
  workspaceId
) {
  usersIds = usersIds.map((id) => addBearer(id));
  channelId = addBearer(channelId);
  workspaceId = addBearer(workspaceId);
  client.mutate({
    mutation,
    variables: {
      usersIds,
      meetingCode,
      channelId,
      workspaceId,
    },
  });
  return;
};

export default inviteUsersToCall;
