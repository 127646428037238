import client from "../../../client";
import mutation from "./addUserToRooms.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} userId Id of the user who will be added
 * @param {array} roomsIds Ids of the rooms to which the user will be added
 * @param {string} userRoomId Id of the individual room with the user
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const addUserToRooms = async function (userId, roomsIds, userRoomId) {
  roomsIds = roomsIds.map((roomId) => addBearer(roomId));
  userId = addBearer(userId);
  userRoomId = addBearer(userRoomId);

  await client.mutate({
    mutation,
    variables: {
      userId,
      roomsIds,
      userRoomId,
    },
  });
};

export default addUserToRooms;
