export const getHandlerName = function (eventName = "") {
  const first = eventName[0];
  eventName = eventName
    .substring(1)
    .toLowerCase()
    .replace(/_[a-z]/g, (match) => `${match[1]}`.toUpperCase());
  return `on${first}${eventName}`;
};

export default getHandlerName;
