import { eventChannel } from "redux-saga";

import {
  registerEventHandlers,
  unregisterEventHandlers,
} from "XmarsJitsiMeetJS/index";
import { groupLog } from "XmarsUtils/groupLog";

import { createConnectionHandlers } from "./createConnectionHandlers";

export function jitsiConnectionChannel(jitsiConnection) {
  const jitsiSubscription = (emitter) => {
    const connectionHandlers = createConnectionHandlers(emitter);
    registerEventHandlers("connection", jitsiConnection, connectionHandlers);

    jitsiConnection.connect();

    return () => {
      groupLog("Ending connection channel");
      unregisterEventHandlers(
        "connection",
        jitsiConnection,
        connectionHandlers
      );
      jitsiConnection.disconnect();
    };
  };
  return eventChannel(jitsiSubscription);
}
