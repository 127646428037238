import React, { useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";

import { TooltipIconButton } from "../../prestyledComponents";

export const HangButton = function ({
  jitsiCallHang,
  disabled,
  sharedScreen,
  screenConnection,
  screenTrack,
  meetingCode,
  stopSharing,
  ...props
}) {
  const [open, setOpen] = useState(false);

  const handleHang = async function () {
    if (sharedScreen) {
      stopSharing({
        conference: sharedScreen,
        screenTrack,
        connection: screenConnection,
        meetingCode,
      });
    }

    jitsiCallHang();
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <TooltipIconButton
        {...props}
        disabled={disabled}
        title="Hang"
        name={"Hang"}
        TooltipProps={{ placement: "top" }}
        // onClick={() => setOpen(true)}
        onClick={handleHang}
      >
        <Icons.PhoneOff />
      </TooltipIconButton>
      <Mui.Dialog open={open} onClose={handleClose}>
        <Mui.DialogTitle>Leave Conference?</Mui.DialogTitle>
        <Mui.DialogActions>
          <TooltipIconButton title="Yes" color="secondary" onClick={handleHang}>
            <Icons.PhoneOff />
          </TooltipIconButton>
          <TooltipIconButton title="No" color="primary" onClick={handleClose}>
            <Icons.X />
          </TooltipIconButton>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default HangButton;
