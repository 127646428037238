import client from "../../../client";
import mutation from "./guestLogin.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 * @param {string} email
 */
export const guestLogin = async function (id, email) {
  id = addBearer(id);
  await client.mutate({
    mutation,
    variables: {
      id,
      email,
    },
  });
};

export default guestLogin;
