import { connect } from "react-redux";

import RemoteVideo from "./RemoteVideo.component";

const mapStateToProps = (state) => {
  const { isSmallVideoOpen } = state.video;
  return { isSmallVideoOpen };
};

const RemoteVideoContainer = connect(mapStateToProps, null)(RemoteVideo);

export default RemoteVideoContainer;
