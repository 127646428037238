import * as Icons from "react-feather";

import TooltipIconButton from "../TooltipIconButton";

export const SearchSubmitButton = function (props) {
  return (
    <TooltipIconButton {...props} type="submit">
      <Icons.Search />
    </TooltipIconButton>
  );
};

export default SearchSubmitButton;

SearchSubmitButton.defaultProps = {
  title: "Search",
};
