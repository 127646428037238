import env from "../../../env";

const initialState = {
  connection: null,
  conference: null,
  connectionStatus: null,
  conferenceStatus: null,
  localParticipant: {
    sharingScreen: false,
    local: true,
    focused: false,
    id: null,
    name: "Me",
    audio: null,
    video: null,
    localVideo: null,
  },
  localTracksData: {
    localAudioVolume: 0,
    localAudioIsMuted: true,
    localAudioTrackReady: false,
    localVideoTrackReady: false,
    localVideoIsMuted: true,
  },
  remoteParticipants: [],
  recordings: [],
  videoEffects: {
    /** @type {JitsiEffect} */
    handtrackEffect: null,
    handtrackActive: false,
    /** @type {JitsiEffect} */
    backgroundEffect: null,
    backgroundActive: false,
    backgroundCurrent: `${env.PUBLIC_URL}/images/background0.jpg`,
  },
  /** @type {"gallery"|"speaker"|"listener"} */
  videoGrid: "gallery",
  sharedScreen: null,
  screenConnection: null,
  screenTrack: null,
};

export default initialState;
