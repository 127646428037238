import client from "../../../client";
import mutation from "./deleteUser.mutation";

/**
 * @returns {Promise<{ username: string }>}
 */
export const deleteUser = async function (username) {
  const { data } = await client.mutate({
    mutation,
    variables: {
      input: {
        username,
      },
    },
  });

  return data.deleteUser;
};

export default deleteUser;
