import { gql } from "../../../utils/apollo";

export const getFriends = gql`
  query GetFriends {
    getUser {
      friends {
        id
        username
        avatar
      }
    }
  }
`;

export default getFriends;
