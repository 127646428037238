import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import User from "./User.component";

import { logout, changeStatus, getUser } from "../../../store/actions";

const mapStateToProps = (state) => {
  const { user, status } = state.chat;

  return { user, status };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      changeStatus,
      getUser,
    },
    dispatch
  );

const UserContainer = connect(mapStateToProps, mapDispatchToProps)(User);

export default UserContainer;
