import client from "../../../client";
import mutation from "./setOnlineStatus.mutation";

/**
 * @param {boolean} online
 */
export const setOnlineStatus = async function (online) {
  await client.mutate({
    mutation,
    variables: {
      online,
    },
  });
};

export default setOnlineStatus;
