import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  inputBase: {
    color: theme.palette.textGray.input,
    padding: "0.375rem 0.75rem",
    height: "auto",

    "&::placeholder": {
      color: `${theme.palette.textGray.inputPlaceholder} !important`,
      opacity: 1,
    },
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
}));
