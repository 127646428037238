import React, { createRef, useEffect } from "react";
import PropTypes from "prop-types";

import { useAvatarImageDataUrl, useDependency } from "../../../hooks/index";

import {
  registerEventHandlers,
  unregisterEventHandlers,
} from "../../../JitsiMeetJS";
import useStyles from "./RemoteVideo.styles";

export const RemoteVideo = function ({ track, isSmallVideoOpen }) {
  /** @type {React.RefObject<HTMLVideoElement>} */
  const ref = createRef(null);

  const [updater, dependency] = useDependency();
  const poster = useAvatarImageDataUrl();

  const classes = useStyles();

  //mount
  useEffect(() => {
    if (track) {
      registerEventHandlers("track", track, trackEventHandlers);
      updater();
      //unmount
      return () => {
        // eslint-disable-next-line
        track.detach(ref.current);
        unregisterEventHandlers("track", track, trackEventHandlers);
      };
    }
    // eslint-disable-next-line
  }, [track]);

  useEffect(() => {
    if (track) {
      const video = ref.current;
      if (track.isMuted()) {
        track.detach(video);
      } else {
        track.attach(video);
      }
    }
    // eslint-disable-next-line
  }, [dependency, track]);

  const trackEventHandlers = {
    onTrackMuteChanged: updater,
  };

  return (
    <video
      autoPlay
      ref={ref}
      className={isSmallVideoOpen ? classes.smallVideos : classes.video}
      poster={poster}
    />
  );
};

export default RemoteVideo;

RemoteVideo.propTypes = {
  track: PropTypes.object,
};
