import React, { useEffect } from "react";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { Recording } from "../../collectionItems";

export const Recordings = function ({ recordings, getRecordings }) {
  useEffect(() => {
    getRecordings();
    // eslint-disable-next-line
  }, []);

  return recordings.length ? (
    <Mui.List>
      {recordings.map((recording, index) => (
        <Recording key={index} recording={recording} />
      ))}
    </Mui.List>
  ) : (
    <Mui.ListItem>
      <Mui.ListItemText primary="No recordings" />
    </Mui.ListItem>
  );
};

export default Recordings;

Recordings.propTypes = {
  recordings: PropTypes.array.isRequired,
  getRecordings: PropTypes.func.isRequired,
};
