import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { Tooltip } from "../../prestyledComponents";

export const SearchedUser = function ({
  user,
  sendFriendRequest,
  selected,
  onClick,
}) {
  const handleClick = () => sendFriendRequest(user);

  return (
    <Mui.ListItem button divider selected={selected} onClick={onClick}>
      <Mui.ListItemAvatar>
        <Mui.Avatar src={user.avatar} />
      </Mui.ListItemAvatar>
      <Mui.ListItemText primary={user.username} />
      <Mui.ListItemIcon>
        {user.friendRequestSent ? (
          <Mui.Icon color="primary">
            <Icons.Check />
          </Mui.Icon>
        ) : (
          <Tooltip title="Send friend request">
            <Mui.IconButton color="primary" onClick={handleClick}>
              <Icons.UserPlus />
              <Icons.Send />
            </Mui.IconButton>
          </Tooltip>
        )}
      </Mui.ListItemIcon>
    </Mui.ListItem>
  );
};

export default SearchedUser;

SearchedUser.propTypes = {
  user: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  sendFriendRequest: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
