import ctxDrawPath from "./ctxDrawPath";
import { getCirclePath } from "./path2D";

/**
 * @callback configCallback
 * @param {CanvasRenderingContext2D} ctx
 */

/**
 * @param {CanvasRenderingContext2D} ctx
 * @param {Object} [options]
 * @param {number} [options.x]
 * @param {number} [options.y]
 * @param {number} [options.radius]
 * @param {boolean} [options.full]
 * @param {boolean} [options.strike]
 * @param {configCallback} [options.configCallback]
 */
export const ctxDrawCircle = function (
  ctx,
  { x = 0, y = 0, radius = 10, full, strike, configCallback } = {}
) {
  const path = getCirclePath({ x, y, radius });
  ctxDrawPath(ctx, path, { full, strike, configCallback });
};

export default ctxDrawCircle;
