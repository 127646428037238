import { getMainDefinition } from "../apollo";

import { getLogger } from "../gql-logger";

const getLoggers = function (color) {
  const primaryConsoleProps = {
    "font-size": "1.15em",
    "font-weight": "bold",
  };
  const group = getLogger(
    {
      ...primaryConsoleProps,
      color,
    },
    "groupCollapsed"
  );
  const primary = getLogger({
    ...primaryConsoleProps,
    color,
  });
  return [group, primary];
};

export const [queryGroupLog, queryMainLog] = getLoggers("#3388bb");
export const [mutationGroupLog, mutationMainLog] = getLoggers("#6666ff");
export const [subscriptionGroupLog, subscriptionMainLog] =
  getLoggers("#009933");

export const logGqlInfo = function (
  queryType,
  query,
  variables,
  mainLog,
  groupLog
) {
  const queryTitle = `${queryType} ${getMainDefinition(query).name.value}`;
  if (!variables) {
    mainLog(queryTitle);
  } else {
    groupLog(queryTitle, () => console.log(variables));
  }
};
