import { gql } from "../../../utils/apollo";

export const getWorkspaceAdmins = gql`
  query GetWorkspaceAdmins($input: getWorkspaceAdminsInput!) {
    getWorkspaceAdmins(input: $input) {
      users {
        id
        entryDate
        admin
      }
    }
  }
`;

export default getWorkspaceAdmins;
