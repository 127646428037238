import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SearchedFriend from "./SearchedFriend.component";

import { sendRoomInvitation } from "../../../store/actions/index";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  return { room };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendRoomInvitation,
    },
    dispatch
  );

const SearchedFriendContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchedFriend);

export default SearchedFriendContainer;
