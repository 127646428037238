import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AddFriendsButton from "./AddFriendsButton.component";

import {
  searchByEmail,
  sendFriendRequest,
  resetSearchedUsers,
  addSnackbar,
} from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { searchedUsers } = state.chat;
  return { searchedUsers };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchByEmail,
      sendFriendRequest,
      resetSearchedUsers,
      addSnackbar,
    },
    dispatch
  );

const AddFriendsButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(AddFriendsButton);

export default AddFriendsButtonContainer;
