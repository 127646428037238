import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";

import useStyles from "./UnreadReplies.styles";
import { useViewport } from "../../hooks/index";
import { Tooltip } from "../prestyledComponents";
import { Threads } from "../collections";

/**
 * Container of replies.
 * @component
 * @param {Object} obj - Input.
 * @param {Object} message - Contains the information of the current message.
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
function UnreadReplies({
  activeChatRoomId,
  setRepliesPanelOpen,
  setUnreadRepliesPanelOpen,
  setCurrentMessage,
  unreadRepliesPanelOpen,
  setRepliesProps,
}) {
  const classes = useStyles();
  const [drawerVariant, setDrawerVariant] = useState("temporary");
  const viewport = useViewport();
  const sizesHidingReplies = ["xs", "sm", "videoSm", "md", "lg"];

  useEffect(() => {
    setUnreadRepliesPanelOpen(false);
  }, [activeChatRoomId]);

  useEffect(() => {
    if (sizesHidingReplies.includes(viewport)) {
      setDrawerVariant("temporary");
      setUnreadRepliesPanelOpen(false);
    } else {
      setDrawerVariant("persistent");
    }
  }, [viewport]);

  /**
   * Closes the panel with the replies.
   */
  function handleCloseReplies() {
    setUnreadRepliesPanelOpen(false);
  }

  function handleMessageClick(message) {
    return () => {
      setRepliesProps({
        breadcrumbs: ["Threads", "Replies"],
      });
      setCurrentMessage(message);
      setRepliesPanelOpen(true);
    };
  }

  return unreadRepliesPanelOpen ? (
    <Mui.Drawer
      anchor="right"
      variant={drawerVariant}
      open={unreadRepliesPanelOpen}
      onClose={() => {
        setUnreadRepliesPanelOpen(false);
      }}
      classes={{ paper: classes.repliesPanel }}
    >
      <Mui.ListItem className={classes.repliesHeader}>
        <Mui.Box className={classes.headerText}>
          <span className={classes.threadTitle}>Threads</span>
        </Mui.Box>
        <Tooltip placement="bottom" title="Close">
          <Mui.IconButton
            color="secondary"
            size="small"
            className={classes.closeButton}
            onClick={handleCloseReplies}
          >
            <Icons.X />
          </Mui.IconButton>
        </Tooltip>
      </Mui.ListItem>
      <Mui.Divider className={classes.divider} />
      <Threads handleMessageClick={handleMessageClick} />
    </Mui.Drawer>
  ) : null;
}

export default UnreadReplies;
