import { gql } from "../../../utils/apollo";

export const unsetWorkspaceAdmin = gql`
  mutation UnsetWorkspaceAdmin($input: unsetWorkspaceAdminInput!) {
    unsetWorkspaceAdmin(input: $input) {
      null
    }
  }
`;

export default unsetWorkspaceAdmin;
