import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  deleteReply,
  readMessage,
  setIsEditingReply,
  setEditingReply,
  setReplyToJumpTo,
} from "../../../store/actions/index";

import Reply from "./Reply.component";

const mapStateToProps = (state, { message }) => {
  const { user, replyToJumpTo } = state.chat;
  return { user, shouldScrollIntoView: replyToJumpTo?.id === message.id };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteReply,
      readMessage,
      setIsEditingReply,
      setEditingReply,
      setReplyToJumpTo,
    },
    dispatch
  );

const ReplyContainer = connect(mapStateToProps, mapDispatchToProps)(Reply);

export default ReplyContainer;
