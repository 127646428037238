import { makeStyles } from "@mui/styles";
//TODO add alpha
export default makeStyles((theme) => ({
  linkText: {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      boxShadow: `${theme.palette.primary.mainTransarent} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));
