import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import useStyles from "./RemoveUserFromCall.styles";

export const RemoveUserFromCall = function ({
  conferenceObjectControl,
  currentWorkspace,
  open,
  handleClose,
  username,
  userId,
}) {
  const classes = useStyles();

  const handleRemoveFriend = () => {
    conferenceObjectControl({
      method: "removeFromConference",
      args: [userId],
    });
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-remove-friend"
      PaperProps={{ elevation: 0 }}
    >
      <DialogTitle id="dialog-remove-friends" className={classes.dialogHeader}>
        <Icons.UserMinus style={{ height: "18px", width: "18px" }} />
        <Typography className={classes.headerText}>
          Remove {username} from the call?
        </Typography>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Icons.X style={{ strokeWidth: "2.5px" }} />
        </IconButton>
      </DialogTitle>
      {/* {currentWorkspace.admin ? ( */}
      <DialogActions className={classes.dialogFooter}>
        <Button
          onClick={handleRemoveFriend}
          className={classes.actionButton}
          // disabled={!currentWorkspace.admin}
        >
          Yes
        </Button>
        <Button onClick={handleClose} className={classes.actionButton}>
          No
        </Button>
      </DialogActions>
      {/* ) : (
                <Typography
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    color={"red"}
                    height={"3.5em"}
                >
                    Sorry, you are not allowed to remove users from a call
                </Typography>
            )} */}
    </Dialog>
  );
};

export default RemoveUserFromCall;

RemoveUserFromCall.propTypes = {
  user: PropTypes.object,
};
