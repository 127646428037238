import React, { useRef, useCallback, useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";
import { VerticalScrollbars } from "../../prestyledComponents";
import { SidebarItem } from "../../collectionItems";
import { AddFriendsButton, CreateCustomRoomButton } from "../../buttons";
import useStyles from "./SidebarItems.styles";
import clsx from "clsx";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const SidebarItems = function ({
  user,
  panel,
  setPanel,
  friends,
  rooms,
  roomFilter,
  closePanels,
  setRepliesPanelOpen,
  setUnreadRepliesPanelOpen,
  showingOrganized,
  isGroupChatsOpen,
  setIsGroupChatsOpen,
  isOneToOneChatsOpen,
  setIsOneToOneChatsOpen,
  updateIsOneToOneChatsOpen,
  updateIsGroupChatsOpen,
}) {
  const classes = useStyles();

  const addFriendsRef = useRef(null);
  const createRoomRef = useRef(null);

  useEffect(() => {
    setIsGroupChatsOpen(user?.groupsPanelOpen);
    setIsOneToOneChatsOpen(user?.conversationsPanelOpen);
  }, []);

  const handleAddFriendsClick = useCallback((event) => {
    addFriendsRef.current && addFriendsRef.current.click();
    event.stopPropagation();
  }, []);

  const handleCreateRoomClick = useCallback((event) => {
    createRoomRef.current && createRoomRef.current.click();
    event.stopPropagation();
  }, []);

  /**
   * Filters  the rooms in one to one and group rooms.
   * @param {array} mixedRooms - List of all the rooms.
   */
  const organizeRooms = function (mixedRooms) {
    const oneToOneRooms = mixedRooms.filter((room) => {
      return room.friend;
    });
    const groupRooms = mixedRooms.filter((room) => {
      return !room.friend;
    });
    return { oneToOneRooms, groupRooms };
  };

  /**
   * Renders the buttons to open the rooms.
   * @param {array} roomsArray - List of  the rooms.
   * @param {string} typeOfRoom - Kind of room: individual, group or all.
   */
  const renderRoomButtons = function (roomsArray, typeOfRoom) {
    if (roomsArray.length) {
      return roomsArray.map((room) => (
        <SidebarItem
          filtered={!!roomFilter}
          key={room.id}
          type="chats"
          room={room}
          lastMessage={room.lastMessage}
          unreadMessages={
            room?.unreadMessages?.total - room?.unreadMessages?.replies
          }
          unreadReplies={room?.unreadMessages?.replies}
          draftText={room.draft?.text}
          closePanels={closePanels}
          lastUpdate={room.lastUpdate}
        />
      ));
    } else if (roomFilter) {
      return (
        <Mui.ListItem>
          <Mui.ListItemIcon>
            <Icons.Search />
          </Mui.ListItemIcon>
          <Mui.ListItemText
            primary={`No match for the search "${roomFilter}" ${
              typeOfRoom !== "all" && `in the ${typeOfRoom} rooms.`
            }`}
            style={{ wordBreak: "break-word" }}
          />
        </Mui.ListItem>
      );
    }
    if (typeOfRoom === "individual") {
      return (
        <>
          {panel === "friends" ? (
            <>
              <AddFriendsButton
                style={{ display: "none" }}
                ref={addFriendsRef}
              />
              {addFriendsButton}
            </>
          ) : (
            <>{goToContactsButton}</>
          )}
        </>
      );
    }
    return (
      <>
        <CreateCustomRoomButton
          style={{ display: "none" }}
          ref={createRoomRef}
        />
        {createRoomButton}
      </>
    );
  };

  /**
   * Merge friends and rooms
   * @param {object} friends
   * @param {object} rooms
   * @return {object}
   */
  function organizeRoomsAndFriends(friends, rooms) {
    let roomsAndFriends = rooms.concat(friends);
    roomsAndFriends.sort((roomA, roomB) => {
      let stringA = roomA.username || roomA.title;
      stringA.toLowerCase();
      let stringB = roomB.username || roomB.title;
      stringB.toLowerCase();
      if (stringA < stringB) return -1;
      if (stringA > stringB) return 1;
      return 0;
    });

    return roomsAndFriends;
  }

  const renderItems = function () {
    switch (panel) {
      case "chats":
        if (rooms.length) {
          //const regex = RegExp(roomFilter, "i");
          const uncaseFilter = roomFilter.toLowerCase();
          let showedRooms = rooms;

          if (roomFilter) {
            //showedRooms = showedRooms.filter((room) => regex.test(room.title));
            showedRooms = showedRooms.filter((room) =>
              room.title.toLowerCase().includes(uncaseFilter)
            );
          }

          if (showedRooms.length) {
            let { oneToOneRooms, groupRooms } = organizeRooms(showedRooms);
            const height = "calc(100vh - 120px)";
            return (
              <ReactCardFlip
                isFlipped={!showingOrganized}
                cardStyles={{
                  front: { height },
                  back: { height },
                }}
                containerStyle={{
                  height,
                  //overFlow: "hidden",
                }}
              >
                <VerticalScrollbars style={{ height: "100vh" }}>
                  <Mui.Box className={classes.front}>
                    <Mui.ListItem
                      className={classes.chatsContainer}
                      classes={{ root: classes.rootListItem }}
                      onClick={() => {
                        setRepliesPanelOpen(false);
                        setUnreadRepliesPanelOpen(false);
                        updateIsOneToOneChatsOpen(!isOneToOneChatsOpen);
                        // setIsOneToOneChatsOpen(!isOneToOneChatsOpen);
                      }}
                    >
                      <Mui.ListItemIcon
                        classes={{ root: classes.organizingIcon }}
                      >
                        {isOneToOneChatsOpen ? (
                          <Icons.ChevronDown />
                        ) : (
                          <Icons.ChevronRight />
                        )}
                      </Mui.ListItemIcon>
                      <Mui.ListItemText
                        primary="conversations"
                        primaryTypographyProps={{
                          className: clsx(classes.chatsContainerText),
                        }}
                      />
                    </Mui.ListItem>
                    <Mui.Box display={isOneToOneChatsOpen ? "block" : "none"}>
                      {renderRoomButtons(oneToOneRooms, "individual")}
                    </Mui.Box>
                    <Mui.ListItem
                      className={classes.chatsContainer}
                      classes={{ root: classes.rootListItem }}
                      onClick={() => updateIsGroupChatsOpen(!isGroupChatsOpen)}
                    >
                      <Mui.ListItemIcon
                        classes={{ root: classes.organizingIcon }}
                      >
                        {isGroupChatsOpen ? (
                          <Icons.ChevronDown />
                        ) : (
                          <Icons.ChevronRight />
                        )}
                      </Mui.ListItemIcon>
                      <Mui.ListItemText
                        primary="groups"
                        primaryTypographyProps={{
                          className: classes.chatsContainerText,
                        }}
                      />
                    </Mui.ListItem>
                    <Mui.Box display={isGroupChatsOpen ? "block" : "none"}>
                      {renderRoomButtons(groupRooms, "group")}
                    </Mui.Box>
                  </Mui.Box>
                </VerticalScrollbars>

                <VerticalScrollbars style={{ height: "100vh" }}>
                  <Mui.Box className={classes.back}>
                    {renderRoomButtons(showedRooms, "all")}
                  </Mui.Box>
                </VerticalScrollbars>
              </ReactCardFlip>
            );
          }
          return (
            <Mui.ListItem>
              <Mui.ListItemIcon>
                <Icons.Search />
              </Mui.ListItemIcon>
              <Mui.ListItemText
                primary={`No match for the search "${roomFilter}"`}
                style={{ wordBreak: "break-word" }}
              />
            </Mui.ListItem>
          );
        }
        break;
      case "friends":
        let groupRooms = rooms.filter((room) => room.group);
        if (friends.length || groupRooms.length) {
          //const regex = RegExp(roomFilter, "i");
          const uncaseFilter = roomFilter.toLowerCase();
          let showedElements = organizeRoomsAndFriends(friends, groupRooms);
          if (roomFilter) {
            showedElements = showedElements.filter((element) => {
              // is element a group chat
              if (element.group) {
                return element.title.toLowerCase().includes(uncaseFilter);
              }
              return element.username.toLowerCase().includes(uncaseFilter);
            });
          }
          if (showedElements.length) {
            return (
              <VerticalScrollbars style={{ height: "100vh" }}>
                <Mui.Box className={classes.friendsContainer}>
                  {showedElements.map((element) => (
                    <SidebarItem
                      filtered={!!roomFilter}
                      key={element.id}
                      type="friends"
                      room={element}
                      closePanels={closePanels}
                    />
                  ))}
                </Mui.Box>
              </VerticalScrollbars>
            );
          }
          return (
            <Mui.ListItem>
              <Mui.ListItemIcon>
                <Icons.Search />
              </Mui.ListItemIcon>
              <Mui.ListItemText
                primary={`No match for the search "${roomFilter}"`}
                style={{ wordBreak: "break-word" }}
              />
            </Mui.ListItem>
          );
        }
        break;

      default:
        return null;
    }

    if (user.role === "guest") return null;

    return (
      <>
        {panel !== "chats" ? (
          <AddFriendsButton style={{ display: "none" }} ref={addFriendsRef} />
        ) : null}
        <CreateCustomRoomButton
          style={{ display: "none" }}
          ref={createRoomRef}
        />
        {panel !== "chats" ? addFriendsButton : goToContactsButton}
        {createRoomButton}
      </>
    );
  };

  const goToContactsButton = (
    <Mui.ListItem
      button
      onClick={() => setPanel("friends")}
      className={classes.item}
      classes={{ root: classes.root }}
    >
      <Mui.ListItemAvatar>
        <Mui.Avatar>
          <Icons.UserPlus />
        </Mui.Avatar>
      </Mui.ListItemAvatar>
      <Mui.ListItemText
        primary={"Go to contacts"}
        primaryTypographyProps={{
          className: classes.primaryText,
        }}
        className={classes.itemText}
      />
    </Mui.ListItem>
  );

  const addFriendsButton = (
    <Mui.ListItem
      button
      onClick={handleAddFriendsClick}
      className={classes.item}
      classes={{ root: classes.root }}
    >
      <Mui.ListItemAvatar>
        <Mui.Avatar>
          <Icons.UserPlus />
        </Mui.Avatar>
      </Mui.ListItemAvatar>
      <Mui.ListItemText
        primary={"Add a friend"}
        primaryTypographyProps={{
          className: classes.primaryText,
        }}
        className={classes.itemText}
      />
    </Mui.ListItem>
  );
  const createRoomButton = (
    <Mui.ListItem
      button
      onClick={handleCreateRoomClick}
      className={classes.item}
      classes={{ root: classes.root }}
    >
      <Mui.ListItemAvatar>
        <Mui.Avatar>
          <Icons.Users />
        </Mui.Avatar>
      </Mui.ListItemAvatar>
      <Mui.ListItemText
        primary={"Create a room"}
        primaryTypographyProps={{
          className: classes.primaryText,
        }}
        className={classes.itemText}
      />
    </Mui.ListItem>
  );

  return (
    <Mui.List
      className={classes.customPadding}
      style={{ paddingButton: "12px", height: "calc(100vh - 120px)" }}
    >
      {renderItems()}
    </Mui.List>
  );
};

export default SidebarItems;

SidebarItems.propTypes = {
  user: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
  roomFilter: PropTypes.string.isRequired,
};
