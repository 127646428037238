import client from "../../../client";
import mutation from "./changeAvatar.mutation";

/**
 * @param {string} avatar
 * @returns {Promise<{ avatar: string }>}
 */
export const changeAvatar = async function (avatar) {
  const { data } = await client.mutate({
    mutation,
    variables: {
      avatar,
    },
  });
  return data.updateUser;
};

export default changeAvatar;
