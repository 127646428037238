import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BackgroundSelect from "./BackgroundSelect.component";

import { addSnackbar, updateVideoEffects } from "../../store/actions";

const mapStateToProps = (state) => {
  const { videoEffects } = state.jitsi;
  return { videoEffects };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addSnackbar,
      updateVideoEffects,
    },
    dispatch
  );

export const BackgroundSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BackgroundSelect);

export default BackgroundSelectContainer;
