import React, { useState } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";

import { UsernameTextField } from "../../inputs";
import { LinearSubmitButton } from "../../prestyledComponents";
import { useMounted } from "../../../hooks/useMounted";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

export const ChangeUsernameForm = function ({ changeUsername, onSubmit }) {
  const [processing, setProcessing] = useState(false);
  const mounted = useMounted();

  const handleSubmit = formSubmitHandler(async ({ username }, event) => {
    try {
      setProcessing(true);
      await getBoundActionPromise(changeUsername, { username });
      if (onSubmit) onSubmit(event);
    } catch (error) {
      logError(error);
    } finally {
      if (mounted.current) {
        setProcessing(false);
      }
    }
  });

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      component="form"
      onSubmit={handleSubmit}
    >
      <Grid item>
        <UsernameTextField disabled={processing} />
      </Grid>
      <Grid item>
        <LinearSubmitButton loading={processing} children="Change username" />
      </Grid>
    </Grid>
  );
};

export default ChangeUsernameForm;

ChangeUsernameForm.propTypes = {
  changeUsername: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};
