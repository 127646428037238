export const ACTIVE = "ACTIVE";
export const SHORT_INACTIVE = "SHORT_INACTIVE";
export const LONG_INACTIVE = "LONG_INACTIVE";
export const DONT_DISTURB = "DONT_DISTURB";
export const OFFLINE = "OFFLINE";

export type UserStatus =
  | typeof ACTIVE
  | typeof SHORT_INACTIVE
  | typeof LONG_INACTIVE
  | typeof DONT_DISTURB
  | typeof OFFLINE;
