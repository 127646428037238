import { handleSagaError } from "XmarsStore/sagas/sagas.utils/handleSagaError";
import * as actions from "XmarsStore/actions/index";

import { localAudioTrackChannel } from "./localAudioTrackChannel";
import { processAudioSignal } from "./procesAudioSignal";
import { put, call, race, take, fork } from "redux-saga/effects";
import { audioTrackControl } from "./audioTrackControl";

export function* initLocalAudioTrack(track) {
  const channel = yield call(localAudioTrackChannel, track);
  yield fork(audioTrackControl, track);
  yield put(actions.setLocalAudioTrackReady(true));
  try {
    while (true) {
      const { signal, endLocalAudioTrack } = yield race({
        signal: take(channel),
        endLocalAudioTrack: take(actions.endLocalAudioTrack),
      });
      if (endLocalAudioTrack) {
        channel.close();
      } else {
        yield fork(processAudioSignal, { signal });
      }
    }
  } catch (error) {
    yield handleSagaError(error);
  } finally {
    console.log("disposing local audio track");
    yield track.dispose();
    yield put(actions.setLocalAudioTrackReady(false));
    yield put(actions.localAudioTrackRemoved());
  }
}
