import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { EmailTextField } from "../../inputs";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { isEmail } from "../../../utils/validators/isEmail";
import { isEmptyField } from "../../../utils/validators/isEmptyField";

import useStyles from "./GuestAuthForm.styles";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const GuestAuthForm = function ({ guestSignIn, data, ...props }) {
  const classes = useStyles();

  const handleSubmit = formSubmitHandler(({ email } = {}) => {
    if (validation({ email })) {
      guestSignIn({
        email,
        id: data.id,
        room: data.room,
      });
    }
  });

  const validation = ({ email } = {}) => {
    return isEmail(email) && !isEmptyField(email);
  };

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      component="form"
      onSubmit={handleSubmit}
    >
      <Mui.Avatar className={classes.avatar}>
        <Icons.User fontSize="large" />
      </Mui.Avatar>
      <Mui.Typography component="h1" variant="h5" align="center">
        Guest login
      </Mui.Typography>
      <Grid item>
        <EmailTextField
          {...props}
          placeholder="Type your e-mail"
          autoComplete="on"
        />
      </Grid>
      <Grid item>
        <Mui.Button fullWidth variant="contained" type="submit" color="primary">
          Login
        </Mui.Button>
      </Grid>
    </Grid>
  );
};

export default GuestAuthForm;

GuestAuthForm.propTypes = {
  guestSignIn: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
