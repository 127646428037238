import { eventChannel } from "redux-saga";
import {
  registerEventHandlers,
  unregisterEventHandlers,
} from "XmarsJitsiMeetJS/index";
import { createAudioTrackEventHandlers } from "./createAudioTrackEventHandlers";

export function localAudioTrackChannel(track) {
  const localAudioSubscription = (emitter) => {
    const audioTrackEventHandlers = createAudioTrackEventHandlers(emitter);
    registerEventHandlers("track", track, audioTrackEventHandlers);
    return () => {
      unregisterEventHandlers("track", track, audioTrackEventHandlers);
    };
  };
  return eventChannel(localAudioSubscription);
}
