import { useEffect, useState } from "react";

/**
 * @typedef setItem
 * @type {(value: string) => void}
 */

/**
 * To get and update easily localStorage items in react
 * @param {string} key
 * @param {string} defaultValue
 * @returns {[string,setItem]}
 */
export const useLocalStorage = function (key, defaultValue) {
  const [value, setValue] = useState(defaultValue);

  const setItem = function (newValue) {
    localStorage.setItem(key, newValue);
    setValue(newValue);
  };

  useEffect(() => {
    const savedValue = localStorage.getItem(key);
    if (savedValue) {
      setValue(savedValue);
    } else {
      localStorage.setItem(key, defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  return [value, setItem];
};

export default useLocalStorage;
