import React, { useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";

// eslint-disable-next-line
import { Props } from "./VideoGridButton.propTypes";

/**
 * @param {Props} props
 */
export const VideoGridButton = function ({
  videoGrid,
  conference,
  sharedScreen,
  setVideoGrid,
  mapRemoteParticipants,
  sendJitsiMessage,
  screenConference,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  /** @type {React.MouseEventHandler} */
  const handleClick = function (event) {
    setAnchorEl(event.target);
  };

  const handleClose = function () {
    setAnchorEl(null);
  };

  const getSelectionHandler = function (grid) {
    return function () {
      handleClose();
      setVideoGrid(grid);
      switch (grid) {
        case "gallery":
          const mapper = (participant) => ({
            ...participant,
            focused: true,
          });
          return mapRemoteParticipants(mapper);
        case "speaker":
          let focusConf;
          if (sharedScreen) {
            focusConf = sharedScreen;
          } else {
            focusConf = conference;
          }
          if (focusConf) {
            const message = { type: "speaker" };
            getBoundActionPromise(sendJitsiMessage, {
              conference: focusConf,
              message,
            }).catch(() => {});
          }
          return;
        default:
          return;
      }
    };
  };

  const renderIcon = function () {
    switch (videoGrid) {
      case "gallery":
        return <Icons.Grid />;
      case "speaker":
        return <Icons.User />;
      case "listener":
        return <Icons.Users />;
      default:
        return null;
    }
  };

  return (
    <>
      <Mui.Fab {...props} size="small" onClick={handleClick}>
        {renderIcon()}
      </Mui.Fab>
      <Mui.Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Mui.MenuItem onClick={getSelectionHandler("gallery")}>
          <Mui.ListItemIcon>
            <Mui.Icon>
              <Icons.Grid />
            </Mui.Icon>
          </Mui.ListItemIcon>
          <Mui.ListItemText primary="Gallery" />
        </Mui.MenuItem>
        <Mui.MenuItem onClick={getSelectionHandler("speaker")}>
          <Mui.ListItemIcon>
            <Mui.Icon>
              <Icons.User />
            </Mui.Icon>
          </Mui.ListItemIcon>
          <Mui.ListItemText primary="Speaker" />
        </Mui.MenuItem>
      </Mui.Menu>
    </>
  );
};

export default VideoGridButton;

VideoGridButton.propTypes = {
  setVideoGrid: PropTypes.func.isRequired,
};
