import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => {
  return {
    tip: {
      fontSize: "13px",
      background: "rgb(10,10,10,0.5)",
      height: "30px",
    },
    tipContainer: {
      marginLeft: "8px",
    },
    navBarButton: {
      textTransform: "capitalize",
      fontWeight: "300",
    },
    tipContainerMarginTop: {
      marginTop: "11px",
    },
  };
});
