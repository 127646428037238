import client from "../../../client";
import { addBearer } from "../../../utils";
import mutation from "./deleteCategory.mutation";

/**
 * @returns {Promise<{ username: string }>}
 */
export const deleteCategory = async function (id) {
  id = addBearer(id);
  const { data } = await client.mutate({
    mutation,
    variables: {
      input: {
        id,
      },
    },
  });
  return data.deleteCategory;
};

export default deleteCategory;
