import {
  put,
  call,
  race,
  take,
  fork,
  delay,
  select,
  cancel,
} from "redux-saga/effects";

import * as actions from "XmarsStore/actions";
import { getJitsiConnection } from "XmarsJitsiMeetJS/index";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils/handleSagaError";
import { jitsiConnectionChannel } from "./jitsiConnectionChannel";
import { waitForAnswer } from "./waitForAnswer";

import { jitsiHangSaga } from "../jitsiHangSaga";

import { processConnectionSignal } from "./processConnectionSignal";

const startJitsiCallGuts = function* ({ payload }) {
  const { meetingCode } = payload;
  const jitsiConnection = getJitsiConnection(meetingCode);
  const channel = yield call(jitsiConnectionChannel, jitsiConnection);
  const dialing = yield select((state) => state.video.callStatus.dialing);
  const callTasksList = [];
  if (dialing) {
    const waitTask = yield fork(waitForAnswer);
    callTasksList.push(waitTask);
  }

  try {
    while (true) {
      const { signal, endJitsiCall } = yield race({
        signal: take(channel),
        endJitsiCall: take(actions.jitsiCallHang),
      });
      if (endJitsiCall) {
        yield jitsiHangSaga();
        channel.close();
      } else {
        const callTask = yield fork(processConnectionSignal, {
          jitsiConnection,
          signal,
          meetingCode,
        });
        callTasksList.push(callTask);
      }
    }
  } catch (error) {
    yield handleSagaError(error);
  } finally {
    cancel(callTasksList);
  }
};

export const startJitsiCall = function* () {
  try {
    const incomingCall = yield select((state) => state.video.incomingCall);
    let meetingCode;
    if (incomingCall) {
      const { callAnswered, missedCall } = yield race({
        callAnswered: take(actions.setMeetingCode),
        missedCall: delay(15000, true),
      });
      if (missedCall) {
        yield put(
          actions.addSnackbar({
            severity: "info",
            message: "missed call",
          })
        );
        yield put(actions.setIncomingCall(null));
        yield put(actions.cleanConferenceRequest());
        return;
      }

      if (callAnswered.payload === "") {
        yield put(
          actions.addSnackbar({
            severity: "error",
            message: "no meeting code",
          })
        );
        yield put(actions.cleanConferenceRequest());
        return;
      }
      meetingCode = callAnswered.payload;
    } else {
      meetingCode = yield select((state) => state.video.meetingCode);
      console.log(meetingCode);
    }

    yield startJitsiCallGuts({ payload: { meetingCode } });
    yield call(console.log, "jitsi call really ended...");
  } catch (error) {
    yield call(handleSagaError, error);
  }
};
