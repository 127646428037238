import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CreateGuestRoomForm from "./CreateGuestRoomForm.component";

import { createPublicRoom } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createPublicRoom,
    },
    dispatch
  );

const CreateGuestRoomFormContainer = connect(
  null,
  mapDispatchToProps
)(CreateGuestRoomForm);

export default CreateGuestRoomFormContainer;
