/**
 * @typedef {Object} Coords
 * @property {number} x
 * @property {number} y
 */

/**
 * @param {Coords} start
 * @param {Coords} end
 */
export const getLinePath = function (start = {}, end = {}) {
  const { x: startX = 0, y: startY = 0 } = start;
  const { x: endX = 0, y: endY = 0 } = end;
  const path = new Path2D();
  path.moveTo(startX, startY);
  path.lineTo(endX, endY);
  return path;
};

export default getLinePath;
