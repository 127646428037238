import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SettingsPage from "./SettingsPage.component";

import { setPage } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPage,
    },
    dispatch
  );

const SettingsPageContainer = connect(null, mapDispatchToProps)(SettingsPage);

export default SettingsPageContainer;
