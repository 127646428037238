import { createAction } from "@reduxjs/toolkit";

export const guestSignIn = createAction("saga/guestSignIn");
export const createGuest = createAction("saga/createGuest");
export const deleteUser = createAction("saga/deleteUser");
export const deleteAppUser = createAction("saga/deleteAppUser");

export const login = createAction<{ email: string; password: string }>(
  "saga/login"
);
export const logout = createAction("saga/logout");
export const parseLink = createAction("saga/parseLink");
export const handshake = createAction("saga/handshake");
export const createUser = createAction("saga/createUser");

export const requestRecovery = createAction("saga/requestRecovery");
export const recoverPassword = createAction("saga/recoverPassword");

export const getUser = createAction("saga/getUser");
export const getFriends = createAction("saga/getFriends");
export const searchByUsername = createAction("saga/searchByUsername");
export const searchByEmail = createAction("saga/searchByEmail");
export const getUserByEmail = createAction("saga/getUserByEmail");
export const searchFriends = createAction("saga/searchFriends");
export const unfriend = createAction("saga/unfriend");

export const changeUsername = createAction("saga/changeUsername");
export const changeEmail = createAction("saga/changeEmail");
export const changePassword = createAction("saga/changePassword");
export const changeAvatar = createAction("saga/changeAvatar");
export const removeAvatar = createAction("saga/removeAvatar");

// TODO keep an eye on the code that has ActionPromise

// export const changeUsername = ({ username, promise = new ActionPromise("changeUsername") }) => ({
//     type: types.CHANGE_USERNAME,
//     payload: { username, promise },
// });

// export const changeEmail = ({ email, password, promise = new ActionPromise("changeEmail") }) => ({
//     type: types.CHANGE_EMAIL,
//     payload: { email, password, promise },
// });

// export const changePassword = ({ password, newPassword, promise = new ActionPromise("changePassword") }) => ({
//     type: types.CHANGE_PASSWORD,
//     payload: { password, newPassword, promise },
// });

// export const changeAvatar = ({ avatar, promise = new ActionPromise("changeAvatar") }) => ({
//     type: types.CHANGE_AVATAR,
//     payload: { avatar, promise },
// });

// export const removeAvatar = ({ promise = new ActionPromise("removeAvatar") } = {}) => ({
//     type: types.REMOVE_AVATAR,
//     payload: { promise },
// });
