import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SearchedFriends from "./SearchedFriends.component";

import { resetSearchedUsers } from "../../../store/actions";

const mapStateToProps = (state) => {
  const { searchedUsers } = state.chat;

  return { friends: searchedUsers };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetSearchedUsers,
    },
    dispatch
  );

const SearchedFriendsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchedFriends);

export default SearchedFriendsContainer;
