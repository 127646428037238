import { Badge } from "@mui/material";
import {
  ACTIVE,
  SHORT_INACTIVE,
  LONG_INACTIVE,
  DONT_DISTURB,
} from "../../../store/types/status";
import { indicatorStyles } from "./StatusIndicator.styles";

export const StatusIndicator = function ({ status, userBadgeProps, ...props }) {
  const classes = indicatorStyles();
  let className;
  let visible = true;
  switch (status) {
    case ACTIVE:
      className = classes.active;
      break;
    case DONT_DISTURB:
      className = classes.dontDisturb;
      break;
    case SHORT_INACTIVE:
      className = classes.shortInactive;
      break;
    case LONG_INACTIVE:
      className = classes.longInactive;
      break;
    default:
      visible = false;
      break;
  }
  return (
    <Badge
      classes={{ badge: className }}
      {...userBadgeProps}
      variant="dot"
      invisible={!visible}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      {props.children}
    </Badge>
  );
};
