import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import LocalVideo from "./LocalVideo.component";

import {
  addSnackbar,
  updateVideoEffects,
  jitsiRegisterVideoComponent,
  jitsiUnregisterVideoComponent,
} from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { videoEffects } = state.jitsi;
  const { localVideoTrackReady } = state.jitsi.localTracksData;

  return { videoEffects, localVideoTrackReady };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addSnackbar,
      updateVideoEffects,
      jitsiRegisterVideoComponent,
      jitsiUnregisterVideoComponent,
    },
    dispatch
  );

const LocalVideoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalVideo);

export default LocalVideoContainer;
