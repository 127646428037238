import * as Icons from "react-feather";
import { TooltipIconButton } from "../../prestyledComponents";

export const ReceiveVideoCallButton = function ({
  incomingCall,
  setPage,
  setMeetingCode,
  setActiveChannel,
  setIncomingCall,
  setOnlyAudio,
  ...props
}) {
  const handleClick = function (audio) {
    setIncomingCall(null);
    let { meetingCode, channel } = incomingCall;
    if (meetingCode === undefined) {
      meetingCode = channel.id;
    }
    setActiveChannel(channel);
    setMeetingCode(meetingCode);
    setPage("videoCall");
    setOnlyAudio(audio);
  };

  return (
    <>
      <TooltipIconButton
        {...props}
        onClick={() => handleClick(true)}
        title={"Answer Voice Call"}
        name={"AnswerVoiceCall"}
      >
        <Icons.Phone />
      </TooltipIconButton>
      <TooltipIconButton
        {...props}
        onClick={() => handleClick(false)}
        title={"Answer Video Call"}
        name={"AnswerVideoCall"}
      >
        <Icons.Video />
      </TooltipIconButton>
    </>
  );
};

export default ReceiveVideoCallButton;
