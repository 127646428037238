import { gql } from "../../../utils/apollo";

export const sendPushNotification = gql`
  mutation sendPushNotification(
    $endpoint: String
    $expirationTime: String
    $p256dh: String
    $auth: String
  ) {
    createPushObject(
      input: {
        endpoint: $endpoint
        expirationTime: $expirationTime
        p256dh: $p256dh
        auth: $auth
      }
    ) {
      null
    }
  }
`;

export default sendPushNotification;
