import { createSelector } from "@reduxjs/toolkit";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
const getActiveChannelId = (state) => state.chat.room?.id;

export const selectActiveChannelId = createSelector(
  getActiveChannelId,
  (id) => id
);
