import { useEffect } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  overflowingTextLine: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      overflow: "auto",
      textOverflow: "initial",
    },
  },
}));

export const useOverflowingTextline = function (ref) {
  const classes = useStyles();

  useEffect(() => {
    const element = ref.current;
    if (element) {
      element.classList.add(classes.overflowingTextLine);
      element.addEventListener("mouseleave", scrollToLeft);
      element.addEventListener("touchend", scrollToLeft);
      return () => {
        element.classList.remove(classes.overflowingTextLine);
        element.removeEventListener("mouseleave", scrollToLeft);
        element.removeEventListener("touchend", scrollToLeft);
      };
    }
    // eslint-disable-next-line
  }, [ref, ref.current]);

  const scrollToLeft = function (event) {
    const element = event.target;
    element.scrollLeft = 0;
  };

  return null;
};

export default useOverflowingTextline;
