import client from "../../../client";
import mutation from "./sendMessage.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} messageType
 * @param {string} content
 * @param {string} room_id
 * @returns {Promise<{
 *  id: string,
 *  edited: boolean,
 *  status: string,
 *  text: string,
 *  scribble: string,
 *  form: string,
 *  createdAt: number,
 *  owner: {
 *      avatar: string,
 *      username: string,
 *  },
 *  readers: Array<{
 *      id: string,
 *      avatar: string,
 *      username: string,
 *  }>,
 * }>}
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const sendMessage = async function (messageType, content, room_id) {
  room_id = addBearer(room_id);
  const text =
    messageType === "text" || messageType === "file" ? content : null;
  const scribble = messageType === "scribble" ? content : null;
  const { data } = await client.mutate({
    mutation,
    variables: {
      messageType,
      room_id,
      text,
      scribble,
    },
  });
  return data.sendMessage;
};

export default sendMessage;
