import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { LinearSubmitButton } from "../../prestyledComponents";
import { EmailTextField } from "../../inputs";
import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { useForm } from "../../../hooks/useForm";
import useStyles from "./RequestRecoveryForm.styles";

import logo from "../../../assets/images/logo-sb.png";

const formName = Symbol("requestRecovery");

export const RequestRecoveryForm = function ({ setPage, requestRecovery }) {
  const classes = useStyles();
  const { submitting } = useForm(formName);

  const handleSubmit = formSubmitHandler(({ email }) => {
    requestRecovery({ email, formName });
  });

  const handleClick = () => setPage("login");

  return (
    <Mui.Paper variant="outlined" elevation={0} className={classes.formBox}>
      <Mui.Box className={classes.logo}>
        <img src={logo} alt="Sibatel logo" className={classes.logoImg} />
      </Mui.Box>
      <Mui.Typography
        variant="h5"
        align="center"
        gutterBottom
        className={classes.title}
      >
        Password recovery
      </Mui.Typography>

      <Grid
        container
        component="form"
        direction="column"
        spacing={3}
        onSubmit={handleSubmit}
      >
        <Grid
          item
          sx={{ pb: 1.5, pl: 0, pr: 0 }}
          component={EmailTextField}
          disabled={submitting}
        />
        <Grid item className={classes.removeVPadding}>
          <LinearSubmitButton
            loading={submitting}
            children="Request Password Recovery"
            className={classes.actionButton}
          />
        </Grid>

        <Mui.Divider className={classes.divider} variant="middle" />

        <Grid item className={classes.linkBox}>
          <Mui.Link
            onClick={handleClick}
            variant="button"
            className={classes.routeButton}
          >
            Return to login
          </Mui.Link>
        </Grid>
      </Grid>
    </Mui.Paper>
  );
};

export default RequestRecoveryForm;

RequestRecoveryForm.propTypes = {
  setPage: PropTypes.func.isRequired,
  requestRecovery: PropTypes.func.isRequired,
};
