import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  avatar: {
    // margin: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    // backgroundColor: theme.palette.secondary.main,
  },
}));
