import React, { useEffect, useRef, useState } from "react";
import * as Mui from "@mui/material";
import { VerticalScrollbars } from "../../prestyledComponents";
import { PaginationControls } from "../../PaginationControls";
import { FoundFile } from "../../collectionItems";
import { FoundItemsLoading } from "../FoundItemsLoading";

import useStyles from "./FoundFiles.styles";

const scrollBarsStyles = { padding: "30px", height: "calc(100vh - 140px)" };

export const FoundFiles = function ({
  files,
  totalFiles,
  resultsPerPage,
  searchedValue,
  searchPageAndSection,
}) {
  const classes = useStyles();

  const count = Math.ceil(totalFiles / resultsPerPage);
  const [page, setPage] = useState(1);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const ref = useRef();

  const handleChange = (_, value) => {
    setPage(value);
    setIsLoadingFiles(true);
    ref.current.scrollTop = 0;
    searchPageAndSection({ searchedValue, page: value, section: ["Files"] });
  };

  useEffect(() => {
    setIsLoadingFiles(false);
  }, [files]);

  /**
   * Renders the found files.
   * @returns {React.ReactElement} List with the files.
   */
  const renderFiles = function () {
    if (!files?.length)
      return (
        <Mui.ListItem>
          <Mui.ListItemText primary="No results" />
        </Mui.ListItem>
      );

    return (
      <Mui.Box>
        {isLoadingFiles ? (
          <FoundItemsLoading style={{ padding: "0px", marginTop: "-8px" }} />
        ) : (
          <>
            {files?.map((file, index) => (
              <FoundFile
                key={`found-file-${index}`}
                message={file}
                //containerRef={ref}
                position={"unique"}
                //isDrawer={isDrawer}
              />
            ))}
          </>
        )}
      </Mui.Box>
    );
  };

  return (
    <VerticalScrollbars
      viewStyle={scrollBarsStyles}
      ref={(s) => (ref.current = s && s.view)}
    >
      <Mui.List className={classes.filesContainer}>
        {renderFiles()}
        {count > 1 && (
          <PaginationControls
            count={count}
            page={page}
            handleChange={handleChange}
            disabled={isLoadingFiles}
          />
        )}
      </Mui.List>
    </VerticalScrollbars>
  );
};

export default FoundFiles;
