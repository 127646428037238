import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  item: {
    padding: "6px 13px",
    borderRadius: "7px",
    //maxWidth: "80%",
  },

  ownMessage: {
    justifyContent: "flex-end",
    padding: 0,
    paddingTop: "2px",
    marginBottom: "0",
    transition: "background 500ms",
  },
  otherMessage: {
    padding: 0,
    paddingTop: "2px",
    marginBottom: "-4px",
    marginTop: "4px",
    transition: "background 500ms",
  },
  messageHighlighted: {
    background: theme.palette.messages.highlightedBg,
    //border: "solid",
    borderColor: theme.palette.messages.highlightedBg,
  },
  lastMessage: {
    //marginBottom: "24px",
    //borderBottomWidth: "24px",
  },
  username: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  date: {
    fontSize: 12,
    fontStyle: "italic",
    marginTop: "2px",
    color: theme.palette.messages.time,
    lineHeight: 1.5,
  },
  ownDate: {
    //width: "100%",
  },
  scribble: {
    maxHeight: "15em",
    maxWidth: "15em",
  },
  scribbleSmall: {
    maxWidth: "11.5em",

    [theme.breakpoints.down(theme.breakpoints.values.videoLg)]: {
      maxWidth: "10em",
    },
  },
  adminMessage: {
    backgroundColor: theme.palette.messages.lightOther,
    color: theme.palette.common.black,
    opacity: 0.8,

    "&:last-of-type": {
      marginBottom: "1rem",
    },
  },
  content: {
    maxWidth: `${(100 * 4) / 5}%`,
    padding: 0,
    marginRight: "-11px",
  },
  ownText: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.messages.textOwn,
    width: "max-content",
    paddingRight: "20px",
  },

  ownTextWithReplies: {
    marginRight: "0px",
  },
  ownTextNoReplies: {
    marginRight: "60px",
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  messageWidthNoPanel: {
    maxWidth: "40vw",
  },
  messageWidthInDrawer: {
    maxWidth: "194px",
  },
  messageWidthPanel: {
    maxWidth: "20vw",
  },
  highLightedWithNoPanel: {
    //width: "60vw",
    //width: "43vw",
    width: "425px",
  },
  ownImageNoReplies: {
    marginRight: "60px",
  },
  highLightedWithPanel: {
    width: "38vw",
  },
  ownSecondLevel: {
    justifyContent: "flex-end",
  },
  otherText: {
    backgroundColor: theme.palette.messages.lightOther,
    color: "#393939",
    width: "max-content",
    paddingRight: "20px",
  },
  ownNonText: {
    justifyContent: "flex-end",
  },
  noPadding: {
    padding: 0,
  },

  secondInfo: {
    // border: "solid",
  },
  editedLabel: {
    fontSize: "0.85em",
    color: "gray",
  },

  invisible: {
    opacity: 0,
  },
  text: {
    fontSize: 14,
    fontWeight: 400,
  },
  messageInfo: {
    padding: 0,
    marginBottom: "0px",
  },
  messageInfoText: {
    flexGrow: 0,
    textAlign: "left",
    margin: 0,
  },
  ownMessageItem: {
    justifyContent: "flex-end",
  },
  messageInfoTextLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  messageContent: {
    border: "solid",
  },
  messageContentPadding: {
    padding: "0px",
    //width: "70%",
  },
  textMessageBody: {
    margin: 0,
    marginRight: "4px",
    wordBreak: "break-word",
  },
  ownHeader: {
    marginRight: "60px",
  },

  messageHeader: {
    fontSize: "12px",
    color: "#656566",
    fontWeight: 500,
    fontFamily: "Inter, sans-serif",
    marginBottom: "5px",
  },
  avatar: {
    marginRight: "-8px",
  },
  messageMenu: {
    width: "0.7em",
    height: "1em",
    position: "absolute",
    top: "7px",
    right: "2px",
    zIndex: 10,
  },
  messageMenuIcon: {
    // transform: "scale(0.7)",
    strokeColor: "black",
  },
  fileThreeDots: {
    top: "12px",
    right: "10px",
  },
  scribbleButton: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  usernameStyle: {
    textTransform: "capitalize",
  },

  secondLevel: {
    display: "flex",
    //justifyContent: "flex-end",
    //border: "solid",
    //width: "100%",
  },

  hidden: {
    visibility: "hidden",
  },

  ownTextContainer: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  conversationMenuOptionItem: {
    padding: "0px 10px",
  },
  conversationMenuOptionIcon: {
    minWidth: "24px",
  },
  conversationMenuOptionText: {
    fontSize: "13px",
    fontWeight: "300",
    color: theme.palette.buttons.main1,
  },

  fileContent: {
    width: "320px",
    padding: "0px",
  },
  fileContentInDrawer: {
    width: "180px",
  },
  imageContent: {
    maxWidth: "360px",
  },
  imageContentInDrawer: {
    maxWidth: "220px",
  },
  marginBottom: {
    marginBottom: "27px",
  },
}));
