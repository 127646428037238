import React, { useRef, useState } from "react";
import { Grid } from "@mui/material";
// import * as Icons  from "react-feather";
import PropTypes from "prop-types";

import { DatePicker, EmailTextField, UsernameTextField } from "../../inputs";
// import { LinearSubmitButton } from "../../prestyledComponents";
import { useMounted } from "../../../hooks/useMounted";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

export const CreateMedicalRoomForm = function ({
  createMedicalRoom,
  onSubmit,
}) {
  const [processing, setProcessing] = useState(false);
  const mounted = useMounted();

  const formRef = useRef(null);

  const handleSubmit = formSubmitHandler(
    async ({ date, username, email }, event) => {
      try {
        setProcessing(true);
        date = new Date(parseInt(date));
        await getBoundActionPromise(createMedicalRoom, {
          date,
          username,
          email,
        });
        if (onSubmit) onSubmit(event);
      } catch (error) {
        logError(error);
      } finally {
        if (mounted.current) {
          setProcessing(false);
        }
      }
    }
  );

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      component="form"
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <Grid item>
        <DatePicker disabled={processing} />
      </Grid>
      <Grid item>
        <UsernameTextField disabled={processing} />
      </Grid>
      <Grid item>
        <EmailTextField disabled={processing} />
      </Grid>
      {/* <Grid item>
        <LinearSubmitButton
          loading={processing}
          children="Create Medical Room"
          endIcon={<Icons.Heart />}
        />
      </Grid>
      <Grid item>
      </Grid> */}
    </Grid>
  );
};

export default CreateMedicalRoomForm;

CreateMedicalRoomForm.propTypes = {
  onSubmit: PropTypes.func,
  createMedicalRoom: PropTypes.func.isRequired,
};
