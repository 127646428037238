import client from "../../../client";
import query from "./searchByUsername.query";

/**
 * @param {string} username
 * @returns {Promise<Array<{
 *  id: string,
 *  username: string,
 *  avatar: string,
 * }>>}
 */
export const searchByUsername = async function (username) {
  const { data } = await client.query({
    query,
    variables: {
      username,
    },
  });
  return data.searchByUsername;
};

export default searchByUsername;
