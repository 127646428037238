import { makeStyles } from "@mui/styles";

import { classes } from "../../../style";

export default makeStyles((theme) => ({
  file: {
    position: "relative",
    padding: "0 10px 0 0",
    width: "190px",
    height: "80px",
    margin: "0.25em",
    borderRadius: "0.25em",
    backgroundColor: theme.palette.common.white,
    overflowX: "auto",
    "&::-webkit-scrollbar": { width: "5px", height: "5px" },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  fileDrawer: {
    width: "130px",
  },
  thumbnail: {
    marginRight: "0.5em",
    ...classes.avatarThumbnail,
    maxWidth: "80px",
  },
  fileName: {
    fontWeight: 700,
    fontSize: "1rem",
  },
  fileNameDrawer: {
    fontSize: "0.85rem",
  },
  fileSize: {
    fontWeight: 500,
    fontSize: "0.85rem",
  },
  fileSizeDrawer: {
    fontSize: "0.7rem",
  },
  fileIcon: {
    "& > svg": {
      height: "50px !important",
      width: "50px !important",
    },
  },
}));
