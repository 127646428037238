import { getOffscreenCanvas } from "./canvas";

/**
 * @callback configCallback
 * @param {CanvasRenderingContext2D} ctx
 */

/**
 * @param {CanvasImageSource} media
 * @param {Object} options
 * @param {configCallback} [options.configCallback]
 * @param {number} [options.width]
 * @param {number} [options.height]
 * @returns {ImageData}
 */
export const getImageDataFromMedia = function (
  media,
  { configCallback, width: newWidth, height: newHeight } = {}
) {
  const { width, height } = media;
  const resize = !!newWidth && !!newHeight;
  const canvas = resize
    ? getOffscreenCanvas(newWidth, newHeight)
    : getOffscreenCanvas(width, height);
  const ctx = canvas.getContext("2d");
  if (configCallback) configCallback(ctx);
  if (resize) {
    ctx.drawImage(media, 0, 0, width, height, 0, 0, newWidth, newHeight);
  } else {
    ctx.drawImage(media, 0, 0, width, height);
  }
  const imageData = resize
    ? ctx.getImageData(0, 0, newWidth, newHeight)
    : ctx.getImageData(0, 0, width, height);
  return imageData;
};

export default getImageDataFromMedia;
