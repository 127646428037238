import React, { useEffect, useMemo, useRef, useState } from "react";
//import Box from "@mui/material/Box";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import { Alert } from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import clsx from "clsx";

import {
  MessageFileContent,
  MessageFormContent,
  MessageStatusIcon,
} from "../../pseudoItems";
import { stringAvatar } from "../../../utils/defaultAvatar/defaultAvatar";
import { NumberOfRepliesButton } from "../../buttons";

import useStyles from "./Message.styles";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import {
  useMounted,
  useOnScreen,
  //useAutoScroll,
  //useDependency,
} from "../../../hooks/index";
import { logError } from "../../../utils/errors/logError";

import { MessageMenu } from "./MessageMenu";

/**
 * Individual message.
 * @component
 * @param {Object} user - Information of the current user.
 * @param {function} deleteMessage - Action to delete the current message.
 * @param {function} readMessage - Action to set the message as read.
 * @param {RefType} containerRef - Reference to the container of the messages.
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const Message = function ({
  user,
  message,
  isDrawer,
  deleteMessage,
  readMessage,
  isGroupRoom,
  containerRef,
  setRepliesPanelOpen,
  setCurrentMessage,
  currentMessage,
  setIsEditing,
  setEditingMessage,
  position,
  repliesPanelOpen,
  shouldScrollIntoView,
  setMessageToJumpTo,
  unreadRepliesPanelOpen,
}) {
  const [showingDotsButton, setShowingDotsButton] = useState(false);
  const [readTimeout, setReadTimeout] = useState(false);
  const [hasReplies, setHasReplies] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [highlighted, setHighlighted] = useState(false);

  const file = message.messageType === "file" ? JSON.parse(message.text) : null;

  //if (file?.thumbnail) console.log("here", message);

  const mounted = useMounted(false);
  const ref = useRef(false);
  const onScreen = useOnScreen(ref);

  const shouldRead = useMemo(
    () =>
      message.owner.username !== "You" &&
      (message.status !== "read" ||
        (message.status === "read" &&
          !message.readers.some((reader) => reader.id === user.id))),
    // eslint-disable-next-line
    [message]
  );

  const classes = useStyles();

  useEffect(() => {
    if (message.replies && message.replies.length > 0) {
      setHasReplies(true);
    } else {
      setHasReplies(false);
    }

    if (currentMessage && currentMessage.id === message.id) {
      setCurrentMessage(message);
    }
  }, [message]);

  useEffect(() => {
    if (shouldRead) {
      if (!readTimeout) {
        // console.log("mounted.current", mounted.current);
        // console.log("onScreen", onScreen);
        if (onScreen) {
          const timeout = setTimeout(() => {
            if (mounted.current) {
              readMessage({ message });
            }
          }, 8);
          setReadTimeout(timeout);
        }
      } else {
        if (!onScreen) {
          clearTimeout(readTimeout);
          setReadTimeout(null);
        }
      }
    }
    // eslint-disable-next-line
  }, [onScreen]);

  useEffect(() => {
    if (shouldScrollIntoView) {
      if (!onScreen) {
        containerRef.current.scrollTop = ref.current.offsetTop || 10;
      }
      setMessageToJumpTo(null);
      setHighlighted(true);
      setTimeout(() => {
        if (mounted.current) setHighlighted(false);
      }, 3000);
    }
  }, [shouldScrollIntoView, onScreen]);

  const username =
    message.messageType === "form" ? "You" : message.owner.username;
  const own = username === "You";
  const hasThumbnail =
    file?.thumbnail && !file?.thumbnail?.slice(0, 18)?.includes("svg");

  const dateTimeAdmin = format(message.createdAt, "d LLL, h:mm a");
  const dateTime = format(message.createdAt, "hh:mm a");

  const handleMouseMove = () => setShowingDotsButton(true);

  const handleMouseLeave = () => setShowingDotsButton(false);

  /**
   * Opens the form to write a reply.
   */
  const handleOpenReplyForm = function () {
    setContextMenu(null);
    setShowingDotsButton(false);
    setCurrentMessage(message);
    //setDraft(message.text);
    setRepliesPanelOpen(true);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
    setShowingDotsButton(false);
  };

  /**
   * Starts the process to delete the current message.
   * @async
   */
  const handleDelete = async function () {
    try {
      await getBoundActionPromise(deleteMessage, { message });
    } catch (error) {
      logError(error);
    } finally {
      if (mounted.current) {
        setContextMenu(null);
        setShowingDotsButton(false);
      }
    }
  };

  /**
   * Organize all the classes that correspond to the current message.
   * @returns {string} The classes corresponding to the current message.
   */
  const getContentClassName = function () {
    const clsxParams = [classes.item];
    if (own) {
      if (message.messageType === "text") {
        clsxParams.push(classes.ownText);
        if (message.replies?.length) {
          clsxParams.push(classes.ownTextWithReplies);
        } else {
          clsxParams.push(classes.ownTextNoReplies);
        }
      } else {
        clsxParams.push(classes.ownNonText);
      }
    } else {
      if (message.messageType === "text") clsxParams.push(classes.otherText);
    }

    if (position === "end" || position === "unique") {
      clsxParams.push(classes.lastMessage);
    }
    return clsx(...clsxParams);
  };

  /**
   * Return the status icon. The status can be send and received.
   * @returns {React.ReactElement|null} Icon that shows the status.
   */
  const renderMessageStatusIcon = function () {
    return !own ? null : <MessageStatusIcon message={message} />;
  };

  /**
   * Opens the panel with the replies.
   */
  function handleOpenReplies() {
    setRepliesPanelOpen(true);
    setCurrentMessage(message);
  }

  /*
   *
   *
   */
  const handleOpenEditing = function () {
    if (message.owner.username !== "You") return;
    setContextMenu(null);
    setShowingDotsButton(false);

    setEditingMessage(message);
    setIsEditing(true);
  };

  /**
   * Renders the contend depending on the message type.
   * @returns {React.ReactElement} - The content
   */
  const renderContent = function () {
    switch (message.messageType) {
      case "form":
        return (
          <Mui.ListItem
            className={getContentClassName()}
            onClick={handleContextMenu}
          >
            <MessageFormContent message={message} />
          </Mui.ListItem>
        );
      case "file":
        return (
          <Mui.List>
            <Mui.ListItem
              style={{
                padding: "0px",
                marginRight:
                  !message.replies?.length && own && !hasThumbnail
                    ? "59px"
                    : "0px",
              }}
              className={clsx(
                getContentClassName(),
                hasThumbnail
                  ? isDrawer
                    ? classes.imageContentInDrawer
                    : classes.imageContent
                  : isDrawer
                  ? classes.fileContentInDrawer
                  : classes.fileContent
              )}
              //onClick={handleContextMenu}
              onMouseEnter={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              onContextMenu={handleContextMenu}
            >
              <MessageFileContent message={message} file={file} />
              {showingDotsButton && (
                <Mui.IconButton
                  className={clsx(classes.messageMenu, classes.fileThreeDots)}
                  onClick={handleContextMenu}
                >
                  <Icons.MoreVertical
                  // classes={{ root: classes.messageMenuIcon }}
                  />
                </Mui.IconButton>
              )}
            </Mui.ListItem>
          </Mui.List>
        );
      case "text":
        const messageText = (
          <>
            {message.text}
            {"  "}
            {message.edited && (
              <span className={classes.editedLabel}>(edited)</span>
            )}
          </>
        );

        return (
          <Mui.List
            classes={{
              padding: classes.messageContentPadding,
              root: own && classes.alignRight,
            }}
            onMouseEnter={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onContextMenu={handleContextMenu}
          >
            <Mui.ListItem
              className={clsx(
                getContentClassName(),
                repliesPanelOpen || unreadRepliesPanelOpen
                  ? classes.messageWidthPanel
                  : isDrawer
                  ? classes.messageWidthInDrawer
                  : classes.messageWidthNoPanel
              )}
            >
              <Mui.ListItemText
                primary={messageText}
                primaryTypographyProps={{ className: classes.text }}
                className={classes.textMessageBody}
                onDoubleClick={handleOpenEditing}
              />
              {showingDotsButton && (
                <Mui.IconButton
                  className={classes.messageMenu}
                  onClick={handleContextMenu}
                >
                  <Icons.MoreVertical
                  // classes={{ root: classes.messageMenuIcon }}
                  />
                </Mui.IconButton>
              )}
            </Mui.ListItem>
          </Mui.List>
        );
      case "scribble":
        return (
          <Mui.List
            classes={{ padding: classes.messageContentPadding }}
            onMouseEnter={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onContextMenu={handleContextMenu}
          >
            <Mui.ListItem className={getContentClassName()}>
              <img
                alt="scribble"
                src={message.scribble}
                className={clsx(
                  classes.scribble,
                  isDrawer ? classes.scribbleSmall : null
                )}
              />
              <div style={{ minWidth: "1em" }} />
            </Mui.ListItem>
            {showingDotsButton && (
              <Mui.IconButton
                className={classes.scribbleButton}
                onClick={handleContextMenu}
              >
                <Icons.MoreVertical />
              </Mui.IconButton>
            )}
          </Mui.List>
        );
      default:
        return (
          <Alert severity="error" variant="filled">
            {`Unhandled message type "${message.messageType}"`}
          </Alert>
        );
    }
  };

  const messageMenu = (
    <MessageMenu contextMenu={contextMenu} onClose={handleClose}>
      {message.owner.username !== "You" && (
        <Mui.MenuItem classes={{ root: classes.conversationMenuOptionItem }}>
          <Mui.ListItemIcon
            classes={{ root: classes.conversationMenuOptionIcon }}
          >
            <Icons.Check />
          </Mui.ListItemIcon>
          <Mui.ListItemText
            primary="Mark as Unread"
            disableTypography
            className={classes.conversationMenuOptionText}
          />
        </Mui.MenuItem>
      )}
      {message.owner.username === "You" && message.messageType === "text" && (
        <Mui.MenuItem
          onClick={handleOpenEditing}
          classes={{ root: classes.conversationMenuOptionItem }}
        >
          <Mui.ListItemIcon
            classes={{ root: classes.conversationMenuOptionIcon }}
          >
            <Icons.Tag />
          </Mui.ListItemIcon>
          <Mui.ListItemText
            primary="Edit"
            disableTypography
            className={classes.conversationMenuOptionText}
          />
        </Mui.MenuItem>
      )}
      {true && (
        // {isGroupRoom && (
        <Mui.MenuItem
          onClick={handleOpenReplyForm}
          classes={{ root: classes.conversationMenuOptionItem }}
        >
          <Mui.ListItemIcon
            classes={{ root: classes.conversationMenuOptionIcon }}
          >
            <Icons.Repeat />
          </Mui.ListItemIcon>
          <Mui.ListItemText
            primary="Reply Message"
            disableTypography
            className={classes.conversationMenuOptionText}
          />
        </Mui.MenuItem>
      )}
      <Mui.MenuItem classes={{ root: classes.conversationMenuOptionItem }}>
        <Mui.ListItemIcon
          classes={{ root: classes.conversationMenuOptionIcon }}
        >
          <Icons.Share />
        </Mui.ListItemIcon>
        <Mui.ListItemText
          primary="Forward Message"
          disableTypography
          className={classes.conversationMenuOptionText}
        />
      </Mui.MenuItem>
      <Mui.MenuItem classes={{ root: classes.conversationMenuOptionItem }}>
        <Mui.ListItemIcon
          classes={{ root: classes.conversationMenuOptionIcon }}
        >
          <Icons.Flag />
        </Mui.ListItemIcon>
        <Mui.ListItemText
          primary="Pin Message"
          disableTypography
          className={classes.conversationMenuOptionText}
        />
      </Mui.MenuItem>
      <Mui.MenuItem classes={{ root: classes.conversationMenuOptionItem }}>
        <Mui.ListItemIcon
          classes={{ root: classes.conversationMenuOptionIcon }}
        >
          <Icons.File />
        </Mui.ListItemIcon>
        <Mui.ListItemText
          primary="Message Reminder"
          disableTypography
          className={classes.conversationMenuOptionText}
        />
      </Mui.MenuItem>
      {message.owner.username === "You" && (
        <Mui.MenuItem
          onClick={handleDelete}
          classes={{ root: classes.conversationMenuOptionItem }}
        >
          <Mui.ListItemIcon
            classes={{ root: classes.conversationMenuOptionIcon }}
          >
            <Icons.Trash />
          </Mui.ListItemIcon>
          <Mui.ListItemText
            primary="Delete Message"
            disableTypography
            className={classes.conversationMenuOptionText}
          />
        </Mui.MenuItem>
      )}
    </MessageMenu>
  );

  return username === "Admin" ? (
    <Alert
      ref={ref}
      className={classes.adminMessage}
      icon={<Icons.Info color="action" />}
      severity="info"
    >
      {message.text}
      <Mui.Typography className={classes.date}>{dateTimeAdmin}</Mui.Typography>
    </Alert>
  ) : (
    <>
      <Mui.ListItem
        innerRef={ref}
        ref={ref}
        className={clsx(own ? classes.ownMessage : classes.otherMessage)}
      >
        <Mui.List
          className={clsx(
            classes.content
            // highlighted ? classes.messageHighlighted : null
          )}
        >
          <Mui.ListItem
            className={clsx(
              classes.item,
              own ? classes.ownMessageItem : null,
              classes.messageInfo
            )}
          >
            {!own && (
              <Mui.ListItemAvatar
                classes={{
                  root: clsx(
                    classes.avatar,
                    position !== "start" &&
                      position !== "unique" &&
                      classes.hidden
                  ),
                }}
                onMouseEnter={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onContextMenu={handleContextMenu}
              >
                <Mui.Avatar
                  src={message.owner.avatar}
                  classes={{ root: classes.avatar }}
                  {...stringAvatar(own ? user.username : username)}
                />
              </Mui.ListItemAvatar>
            )}

            <Mui.Box className={clsx(own && classes.ownTextContainer)}>
              {(position === "start" || position === "unique") && (
                <Mui.Box
                  className={clsx(
                    classes.messageHeader,
                    own && classes.ownHeader
                  )}
                >
                  {own ? null : (
                    <span className={classes.usernameStyle}>{username},</span>
                  )}{" "}
                  {dateTime}{" "}
                </Mui.Box>
              )}
              <Mui.Box
                className={clsx(
                  classes.secondLevel,
                  highlighted ? classes.messageHighlighted : null,
                  repliesPanelOpen || unreadRepliesPanelOpen
                    ? classes.highLightedWithPanel
                    : classes.highLightedWithNoPanel,
                  own ? classes.ownSecondLevel : null,
                  (position === "end" || position === "unique") &&
                    classes.marginBottom,
                  own &&
                    !message.replies?.length &&
                    hasThumbnail &&
                    classes.ownImageNoReplies
                )}
              >
                {renderContent()}
                {hasReplies && (
                  <NumberOfRepliesButton
                    message={message}
                    own={own}
                    handleOpenReplies={handleOpenReplies}
                    position={position}
                  />
                )}
              </Mui.Box>
            </Mui.Box>
          </Mui.ListItem>
          {(message.messageType === "text" ||
            message.messageType === "scribble") &&
          (position === "end" || position === "unique")
            ? renderMessageStatusIcon()
            : null}
          {messageMenu}
        </Mui.List>
      </Mui.ListItem>
    </>
  );
};

export default Message;

Message.propTypes = {
  message: PropTypes.object.isRequired,
  deleteMessage: PropTypes.func.isRequired,
};
