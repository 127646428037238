import formInitialState from "./initialState";
import type { PayloadAction } from "@reduxjs/toolkit";
type SubmitFailArgs = { name: string; error: any };

export function initForm(state: any, { payload }: any) {
  return {
    ...formInitialState,
    [payload]: initForm,
  };
}

export function submit(state: any, { payload }: any) {
  state[payload].submitting = true;
}

export function submitSuccess(state: any, { payload }: any) {
  state[payload].submitting = false;
  state[payload].error = null;
}

export const submitFail = {
  reducer(state: any, { payload }: PayloadAction<SubmitFailArgs>) {
    state[payload.name].submitting = false;
    state[payload.name].error = payload.error;
  },
  prepare: (name: string, error: any) => {
    return { payload: { name, error } };
  },
};

export function clear(state: any, { payload }: any) {
  delete state[payload];
}
