import React, { useEffect, useState } from "react";
import * as Icons from "react-feather";

import { TooltipIconButton } from "../../prestyledComponents";

// eslint-disable-next-line
import { Props } from "./MuteAudioButton.propTypes";

/**
 * @param {Props} props
 */
export const MuteAudioButton = function ({
  localAudioTrackReady,
  localAudioIsMuted,
  localAudioTrackController,
  ...props
}) {
  const muted = localAudioIsMuted;

  const handleClick = () => {
    localAudioTrackController({
      command: "toggle",
    });
  };

  return (
    <TooltipIconButton
      {...props}
      disabled={!localAudioTrackReady}
      aria-label="micro"
      onClick={handleClick}
      TooltipProps={{ placement: "top" }}
      // color={muted ? "secondary" : undefined}
      name={"Mic"}
      title={muted ? "Unmute" : "Mute"}
    >
      {muted ? <Icons.MicOff /> : <Icons.Mic />}
    </TooltipIconButton>
  );
};

export default MuteAudioButton;
