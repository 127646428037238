// eslint-disable-next-line
import { HandType } from "./handTracking.utils";

export class LockTracker {
  locked = false;
  #cooling = false;
  #switching = false;
  #start = 0;

  constructor({ time = 3000, onChange } = {}) {
    this.time = time;
    this.onChange = onChange;
  }

  _cool() {
    this.#cooling = true;
    setTimeout(() => (this.#cooling = false), this.time);
  }

  reset() {
    this.#start = 0;
    this.#switching = false;
  }

  /**
   * @param {Object} predictions
   * @param {Array<HandType>} predictions.open
   */
  handlePredictions({ open }) {
    const singleHand = open.length === 1;
    const [lockHand] = open;
    let value = 0;
    if (this.#cooling) return [lockHand, value];
    const now = Date.now();
    if (this.#switching) {
      if (singleHand) {
        value = ((now - this.#start) / this.time) * 100;
        if (this.locked) value = 100 - value;
        if (now - this.#start > this.time) {
          // Switching
          this.locked = !this.locked;
          this.reset();
          this._cool();
          if (this.onChange) {
            this.onChange(this.locked);
          } else {
            console.warn("onChange missing", this.locked);
          }
        }
        // else {
        //     // Continuing
        // };
      } else {
        // Aborting
        this.reset();
      }
    } else {
      if (singleHand) {
        // Starting
        this.#start = now;
        this.#switching = true;
      }
      // else {
      //     // Nothing
      // };
    }
    return [lockHand, value];
  }
}

export default LockTracker;
