import { call, put } from "redux-saga/effects";

import api from "../../../../dataApi";
import { registerFriend, registerRoom, registerWorkpace } from "../utils";

import * as actions from "../../../actions";
import { handleSagaError } from "../../sagas.utils";
import { ContactlessOutlined } from "@mui/icons-material";

export const getNotifications = function* () {
  try {
    const notifications = yield call(api.getNotifications);
    yield put(actions.setNotifications(notifications));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const sendFriendRequest = function* ({ payload: user }) {
  try {
    yield call(api.sendNotification, "friendRequest", user.id);
    const snackbar = {
      severity: "success",
      message: `Friend request sent to ${user.username}`,
    };
    yield put(actions.addSnackbar(snackbar));
    yield put(actions.updateSearchedUser(user, { friendRequestSent: true }));
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const sendRoomInvitation = function* ({ payload }) {
  try {
    const { friend, room } = payload;
    yield call(api.sendNotification, "roomInvitation", friend.id, room.id);
    const snackbar = {
      severity: "success",
      message: `${room.title}'s room invitation sent to ${friend.username}`,
    };
    yield put(actions.addSnackbar(snackbar));
    yield put(actions.updateSearchedUser(friend, { roomInvitationSent: true }));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const sendWorkspaceInvitation = function* ({ payload }) {
  try {
    const { workspace, receivingUser, invitationMessage } = payload;
    yield call(
      api.sendNotification,
      "workspaceRequest",
      receivingUser,
      workspace.id,
      invitationMessage
    );
    const snackbar = {
      severity: "success",
      message: `${receivingUser} has been invited to ${workspace.title}`,
    };
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const rejectNotification = function* ({ payload }) {
  try {
    const notification = payload.notifData;
    yield call(api.rejectNotification, notification.id);
    yield put(actions.removeNotification(notification));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const acceptFriendRequest = function* ({ payload }) {
  try {
    yield call(api.acceptNotification, payload.id);
    yield put(actions.removeNotification(payload));
    yield registerFriend(payload.sender);
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const acceptRoomInvitation = function* ({ payload }) {
  try {
    yield call(api.acceptNotification, payload.id);
    yield put(actions.removeNotification(payload));
    registerRoom(payload.room);
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const acceptWorkspaceInvitation = function* ({ payload }) {
  try {
    console.log("Accept WS invitation debug:");
    console.log(payload);
    const notification = payload.notifData;
    yield call(api.acceptNotification, notification.id);
    yield put(actions.removeNotification(notification));
    const workspace = yield call(api.getWorkspace, notification.workspace.id);
    yield put(actions.addWorkspace(workspace));

    yield registerWorkpace(notification.workspace);
  } catch (error) {
    yield handleSagaError(error);
  }
};
