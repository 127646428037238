import React, { useRef, useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import textToClipboard from "../../../utils/browserAPIs/clipboard/textFromClipboard";
import { selectInputContent } from "../../../utils/input/selectInputContent/index";

import { TooltipIconButton } from "../../prestyledComponents";

import useStyles from "./PublicLinkButton.styles";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const PublicLinkButton = function ({ room, getPublicLink, guest = {} }) {
  const classes = useStyles();

  const { id, kind } = room;
  const { id: user_id } = guest;

  const [link, setLink] = useState("");
  const linkHandler = (link) => setLink(link);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleClick = () => {
    getPublicLink({ id, user_id, callback: linkHandler });
    setOpen(true);
  };

  const [copied, setCopied] = useState(false);
  const publicLinkText = useRef(null);
  const copyHandler = (e) => {
    const input = publicLinkText.current.querySelector("input");
    selectInputContent(input);
    textToClipboard(input.value);
    setCopied(true);
  };

  const clickOnText = (e) => {
    const { target } = e;
    selectInputContent(target);
  };

  return kind && kind === "public" ? (
    <>
      <TooltipIconButton
        TooltipProps={{ placement: "bottom" }}
        title="Public link"
        color="primary"
        onClick={handleClick}
        aria-label="Open public link dialog"
        aria-haspopup="true"
      >
        <Icons.Link2 />
      </TooltipIconButton>
      <Mui.Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Public link options"
      >
        <Mui.DialogTitle id="form-dialog-title">
          {"Public Link Options"}
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.List aria-label="Guest public link options">
            <Mui.ListItem>
              <Mui.TextField
                id="publicLinkText"
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.linkText,
                    focused: classes.focused,
                  },
                }}
                label="Public link"
                className={classes.margin}
                value={link}
                variant="filled"
                onClick={clickOnText}
                ref={publicLinkText}
                disabled={link ? false : true}
                aria-disabled={link ? false : true}
                aria-readonly="true"
                contentEditable="true"
              />
              <Mui.Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={copied ? <Icons.Check /> : null}
                onClick={copyHandler}
                disabled={link ? false : true}
                aria-disabled={link ? false : true}
              >
                {copied ? "Copied" : "Copy link"}
              </Mui.Button>
            </Mui.ListItem>
          </Mui.List>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.IconButton color="primary" onClick={handleClose}>
            <Icons.X />
          </Mui.IconButton>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  ) : null;
};

export default PublicLinkButton;

PublicLinkButton.propTypes = {
  room: PropTypes.object,
  getPublicLink: PropTypes.func.isRequired,
};
