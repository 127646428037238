import client from "../../../client";
import mutation from "./sendNotification.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} notificationType
 * @param {string} workspace_id
 * @param {string} room_id
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const sendNotification = async function (
  notificationType,
  receivingUser,
  workspace_id,
  invitationMessage,
  room_id
) {
  workspace_id = addBearer(workspace_id);
  room_id = addBearer(room_id);
  await client.mutate({
    mutation,
    variables: {
      notificationType,
      receivingUser,
      room_id,
      workspace_id,
      invitationMessage,
    },
  });
};

export default sendNotification;
