import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  messageInput: {
    "&:focus": {
      color: theme.palette.textGray.input,
    },
  },
  chatFormBox: {
    // height: "7em",
    // padding: "17px 31px 50px",
    padding: "17px",

    // [theme.breakpoints.down("lg")]: {
    // height: "5em",
    //   paddingBottom: "17px",
    // },
    //border: "solid",
  },
  chatFormBoxFiles: {
    paddingTop: "166px",
  },
  chatButton: {
    backgroundColor: theme.palette.buttons.light,
    border: `1px solid ${theme.palette.buttons.light}`,
    color: theme.palette.buttons.dark,
    marginLeft: "1rem",
    borderRadius: "0.25rem",
    transition:
      "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out",

    "&:hover": {
      backgroundColor: theme.palette.buttons.medium,
      borderColor: theme.palette.buttons.medium,
    },
  },

  emojiButton: {
    //transform: "scale(1.2)",
    //color: "rgba(0, 0, 0, 0.54)",
  },

  chatButtonRepliesOpen: {
    // [theme.breakpoints.down("lg")]: {
    //   marginLeft: "0px",
    // },
  },

  sendButton: {
    //backgroundColor: theme.palette.primary.main,
    background:
      "linear-gradient(to left, #1FA2FF 30%, #12D8FA 91%, #1FA2FF 170%)",
    border: `1px solid ${theme.palette.primary.main}`,
    color: "#fff",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  focused: {},
  notchedOutline: {},
  filesRoot: {
    // position: "absolute",
    zIndex: 1000,
    marginTop: "-149px",
  },
  files: {
    background: theme.palette.bgGray.files,
  },
}));
