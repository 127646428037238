import client from "../../../client";
import query from "./searchPageAndSection.query";

/**
 * @param {string} searchValue
 */
export const searchPageAndSection = async function (parameters) {
  const { page, section, searchedValue } = parameters;
  const { data } = await client.query({
    query,
    variables: {
      searchValue: searchedValue,
      page,
      section,
    },
  });
  return data.searchFromPanel;
};

export default searchPageAndSection;
