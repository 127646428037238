import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  statusIcon: {
    position: "absolute",
    right: "60px",
    bottom: "5px",
  },
  statusIconReplies: {
    position: "absolute",
    right: "0px",
    bottom: "-19px",
  },
  fileStatusIcon: {
    position: "absolute",
    right: "5px",
    bottom: "0px",
  },
}));
