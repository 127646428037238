import client from "../../../client";
import query from "./getPublicRoomLink.query";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 * @param {string} user_id
 * @returns {Promise<string>}
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getPublicRoomLink = async function (id, user_id) {
  id = addBearer(id);
  user_id = addBearer(user_id);
  const { data } = await client.query({
    query,
    variables: {
      id,
      user_id,
    },
  });
  return data.getPublicRoomLink;
};

export default getPublicRoomLink;
