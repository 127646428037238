import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import WorkspacesButton from "./WorkspacesButton.component";

import {
  setCurrentWorkspace,
  getCategories,
  setCategories,
  setRoom,
} from "../../../store/actions/index";
import { selectActiveWorkspace } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const { user, currentWorkspace } = state.chat;
  const { rooms } = state.chat;
  return {
    currentWorkspace,
    user,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getCategories, setCategories, setCurrentWorkspace, setRoom },
    dispatch
  );

const WorkspacesButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspacesButton);

export default WorkspacesButtonContainer;
