import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const Notification = function ({
  notification,
  onClick,
  rejectNotification,
  acceptFriendRequest,
  acceptRoomInvitation,
  ...props
}) {
  const renderNotificationIcon = function () {
    switch (notification.notificationType) {
      case "friendRequest":
        return <Icons.UserPlus color="primary" />;
      case "roomInvitation":
        return <Icons.Mail color="primary" />;
      default:
        return null;
    }
  };

  const renderNotificationTitle = function () {
    switch (notification.notificationType) {
      case "friendRequest":
        return "Contact Request";
      case "roomInvitation":
        return `Chatroom Invitation to ${notification.room.title}`;
      default:
        return null;
    }
  };

  const getAccepthandler = function () {
    switch (notification.notificationType) {
      case "friendRequest":
        return () => acceptFriendRequest(notification);
      case "roomInvitation":
        return () => acceptRoomInvitation(notification);
      default:
        return () =>
          alert(
            `Notification type: "${notification.notificationType}" not implemented`
          );
    }
  };

  const onReject = () => rejectNotification(notification);

  return (
    <Mui.ListItem {...props}>
      <Mui.ListItemIcon>{renderNotificationIcon()}</Mui.ListItemIcon>
      <Mui.ListItemAvatar>
        <Mui.Avatar src={notification.sender.avatar} />
      </Mui.ListItemAvatar>
      <Mui.ListItemText
        primary={renderNotificationTitle()}
        secondary={notification.sender.username}
      />
      <Mui.ListItemIcon>
        <Mui.IconButton onClick={getAccepthandler()} color="primary">
          <Icons.Check />
        </Mui.IconButton>
      </Mui.ListItemIcon>
      <Mui.ListItemIcon>
        <Mui.IconButton onClick={onReject} color="secondary">
          <Icons.X />
        </Mui.IconButton>
      </Mui.ListItemIcon>
    </Mui.ListItem>
  );
};

export default Notification;

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  rejectNotification: PropTypes.func.isRequired,
  acceptFriendRequest: PropTypes.func.isRequired,
  acceptRoomInvitation: PropTypes.func.isRequired,
};

Notification.defaultProps = {
  button: true,
  divider: true,
};
