import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => {
  return {
    fullContainer: {
      height: "86px",
      paddingTop: "26px",
      paddingBottom: "10px",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    formButton: {
      backgroundColor: theme.palette.buttons.light,
      border: `1px solid ${theme.palette.buttons.light}`,
      color: theme.palette.buttons.dark,
      marginLeft: "1rem",
      marginTop: "-1px",
      borderRadius: "0.25rem",
      transition:
        "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out",
      "&:hover": {
        backgroundColor: theme.palette.buttons.medium,
        borderColor: theme.palette.buttons.medium,
      },

      [theme.breakpoints.down("lg")]: {
        marginLeft: "0px",
      },
    },
    sendButton: {
      //backgroundColor: theme.palette.primary.main,
      background:
        "linear-gradient(to left, #1FA2FF 30%, #12D8FA 91%, #1FA2FF 170%)",
      border: `1px solid ${theme.palette.primary.main}`,
      color: "#fff",

      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
    },
    filesButton: {
      marginTop: "-10px",
      marginRight: "-9px",
      marginLeft: "7px",
    },
    emojiButton: {
      color: "rgba(0, 0, 0, 0.54)",
    },
  };
});
