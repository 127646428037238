import { useState } from "react";

/**
 * @typedef updater
 * @type {<function>}
 */

/**
 * @typedef dependency
 * @type {object}
 */

/**
 * Useful to solve hook stale closure issues
 * @returns {[updater, dependency]} [updater, dependency]
 */
export const useDependency = function (initialState = null) {
  const [dependency, setDependency] = useState(initialState);
  const updater = () => setDependency({});
  return [updater, dependency];
};

export default useDependency;
