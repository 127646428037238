import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MuteAudioButton } from "./MuteAudioButton.component";

import { localAudioTrackController } from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { localAudioTrackReady, localAudioIsMuted } =
    state.jitsi.localTracksData;
  return { localAudioTrackReady, localAudioIsMuted };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      localAudioTrackController,
    },
    dispatch
  );

const MuteAudioButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MuteAudioButton);

export default MuteAudioButtonContainer;
