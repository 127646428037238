import * as Icons from "react-feather";
import { TooltipIconButton } from "../../prestyledComponents";

export const OpenSearchBarButton = function ({
  searchPanelOpen,
  setSearchPanelOpen,
  ...props
}) {
  function handleClick() {
    setSearchPanelOpen(!searchPanelOpen);
  }
  return (
    <TooltipIconButton {...props} title="search" onClick={handleClick}>
      <Icons.Search />
    </TooltipIconButton>
  );
};

export default OpenSearchBarButton;
