import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jitsiCallHang, stopSharing } from "XmarsStore/actions";

import HangButton from "./HangButton.component";

const mapStateToProps = (state) => {
  const { sharedScreen, screenTrack, screenConnection } = state.jitsi;
  const { meetingCode } = state.video;

  return {
    sharedScreen,
    screenTrack,
    screenConnection,
    meetingCode,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      jitsiCallHang,
      stopSharing,
    },
    dispatch
  );

const HangButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HangButton);

export default HangButtonContainer;
