import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import * as Icons from "react-feather";

import * as Buttons from "../buttons";
import { TooltipIconButton } from "../prestyledComponents";
import { LocalParticipant } from "../collectionItems";
import { useDependency } from "../../hooks/index";

import JitsiMeetJS, { createLocalTracks } from "../../JitsiMeetJS";
import { logError } from "../../utils/errors/logError";
import { tryOrLog } from "../../utils/errors/tryOrLog";

// eslint-disable-next-line
import { JitsiTrack } from "../../JitsiMeetJS/typeDefs";
// eslint-disable-next-line
import { Props } from "./TestCall.propTypes";

/** @type {Set<JitsiTrack>} */
const trackSet = new Set();

/**
 * @param {Props} props
 */
export const TestCall = function ({
  localParticipant,
  onFinish,
  addLocalTrack,
  addSnackbar,
  cleanConferenceRequest,
}) {
  const [updater, dependency] = useDependency();

  useEffect(() => {
    return async () => {
      for (const track of trackSet) {
        tryOrLog(async () => {
          if (track && track.dispose) await track.dispose();
        }, true);
      }
      trackSet.clear();
      cleanConferenceRequest();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localParticipant.video) trackSet.add(localParticipant.video);
    if (localParticipant.audio) trackSet.add(localParticipant.audio);
  }, [localParticipant]);

  useEffect(() => {
    const track = localParticipant.video;
    if (dependency && track) {
      track.emit(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED);
    }
    // eslint-disable-next-line
  }, [dependency]);

  useEffect(() => {
    const handle = async function () {
      let shouldEndCall = true;

      try {
        const tracksResult = await Promise.allSettled([
          createLocalTracks.audio(),
          createLocalTracks.video(),
        ]);
        const tracksSuccess = [];
        const tracksFail = [];

        tracksResult.forEach((result) => {
          if (result.status === "fulfilled") {
            tracksSuccess.push(result.value);
          } else {
            tracksFail.push(result.reason);
          }
        });

        if (tracksSuccess.length) {
          for (const track of tracksSuccess) {
            addLocalTrack(track);
            shouldEndCall = false;
          }
        }

        if (tracksFail.length) {
          const permissionFail = [];
          const notFoundFail = [];
          const otherFail = [];

          tracksFail.forEach((error) => {
            switch (error.name) {
              case "gum.permission_denied":
                permissionFail.push(...error.gum.devices);
                break;

              case "gum.not_found":
                notFoundFail.push(...error.gum.devices);
                break;

              default:
                otherFail.push(error);
            }
          });

          const errors = [];

          if (permissionFail.length) {
            const e = new Error(
              `Please check your permissions for the following devices: ${permissionFail.join(
                ", "
              )}`
            );
            e.name = "Permission Error";
            errors.push(e);
          }

          if (notFoundFail.length) {
            const e = new Error(
              `The following devices were not found: ${notFoundFail.join(", ")}`
            );
            e.name = "Not Found Error";
            errors.push(e);
          }

          if (otherFail.length) errors.push(...otherFail);

          if (errors.length) throw errors;
        }
      } catch (error) {
        if (shouldEndCall) {
          onFinish();
        }

        if (Array.isArray(error)) {
          error.forEach((e) => {
            const snackBar = {
              severity: "error",
              message: `${e.message}`,
            };
            addSnackbar(snackBar);
            logError(e);
          });
        } else {
          const snackBar = {
            severity: "error",
            message: `${error.message}`,
          };
          addSnackbar(snackBar);
          logError(error);
        }
      }
    };
    handle();
    // eslint-disable-next-line
  }, []);

  const handleClick = updater;

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column">
      <Box height="90%" flexGrow={0}>
        <LocalParticipant participant={localParticipant} />
      </Box>
      <Box
        height="10%"
        flexGrow={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box marginRight="3em">
          <TooltipIconButton title="Finish" color="primary" onClick={onFinish}>
            <Icons.PhoneOff />
          </TooltipIconButton>
        </Box>
        <Box>{/* <Buttons.HandtrackButton onClick={handleClick} /> */}</Box>
        <Box>
          <Buttons.VideoBackgroundButton
            onClick={handleClick}
            disabled={!localParticipant.video}
            TooltipProps={{ placement: "top" }}
          />
        </Box>
        <Box>
          <Buttons.MuteVideoButton track={localParticipant.video} />
        </Box>
        <Box>
          <Buttons.MuteAudioButton track={localParticipant.audio} />
        </Box>
      </Box>
    </Box>
  );
};

export default TestCall;
