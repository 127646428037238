import { connect } from "react-redux";
import RequestRecoveryForm from "./RequestRecoveryForm.component";
import { actions as act } from "../../../store/actions/index";

const mapDispatchToProps = {
  setPage: act.general.setPage,
  requestRecovery: act.sagas.requestRecovery,
};

const RequestRecoveryFormContainer = connect(
  null,
  mapDispatchToProps
)(RequestRecoveryForm);

export default RequestRecoveryFormContainer;
