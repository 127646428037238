import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.buttons.main1,
    marginTop: "6px",
    marginBottom: "6px",

    "& .MuiSvgIcon-root": {
      height: "18px !important",
      width: "18px !important",
    },
  },
}));
