import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.common.white,
  },
  searchBar: {
    width: "88%",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    marginBottom: 0,
  },
  input: {
    width: "103.5%",
  },
  inputShowingButton: { width: "109%" },
  inputRoot: {
    "&$focused": {},
    "&$notchedOutline": {},
  },
  inputFocused: {
    backgroundColor: theme.palette.bgGray.sidebar,
  },
  inputNotchedOutline: {
    display: "none",
  },
  inputBase: {
    fontSize: "14px",
    padding: "6px 11px",
    height: "31px",
    textDecorationColor: theme.palette.textGray.input,
    color: theme.palette.textGray.input,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.buttons.light}`,
    lineHeight: 1.5,
    boxSizing: "border-box",
    backgroundClip: "padding-box",
    paddingRight: "38px",
    marginTop: "-7px",
    marginLeft: "-1px",

    "&::placeholder": {
      color: `${theme.palette.textGray.inputPlaceholder} !important`,
      opacity: 1,
    },
  },
  clearButton: {
    position: "absolute",
    right: "2px",
    top: "-7px",
    height: "32px",
  },
  inputAdornedEnd: {
    padding: "0",
  },
}));
