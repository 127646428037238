import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import RecordingButton from "./ShareScreenButton.component";

import { addSnackbar, startSharing, stopSharing } from "XmarsStore/actions";

const mapStateToProps = (state) => {
  const { user } = state.chat;
  const { localParticipant, screenTrack, sharedScreen, screenConnection } =
    state.jitsi;
  const { meetingCode } = state.video;

  return {
    localParticipant,
    meetingCode,
    screenTrack,
    sharedScreen,
    screenConnection,
    user,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addSnackbar,
      startSharing,
      stopSharing,
    },
    dispatch
  );

const RecordingButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordingButton);

export default RecordingButtonContainer;
