import { createAction } from "@reduxjs/toolkit";

export const deleteConversation = createAction("saga/deleteConversation");
export const startedTyping = createAction("saga/startedTyping");

// export const setRoom = createAction("saga/setRoom");
// export const hideRoom = createAction("saga/hideRoom");
// export const setFirstRoom = createAction("saga/setFirstRoom");
// export const addRoom = createAction("saga/addRoom");
// export const removeRoom = createAction("saga/removeRoom");
// export const updateRooms = createAction("saga/updateRooms");
// export const searchRoom = createAction("saga/searchRoom");
