import client from "../../../client";
import { addBearer } from "../../../utils";
import mutation from "./createCategory.mutation";

/**
 * @param {string} title
 * @param {string} workspaceId
 * @returns {Promise<{
 *  id: string,
 *  title: string,
 *  createdAt: date,
 *  admin: boolean
 *  lastUpdate: date,
 *
 * }>}
 */
export const createCategory = async function (title, workspaceId) {
  workspaceId = addBearer(workspaceId);
  const { data } = await client.mutate({
    mutation,
    variables: {
      input: {
        title,
        workspaceId,
      },
    },
  });
  return data.createCategory;
};

export default createCategory;
