import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  inputBase: {
    color: theme.palette.textGray.input,
    padding: "0.375rem 0.75rem",
    height: "auto",

    "&::placeholder": {
      color: `${theme.palette.textGray.inputPlaceholder} !important`,
      opacity: 1,
    },
  },
  input: {
    fontSize: "14px",
    lineHeight: 1.5,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.borderGray.input}`,
    boxSizing: "border-box",
    backgroundClip: "padding-box",
  },
  inputRoot: {},
  inputFocused: {
    backgroundColor: theme.palette.bgGray.sidebar,
  },
  inputNotchedOutline: {
    display: "none",
  },
  modalElements: {
    position: "absolute",
    top: "40%",
    left: "40%",
    transform: "translate(-50%, 0%)",
    width: "20%",
    background: "white",
    boxShadow: "5px 5px 15px -4px #000000",
    borderRadius: "12px",
    "&:focus": {
      outline: "none",
    },
    paddingRight: "5px",
    [theme.breakpoints.between("xs", "md")]: {
      width: "45%",
    },
  },
  rootItem: {
    minHeight: "auto",
  },
}));
