import { makeStyles } from "@mui/styles";
import { classes } from "../../../style";

export default makeStyles((theme) => {
  return {
    ...classes,
    selected: {
      background: theme.palette.buttons.medium,
    },
  };
});
