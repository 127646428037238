import { gql } from "../../../utils/apollo";

export const getWorkspace = gql`
  query GetWorkspace($input: getWorkspaceInput!) {
    getWorkspace(input: $input) {
      admin
      avatar
      createdAt
      description
      id
      owner {
        id
        username
      }
      lastUpdate
      title
      users {
        id
        username
        token
        avatar
        status
      }
    }
  }
`;

export default getWorkspace;
