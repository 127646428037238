import { makeStyles } from "@mui/styles";
import { classes } from "../../../style";

export default makeStyles((theme) => {
  return {
    ...classes,
    selected: {
      background: theme.palette.buttons.medium,
    },
    badge: {
      height: "18px",
      width: "18px",
      minWidth: "10px",
      padding: "0 0px",
      fontSize: "0.5rem",
    },
    numberPosition: {
      top: "-9px",
      right: "-4px",
    },
  };
});
