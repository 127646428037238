import { useEffect, useState } from "react";

export const useDrop = function (ref) {
  const [files, setFiles] = useState(null);

  useEffect(() => {
    const element = ref.current;
    element.addEventListener("drop", handleDrop);
    return () => element.removeEventListener("drop", handleDrop);
    // eslint-disable-next-line
  }, []);

  const handleDrop = function (event) {
    event.preventDefault();
    event.stopPropagation();
    setFiles(event.dataTransfer.files);
  };

  return files;
};

export default useDrop;
