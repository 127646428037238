import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  noMargin: {
    margin: "0px",
  },

  tip: {
    fontSize: "13px",
    background: "rgb(10,10,10,0.5)",
    height: "30px",
    marginBottom: "6px",
  },
}));
