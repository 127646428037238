import { forwardRef, useState, useEffect, useRef } from "react";
import { TooltipIconButton } from "../../prestyledComponents";
import * as Icons from "react-feather";
import * as Mui from "@mui/material";
import SearchBarMember from "../../SearchBarMember";
import {
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
} from "@mui/material";
import { stringAvatar } from "XmarsUtils/defaultAvatar";
import { StatusIndicator } from "../../collectionItems";
import { Scrollbars } from "react-custom-scrollbars";
import { getBoundActionPromise } from "../../../utils";
import { logError } from "../../../utils";

import useStyles from "./CreateDirectMessageButton.styles";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const CreateChannelButton = forwardRef(function (
  {
    currentWorkspace,
    getOneVsOneRoom,
    user,
    status,
    categories,
    getRoom,
    isIcon,
    categoryId,
    ...props
  },
  ref
) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const membersData = currentWorkspace.users.filter(
    (member) => member.username !== user.username
  );

  const filterData = (query, data) => {
    if (!query) {
      return data;
    } else {
      return data.filter((d) => d.username.toLowerCase().includes(query));
    }
  };

  const membersWS = filterData(searchQuery, membersData).sort(function (a, b) {
    if (a.username < b.username) {
      return -1;
    }
    if (a.username > b.username) {
      return 1;
    }
    return 0;
  });

  const handleSendMessage = async (userId, userUsername) => {
    let rooms = categories.find((category) => category.id === categoryId).rooms;
    let roomAlreadyCreated = rooms.find((room) => room.title === userUsername);
    if (roomAlreadyCreated) {
      setOpen(false);
      try {
        // setProcessing(true);
        await getBoundActionPromise(getRoom, {
          id: roomAlreadyCreated.id,
        });
      } catch (error) {
        logError(error);
      }
    } else {
      setOpen(false);
      try {
        // setProcessing(true);
        await getBoundActionPromise(getOneVsOneRoom, {
          userId,
          categoryId,
        });
      } catch (error) {
        logError(error);
      }
    }
  };

  return (
    <>
      {isIcon ? (
        <TooltipIconButton
          {...props}
          ref={ref}
          title="Send a direct message"
          onClick={handleClick}
        >
          <Icons.Plus />
        </TooltipIconButton>
      ) : (
        <Mui.MenuItem {...props} ref={ref} onClick={handleClick}>
          Create Channel
        </Mui.MenuItem>
      )}
      {open && (
        <Mui.Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          PaperProps={{ elevation: 0 }}
          classes={{
            root: classes.dialogRoot,
            paper: classes.dialog,
            container: classes.dialogContainer,
          }}
        >
          <Mui.DialogTitle
            disableTypography
            id="form-dialog-title"
            className={classes.dialogHeader}
          >
            <Icons.Users style={{ height: "18px", width: "18px" }} />
            <Mui.Typography variant="h6" className={classes.headerText}>
              Select a member
            </Mui.Typography>
            <Mui.IconButton
              className={classes.closeButton}
              onClick={handleClose}
            >
              <Icons.X style={{ strokeWidth: "2.5px" }} />
            </Mui.IconButton>
          </Mui.DialogTitle>
          <Mui.DialogContent className={classes.dialogContent}>
            <SearchBarMember
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            <Mui.Grid sx={{ marginTop: "10px" }}>
              {membersWS.length == 0 && (
                <Mui.Typography variant="p" className={classes.label}>
                  No members yet. Start by inviting a friend.
                </Mui.Typography>
              )}
              <Scrollbars
                autoHeight
                autoHeightMin={50}
                autoHeightMax={450}
                //autoWidth
              >
                {membersWS.map((user) => {
                  return (
                    <List key={user.id}>
                      <ListItem
                        onClick={() => {
                          handleSendMessage(user.id, user.username);
                        }}
                      >
                        <ListItemAvatar>
                          <StatusIndicator status={user.status}>
                            <Avatar
                              src={user.avatar}
                              {...stringAvatar(user.username)}
                            />
                          </StatusIndicator>
                        </ListItemAvatar>
                        <ListItemText primary={user.username} />
                        {/* <TooltipIconButton onClick={() => {
                            handleSendMessage(user.id, user.username)
                          }} 
                          className={classes.channelIconButton} title="Send a message"
                          >
                            <Icons.MessageSquare/>
                        </TooltipIconButton> */}
                      </ListItem>
                    </List>
                  );
                })}
              </Scrollbars>
            </Mui.Grid>
          </Mui.DialogContent>
        </Mui.Dialog>
      )}
    </>
  );
});

export default CreateChannelButton;
