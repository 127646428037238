import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import RoomUsers from "./RoomUsers.component";

import { resetSearchedUsers, getRoomUsers } from "../../../store/actions/index";
import { selectActiveChannelId } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const activeChatRoomId = selectActiveChannelId(state);
  const { searchedUsers, user } = state.chat;

  return {
    selfUser: user,
    activeChatRoomId,
    users: searchedUsers,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetSearchedUsers,
      getRoomUsers,
    },
    dispatch
  );

const RoomUsersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomUsers);

export default RoomUsersContainer;
