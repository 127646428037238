import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { getUid } from "XmarsUtils";

import useStyles from "./FromTextField.styles";
import clsx from "clsx";
import { stringAvatar } from "../../../utils/defaultAvatar";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const FromTextField = function ({
  rooms,
  friends,
  setFilters,
  InputProps,
  reset,
  setReset,
  ...props
}) {
  const classes = useStyles();
  const [from, setFrom] = useState("");

  useEffect(() => {
    if (reset === true) {
      setFrom("");
    }
    setReset(false);
  }, [reset, setReset]);

  const handleChange = (e) => {
    const id = e.target.value;
    setFrom(id);
    if (id === "") return;
    setFilters({ from: id });
  };
  return (
    <Mui.Box>
      <Mui.Typography>From</Mui.Typography>
      <Mui.Select
        id={getUid()}
        fullWidth
        label="a"
        {...props}
        type="text"
        inputProps={{ className: classes.inputBase }}
        value={from}
        onChange={handleChange}
      >
        <Mui.MenuItem value="">
          <em>Anyone</em>
        </Mui.MenuItem>
        {friends.map((friend) => (
          <Mui.MenuItem
            key={friend.id}
            value={friend.id}
            className={classes.menuItem}
          >
            <Mui.ListItemAvatar
              classes={{
                root: clsx(classes.avatar),
              }}
            >
              <Mui.Avatar
                src={friend.avatar}
                classes={{ root: classes.avatar }}
                {...stringAvatar(friend.username)}
              />
            </Mui.ListItemAvatar>
            <Mui.ListItemText primary={friend.username} />
          </Mui.MenuItem>
        ))}
      </Mui.Select>
    </Mui.Box>
  );
};

export default FromTextField;
