import { connect } from "react-redux";

import ParticipantsGallery from "./ParticipantsGallery.component";

const mapStateToProps = (state) => {
  const { callStatus, isSmallVideoOpen } = state.video;

  return {
    callStatus,
    isSmallVideoOpen,
  };
};

const ParticipantsGalleryContainer = connect(
  mapStateToProps,
  null
)(ParticipantsGallery);

export default ParticipantsGalleryContainer;
