import env from "../../env";
import groupLog from "../groupLog";

/**
 * @param {Error} error
 */
export const logError = function (error) {
  if (!error) return console.error("logError null error");
  if (env.DEVELOPMENT) {
    groupLog(
      error.message,
      () => {
        console.error(error);
      },
      {
        styles: "color: red",
      }
    );
  } else {
    console.error(`${error}`);
  }
};

export default logError;
