import { gql } from "../../../utils/apollo";

export const unfriend = gql`
  mutation Unfriend($id: ID) {
    unfriend(input: { id: $id }) {
      username
    }
  }
`;

export default unfriend;
