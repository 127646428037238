import { put, call, take, fork } from "redux-saga/effects";
import * as actions from "XmarsStore/actions";

import { createLocalTracks, getJitsiConnection } from "XmarsJitsiMeetJS/index";

import { handleSagaError } from "XmarsStore/sagas/sagas.utils";
import { screenSharingChannel } from "./screenSharingChannel";
import { screenSharingHandler } from "./screenSharingHandler";

export const startSharing = function* ({ payload }) {
  try {
    const { meetingCode, user } = payload;
    const track = yield call(createLocalTracks.screen);
    ////
    const trackConnection = getJitsiConnection(meetingCode);
    yield put(actions.setScreenConnection(trackConnection));
    const channel = yield call(screenSharingChannel, trackConnection);
    while (true) {
      const signal = yield take(channel);
      yield fork(screenSharingHandler, {
        signal,
        meetingCode,
        connection: trackConnection,
        track,
        user,
      });
    }
  } catch (error) {
    yield handleSagaError(error);
  }
};
