import env from "./env";
import api from "./dataApi";
import * as electron from "./electron";
import { logError } from "./utils";

const requestNotificationPermission = async function () {
  if (Notification.permission === "granted") {
    return;
  }
  const permissionResult = await new Promise((res, rej) => {
    // Double handling is necessary because of Notification api recent update
    const resultPromise = Notification.requestPermission(
      (resultInsideCallback) => {
        res(resultInsideCallback);
      }
    );
    if (resultPromise) {
      resultPromise.then(res, rej);
    }
  });
  if (permissionResult !== "granted") {
    throw new Error("Notification permission not granted");
  }
};

const urlBase64ToUint8Array = function (base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const sendSubscription = function (subscription) {
  const { endpoint, expirationTime, keys } = subscription.toJSON();
  const { p256dh, auth } = keys;
  api.sendPushNotification(endpoint, expirationTime, p256dh, auth);
};

const pushServiceAvailable = function () {
  return (
    !!navigator.serviceWorker && !!window.PushManager && !electron.isSupported
  );
};

export const subscribe = async function () {
  if (!pushServiceAvailable()) return console.log("Push service unavailable");
  try {
    await requestNotificationPermission();
  } catch (error) {
    return logError(error);
  }
  const registration = await navigator.serviceWorker.ready;
  const existingSubscription = await registration.pushManager.getSubscription();
  if (!existingSubscription) {
    try {
      const newSubscription = await registration.pushManager.subscribe({
        applicationServerKey: urlBase64ToUint8Array(env.VAPID_PUBLIC_KEY),
        userVisibleOnly: true,
      });
      sendSubscription(newSubscription);
    } catch (error) {
      if (Notification.permission === "granted") {
        logError(error);
      }
    }
  }
};

export const unsubscribe = async function () {
  if (!pushServiceAvailable()) return console.log("Push service unavailable");
  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.getSubscription();
  try {
    await subscription.unsubscribe();
  } catch (error) {
    logError(error);
  }
};
