import * as actions from "../../actions";
import api from "../../../dataApi";
import { ACTIVE } from "../../types/status";
import { handleSagaError } from "../sagas.utils";
import { registerFriend } from "../chat/utils";
import { eventChannel } from "redux-saga";
import { call, put, take, select, fork } from "redux-saga/effects";

function myselfChannel() {
  const cleanedSubscription = (emitter) => {
    const subscription = api.subscribeToMyself(null, {
      next: emitter,
      error: handleSagaError,
    });
    return subscription.unsubscribe;
  };
  return eventChannel(cleanedSubscription);
}

function* processSignal(signal) {
  try {
    console.log("Sub notification debug:");
    console.log(signal);
    switch (signal.signalType) {
      case "notification": {
        yield put(actions.addNotification(signal.notification));
        break;
      }
      case "newFriend": {
        const friend = signal.user;
        const snackbar = {
          severity: "info",
          message: `${friend.username} accepted your friend request`,
        };
        yield put(actions.addSnackbar(snackbar));
        yield registerFriend(friend);
        break;
      }
      case "checkOnline": {
        try {
          const status = yield select((state) => state.chat.status);
          const payload = status || ACTIVE;
          yield call(api.changeStatus, payload);
        } catch (error) {
          handleSagaError(error);
        }
        break;
      }
      case "invitedToCall": {
        // requires implementation on the backend
        console.log("invitedToCall");
        console.log(signal);
        break;
      }
      default: {
        console.log(
          "unhandled case for signal in user subscription. signalType: ",
          signal.signalType
        );
        break;
      }
    }
  } catch (error) {
    handleSagaError(error);
  }
}

export const startUserSubscription = function* () {
  const channel = yield call(myselfChannel);
  try {
    while (true) {
      const signal = yield take(channel);
      yield fork(processSignal, signal);
    }
  } catch (error) {
    handleSagaError(error);
  }
};
