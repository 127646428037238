import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SearchFriendsForm from "./SearchFriendsForm.component";

import { searchFriends } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchFriends,
    },
    dispatch
  );

const SearchFriendsFormContainer = connect(
  null,
  mapDispatchToProps
)(SearchFriendsForm);

export default SearchFriendsFormContainer;
