import { gql } from "../../../utils/apollo";

export const removeRecording = gql`
  mutation RemoveRecording($sessionID: ID) {
    removeRecording(input: { sessionID: $sessionID }) {
      null
    }
  }
`;

export default removeRecording;
