const initialState = {
  incomingCall: null,
  meetingCode: "",
  ongoingCall: null,
  activeChannel: null, // Actual videoCall channel
  onlyAudio: false,
  isSmallVideoOpen: false,
  callStatus: {
    active: false,
    dialing: false,
    successful: false,
  },
  devices: [],
  sharing: {
    status: false,
    id: "",
  },
};

export default initialState;
