import { gql } from "../../../utils/apollo";

export const startSharingScreen = gql`
  mutation StartSharingScreen(
    $channelId: ID
    $workspaceId: ID
    $sharingUserId: String
  ) {
    startSharingScreen(
      input: {
        channelId: $channelId
        workspaceId: $workspaceId
        sharingUserId: $sharingUserId
      }
    ) {
      null
    }
  }
`;

export default startSharingScreen;
