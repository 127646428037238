// The app create one subscription per friend, per room, per user.
// TODO change the app to use only one subscription. There can only be one channel.
import { startUserSubscription } from "./startUserSubscription";
import { startFriendSubscription } from "./startFriendSubscription";
import { startRoomSubscription } from "./startRoomSubscription";
import { startWorkpaceSubscription } from "./startWorkspaceSubscription";

import { actions } from "../../actions";
import { takeEvery } from "redux-saga/effects";

export default function* interceptor() {
  yield takeEvery(actions.sagas.startUserSubscription, startUserSubscription);
  yield takeEvery(
    actions.sagas.startFriendSubscription,
    startFriendSubscription
  );
  yield takeEvery(actions.sagas.startRoomSubscription, startRoomSubscription);
  yield takeEvery(
    actions.sagas.startWorkspaceSubscription,
    startWorkpaceSubscription
  );
}
