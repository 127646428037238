import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { setSearchPanelOpen } from "../../../store/actions/index";
import OpenSearchBarTabButton from "./OpenSearchBarTabButton.component";

const mapStateToProps = (state) => {
  const { searchPanelOpen } = state.general;
  return { searchPanelOpen };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSearchPanelOpen,
    },
    dispatch
  );

const OpenSearchBarTabButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenSearchBarTabButton);

export default OpenSearchBarTabButtonContainer;
