import {
  BackgroundTracker,
  getImageDataFromBackgroundPredictions as getImageFromPredictions,
} from "../../imageProcessing/background";
import { JitsiTrackEffect } from "XmarsJitsiMeetJS/effects/effects.utils";
import {
  createElement,
  ctxSaveExecRestore,
  getImageDataFromMedia,
  getOffscreenCanvas,
} from "XmarsUtils/index";

export class BackgroundEffect extends JitsiTrackEffect {
  #initialized = false;
  #img = createElement("img");
  #tracker;

  constructor(background) {
    super();
    this.#tracker = new BackgroundTracker();
    this.#img.src = background;
  }

  set background(newBackground) {
    if (!newBackground) newBackground = "";
    this.#img.src = newBackground;
  }
  get background() {
    const src = this.#img.src;
    const { host } = window.location;
    if (!src.match(host)) return src;
    return src.split(`${host}/`)[1];
  }

  async init(modelParams) {
    if (this.#initialized) return;

    await this.#tracker.init(modelParams);

    this.#initialized = true;
  }

  dispose() {
    this.#initialized = false;
  }

  startEffect(stream) {
    const { width, height } = stream.getTracks()[0].getSettings();

    const tracker = this.#tracker;

    const canvas = createElement("canvas", { width, height });
    const ctx = canvas.getContext("2d");

    const video = createElement("video", {
      width,
      height,
      autoplay: true,
      srcObject: stream,
    });

    const fakeCanvases = {
      video: getOffscreenCanvas(width, height),
      background: getOffscreenCanvas(width, height),
      blur: getOffscreenCanvas(640, 480),
      big: getOffscreenCanvas(width, height),
    };

    const fakeCtxs = {
      video: fakeCanvases.video.getContext("2d"),
      background: fakeCanvases.background.getContext("2d"),
      blur: fakeCanvases.blur.getContext("2d"),
      big: fakeCanvases.big.getContext("2d"),
    };

    tracker.onPredict = (predictions, media) => {
      fakeCtxs.video.drawImage(media, 0, 0);
      const videoImageData = fakeCtxs.video.getImageData(0, 0, width, height);

      const backgroundImageData = this.background
        ? getImageDataFromMedia(this.#img, { width, height })
        : getImageDataFromMedia(media, {
            width,
            height,
            configCallback(mCtx) {
              mCtx.filter = "blur(20px) brightness(175%) grayscale(75%)";
            },
          });

      fakeCtxs.background.putImageData(backgroundImageData, 0, 0);

      const predictionImageData = getImageFromPredictions(predictions);
      fakeCtxs.blur.putImageData(predictionImageData, 0, 0);

      ctxSaveExecRestore(fakeCtxs.background, (fCtx) => {
        fCtx.filter = "blur(5px)";
        fCtx.drawImage(fakeCanvases.blur, 0, 0, 640, 480, 0, 0, width, height);
      });

      fakeCtxs.big.clearRect(0, 0, width, height);
      fakeCtxs.big.drawImage(
        fakeCanvases.blur,
        0,
        0,
        640,
        480,
        0,
        0,
        width,
        height
      );

      const bigImageData = fakeCtxs.big.getImageData(0, 0, width, height);
      const newImageData = fakeCtxs.background.getImageData(
        0,
        0,
        width,
        height
      );

      for (let i = 0; i < width * height; i++) {
        if (bigImageData.data[4 * i + 3] > 160) {
          newImageData.data[4 * i] = videoImageData.data[4 * i + 0];
          newImageData.data[4 * i + 1] = videoImageData.data[4 * i + 1];
          newImageData.data[4 * i + 2] = videoImageData.data[4 * i + 2];
          newImageData.data[4 * i + 3] = 255;
        }
      }

      ctx.putImageData(newImageData, 0, 0);
      if (this.onDraw) this.onDraw(canvas);
    };

    tracker.start(video);

    const newStream = canvas.captureStream();

    return newStream;
  }

  stopEffect() {
    const tracker = this.#tracker;
    if (tracker) {
      tracker.stop();
      tracker.onPredict = null;
    }
  }

  isEnabled() {
    return this.#initialized;
  }
}

export default BackgroundEffect;
