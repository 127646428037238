import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FoundMessages from "./FoundMessages.component";
import { searchPageAndSection } from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { foundMessages, searchedValue, totalResults } = state.search;
  return {
    messages: foundMessages,
    searchedValue,
    totalMessages: totalResults.messages,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ searchPageAndSection }, dispatch);

const FoundMessagesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FoundMessages);

export default FoundMessagesContainer;
