import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  pagination: {
    margin: "20px",
    display: "flex",
    justifyContent: "center",
    marginLeft: 0,
    marginRight: 0,
  },
}));
