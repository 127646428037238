import { gql } from "../../../utils/apollo";

export const setOnlineStatus = gql`
  mutation SetOnlineStatus($online: Boolean) {
    setOnlineStatus(input: { online: $online }) {
      null
    }
  }
`;

export default setOnlineStatus;
