import { makeStyles } from "@mui/styles";

import { colors } from "../../../style";

const getArrowImage = function (max, ...coords) {
  max = parseInt(max);
  const newCoords = coords
    .map(([x, y]) => [(max / 100) * x, (max / 100) * y].join(","))
    .join(" ");
  const color = `%23${colors.darkGrey.substring(1)}`;
  return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${max}' height='${max}' fill='${color}'><polygon points='${newCoords}'/></svg>")`;
};

export default makeStyles((theme) => {
  const height = "50px";
  return {
    queue: {
      overflow: "auto",
      whiteSpace: "nowrap",
      height: "100%",
      // "&::-webkit-scrollbar": {
      //     height,
      //     width: "6px",
      // },
      // "&::-webkit-scrollbar-track": {
      //     height,
      //     width: "6px",
      //     boxShadow: `inset 0 0 6px ${colors.dark}`,
      //     webkitBoxShadow: `inset 0 0 6px ${colors.dark}`,
      // },
      // "&::-webkit-scrollbar-thumb": {
      //     height,
      //     width: "6px",
      //     backgroundColor: colors.dark,
      //     outline: `1px solid ${colors.lightDark}`,
      // },
      // "&::-webkit-scrollbar-button:single-button": {
      //     height: "6px",
      //     width: "6px",
      //     // width: height,
      //     backgroundColor: colors.dark,
      // },
      // "&::-webkit-scrollbar-button:single-button:horizontal:decrement": {
      //     backgroundImage: getArrowImage(
      //         height,
      //         [80, 20],
      //         [80, 80],
      //         [10, 50]
      //     ),
      // },
      // "&::-webkit-scrollbar-button:single-button:horizontal:increment": {
      //     backgroundImage: getArrowImage(
      //         height,
      //         [20, 20],
      //         [20, 80],
      //         [90, 50]
      //     ),
      // },
    },
  };
  //  This is the another version. If everything looks fine, delete it
  //     max = parseInt(max);
  //     const newCoords = coords
  //         .map(([x, y]) => [max / 100 * x, max / 100 * y].join(","))
  //         .join(" ");
  //     const color = `%23${colors.darkGrey.substring(1)}`;
  //     return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${max}' height='${max}' fill='${color}'><polygon points='${newCoords}'/></svg>")`
  // };

  // export default makeStyles((theme) => {
  //     const height = "16px";
  //     return {
  //         queue: {
  //             overflowX: "auto",
  //             "&::-webkit-scrollbar": {
  //                 height,
  //                 width: "6px",
  //             },
  //             "&::-webkit-scrollbar-track": {
  //                 height,
  //                 width: "6px",
  //                 boxShadow: `inset 0 0 6px ${colors.dark}`,
  //                 webkitBoxShadow: `inset 0 0 6px ${colors.dark}`,
  //             },
  //             "&::-webkit-scrollbar-thumb": {
  //                 height,
  //                 width: "6px",
  //                 backgroundColor: colors.dark,
  //                 outline: `1px solid ${colors.lightDark}`
  //             },
  //             "&::-webkit-scrollbar-button:single-button": {
  //                 height: "6px",
  //                 width: "6px",
  //                 // width: height,
  //                 backgroundColor: colors.dark,
  //             },
  //             "&::-webkit-scrollbar-button:single-button:horizontal:decrement": {
  //                 backgroundImage: getArrowImage(height, [80, 20], [80, 80], [10, 50]),
  //             },
  //             "&::-webkit-scrollbar-button:single-button:horizontal:increment": {
  //                 backgroundImage: getArrowImage(height, [20, 20], [20, 80], [90, 50]),
  //             },
  //         },
  //     };
});
