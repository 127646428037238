import React, { useState } from "react";
import * as Mui from "@mui/material";

import * as Forms from "../forms";

export default function Settings() {
  const [expanded, setExpanded] = useState("");

  const getOnChangeHandler = (panel) => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false);

  const handleCancel = () => setExpanded("");

  return (
    <>
      <Mui.Accordion
        expanded={expanded === "username"}
        onChange={getOnChangeHandler("username")}
      >
        <Mui.AccordionSummary>
          <Mui.ListItem>
            <Mui.ListItemText primary="Change Username" />
          </Mui.ListItem>
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          <Forms.ChangeUsernameForm onSubmit={handleCancel} />
        </Mui.AccordionDetails>
      </Mui.Accordion>

      <Mui.Accordion
        expanded={expanded === "avatar"}
        onChange={getOnChangeHandler("avatar")}
      >
        <Mui.AccordionSummary>
          <Mui.ListItem>
            <Mui.ListItemText primary="Change Avatar" />
          </Mui.ListItem>
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          <Forms.ChangeAvatarForm onSubmit={handleCancel} />
        </Mui.AccordionDetails>
      </Mui.Accordion>

      <Mui.Accordion
        expanded={expanded === "email"}
        onChange={getOnChangeHandler("email")}
      >
        <Mui.AccordionSummary>
          <Mui.ListItem>
            <Mui.ListItemText primary="Change Email" />
          </Mui.ListItem>
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          <Forms.ChangeEmailForm onSubmit={handleCancel} />
        </Mui.AccordionDetails>
      </Mui.Accordion>

      <Mui.Accordion
        expanded={expanded === "password"}
        onChange={getOnChangeHandler("password")}
      >
        <Mui.AccordionSummary>
          <Mui.ListItem>
            <Mui.ListItemText primary="Change Password" />
          </Mui.ListItem>
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          <Forms.ChangePasswordForm onSubmit={handleCancel} />
        </Mui.AccordionDetails>
      </Mui.Accordion>

      <Mui.Accordion
        expanded={expanded === "deleteAccount"}
        onChange={getOnChangeHandler("deleteAccount")}
      >
        <Mui.AccordionSummary>
          <Mui.ListItem>
            <Mui.ListItemText primary="Delete Account" />
          </Mui.ListItem>
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          <Forms.DeleteAccountForm onCancel={handleCancel} />
        </Mui.AccordionDetails>
      </Mui.Accordion>
    </>
  );
}
