/**
 * @param {object} stripe Stripe object instance
 * @param {string} clientSecret Payment intent client_secret
 * @returns {object} Stripe paymentIntent instance
 */
export const retrievePaymentIntent = async function (stripe, clientSecret) {
  const { error, paymentIntent } = await stripe.retrievePaymentIntent(
    clientSecret
  );
  if (error) throw error;
  return paymentIntent;
};

export default retrievePaymentIntent;
