import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AddToGroupButton from "./AddToGroupButton.component";

import { addUserToRooms } from "../../../store/actions/index";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const { rooms } = state.chat;
  let groupRooms = rooms.filter((room) => room.group);
  return { rooms: groupRooms, currentRoom: room };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addUserToRooms,
    },
    dispatch
  );

const AddToGroupButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddToGroupButton);

export default AddToGroupButtonContainer;
