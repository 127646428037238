import { createAction } from "@reduxjs/toolkit";

export const updateIsOneToOneChatsOpen = createAction<boolean>(
  "saga/updateIsOneToOneChatsOpen"
);

export const updateIsGroupChatsOpen = createAction<boolean>(
  "saga/updateIsGroupChatsOpen"
);

// TODO find out the type of the payload

export const setShowingOrganizedAction = createAction(
  "saga/setShowingOrganized",
  (organizedChats: string) => ({
    payload: { organizedChats },
  })
);
