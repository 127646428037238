import { gql } from "../../../utils/apollo";

export const editMessage = gql`
  mutation EditMessage($id: ID, $text: String) {
    editMessage(input: { id: $id, text: $text }) {
      null
    }
  }
`;

export default editMessage;
