import { call, put } from "redux-saga/effects";
import * as actions from "XmarsStore/actions";

export function* screenSharingHandler(payload) {
  const { signal, meetingCode, connection, track, user } = payload;
  if (signal.connectionStatus === "CONNECTED") {
    yield call(onConnected, meetingCode, connection, track, user);
  }
}

const onConnected = function* (meetingCode, connection, track, user) {
  const trackConference = connection.initJitsiConference(meetingCode, {});
  trackConference.setDisplayName(`${user.username}'s screen`);
  trackConference.setLocalParticipantProperty("isDesktop", "true");

  yield put(
    actions.startSharingScreen({
      channelId: meetingCode,
      sharingUserId: trackConference.myUserId(),
    })
  );
  trackConference.join();
  yield trackConference.addTrack(track);

  yield put(actions.setScreenTrack(track));
  yield put(actions.setSharedScreen(trackConference));
  yield put(
    actions.updateLocalParticipant({
      sharingScreen: true,
    })
  );
  yield put(
    actions.addSnackbar({
      severity: "success",
      message: `You are sharing the screen`,
    })
  );
};
