import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { RoomUser } from "../../collectionItems";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const RoomUsers = function ({
  activeChatRoomId,
  users,
  resetSearchedUsers,
  getRoomUsers,
  selfUser,
}) {
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    resetSearchedUsers();
    getRoomUsers({ activeChatRoomId });
    // eslint-disable-next-line
  }, [activeChatRoomId]);

  const getOnClickHandler = (index) => () => setSelected(index);

  return (
    <Mui.List>
      <RoomUser user={selfUser} selfUser={true} />
      {users.length ? (
        users.map((user, index) => (
          <RoomUser
            key={index}
            user={user}
            selected={index === selected}
            onClick={getOnClickHandler(index)}
          />
        ))
      ) : (
        <Mui.ListItem>
          <Mui.ListItemText primary={"You are the only user"} />
        </Mui.ListItem>
      )}
    </Mui.List>
  );
};

export default RoomUsers;

RoomUsers.propTypes = {
  room: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  resetSearchedUsers: PropTypes.func.isRequired,
  getRoomUsers: PropTypes.func.isRequired,
};
