import { createAction } from "@reduxjs/toolkit";

export const createWorkspace = createAction("saga/createWorkspace");
export const getWorkspaces = createAction("saga/getWorkspaces");
export const getWorkspace = createAction("saga/getWorkspace");
export const setCurrentWorkspace = createAction("saga/setCurrentWorkspace");
export const updateWorkspace = createAction("saga/updateWorkspace");
export const deleteWorkspace = createAction("saga/deleteWorkspace");
export const kickUserFromWorkspace = createAction("saga/kickUserFromWorkspace");
export const setWorkspaceAdmin = createAction("saga/setWorkspaceAdmin");
export const unsetWorkspaceAdmin = createAction("saga/unsetWorkspaceAdmin");

export const replaceWorkspaceForUpdated = createAction(
  "saga/replaceWorkspaceForUpdated"
);

export const createCategory = createAction("saga/createCategory");
export const getCategories = createAction("saga/getCategories");
export const updateCategory = createAction("saga/updateCategory");
export const deleteCategory = createAction("saga/deleteCategory");
