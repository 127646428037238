import { createSelector } from "@reduxjs/toolkit";

const getActiveWorkspaceId = (state) => state.chat.currentWorkspace?.id;
export const selectActiveWorkspaceId = createSelector(
  getActiveWorkspaceId,
  (id) => id
);

const selectWorkspacesList = (state) => state.chat.workspaces;

const getActiveWorkspace = (state) => state.chat.currentWorkspace;
export const selectActiveWorkspace = createSelector(
  getActiveWorkspace,
  (activeWorkspace) => activeWorkspace
);

export const selectActiveWorkspaceAvatar = createSelector(
  selectActiveWorkspace,
  (activeWorkspace) => activeWorkspace.avatar
);

export const selectActiveWorkspaceDescription = createSelector(
  selectActiveWorkspace,
  (activeWorkspace) => activeWorkspace.description
);

export const selectActiveWorkspaceMembers = createSelector(
  selectActiveWorkspace,
  (activeWorkspace) => activeWorkspace.users
);

export const selectActiveWorkspaceTitle = createSelector(
  selectActiveWorkspace,
  (activeWorkspace) => activeWorkspace.title
);

export const selectUserIsActiveWorkspaceAdmin = createSelector(
  selectActiveWorkspace,
  (activeWorkspace) => activeWorkspace.admin
);
