import recordingState from "./recordingState";
import {
  registerEventHandlers,
  unregisterEventHandlers,
} from "../eventHandlers";
import { mainLog, groupLog } from "../loggers";
import JitsiError from "../JitsiError";
import { logError, sleep } from "../../utils";

// eslint-disable-next-line
import { JitsiConference } from "../typeDefs";

/**
 * @param {JitsiConference} conference
 * @returns {string}
 */
export const startRecording = async function (conference) {
  const recordingOptions = {
    mode: "file",
    broadcastId: null,
    streamId: null,
  };

  const conferenceHandlers = {
    onRecorderStateChanged(session) {
      const { _status: status, _error: error } = session;
      if (error) return recordingState.rejecter(new Error(error));
      if (status === "on") return recordingState.resolver(status);
    },
  };

  try {
    mainLog("Starting recording...");
    registerEventHandlers("conference", conference, conferenceHandlers);
    const session = await conference.startRecording(recordingOptions);
    groupLog("Recording request accepted", () => {
      console.log(session);
    });
    const sessionID = session.getID();
    await recordingState.recordingHasStarted(sessionID);
    await sleep(3000);
    groupLog("Recording started", () => {
      console.log("sessionID", sessionID);
    });
    return sessionID;
  } catch (error) {
    groupLog("Starting recording failed!", () => {
      logError(error);
    });
    const message =
      error instanceof Element ? error.textContent : error.message;
    throw new JitsiError(message);
  } finally {
    unregisterEventHandlers("conference", conference, conferenceHandlers);
  }
};

export default startRecording;
