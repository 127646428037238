const initialState = {
  searchedValue: "",
  foundMessages: [],
  foundGroups: [],
  foundContacts: [],
  foundFiles: [],
  searchPanelOpen: false,
  totalResults: {
    messages: 0,
    groups: 0,
    contacts: 0,
    files: 0,
  },
  areResultsLoaded: false,
  defaultResults: "Messages",
  searchWithFilters: false,
  onSelectorOpen: false,
  date: "Any time",
  filters: {},
};

export default initialState;
