import client from "../../../client";
import mutation from "./updateIsGroupChatsOpen.mutation";

/**
 * @param {Boolean} isGroupChatsOpen
 */
export const updateIsGroupChatsOpen = async function (isGroupChatsOpen) {
  const {
    data: { updateIsGroupChatsOpen },
  } = await client.mutate({
    mutation,
    variables: {
      isGroupChatsOpen,
    },
  });

  return updateIsGroupChatsOpen;
};

export default updateIsGroupChatsOpen;
