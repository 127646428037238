import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import RoomUser from "./RoomUser.component";

import {
  kickUser,
  setRoomAdmin,
  unsetRoomAdmin,
} from "../../../store/actions/index";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  return { room };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      kickUser,
      setRoomAdmin,
      unsetRoomAdmin,
    },
    dispatch
  );

const RoomUserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomUser);

export default RoomUserContainer;
