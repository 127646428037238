import { call, put, select } from "redux-saga/effects";

import * as electron from "../../../../electron";
import api from "../../../../dataApi";
import * as stripeApi from "../../../../stripe";
import {
  registerRoom,
  unregisterRoom,
  unregisterCurrentRoom,
} from "XmarsStore/sagas/chat/utils";

import * as actions from "../../../actions";
import { logError, tryOrLog } from "XmarsUtils/index";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getRooms = function* () {
  try {
    const rooms = yield call(api.getGroupRooms);
    for (const room of rooms) {
      yield registerRoom(room);
    }
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getRoom = function* ({ payload }) {
  try {
    yield unregisterCurrentRoom();
    const { id } = payload;
    const room = yield call(api.getRoom, id);
    yield registerRoom(room);
    yield put(actions.setRoom(room));
    if (room.activeCall === true) {
      yield put(
        actions.setOngoingCall({
          meetingCode: room.id,
          channel: room,
        })
      );
    } else {
      yield put(actions.setOngoingCall(null));
    }
    if (room.sharing) {
      yield put(actions.setSharing({ status: true, id: room.sharing }));
    }
  } catch (error) {
    yield handleSagaError(error);
  }
};

// export const unsetRoom = function*({payload}){
//   const room = payload
//   try {
//     yield unregisterRoom(room);
//     // yield put(actions.setRoom());
//   } catch (error) {
//     yield handleSagaError(error);
//   }
// }

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getOneVsOneRoom = function* ({ payload }) {
  const { userId, categoryId } = payload;
  try {
    //   const friend = payload;
    //   if (friend.room) return yield put(actions.setRoom(friend.room));
    const room = yield call(api.createFriendRoom, userId, categoryId);
    console.log(room);
    if (room.errors) throw room.errors;
    // friend.room = room;

    registerRoom(room);
    yield put(actions.setRoom(room));
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getPublicLink = function* ({ payload } = {}) {
  try {
    const { id, user_id, callback } = payload;
    const link = yield call(api.getPublicRoomLink, id, user_id);
    callback(link);
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const createCustomRoom = function* ({ payload }) {
  const { title, categoryId, isPublic, promise } = payload;
  try {
    const channel = yield call(
      api.createCustomRoom,
      title,
      categoryId,
      isPublic
    );
    yield call(promise.resolve);
    yield put(actions.addChannel(channel));
  } catch (error) {
    yield handleSagaError(error);
    yield call(promise.reject, error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const updateRoom = function* ({ payload }) {
  const { newTitle, roomId, promise } = payload;
  try {
    const channelId = roomId;
    yield call(api.updateRoom, newTitle, roomId);
    yield call(promise.resolve);
    yield put(actions.updateChannelTitle({ channelId, newTitle }));
  } catch (error) {
    yield handleSagaError(error);
    yield call(promise.reject, error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const createMedicalRoom = function* ({ payload }) {
  const { username, email, date, promise } = payload;
  try {
    const room = yield call(api.createMedicalRoom, { username, email }, date);
    promise.resolve();
    registerRoom(room);
    yield put(actions.setRoom(room));
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const createPublicRoom = function* ({ payload }) {
  const { title, guests, date, promise } = payload;
  try {
    const room = yield call(api.createPublicRoom, title, guests, date);
    promise.resolve();
    registerRoom(room);
    yield put(actions.setRoom(room));
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const deleteConversation = function* ({ payload }) {
  const { roomId, promise } = payload;
  try {
    const channelId = roomId;
    yield call(api.deleteConversation, roomId);
    const { room: currentRoom } = yield select((state) => state.chat);
    const { conference } = yield select((state) => state.jitsi);
    const { activeChannel } = yield select((state) => state.video);

    if (activeChannel && activeChannel.id === channelId && conference)
      conference.leave();

    if (currentRoom && currentRoom.id === channelId) {
      yield put(actions.setRoom(null));
    }
    yield put(actions.removeChannel(channelId));
    yield call(promise.resolve);
    const snackbar = {
      severity: "success",
      message: `Channel deleted`,
    };
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const leaveRoom = function* ({ payload: room }) {
  try {
    yield call(api.leaveRoom, room.id);

    const { conference } = yield select((state) => state.jitsi);
    const { activeChannel } = yield select((state) => state.video);

    if (activeChannel?.id === room.id && conference) {
      yield call(conference.leave);
    }
    yield call(unregisterRoom, room);
    const snackbar = {
      severity: "success",
      message: `You left room ${room.title}`,
    };
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getRoomUsers = function* ({ payload }) {
  try {
    const roomId = payload.activeChatRoomId || payload.id;
    const newUsers = yield call(api.getRoomUsers, roomId);
    yield put(actions.setSearchedUsers(newUsers));
    yield put(actions.updateRoomUsers({ roomId, newUsers: newUsers }));
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const kickUser = function* ({ payload }) {
  try {
    const { room, user } = payload;
    yield call(api.kickUser, room.id, user.id);
    const snackbar = {
      severity: "success",
      message: `You removed ${user.username} from ${room.title}`,
    };
    yield put(actions.removeSearchedUser(user));
    yield put(actions.addSnackbar(snackbar));
    const users = yield call(api.getRoomUsers, room.id);
    yield put(actions.updateRoomUsers({ roomId: room.id, users }));
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const addUserToRoom = function* ({ payload }) {
  try {
    const { room, user, userRoomId } = payload;
    yield call(api.addUserToRoom, room.id, user.id, userRoomId);
    const snackbar = {
      severity: "success",
      message: `The user ${user.username} was added to ${room.title}`,
    };

    yield put(actions.addSnackbar(snackbar));
    const users = yield call(api.getRoomUsers, room.id);
    yield put(actions.updateRoomUsers({ roomId: room.id, users }));
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const addUsersToRoom = function* ({ payload }) {
  const { users, room, promise } = payload;

  try {
    const usersIds = users.map((user) => {
      return user.id;
    });
    const usersRoomList = yield select((state) => {
      return state.chat.rooms.filter(
        (room) => !room.group && usersIds.includes(room.friend.id)
      );
    });
    const userRoomsIds = usersRoomList.map((user) => user.id);
    yield call(api.addUsersToRoom, usersIds, userRoomsIds, room.id);
    const snackbar = {
      severity: "success",
      message: `The users were added to the room ${room.title}`,
    };
    if (promise) {
      yield call(promise.resolve);
    }
    yield put(actions.addSnackbar(snackbar));
    yield put(actions.getRoomUsers(room));
    // const newUsers = yield call(api.getRoomUsers, room.id);
  } catch (error) {
    yield handleSagaError(error);
    if (promise) {
      yield call(promise.reject);
    }
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const addUserToRooms = function* ({ payload }) {
  const { user, rooms, userRoomId, promise } = payload;
  try {
    const roomsIds = rooms.map((room) => room.id);
    yield call(api.addUserToRooms, user.id, roomsIds, userRoomId);

    const snackbar = {
      severity: "success",
      message: `The user ${user.username} was added to the rooms`,
    };
    yield call(promise.resolve);
    yield put(actions.addSnackbar(snackbar));

    for (let roomId of roomsIds) {
      const users = yield call(api.getRoomUsers, roomId);
      yield put(actions.updateRoomUsers({ roomId, users }));
    }
  } catch (error) {
    yield handleSagaError(error);
    yield call(promise.reject);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const setRoomAdmin = function* ({ payload }) {
  try {
    const { room, user } = payload;
    yield call(api.setRoomAdmin, room.id, user.id);
    const snackbar = {
      severity: "success",
      message: `${user.username} is now admin of ${room.title}`,
    };
    yield put(actions.updateSearchedUser(user, { roomAdmin: true }));
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const unsetRoomAdmin = function* ({ payload }) {
  try {
    const { room, user } = payload;
    yield call(api.unsetRoomAdmin, room.id, user.id);
    const snackbar = {
      severity: "success",
      message: `${user.username} is not admin of ${room.title} anymore`,
    };
    yield put(actions.updateSearchedUser(user, { roomAdmin: false }));
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getDraft = function* ({ payload }) {
  try {
    const { roomId } = payload;
    const draft = yield call(api.getDraft, roomId);

    if (draft?.message) {
      const messages = yield select((state) => state.messages);
      let messageA = messages.filter((message) => message.id === draft.message);

      if (messageA.length) {
        yield put(actions.setEditingMessage(messageA[0]));
        yield put(actions.setIsEditingMessage(true));
      }
    }

    yield put(actions.setDraft(draft?.text || ""));
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const saveDraft = function* ({ payload }) {
  try {
    const { room } = payload;
    const roomId = payload.roomId || room.id;
    const { draft, editingMessage } = yield select((state) => state.chat);

    yield call(api.saveDraft, roomId, editingMessage?.id, draft);
    const newDraft = {
      room: roomId,
      text: draft,
      messageId: editingMessage?.id,
    };
    yield put(actions.addDraft(newDraft));
    yield put(actions.setEditingMessage(null));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const channelCall = function* ({ payload }) {
  try {
    const { channel, workspace } = payload;
    yield call(api.channelCall, {
      channelId: channel.id,
      workspaceId: workspace.id,
    });
    yield put(actions.setOngoingCall({ meetingCode: channel.id, channel }));
    yield put(actions.setMeetingCode(channel.id));
    yield put(actions.setActiveChannel(channel));
    yield put(actions.setPage("videoCall"));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const endOngoingCall = function* ({ payload }) {
  try {
    const channel = payload;
    const workspace = yield select((state) => state.chat.currentWorkspace);
    yield call(api.endOngoingCall, channel.id, workspace.id);
    yield put(actions.setOngoingCall(null));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const startSharingScreen = function* ({ payload }) {
  try {
    const { channelId, sharingUserId } = payload;
    const workspace = yield select((state) => state.chat.currentWorkspace);
    yield call(api.startSharingScreen, channelId, workspace.id, sharingUserId);
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const stopSharingScreen = function* ({ payload }) {
  try {
    const { channelId, sharingUserId } = payload;
    const workspace = yield select((state) => state.chat.currentWorkspace);
    yield call(api.stopSharingScreen, channelId, workspace.id, sharingUserId);
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getClientSecret = function* ({ payload }) {
  const { room, promise } = payload;
  try {
    try {
      yield call(api.updateMedicalRoomPayment, room.id);
    } catch (error) {
      logError(error);
      throw new Error("Unable to update payment");
    }
    try {
      const retrieved = yield call(api.getClientSecret, room.id);
      promise.resolve(retrieved.clientSecret);
    } catch (error) {
      logError(error);
      throw new Error("Unable to get client secret");
    }
  } catch (error) {
    logError(error);
    promise.reject(error);
  }
};

export const retrievePaymentIntent = function* ({ payload }) {
  const { stripe, clientSecret, promise } = payload;
  try {
    const paymentIntent = yield call(
      stripeApi.retrievePaymentIntent,
      stripe,
      clientSecret
    );
    promise.resolve(paymentIntent);
  } catch (error) {
    promise.reject(error);
  }
};

export const confirmCardPayment = function* ({ payload }) {
  const { stripe, clientSecret, options, promise } = payload;
  try {
    yield call(stripeApi.confirmCardPayment, stripe, clientSecret, options);
    promise.resolve();
  } catch (error) {
    promise.reject(error);
  }
};
