import client from "../../../client";
import mutation from "./updateIsOneToOneChatsOpen.mutation";

/**
 * @param {Boolean} isOneToOneChatsOpen
 */
export const updateIsOneToOneChatsOpen = async function (isOneToOneChatsOpen) {
  const {
    data: { updateIsOneToOneChatsOpen },
  } = await client.mutate({
    mutation,
    variables: {
      isOneToOneChatsOpen,
    },
  });

  return updateIsOneToOneChatsOpen;
};

export default updateIsOneToOneChatsOpen;
