import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Message from "./Message.component";

import {
  deleteMessage,
  readMessage,
  setRepliesPanelOpen,
  setCurrentMessage,
  setIsEditingMessage,
  setEditingMessage,
  setMessageToJumpTo,
} from "../../../store/actions/index";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state, { message }) => {
  const room = selectActiveChannel(state);
  const isGroupRoom = room.group;
  const { user, currentMessage, editingMessage, messageToJumpTo } = state.chat;
  const { repliesPanelOpen, unreadRepliesPanelOpen } = state.general;
  return {
    user,
    isGroupRoom,
    repliesPanelOpen,
    currentMessage,
    editingMessage,
    unreadRepliesPanelOpen,
    shouldScrollIntoView:
      !!messageToJumpTo &&
      !messageToJumpTo.beforeFetch &&
      messageToJumpTo.id === message.id,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteMessage,
      readMessage,
      setRepliesPanelOpen,
      setCurrentMessage,
      setIsEditing: setIsEditingMessage,
      setEditingMessage,
      setMessageToJumpTo,
    },
    dispatch
  );

const MessageContainer = connect(mapStateToProps, mapDispatchToProps)(Message);

export default MessageContainer;
