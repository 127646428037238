import React, { useState } from "react";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { Notification } from "../../collectionItems";

export const Notifications = function ({ notifications }) {
  const [selected, setSelected] = useState(-1);

  const getOnClickHandler = (index) => () => setSelected(index);

  return (
    <Mui.List>
      {notifications.length ? (
        notifications.map((notification, index) => (
          <Notification
            key={index}
            notification={notification}
            selected={index === selected}
            onClick={getOnClickHandler(index)}
          />
        ))
      ) : (
        <Mui.ListItem>
          <Mui.ListItemText primary={"No notifications Pending"} />
        </Mui.ListItem>
      )}
    </Mui.List>
  );
};

export default Notifications;

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
};
