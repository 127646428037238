import { connect } from "react-redux";

import Notifications from "./Notifications.component";

const mapStateToProps = (state) => {
  const { notifications } = state;
  return { notifications };
};

const NotificationsContainer = connect(mapStateToProps, null)(Notifications);

export default NotificationsContainer;
