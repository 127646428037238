import { Component } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  ListItemText,
} from "@mui/material";
import * as Icons from "react-feather";

import { logError } from "../../utils/errors/logError";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    logError(error);
    // logComponentStackToMyService(info.componentStack);
  }

  handleReload() {
    window.location.reload();
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    // You can render any custom fallback UI
    return (
      <Dialog open>
        <DialogTitle>
          <Box textAlign="center">
            <Icon color="error">
              <Icons.AlertCircle style={{ transform: "scale(2)" }} />
            </Icon>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ListItemText
            primary="Something went wrong"
            secondary="Please refresh page"
          />
        </DialogContent>
        <DialogActions>
          <IconButton onClick={this.handleReload}>
            <Icon>
              <Icons.RefreshCw />
            </Icon>
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ErrorBoundary;
