import { call, delay, fork, put, race, take, select } from "redux-saga/effects";
import * as actions from "XmarsStore/actions";
import api from "../../../../dataApi";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";
import { selectActiveWorkspaceMembers } from "XmarsStore/selectors";

export const inviteUsersToCall = function* ({ payload }) {
  const { usersIds, meetingCode, channelId, workspaceId } = payload;
  try {
    yield call(
      api.inviteUsersToCall,
      usersIds,
      meetingCode,
      channelId,
      workspaceId
    );

    const users = yield select(selectActiveWorkspaceMembers);
    const invitedUsers = users.filter((user) => usersIds.includes(user.id));
    const sharingStatus = yield select((state) => state.video.sharing.status);

    for (let invitedUser of invitedUsers) {
      const remoteParticipant = {
        local: false,
        focused: sharingStatus === true ? false : true,
        id: invitedUser.id,
        name: invitedUser.username,
        avatar: invitedUser.avatar,
        invited: true,
        audio: null,
        video: null,
        messages: [],
      };
      yield put(actions.addRemoteParticipant(remoteParticipant));
      yield fork(waitForUser, remoteParticipant);
    }
  } catch (error) {
    handleSagaError(error);
  }
};

const waitForUser = function* (participant) {
  const { userAnswered } = yield race({
    userAnswered: take((action) => {
      return (
        action.type === actions.addRemoteParticipant.type &&
        action.payload.name === participant.name
      );
    }),
    ghosted: delay(15000, true),
  });
  if (userAnswered) {
    yield put(actions.removeRemoteParticipant({ id: participant.id }));
  } else {
    yield put(
      actions.addSnackbar({
        message: `${participant.name} didn't answer`,
        severity: "error",
      })
    );
    yield put(actions.removeRemoteParticipant({ id: participant.id }));
  }
};
