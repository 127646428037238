import { takeEvery } from "redux-saga/effects";
import { actions } from "XmarsStore/actions/index";
import { startJitsiCall } from "./startJitsiCall";
import { startSharing, stopSharing } from "./screenSharing";

export default function* interceptor() {
  yield takeEvery(actions.sagas.startJitsiCall, startJitsiCall);
  yield takeEvery(actions.sagas.startSharing, startSharing);
  yield takeEvery(actions.sagas.stopSharing, stopSharing);
}
