import { useEffect, useMemo, useState } from "react";

import useDependency from "./useDependency";

export const useDragging = function (ref) {
  const [counter, setCounter] = useState(0);

  const [increase, onIncrease] = useDependency();
  const [decrease, onDecrease] = useDependency();

  const dragging = useMemo(() => counter > 0, [counter]);

  useEffect(() => {
    const element = ref.current;
    element.addEventListener("dragenter", handleDragEnter);
    element.addEventListener("dragover", handleDragOver);
    element.addEventListener("dragleave", handleDragLeave);
    return () => {
      element.removeEventListener("dragenter", handleDragEnter);
      element.removeEventListener("dragover", handleDragOver);
      element.removeEventListener("dragleave", handleDragLeave);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (onIncrease) setCounter(counter + 1);
    // eslint-disable-next-line
  }, [onIncrease]);

  useEffect(() => {
    if (onDecrease) setCounter(counter - 1);
    // eslint-disable-next-line
  }, [onDecrease]);

  const prevent = (callback) =>
    function (event) {
      event.preventDefault();
      event.stopPropagation();
      if (callback) callback(event);
    };

  const handleDragOver = prevent();

  const handleDragEnter = prevent((event) => {
    const { items } = event.dataTransfer;
    if (items && items.length) increase();
  });

  const handleDragLeave = prevent(() => {
    decrease();
  });

  const stopDragging = () => setCounter(0);

  return [dragging, stopDragging];
};

export default useDragging;
