import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PublicLinkButton from "./PublicLinkButton.component";
import { getPublicLink } from "../../../store/actions/index";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const users = state.chat.searchedUsers;
  return { room, users };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPublicLink,
    },
    dispatch
  );

const PublicLinkButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicLinkButton);

export default PublicLinkButtonContainer;
