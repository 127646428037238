import React, { useEffect, useRef, useState } from "react";
import * as Mui from "@mui/material";
import { VerticalScrollbars } from "../../prestyledComponents";
import { FoundGroup } from "../../collectionItems";
import { PaginationControls } from "../../PaginationControls";
import { FoundItemsLoading } from "../FoundItemsLoading";

import useStyles from "./FoundGroups.styles";

const scrollBarsStyles = { padding: "30px", height: "calc(100vh - 140px)" };
export const FoundGroups = function ({
  groups,
  resultsPerPage,
  searchedValue,
  searchPageAndSection,
  totalGroups,
}) {
  const classes = useStyles();
  const count = Math.ceil(totalGroups / resultsPerPage);
  const [page, setPage] = useState(1);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const ref = useRef();

  const handleChange = (_, value) => {
    setPage(value);
    setIsLoadingGroups(true);
    ref.current.scrollTop = 0;
    searchPageAndSection({ searchedValue, page: value, section: ["Groups"] });
  };

  useEffect(() => {
    setIsLoadingGroups(false);
  }, [groups]);

  /**
   * Renders the found groups
   * @returns {React.ReactElement} List with the messages.
   */
  const renderGroups = function () {
    if (!groups.length)
      return (
        <Mui.ListItem>
          <Mui.ListItemText primary="No results" />
        </Mui.ListItem>
      );

    return (
      <Mui.Box>
        {isLoadingGroups ? (
          <FoundItemsLoading style={{ padding: "0px", marginTop: "-8px" }} />
        ) : (
          <>
            {groups.map((group, i) => (
              <FoundGroup group={group} key={`found-group-${i}`} />
            ))}
          </>
        )}
      </Mui.Box>
    );
  };

  return (
    <VerticalScrollbars
      viewStyle={scrollBarsStyles}
      ref={(s) => (ref.current = s && s.view)}
    >
      <Mui.List className={classes.messagesContainer}>
        {renderGroups()}
        {count > 1 && (
          <PaginationControls
            count={count}
            page={page}
            handleChange={handleChange}
            disabled={isLoadingGroups}
          />
        )}
      </Mui.List>
    </VerticalScrollbars>
  );
};

export default FoundGroups;
