export const isSafari = (() => {
  const ua = navigator.userAgent;
  if (!ua) return false;

  const iOS = !!ua.match(/iP(ad|od|hone)/i);
  const hasSafariInUa = !!ua.match(/Safari/i);
  const noOtherBrowsersInUa = !ua.match(
    /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i
  );

  //detecting Safari in IOS mobile browsers
  if (iOS) {
    const webkit = !!ua.match(/WebKit/i);
    return webkit && hasSafariInUa && noOtherBrowsersInUa;
  }

  //detecting Safari in Desktop Browsers
  if (window.safari !== undefined) {
    return true;
  }

  // detecting Safari in other platforms
  return hasSafariInUa && noOtherBrowsersInUa;
})();

export default isSafari;
