import { connect } from "react-redux";

import LoginUserForm from "./LoginUserForm.component";

import { actions as act } from "../../../store/actions/index";

const mapDispatchToProps = {
  login: act.sagas.login,
  setPage: act.general.setPage,
};

const LoginUserFormContainer = connect(null, mapDispatchToProps)(LoginUserForm);

export default LoginUserFormContainer;
