import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CheckoutButton from "./CheckoutButton.component";

import { getClientSecret } from "../../../store/actions/index";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const { user } = state.chat;
  return { user, room };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClientSecret,
    },
    dispatch
  );

const CheckoutButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutButton);

export default CheckoutButtonContainer;
