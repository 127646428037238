import React, { useRef, useState } from "react";
import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { DatePicker, EmailTextField, UsernameTextField } from "../../inputs";
// import { LinearSubmitButton } from "../../prestyledComponents";
import { useMounted } from "../../../hooks/useMounted";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const CreateGuestRoomForm = function ({ createPublicRoom, onSubmit }) {
  // old spliter:
  // const splitter = "__s_p_l_i_t_t_e_r__";
  const splitter = "-";
  const [processing, setProcessing] = useState(false);
  const mounted = useMounted();

  const [guests, setGuests] = useState([null]);

  const formRef = useRef(null);

  const handleSubmit = formSubmitHandler(
    async ({ title, date, ...other }, event) => {
      date = new Date(parseInt(date));
      const regExp = new RegExp(`\\d*${splitter}\\w*`, "i");
      const guests = [];
      for (const [inputName, value] of Object.entries(other)) {
        if (regExp.test(inputName)) {
          const [index, field] = inputName.split(splitter);
          if (guests[index]) {
            guests[index][field] = value;
          } else {
            guests[index] = { [field]: value };
          }
        }
      }
      try {
        setProcessing(true);
        await getBoundActionPromise(createPublicRoom, {
          title,
          guests,
          date,
        });
        if (onSubmit) onSubmit(event);
      } catch (error) {
        logError(error);
      } finally {
        if (mounted.current) {
          setProcessing(false);
        }
      }
    }
  );

  const handleNewGuest = function () {
    const currentGuests = [...guests];
    currentGuests.push(null);
    setGuests(currentGuests);
  };

  const handleRemoveGuest = function () {
    if (guests.length === 1) return;
    const currentGuests = [...guests];
    currentGuests.pop();
    setGuests(currentGuests);
  };

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      component="form"
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <Grid item>
        <Mui.TextField
          fullWidth
          required
          autoComplete="off"
          disabled={processing}
          label="Room title"
          placeholder="Justice league"
          name="title"
        />
      </Grid>
      <Grid item>
        <DatePicker disabled={processing} />
      </Grid>
      <Grid item>
        <Mui.ButtonGroup fullWidth>
          <Mui.Button
            fullWidth
            disabled={processing}
            color="primary"
            variant="contained"
            children="Add guest"
            onClick={handleNewGuest}
            endIcon={<Icons.UserPlus />}
          />
          <Mui.Button
            fullWidth
            disabled={processing || guests.length <= 1}
            color="secondary"
            variant="contained"
            children="Remove guest"
            onClick={handleRemoveGuest}
            endIcon={<Icons.UserMinus />}
          />
        </Mui.ButtonGroup>
      </Grid>
      <Grid item>
        {guests.map((_, index) => (
          <Grid container key={index} direction="row">
            <Grid item xs={5}>
              <UsernameTextField
                disabled={processing}
                label={`Guest ${index + 1}`}
                name={`${index}${splitter}username`}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <EmailTextField
                disabled={processing}
                label={`Email ${index + 1}`}
                name={`${index}${splitter}email`}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      {/* <Grid item>
        <LinearSubmitButton
          loading={processing}
          children="Create Guest Room"
          endIcon={<Icons.Users />}
        />
      </Grid> */}
    </Grid>
  );
};

export default CreateGuestRoomForm;

CreateGuestRoomForm.propTypes = {
  onSubmit: PropTypes.func,
  createPublicRoom: PropTypes.func.isRequired,
};
