import { gql } from "../../../utils/apollo";

export const deleteMessage = gql`
  mutation DeleteMessage($id: ID) {
    deleteMessage(input: { id: $id }) {
      null
    }
  }
`;

export default deleteMessage;
