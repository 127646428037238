import { gql } from "../../../utils/apollo";

export const createWorkspace = gql`
  mutation CreateWorkspace(
    $title: String
    $description: String
    $avatar: String
  ) {
    createWorkspace(
      input: { title: $title, description: $description, avatar: $avatar }
    ) {
      id
      title
      createdAt
      description
      lastUpdate
      admin
      users {
        id
        username
        avatar
        token
      }
      avatar
    }
  }
`;

export default createWorkspace;
