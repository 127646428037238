import { takeEvery } from "redux-saga/effects";
import { actions as act } from "../../actions";

import { searchFromPanel } from "./searchFromPanel";
import { searchPageAndSection } from "./searchPageAndSection";
import { setFoundReply } from "./setFoundReply";

export default function* interceptor() {
  yield takeEvery(act.sagas.searchFromPanel, searchFromPanel);
  yield takeEvery(act.sagas.searchPageAndSection, searchPageAndSection);
  yield takeEvery(act.sagas.setFoundReply, setFoundReply);
}
