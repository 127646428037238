import { gql } from "../../../utils/apollo";

export const deleteAppUser = gql`
  mutation DeleteAppUser($id: ID) {
    deleteAppUser(input: { id: $id }) {
      username
    }
  }
`;

export default deleteAppUser;
