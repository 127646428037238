import { gql } from "../../../utils/apollo";

export const updateWorkspace = gql`
  mutation UpdateWorkspace($input: updateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      null
    }
  }
`;

export default updateWorkspace;
