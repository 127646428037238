import { gql } from "../../../utils/apollo";

export const recoverPassword = gql`
  mutation RecoverPassword($id: ID, $params: ID, $password: String) {
    recoverPassword(input: { id: $id, params: $params, password: $password }) {
      null
    }
  }
`;

export default recoverPassword;
