import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  recording: {
    animation: "$pop 2s ease-in-out infinite",

    ".MuiSvgIcon-root": {
      fill: "currentColor",
    },
  },
  "@keyframes pop": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.5)",
    },
  },
}));
