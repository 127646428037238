import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { SearchedUser } from "../../collectionItems";

export const SearchedUsers = function ({ users, resetSearchedUsers }) {
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    resetSearchedUsers();
    // eslint-disable-next-line
  }, []);

  const getOnClickHandler = (index) => () => setSelected(index);

  return (
    <Mui.List>
      {users.length ? (
        users.map((user, index) => (
          <SearchedUser
            key={index}
            user={user}
            selected={index === selected}
            onClick={getOnClickHandler(index)}
          />
        ))
      ) : (
        <Mui.ListItem>
          <Mui.ListItemText primary={"No users"} />
        </Mui.ListItem>
      )}
    </Mui.List>
  );
};

export default SearchedUsers;

SearchedUsers.propTypes = {
  users: PropTypes.array.isRequired,
  resetSearchedUsers: PropTypes.func.isRequired,
};
