import { forwardRef, useState, useRef } from "react";
import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
// import * as Icons  from "react-feather";
import PropTypes from "prop-types";

// import { LinearSubmitButton } from "../../prestyledComponents";
import { useMounted } from "../../../hooks/useMounted";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const CreateGroupRoomForm = forwardRef(function (
  { createCustomRoom, onSubmit },
  ref
) {
  const [processing, setProcessing] = useState(false);
  const mounted = useMounted();

  const formRef = useRef(null);

  const handleSubmit = formSubmitHandler(async ({ title }, event) => {
    try {
      setProcessing(true);
      await getBoundActionPromise(createCustomRoom, { title });
      if (onSubmit) onSubmit(event);
    } catch (error) {
      logError(error);
    } finally {
      if (mounted.current) {
        setProcessing(false);
      }
    }
  });

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      component="form"
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <Grid item>
        <Mui.TextField
          fullWidth
          required
          autoComplete="off"
          disabled={processing}
          label="Room title"
          name="title"
        />
      </Grid>
      {/* <Grid item>
        <LinearSubmitButton
          loading={processing}
          children="Create Room"
          endIcon={<Icons.Send />}
        />
      </Grid> */}
    </Grid>
  );
});

export default CreateGroupRoomForm;

CreateGroupRoomForm.propTypes = {
  onSubmit: PropTypes.func,
  createCustomRoom: PropTypes.func.isRequired,
};
