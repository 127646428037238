import { forwardRef } from "react";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";
import useStyles from "./TooltipIconButton.styles";
import clsx from "clsx";

export const TooltipIconButton = forwardRef(function (
  { title, TooltipProps, isInNavBar, addMarginTop, ...props },
  ref
) {
  const classes = useStyles();

  // const { isInNavBar } = props;
  return (
    <Mui.Tooltip
      title={title}
      classes={{
        tooltip: clsx(classes.tip, isInNavBar && classes.navBarButton),
        popper: clsx(
          classes.tipContainer,
          addMarginTop && classes.tipContainerMarginTop
        ),
      }}
      {...TooltipProps}
    >
      <span>
        <Mui.IconButton ref={ref} {...props} />
      </span>
    </Mui.Tooltip>
  );
});

export default TooltipIconButton;

TooltipIconButton.propTypes = {
  title: PropTypes.string.isRequired,
  TooltipProps: PropTypes.object,
};

TooltipIconButton.defaultProps = {
  TooltipProps: {
    placement: "bottom",
  },
};
