import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  settings: {
    minWidth: "50vw",
  },
  userAvatar: {
    cursor: "pointer",
    //marginRight: " !important",
  },
  userText: {
    fontSize: "14px",
    padding: "5px",
    color: "black",
    marginLeft: "-5px",
    textTransform: "capitalize",
  },
  userInfo: {
    marginLeft: "0px",
  },
  verticalDivider: {
    // position: "absolute",
    // right: "0",
    border: "solid",
    height: "100%",
  },
}));
