import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PaginationControls from "./PaginationControls.component";

//import { setFiles } from "../../stateManagement/actions";

//const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const PaginationControlsContainer = connect(
  null,
  mapDispatchToProps
)(PaginationControls);

export default PaginationControlsContainer;
