import { connect } from "react-redux";

import InviteToRoom from "./InviteToRoom.component";

const mapStateToProps = (state) => {
  const { user, friends } = state.chat;
  return {
    user,
    friends,
  };
};

const InviteToRoomContainer = connect(mapStateToProps)(InviteToRoom);

export default InviteToRoomContainer;
