import { useEffect, useRef } from "react";

/**
 * Useful to avoid state update on unmounted components
 */
export const useMounted = function () {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);

  return ref;
};

export default useMounted;
