import React, { useState, useEffect } from "react";
import { format } from "date-fns";

import useStyles from "./LastUpdateDate.styles";

/**
 * Checks if a date is the previous day of another.
 *  @param {Date} - currentDate
 *  @param {Date} - date
 *  @return {Boolean}
 */
function isYesterday(currentDate, date) {
  let previousDay = new Date(currentDate.getTime());
  previousDay.setDate(previousDay.getDate() - 1);

  return previousDay.toDateString() === date.toDateString();
}

export const LastUpdateDate = ({ lastUpdate }) => {
  const [parsedDate, setParsedDate] = useState(format(new Date(), "hh:mm a"));
  const classes = useStyles();
  useEffect(() => {
    let currentDate = new Date();
    const lastUpdateDate = new Date(lastUpdate);

    if (currentDate.toDateString() === lastUpdateDate.toDateString()) {
      setParsedDate(format(lastUpdate, "hh:mm a"));
    } else if (isYesterday(currentDate, lastUpdateDate)) {
      setParsedDate("Yesterday");
    } else {
      if (lastUpdate) {
        setParsedDate(format(lastUpdate, "MMM d, yyyy"));
      }
    }
  }, [lastUpdate]);

  return (
    <div className={classes.dateContainer}>
      <small width="100%">{parsedDate}</small>
    </div>
  );
};
