import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => {
  return {
    replies: {
      // maxHeight: "68vh",
      // minHeight: "68vh",
      height: "100%",
      paddingBottom: "10px",
      // maxWidth: "30vw",
      // minWidth: "87%",
    },

    dateDivider: {
      fontSize: "12px",
      letterSpacing: ".5px",
      fontFamily: theme.typography.fontFamily,
      marginLeft: "-11px",
      marginBottom: "11px",
      marginTop: "6px",
    },

    textDividerLine: {
      marginTop: "10px",
      marginLeft: "2px",
    },
    topDivider: {
      marginTop: "-20px",
      fontSize: "12px",
      letterSpacing: ".5px",
      fontFamily: theme.typography.fontFamily,
      marginLeft: "-11px",
    },
    numRepliesDivider: {
      marginBottom: "10px",
      fontSize: "12px",
      letterSpacing: ".5px",
      fontFamily: theme.typography.fontFamily,
      marginLeft: "-11px",
    },
  };
});
