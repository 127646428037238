import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FoundGroups from "./FoundGroups.component";
import { searchPageAndSection } from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { searchedValue, foundGroups, totalResults } = state.search;
  return {
    groups: foundGroups,
    searchedValue,
    totalGroups: totalResults.groups,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchPageAndSection,
    },
    dispatch
  );

const FoundGroupsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FoundGroups);

export default FoundGroupsContainer;
