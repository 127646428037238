import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { getUid } from "XmarsUtils";

import useStyles from "./InTextField.styles";
import clsx from "clsx";
import { stringAvatar } from "../../../utils/defaultAvatar";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const InTextField = function ({
  rooms,
  setFilters,
  InputProps,
  reset,
  setReset,
  ...props
}) {
  const classes = useStyles();
  const [inRoom, setInRoom] = useState("");

  useEffect(() => {
    if (reset === true) {
      setInRoom("");
    }
    setReset(false);
  }, [reset, setReset]);

  const handleChange = (e) => {
    const id = e.target.value;
    setInRoom(id);
    setFilters({ inRoom: id });
  };

  return (
    <Mui.Box>
      <Mui.Typography>In</Mui.Typography>
      <Mui.Select
        id={getUid()}
        fullWidth
        label="a"
        {...props}
        type="text"
        inputProps={{ className: classes.inputBase }}
        value={inRoom}
        onChange={handleChange}
      >
        <Mui.MenuItem value="">
          <em>All conversations</em>
        </Mui.MenuItem>
        {rooms.map((room) => (
          <Mui.MenuItem
            key={room.id}
            value={room.id}
            className={classes.menuItem}
          >
            <Mui.ListItemAvatar
              classes={{
                root: clsx(classes.avatar),
              }}
            >
              {!room.group ? (
                <Mui.Avatar
                  src={room.friend.avatar}
                  classes={{ root: classes.avatar }}
                  {...stringAvatar(room.friend.username)}
                />
              ) : (
                <Mui.Avatar
                  classes={{ root: classes.avatar }}
                  {...stringAvatar(room.title)}
                />
              )}
            </Mui.ListItemAvatar>
            <Mui.ListItemText primary={room.title} />
          </Mui.MenuItem>
        ))}
      </Mui.Select>
    </Mui.Box>
  );
};

export default InTextField;
