import React from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import { Alert } from "@mui/material";

import { format } from "date-fns";
import { hightLight } from "../../utils";
import { getCurrentDate } from "../../collections/Messages/utils";

import clsx from "clsx";

import { stringAvatar } from "../../../utils/defaultAvatar";

import useStyles from "./FoundMessage.styles";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const FoundMessage = function ({
  searchedValue,
  user,
  message,
  repliesPanelOpen,
  setFoundReply,
  setIsResultsPanelOpen,
  setMessageToJumpTo,
  setRoom,
  unreadRepliesPanelOpen,
}) {
  const classes = useStyles();

  const username =
    message.messageType === "form" ? "You" : message.owner.username;
  const own = username === "You";

  const dateTimeAdmin = format(message.createdAt, "d LLL, h:mm a");
  const dateTime = format(message.createdAt, "hh:mm a");
  const creationDate = getCurrentDate(message.createdAt);

  /**
   * Renders the contend
   * @returns {React.ReactElement} - The content
   */
  const renderContent = function () {
    const messageText = (
      <>
        {hightLight(message.text, searchedValue, classes.foundText)}
        {"  "}
        {message.edited && (
          <span className={classes.editedLabel}>(edited)</span>
        )}
      </>
    );

    return (
      <Mui.List
        classes={{
          padding: classes.messageContentPadding,
        }}
      >
        <Mui.ListItem
          className={clsx(
            classes.otherText,
            own ? classes.ownBackground : classes.otherBackground,
            repliesPanelOpen || unreadRepliesPanelOpen
              ? classes.messageWidthPanel
              : classes.messageWidthNoPanel
          )}
        >
          <Mui.ListItemText
            primary={messageText}
            primaryTypographyProps={{ className: classes.text }}
            className={classes.textMessageBody}
          />
        </Mui.ListItem>
      </Mui.List>
    );
  };

  const handleClick = () => {
    setRoom(message.room);
    setIsResultsPanelOpen(false);
    if (!message.replyingTo?.id) {
      setMessageToJumpTo({
        beforeFetch: true,
        id: message.id,
        messageDate: message.createdAt,
      });
    } else {
      // setMessageToJumpTo({
      //   beforeFetch: true,
      //   id: message.replyingTo.id,
      //   messageDate: message.replyingTo.createdAt,
      // });
      setFoundReply({
        replyId: message.id,
        repliedMessageId: message.replyingTo?.id,
      });
    }
  };

  return (
    <Mui.Card variant="outlined" onClick={handleClick}>
      <Mui.CardActionArea>
        <Mui.CardContent>
          <Mui.Typography
            classes={{ body1: classes.containerInfo }}
            gutterBottom
          >
            In {message.room?.group ? "the room " : "your conversation with "}"
            {message.room?.title}" -{" "}
            <span className={classes.creationDate}>{creationDate}</span>
          </Mui.Typography>
          {username === "Admin" ? (
            <Alert
              className={classes.adminMessage}
              icon={<Icons.Info color="action" />}
              severity="info"
            >
              {hightLight(message.text, searchedValue, classes.foundText)}
              <Mui.Typography className={classes.date}>
                {dateTimeAdmin}
              </Mui.Typography>
            </Alert>
          ) : (
            <>
              <Mui.ListItem className={clsx(classes.otherMessage)}>
                <Mui.List className={clsx(classes.content)}>
                  <Mui.ListItem
                    className={clsx(classes.item, classes.messageInfo)}
                  >
                    <Mui.ListItemAvatar
                      classes={{
                        root: clsx(classes.avatar),
                      }}
                    >
                      <Mui.Avatar
                        src={message.owner.avatar}
                        classes={{ root: classes.avatar }}
                        {...stringAvatar(own ? user.username : username)}
                      />
                    </Mui.ListItemAvatar>

                    <Mui.Box className={clsx(own && classes.ownTextContainer)}>
                      <Mui.Box className={clsx(classes.messageHeader)}>
                        <span className={classes.usernameStyle}>
                          {username},
                        </span>{" "}
                        {dateTime}{" "}
                      </Mui.Box>

                      <Mui.Box className={clsx(classes.secondLevel)}>
                        {renderContent()}
                      </Mui.Box>
                    </Mui.Box>
                  </Mui.ListItem>
                </Mui.List>
              </Mui.ListItem>
            </>
          )}
        </Mui.CardContent>
      </Mui.CardActionArea>
    </Mui.Card>
  );
};

export default FoundMessage;
