export const setConference = function (state, { payload }) {
  if (payload !== null) {
    throw new Error("Something is trying to set a conference");
  }
  state.conference = payload;
};

export const setConferenceStatus = function (state, { payload }) {
  state.conferenceStatus = payload;
};
