import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TimeSelector from "./TimeSelector.component";

import { setOnSelectorOpen, setDate, setFilters } from "XmarsStore/actions";

const mapStateToProps = (state) => {
  const { date } = state.search;

  return {
    date,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setOnSelectorOpen,
      setDate,
      setFilters,
    },
    dispatch
  );

const TimeSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeSelector);

export default TimeSelectorContainer;
