import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ChatInputForm from "./ChatInputForm.component";

import {
  sendJitsiMessage,
  sendTextMessage,
  setFiles,
  startedTyping,
  setSendingFiles,
  setDraft,
} from "XmarsStore/actions/index";
import { selectActiveChannel, selectUser } from "XmarsStore/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const user = selectUser(state);
  const { draft } = state.chat;
  const { conference } = state.jitsi;
  const { activeChannel } = state.video;
  const { files, repliesPanelOpen } = state.general;
  return {
    conference,
    user,
    room,
    activeChannel,
    files,
    value: draft,
    repliesPanelOpen,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendJitsiMessage,
      sendTextMessage,
      setFiles,
      startedTyping,
      setSendingFiles,
      setValue: setDraft,
    },
    dispatch
  );

const ChatInputFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatInputForm);

export default ChatInputFormContainer;
