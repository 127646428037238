import { groupLog } from "../../utils";

const getStyles = function (styles) {
  const arrStyles = [];
  for (const entry of Object.entries(styles)) arrStyles.push(entry.join(": "));
  return arrStyles.join("; ");
};

/**
 * Returns a function that logs the given arguments with the given styles.
 * @param {object} styles - Object with css like styles
 * @param {string} [logger="log"] - Console logger method
 */
export const getLogger = function (styles, logger = "log") {
  const parsedStyles = getStyles(styles);
  let collapsed = true;
  switch (logger) {
    case "group":
      collapsed = false;
    // eslint-disable-next-line
    case "groupCollapsed":
      return function (title, callback, options = {}) {
        groupLog(title, callback, {
          ...options,
          collapsed,
          styles: parsedStyles,
        });
      };
    default:
      return function (...args) {
        console[logger](`%c${args.join(" ")}`, parsedStyles);
      };
  }
};
