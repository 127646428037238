import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import RecoverPasswordForm from "./RecoverPasswordForm.component";

import { recoverPassword } from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { generic } = state.general;

  return { recoverParams: generic.params, id: generic.id };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      recoverPassword,
    },
    dispatch
  );

const RecoverPasswordFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecoverPasswordForm);

export default RecoverPasswordFormContainer;
