import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import bytes from "bytes";
import PropTypes from "prop-types";

import { blobToDataUrl } from "../../../utils/converters/blobToDataUrl";

import useStyles from "./File.styles";
import "./File.overrideStyle.css";
import clsx from "clsx";

export const File = function ({ file, deleteFile, isDrawer, ...props }) {
  const [src, setSrc] = useState(null);

  const classes = useStyles();

  const type = file.type.split("/")[0];

  useEffect(() => {
    if (type === "image")
      blobToDataUrl(file)
        .then(setSrc)
        .catch(() => {});
  }, [file, type]);

  const renderIcon = function () {
    switch (type) {
      case "audio":
        return <Icons.Music />;
      case "video":
        return <Icons.Film />;
      //case "image": return <Icons.Image />;
      case "application":
        return <Icons.File />;
      default:
        return <Icons.File />;
    }
  };
  return (
    <Mui.ListItem
      {...props}
      className={clsx(classes.file, isDrawer ? classes.fileDrawer : null)}
    >
      {type !== "image" ? (
        <Mui.ListItemIcon className={classes.fileIcon}>
          {renderIcon()}
        </Mui.ListItemIcon>
      ) : (
        <Mui.ListItemAvatar className="override-list-avatar-style">
          <Mui.Avatar
            variant="rounded"
            src={src}
            className={clsx(classes.thumbnail, "override-avatar-style")}
          />
        </Mui.ListItemAvatar>
      )}
      <Mui.ListItemText
        primary={file.name}
        primaryTypographyProps={{
          className: clsx(
            classes.fileName,
            isDrawer ? classes.fileNameDrawer : null
          ),
        }}
        secondary={bytes(file.size)}
        secondaryTypographyProps={{
          className: clsx(
            classes.fileSize,
            isDrawer ? classes.fileSizeDrawer : null
          ),
        }}
      />
      <Mui.IconButton
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          background: "#c0c0c05c",
        }}
        className="override-button-style"
        onClick={() => deleteFile(file)}
        size="small"
        color="primary"
      >
        <Icons.X />
      </Mui.IconButton>
    </Mui.ListItem>
  );
};

export default File;

File.propTypes = {
  file: PropTypes.object.isRequired,
};
