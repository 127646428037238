import React, { useState } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";

import { DoublePassword, PasswordTextField } from "../../inputs";
import { LinearSubmitButton } from "../../prestyledComponents";
import { useMounted } from "../../../hooks/useMounted";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

export const ChangePasswordForm = function ({ changePassword, onSubmit }) {
  const [processing, setProcessing] = useState(false);
  const mounted = useMounted();

  const handleSubmit = formSubmitHandler(
    async ({ password, newPassword }, event) => {
      try {
        setProcessing(true);
        await getBoundActionPromise(changePassword, {
          password,
          newPassword,
        });
        if (onSubmit) onSubmit(event);
      } catch (error) {
        logError(error);
      } finally {
        if (mounted.current) {
          setProcessing(false);
        }
      }
    }
  );

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      component="form"
      onSubmit={handleSubmit}
    >
      <Grid item>
        <PasswordTextField label="Current Password" disabled={processing} />
      </Grid>
      <DoublePassword
        disabled={processing}
        name1="newPassword"
        label1="New Password"
        placeholder1="new secret"
        label2="Confirm New Password"
        placeholder2="This must match the new password"
        Wrapper={Grid}
        WrapperProps={{ item: true }}
      />
      <Grid item>
        <LinearSubmitButton loading={processing} children="Change password" />
      </Grid>
    </Grid>
  );
};

export default ChangePasswordForm;

ChangePasswordForm.propTypes = {
  changePassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};
