import client from "../../../client";
import query from "./searchFromPanel.query";

export const searchFromPanel = async function (searchInput) {
  const { data } = await client.query({
    query,
    variables: {
      searchInput,
    },
  });
  return data.searchFromPanel;
};

export default searchFromPanel;
