import { put, call } from "redux-saga/effects";
import * as actions from "../../actions";
import api from "../../../dataApi";
import { handleSagaError } from "../sagas.utils";

export const setFoundReply = function* ({ payload }) {
  try {
    const { replyId, repliedMessageId } = payload;
    const repliedMessage = yield call(api.getMessage, repliedMessageId);
    repliedMessage.id = repliedMessageId;
    yield put(actions.setCurrentMessage(repliedMessage));
    yield put(actions.setRepliesPanelOpen(true));

    const foundReply = repliedMessage.replies?.find(
      (reply) => reply.id === replyId
    );

    yield put(actions.setReplyToJumpTo(foundReply));
  } catch (error) {
    handleSagaError(error);
  }
};
