import { put, call, select } from "redux-saga/effects";
import * as actions from "../../actions";
import api from "../../../dataApi";
import { handleSagaError } from "../sagas.utils";

export const searchFromPanel = function* ({ payload }) {
  try {
    yield put(actions.setSearchedValue(payload.searchValue));
    const results = yield call(api.searchFromPanel, payload);

    yield put(actions.setFoundMessages(results.messages));
    yield put(actions.setFoundGroups(results.groups));
    const { friends, user } = yield select((state) => state.chat);
    const foundIds = results.contacts.map((foundContact) => foundContact.id);
    const foundFriends = friends.filter((friend) =>
      foundIds.includes(friend.id)
    );
    if (foundIds.includes(user.id)) {
      foundFriends.unshift(user);
    }
    yield put(actions.setFoundContacts(foundFriends));

    yield put(actions.setFoundFiles(results.files));

    yield put(actions.setTotalResults(results.totals));

    yield put(actions.setAreResultsLoaded(true));
  } catch (error) {
    handleSagaError(error);
  }
};
