import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import UserMenuButton from "./UserMenuButton.component";

import { logout, changeStatus } from "../../../store/actions";

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      changeStatus,
    },
    dispatch
  );

const UserMenuButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMenuButton);

export default UserMenuButtonContainer;
