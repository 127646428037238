import { groupLog } from "XmarsUtils/groupLog";
import * as connectionStatus from "./statusConstants";

export const createConnectionHandlers = (emitter) => {
  return {
    onConnectionEstablished() {
      groupLog("connection => onConnectionEstablished");
      emitter({
        connectionStatus: connectionStatus.CONNECTED,
        message: "successful connection",
      });
    },

    onConnectionFailed(errorType, message, credentials, details) {
      groupLog("connection => onConnectionFailed", () => {
        console.log("errorType:", errorType);
        console.log("message:", message);
        console.log("credentials:", credentials);
        console.log("details:", details);
      });
      emitter({ connectionStatus: connectionStatus.FAILED, message });
    },

    onConnectionDisconnected(errorMessage) {
      const title = "connection => onConnectionDisconnected";
      if (!errorMessage) {
        console.log(title);
      } else {
        groupLog(title, () => {
          console.log("errorMessage:", errorMessage);
        });
      }
      emitter({
        connectionStatus: connectionStatus.DISCONNECTED,
        message: errorMessage | "disconected and no error",
      });
    },
    onWrongState() {
      console.log("connection => onWrongState");
      emitter({
        connectionStatus: connectionStatus.WRONG_STATE,
        message:
          "wrong state happened. Check the docs and implement apropiate handler.",
      });
    },
  };
};
