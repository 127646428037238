import { gql } from "../../../utils/apollo";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const saveDraft = gql`
  mutation saveDraft($room: ID, $message: ID, $text: String) {
    saveDraft(input: { room: $room, message: $message, text: $text }) {
      null
    }
  }
`;

export default saveDraft;
