import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { Tooltip } from "../../prestyledComponents";
import PublicLinkButton from "../../buttons/PublicLinkButton";
import { stringAvatar } from "../../../utils/defaultAvatar/defaultAvatar";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const RoomUser = function ({
  room,
  user,
  setRoomAdmin,
  unsetRoomAdmin,
  kickUser,
  selfUser,
}) {
  const handleUpgrade = () => {
    setRoomAdmin(room, user);
  };
  const handleDowngrade = () => {
    unsetRoomAdmin(room, user);
  };
  const handleKick = () => kickUser({ room, user });

  return (
    <Mui.ListItem button divider>
      <Mui.Badge
        badgeContent={
          !user.roomAdmin ? null : (
            <Tooltip placement="left" title="Room admin">
              <Icons.Shield color="primary" />
            </Tooltip>
          )
        }
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <Mui.ListItemAvatar>
          <Mui.Avatar src={user.avatar} {...stringAvatar(user.username)} />
        </Mui.ListItemAvatar>
      </Mui.Badge>
      <Mui.ListItemText primary={user.username} />
      <PublicLinkButton guest={user} />
      {!room.admin || selfUser ? null : (
        <>
          <Tooltip title="Remove">
            <Mui.ListItemIcon>
              <Mui.IconButton color="secondary" onClick={handleKick}>
                <Icons.Slash />
              </Mui.IconButton>
            </Mui.ListItemIcon>
          </Tooltip>
          {user.roomAdmin ? (
            <Tooltip title="Remove from admins">
              <Mui.ListItemIcon>
                <Mui.IconButton color="primary" onClick={handleDowngrade}>
                  {/* <Icons.UserX /> */}
                  <Icons.Lock />
                </Mui.IconButton>
              </Mui.ListItemIcon>
            </Tooltip>
          ) : (
            <Tooltip title="Set as admin">
              <Mui.ListItemIcon>
                <Mui.IconButton color="primary" onClick={handleUpgrade}>
                  <Icons.UserCheck />
                  <Icons.Send />
                </Mui.IconButton>
              </Mui.ListItemIcon>
            </Tooltip>
          )}
        </>
      )}
    </Mui.ListItem>
  );
};

export default RoomUser;

RoomUser.propTypes = {
  room: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setRoomAdmin: PropTypes.func.isRequired,
  kickUser: PropTypes.func.isRequired,
};
