import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import VideoGridButton from "./VideoGridButton.component";

import {
  setVideoGrid,
  mapRemoteParticipants,
  sendJitsiMessage,
} from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { videoGrid, conference, screenConference } = state.jitsi;
  return {
    videoGrid,
    conference,
    screenConference,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setVideoGrid,
      mapRemoteParticipants,
      sendJitsiMessage,
    },
    dispatch
  );

const VideoGridButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoGridButton);

export default VideoGridButtonContainer;
