import React, { useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import EmojiPicker from "emoji-picker-react";
import PropTypes from "prop-types";

import { TooltipIconButton } from "../../prestyledComponents";

export const EmojisButton = function ({ disabled, onEmoji, ...props }) {
  const [anchor, setAnchor] = useState(false);

  const handleClose = () => setAnchor(null);
  const handleClick = (event) => setAnchor(event.target);

  const handleEmojiClick = function (event, { emoji }) {
    onEmoji(emoji);
  };

  return (
    <>
      <TooltipIconButton
        {...props}
        title="Emojis"
        disabled={disabled || !!anchor}
        onClick={handleClick}
      >
        <Icons.Smile />
      </TooltipIconButton>
      <Mui.Menu
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Mui.MenuItem disableGutters disableRipple selected={true}>
          <EmojiPicker
            disableAutoFocus
            disableSearchBar
            disableSkinTonePicker
            onEmojiClick={handleEmojiClick}
          />
        </Mui.MenuItem>
      </Mui.Menu>
    </>
  );
};

export default EmojisButton;

EmojisButton.propTypes = {
  disabled: PropTypes.bool,
  onEmoji: PropTypes.func.isRequired,
};

EmojisButton.defaultProps = {
  disabled: false,
};
