import logError from "./logError";

/**
 * @param {() => void} callback
 * @param {boolean} [isAsync]
 * @returns {void|Promise<void>}
 */
export const tryOrLog = function (callback, isAsync = false) {
  if (isAsync)
    return new Promise(async (res) => {
      try {
        await callback();
      } catch (error) {
        logError(error);
      } finally {
        res();
      }
    });
  try {
    callback();
  } catch (error) {
    logError(error);
  }
};

export default tryOrLog;
