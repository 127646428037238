import { gql } from "../../../utils/apollo";

export const requestRecovery = gql`
  mutation RequestRecovery($email: String) {
    requestRecovery(input: { email: $email }) {
      null
    }
  }
`;

export default requestRecovery;
