import { makeStyles } from "@mui/styles";

import { classes } from "../../../style";

export default makeStyles((theme) => ({
  width100: classes.width100,
  progressIconWrapper: {
    position: "relative",
    minWidth: "2.5rem",
  },
  fileBox: {
    background: theme.palette.bgGray.files,
    borderRadius: "10px",
    padding: "0.25rem",
  },
  progress: {
    position: "absolute",
    top: "-0",
    right: "1.3em",
    zIndex: 1,
  },
  imageFile: {
    maxWidth: "100%",
    minWidth: "1%",
    borderRadius: "15px",

    "&:hover": {
      cursor: "pointer",
    },
  },
  imageFileName: {
    marginTop: "-25px",
  },
  progressSize: {
    transform: "scale(0.85)",
  },
  thumbnail: classes.avatarThumbnail,
  text: {
    width: "100%",
    overflow: "auto",
  },
  fileName: {
    fontWeight: 400,
    fontSize: "0.9rem",
    color: "#888a8c",
  },
  fileSize: {
    fontWeight: 500,
    fontSize: "0.8rem",
  },
  fileIcon: {
    width: "2rem",
    height: "2rem",

    "& > svg": {
      height: "2rem !important",
      width: "2rem !important",
    },
  },
  downloadButton: {
    width: "100%",
    textTransform: "none",
    borderTop: "1px solid",
    borderRadius: "0 0 10px 10px",
  },
  fileMessageContainer: {
    width: "100%",
    marginRight: "0px",
  },
  ownImageNoReplies: {
    marginRight: "0px",
  },
  headerFile: {
    width: "220px",
  },
  foundText: {
    backgroundColor: theme.palette.messages.highlightedBg,
    //fontWeight: "500",
    border: `1px solid ${theme.palette.messages.highlightedBg}`,
  },
}));
