import client from "../../../client";
import mutation from "./createPublicRoom.mutation";

/**
 * @param {string} title
 * @param {Array<{
 *  email: string,
 *  username: string,
 * }>} guests
 * @param {Date} date
 * @returns {Promise<{
 *  id: string,
 *  group: boolean,
 *  title: string,
 *  admin: boolean,
 *  meetingCode: string,
 *  kind: string,
 * }>}
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const createPublicRoom = async function (title, guests, date) {
  const { data } = await client.mutate({
    mutation,
    variables: {
      title,
      guests,
      date,
    },
  });
  return data.createPublicRoom;
};

export default createPublicRoom;
