import { takeEvery, put, call } from "redux-saga/effects";

import { actions as act } from "../../actions";
import * as actions from "../../actions";

import {
  JitsiMessage,
  startRecording as JitsiStartRecording,
  stopRecording as JitsiStopRecording,
} from "XmarsJitsiMeetJS/index";
import JitsiRecordingState from "XmarsJitsiMeetJS/recording/recordingState";
import api from "../../../dataApi";

import { handleSagaError } from "../sagas.utils";

export default function* interceptor() {
  yield takeEvery(act.sagas.cleanConferenceRequest, cleanConference);
  yield takeEvery(act.sagas.getRecordings, getRecordings);
  yield takeEvery(act.sagas.startRecording, startRecording);
  yield takeEvery(act.sagas.stopRecording, stopRecording);
  yield takeEvery(act.sagas.sendJitsiMessage, sendJitsiMessage);
}

const cleanConference = function* () {
  yield put(actions.setActiveChannel(null));
  yield put(actions.setConference(null));
  yield put(actions.setConferenceStatus(null));
  yield put(actions.setConnection(null));
  yield put(actions.setConnectionStatus(null));
  yield put(actions.resetLocalParticipant());
  yield put(actions.resetRemoteParticipants());
  yield put(actions.resetVideoGrid());
  yield put(actions.setMeetingCode(""));
  yield put(actions.setDevices([]));
  yield put(actions.setLocalVideoTrackReady(false));
  yield put(actions.setLocalAudioTrackReady(false));
  yield put(actions.setPage("home"));
  yield put(actions.resetCallStatus());
  yield put(actions.resetVideoEffects());
};

const getRecordings = function* () {
  try {
    const recordings = yield call(api.getRecordings);
    yield put(actions.setRecordings(recordings));
  } catch (error) {
    yield handleSagaError(error);
  }
};

const startRecording = function* ({ payload }) {
  const { conference, promise } = payload;
  let sessionID;
  try {
    sessionID = yield call(JitsiStartRecording, conference);
    yield call(api.addRecording, sessionID);
    promise.resolve();
  } catch (error) {
    promise.reject(error);
    yield handleSagaError(error);
  }
};

const stopRecording = function* ({ payload }) {
  const { jitsiConference, promise } = payload;
  try {
    if (JitsiRecordingState.recording) {
      yield call(JitsiStopRecording, jitsiConference);
      yield call(getRecordings);
    }
    if (promise) {
      promise.resolve();
    }
  } catch (error) {
    if (promise) {
      promise.reject(error);
    }
    yield handleSagaError(error);
  }
};

const sendJitsiMessage = function* ({ payload }) {
  const { conference, message, promise } = payload;
  try {
    const jitsiMessage = new JitsiMessage(message);
    const jsonMessage = JSON.stringify(jitsiMessage);
    yield conference.sendTextMessage(jsonMessage);
    promise.resolve();
  } catch (error) {
    promise.reject(error);
    yield handleSagaError(error);
  }
};
