import notificationsInitialState from "./initialState";

export const getStateNotificationList = (state) => {
  return state;
};

export const resetNotifications = (state) => {
  return notificationsInitialState;
};

export const setNotifications = (state, { payload }) => {
  return payload;
};

export const addNotification = (state, { payload }) => {
  return [...state, payload];
};

export const removeNotification = (state, { payload }) => {
  return state.filter((item) => item.id !== payload.id);
};
