import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  instMeetButton: {
    background:
      "linear-gradient(to left, #1FA2FF 30%, #12D8FA 91%, #1FA2FF 170%)",
    color: "white",
    textTransform: "capitalize",
    padding: "0",
    width: "161px",
    height: "26px",
    borderRadius: "32px",
    fontSize: "11px",
    paddingTop: "1px",
    marginRight: "-8px",
    borderWidth: "0px",
    fontWeight: "400",

    "&&:hover": {
      border: "solid 1px #0a80ff",
    },
  },
}));
