import client from "../../../client";
import query from "./getUserByEmail.query";

/**
 * @param {string} email
 * @returns {Promise<{
 *  id: string,
 *  username: string,
 *  avatar: string,
 * }?>}
 */
export const getUserByEmail = async function (email) {
  const { data } = await client.query({
    query,
    variables: {
      email,
    },
  });
  return data.getUserByEmail;
};

export default getUserByEmail;
