import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ChangeEmailForm from "./ChangeEmailForm.component";

import { changeEmail } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeEmail,
    },
    dispatch
  );

const ChangeEmailFormContainer = connect(
  null,
  mapDispatchToProps
)(ChangeEmailForm);

export default ChangeEmailFormContainer;
