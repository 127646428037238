import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Alert } from "@mui/material";
import { CardElement } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

export const Card = function ({ onChange, CardProps, AlertProps, ...props }) {
  const [alert, setAlert] = useState({});

  const handleChange = async (event) => {
    if (onChange) onChange(event);
    const { error, complete } = event;
    if (error)
      return setAlert({
        severity: "error",
        message: error.message,
      });
    if (complete)
      return setAlert({
        severity: "success",
        message: "Complete",
      });
    return setAlert({});
  };

  return (
    <Grid {...props} container direction="column" spacing={1}>
      <Grid item>
        <CardElement {...CardProps} onChange={handleChange} />
      </Grid>
      <Grid item>
        <Alert
          {...AlertProps}
          style={{ visibility: alert.severity ? undefined : "hidden" }}
          severity={alert.severity}
          children={alert.message}
        />
      </Grid>
    </Grid>
  );
};

export default Card;

Card.element = CardElement;

Card.propTypes = {
  onChange: PropTypes.func,
  CardProps: PropTypes.object,
  AlertProps: PropTypes.object,
};

Card.defaultProps = {
  CardProps: {},
  AlertProps: {},
};
