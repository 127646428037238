import React, { Fragment } from "react";
/**
 * Wraps a word in spans to highlight it
 * @param {string} text: Full text containing the word to be highlighted
 * @param {string} word: Word that will be highlighted
 * @param {classes.class} className: ClassName of the span that will be hightailed
 * @returns React element
 */
export function hightLight(text, word, className) {
  let lowerText = text.toLocaleLowerCase();
  let lowerWord = word.toLocaleLowerCase();

  let startIndex = 0;
  const positionIndexes = [];
  while (lowerText.indexOf(lowerWord, startIndex) >= 0) {
    positionIndexes.push(lowerText.indexOf(lowerWord, startIndex));

    startIndex = lowerText.indexOf(lowerWord, startIndex) + word.length;
  }

  let texts = [text.slice(0, positionIndexes[0])];
  let wordVariants = [];
  for (let i = 0; i < positionIndexes.length; i++) {
    let fragment = text.slice(
      positionIndexes[i] + word.length,
      positionIndexes[i + 1]
    );
    texts.push(fragment);

    if (true) {
      let wordVariant = text.slice(
        positionIndexes[i],
        positionIndexes[i] + word.length
      );
      wordVariants.push(wordVariant);
    }
  }

  return (
    <>
      {texts.map((textPart, index) => (
        <Fragment key={index}>
          {textPart}
          {index !== texts.length - 1 && (
            <span className={className}>{wordVariants[index]}</span>
          )}
        </Fragment>
      ))}
    </>
  );
}

export default hightLight;
