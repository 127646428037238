import React, { useEffect, useState } from "react";
import * as Icons from "react-feather";

import { TooltipIconButton } from "../../prestyledComponents";

// eslint-disable-next-line
import { Props } from "./MuteVideoButton.propTypes";

/**
 * @param {Props} props
 */
export const MuteVideoButton = function ({
  onlyAudio,
  localVideoTrackReady,
  localVideoIsMuted,
  localVideoTrackController,
  ...props
}) {
  const muted = localVideoIsMuted;

  const handleClick = () => {
    localVideoTrackController({
      command: "toggle",
    });
  };

  return (
    <TooltipIconButton
      {...props}
      disabled={!localVideoTrackReady}
      aria-label="video"
      onClick={handleClick}
      TooltipProps={{ placement: "top" }}
      // color={muted ? "secondary" : undefined}
      title={`Turn camera ${muted ? "on" : "off"}`}
      name={"Camera"}
    >
      {muted ? <Icons.VideoOff /> : <Icons.Video />}
    </TooltipIconButton>
  );
};

export default MuteVideoButton;
