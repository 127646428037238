import client from "../../../client";
import mutation from "./endOngoingCall.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 */
export const endOngoingCall = async function (channelId, workspaceId) {
  channelId = addBearer(channelId);
  workspaceId = addBearer(workspaceId);
  await client.mutate({
    mutation,
    variables: {
      channelId,
      workspaceId,
    },
  });
};

export default endOngoingCall;
