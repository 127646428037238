import { Pagination } from "@mui/material";

import useStyles from "./PaginationControls.styles";
export const PaginationControls = function ({
  count,
  disabled,
  page,
  handleChange,
}) {
  const classes = useStyles();
  return (
    <Pagination
      // hidePrevButton={page === 1}
      // hideNextButton={page === count}
      count={count}
      page={page}
      onChange={handleChange}
      shape="rounded"
      //color="primary"
      size="small"
      className={classes.pagination}
      disabled={disabled}
    />
  );
};

export default PaginationControls;
