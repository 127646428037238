import { connect } from "react-redux";

import SidebarItems from "./SidebarItems.component";
import { bindActionCreators } from "redux";

import {
  setRepliesPanelOpen,
  setIsGroupChatsOpen,
  setIsOneToOneChatsOpen,
  setUnreadRepliesPanelOpen,
  updateIsGroupChatsOpen,
  updateIsOneToOneChatsOpen,
} from "../../../store/actions/index";
// import { actions as act } from "XmarsStore/actions";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const { user, rooms, friends, roomFilter } = state.chat;
  const { isGroupChatsOpen, isOneToOneChatsOpen } = state.general;
  let shownRooms = rooms.filter((rm) => !rm.hidden);
  return {
    user,
    rooms: shownRooms,
    friends,
    roomFilter,
    mainRoom: room,
    isGroupChatsOpen,
    isOneToOneChatsOpen,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRepliesPanelOpen,
      setIsGroupChatsOpen,
      updateIsGroupChatsOpen,
      setIsOneToOneChatsOpen,
      updateIsOneToOneChatsOpen,
      setUnreadRepliesPanelOpen,
    },
    dispatch
  );

const SidebarItemsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarItems);

export default SidebarItemsContainer;
