import { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import LandingPage from "./components/pages/LandingPage";
import { LoadingBackdrop } from "./components/prestyledComponents";
import IncomingCallBackdrop from "./components/IncomingCallBackdrop";
import DisconnectedBackdrop from "./components/DisconnectedBackdrop";
import { Snackbars } from "./components/collections";

import { useOnline } from "./hooks/useOnline";

import { handleAppInstalled } from "./store/sagas/sagas.utils";
import { focusRoom } from "./store/sagas/chat/utils";
import * as electron from "./electron";
import { logError } from "./utils";
import { detectInactivity } from "./utils/inactivity/detectInactivity";

const ErrorPage = lazy(() => import("./components/pages/ErrorPage"));
const GuestAuthPage = lazy(() => import("./components/pages/GuestAuthPage"));
const HomePage = lazy(() => import("./components/pages/HomePage"));
const LoginPage = lazy(() => import("./components/pages/LoginPage"));
const RecoverPasswordPage = lazy(() =>
  import("./components/pages/RecoverPasswordPage")
);
const RegisterPage = lazy(() => import("./components/pages/RegisterPage"));
const RequestRecoveryPage = lazy(() =>
  import("./components/pages/RequestRecoveryPage")
);
const VideoCallPage = lazy(() => import("./components/pages/VideoCallPage"));
let channel = null;
let clearNotificationChannel = null;
if (typeof BroadcastChannel !== "undefined") {
  channel = new BroadcastChannel("sw-messages");
  clearNotificationChannel = new BroadcastChannel("clear-notifications");
}

export default function App() {
  const page = useSelector(({ general }) => general.page);
  const error = useSelector(({ general }) => general.generic);

  const online = useOnline({ polling: false });
  const [first, setFirst] = useState(true);
  const [connectionError, setConnectionError] = useState(null);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("appinstalled", handleAppInstalled);
    window.addEventListener("load", detectInactivity);
    if (channel) channel.addEventListener("message", handleSwMessage);
    if (electron.isSupported) {
      electron.onMessage(focusRoom);
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (channel) channel.removeEventListener("message", handleSwMessage);
    };
  }, []);

  const handleSwMessage = function (event) {
    if (event.data.room) focusRoom(event.data.room);
  };

  const handleVisibilityChange = async function () {
    try {
      if (electron.isSupported) {
        electron.blinkingIconSwitch(false);
      }
      if (!document.hidden) {
        if (Navigator.clearAppBadge) {
          // I don't know if this will be useful at some point
          await Navigator.clearAppBadge();
        }
        if (clearNotificationChannel) {
          clearNotificationChannel.postMessage({
            msg: "clear notifications",
          });
        }
      }
    } catch (error) {
      logError(error);
    }
  };

  const preventDefaultContextMenu = function (event) {
    // event.preventDefault();
  };

  const renderPage = function () {
    if (connectionError)
      return <ErrorPage message="Connection could not be established" />;
    if (first) {
      setConnectionError(!online);
      setFirst(false);
    }
    switch (page) {
      case "landing":
        return <LandingPage />;
      case "home":
        return <HomePage />;
      case "login":
        return <LoginPage />;
      case "register":
        return <RegisterPage />;
      case "guestAuth":
        return <GuestAuthPage />;
      case "videoCall":
        return <VideoCallPage />;
      case "recoverPassword":
        return <RecoverPasswordPage />;
      case "requestRecovery":
        return <RequestRecoveryPage />;
      case "error":
        return <ErrorPage message={error} />;
      default:
        return <ErrorPage message={`Page: "${page}", not implemented`} />;
    }
  };

  return (
    <Box
      width="100vw"
      height="100vh"
      overflow="hidden auto"
      boxSizing="border-box"
      onContextMenu={preventDefaultContextMenu}
    >
      <Suspense fallback={<LoadingBackdrop />}>{renderPage()}</Suspense>
      <IncomingCallBackdrop />
      <Snackbars />
      <DisconnectedBackdrop />
    </Box>
  );
}
