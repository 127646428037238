const isStandalone = function () {
  return (
    !!navigator.standalone ||
    window.matchMedia("(display-mode: standalone)").matches
  );
};

const exitsOnBack = function () {
  return isStandalone() && /android/i.test(navigator.userAgent);
};

const pushState = () => window.history.pushState({}, "");

export const disableExitsOnBack = function () {
  if (!exitsOnBack()) return;

  pushState();
  window.addEventListener("popstate", () => {
    // 2 sec to avoid immediate exit
    setTimeout(pushState, 2000);
  });
};

export default disableExitsOnBack;
