import { takeEvery, call } from "redux-saga/effects";

import * as user from "./user";
import * as room from "./room";
import * as workspaces from "./workspaces";
import * as messages from "./messages";
import * as notification from "./notification";
import * as handshake from "./handshake";

import { actions as act } from "../../actions";

const sagas = {
  ...handshake,
  ...user,
  ...room,
  ...messages,
  ...notification,
  ...workspaces,
};

export default function* interceptor() {
  yield takeEvery(act.sagas.handshake, sagas.handshake);
  yield takeEvery(act.sagas.guestSignIn, sagas.guestSignIn);
  // // ----
  yield takeEvery(act.sagas.createUser, sagas.createUser);
  yield takeEvery(act.sagas.createGuest, sagas.createGuest);
  yield takeEvery(act.sagas.deleteUser, sagas.deleteUser);
  yield takeEvery(act.sagas.deleteAppUser, sagas.deleteAppUser);
  yield takeEvery(act.sagas.login, sagas.login);
  yield takeEvery(act.sagas.logout, sagas.logout);
  // // ----
  yield takeEvery(act.sagas.changeUsername, sagas.changeUsername);
  yield takeEvery(act.sagas.changeEmail, sagas.changeEmail);
  yield takeEvery(act.sagas.changePassword, sagas.changePassword);
  yield takeEvery(act.sagas.requestRecovery, sagas.requestRecovery);
  yield takeEvery(act.sagas.recoverPassword, sagas.recoverPassword);
  yield takeEvery(act.sagas.changeAvatar, sagas.changeAvatar);
  yield takeEvery(act.sagas.removeAvatar, sagas.removeAvatar);
  // // ----
  yield takeEvery(act.sagas.getUser, sagas.getUser);

  yield takeEvery(act.sagas.getFriends, sagas.getFriends);
  yield takeEvery(act.sagas.getRooms, sagas.getRooms);
  yield takeEvery(act.sagas.getRoom, sagas.getRoom);
  yield takeEvery(act.sagas.getNotifications, sagas.getNotifications);
  yield takeEvery(act.sagas.getMessages, sagas.getMessages);
  yield takeEvery(act.sagas.getMessagesUpToDate, sagas.getMessagesUpToDate);
  yield takeEvery(act.sagas.startedTyping, sagas.startedTyping);
  yield takeEvery(act.chat.changeStatus, sagas.changeStatus);
  yield takeEvery(act.sagas.updateRoom, sagas.updateRoom);
  // // ----
  yield takeEvery(act.sagas.getRoomUsers, sagas.getRoomUsers);
  yield takeEvery(act.sagas.getOneVsOneRoom, sagas.getOneVsOneRoom);
  yield takeEvery(act.sagas.createCustomRoom, sagas.createCustomRoom);
  yield takeEvery(act.sagas.createPublicRoom, sagas.createPublicRoom);

  // //------
  yield takeEvery(act.sagas.getWorkspaces, sagas.getWorkspaces);
  yield takeEvery(act.sagas.getWorkspace, sagas.getWorkspace);
  yield takeEvery(
    act.sagas.replaceWorkspaceForUpdated,
    sagas.replaceWorkspaceForUpdated
  );
  yield takeEvery(act.sagas.getCategories, sagas.getCategories);
  yield takeEvery(act.sagas.setCurrentWorkspace, sagas.setCurrentWorkspace);
  yield takeEvery(act.sagas.createWorkspace, sagas.createWorkspace);
  yield takeEvery(act.sagas.createCategory, sagas.createCategory);
  yield takeEvery(act.sagas.updateWorkspace, sagas.updateWorkspace);
  yield takeEvery(act.sagas.deleteWorkspace, sagas.deleteWorkspace);
  yield takeEvery(act.sagas.updateCategory, sagas.updateCategory);
  yield takeEvery(act.sagas.deleteCategory, sagas.deleteCategory);
  yield takeEvery(act.sagas.kickUserFromWorkspace, sagas.kickUserFromWorkspace);
  yield takeEvery(act.sagas.setWorkspaceAdmin, sagas.setWorkspaceAdmin);
  yield takeEvery(act.sagas.unsetWorkspaceAdmin, sagas.unsetWorkspaceAdmin);

  yield takeEvery(
    act.sagas.sendWorkspaceInvitation,
    sagas.sendWorkspaceInvitation
  );

  // // ----
  yield takeEvery(act.sagas.createMedicalRoom, sagas.createMedicalRoom);
  yield takeEvery(act.sagas.deleteConversation, sagas.deleteConversation);
  yield takeEvery(act.sagas.leaveRoom, sagas.leaveRoom);
  yield takeEvery(act.sagas.kickUser, sagas.kickUser); //Kick user
  yield takeEvery(act.sagas.addUserToRoom, sagas.addUserToRoom);
  yield takeEvery(act.sagas.addUserToRooms, sagas.addUserToRooms);
  yield takeEvery(act.sagas.addUsersToRoom, sagas.addUsersToRoom);
  yield takeEvery(act.sagas.setRoomAdmin, sagas.setRoomAdmin);
  yield takeEvery(act.sagas.unsetRoomAdmin, sagas.unsetRoomAdmin);
  yield takeEvery(act.sagas.sendTextMessage, sagas.sendTextMessage);
  yield takeEvery(act.sagas.saveDraft, sagas.saveDraft);
  yield takeEvery(act.sagas.sendScribbleMessage, sagas.sendScribbleMessage);
  yield takeEvery(act.sagas.editMessage, sagas.editMessage);
  yield takeEvery(act.sagas.editReply, sagas.editReply);
  yield takeEvery(act.sagas.getDraft, sagas.getDraft);
  yield takeEvery(act.sagas.replyMessage, sagas.replyMessage);
  yield takeEvery(act.sagas.readMessage, sagas.readMessage);
  yield takeEvery(act.sagas.deleteMessage, sagas.deleteMessage);
  yield takeEvery(act.sagas.deleteReply, sagas.deleteReply);
  yield takeEvery(act.sagas.sendFiles, sagas.sendFiles);
  yield takeEvery(act.sagas.getFile, sagas.getFile);
  yield takeEvery(act.sagas.getPublicLink, sagas.getPublicLink);
  yield takeEvery(act.sagas.getClientSecret, sagas.getClientSecret);
  yield takeEvery(act.sagas.retrievePaymentIntent, sagas.retrievePaymentIntent);
  yield takeEvery(act.sagas.confirmCardPayment, sagas.confirmCardPayment);

  // // ----
  yield takeEvery(act.sagas.rejectNotification, sagas.rejectNotification);
  yield takeEvery(act.sagas.sendFriendRequest, sagas.sendFriendRequest);
  yield takeEvery(act.sagas.acceptFriendRequest, sagas.acceptFriendRequest);
  yield takeEvery(
    act.sagas.acceptWorkspaceInvitation,
    sagas.acceptWorkspaceInvitation
  );
  yield takeEvery(act.sagas.sendRoomInvitation, sagas.sendRoomInvitation);
  yield takeEvery(act.sagas.acceptRoomInvitation, sagas.acceptRoomInvitation);
  yield takeEvery(act.sagas.searchByUsername, sagas.searchByUsername);
  yield takeEvery(act.sagas.searchByEmail, sagas.searchByEmail);
  yield takeEvery(act.sagas.getUserByEmail, sagas.getUserByEmail);
  yield takeEvery(act.sagas.searchFriends, sagas.searchFriends);
  yield takeEvery(act.sagas.unfriend, sagas.unfriend);
  // // ----
  yield takeEvery(act.sagas.sendBotMessage, sagas.sendBotMessage);
  yield takeEvery(act.sagas.answerBotMessage, sagas.answerBotMessage);
  yield takeEvery(act.sagas.channelCall, sagas.channelCall);
  yield takeEvery(act.sagas.endOngoingCall, sagas.endOngoingCall);
  yield takeEvery(act.sagas.startSharingScreen, sagas.startSharingScreen);
  yield takeEvery(act.sagas.stopSharingScreen, sagas.stopSharingScreen);
  yield takeEvery(act.sagas.inviteUsersToCall, sagas.inviteUsersToCall);
}
