export const confirmCardPayment = async function (
  stripe,
  clientSecret,
  options
) {
  const { error } = await stripe.confirmCardPayment(clientSecret, options);
  if (error) throw error;
};

export default confirmCardPayment;
