import React, { useState, useEffect, useRef } from "react";
import * as Mui from "@mui/material";
import Alert from "@mui/material/Alert";
import * as Icons from "react-feather";
import { forwardRef } from "react";
import { TooltipIconButton } from "../../prestyledComponents";
import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { logError } from "../../../utils/errors/logError";
import useStyles from "./AddFriendsButton.styles";
import clsx from "clsx";

export const AddFriendsButton = forwardRef(function (
  {
    searchedUsers,
    searchByEmail,
    sendFriendRequest,
    resetSearchedUsers,
    addSnackbar,
    location = "navbar",
    ...props
  },
  ref
) {
  const [open, setOpen] = useState(false);
  const [requestsSent, setRequestsSent] = useState(false);
  const [searched, setSearched] = useState(false);
  const [focusInput, setFocusInput] = useState(false);
  const classes = useStyles();

  const inputRef = useRef(null);

  const handleClose = () => setOpen(false);
  const handleClick = () => setOpen(true);

  const handleSubmit = formSubmitHandler(async ({ emails }, event) => {
    try {
      const emailList = emails.split(/\s*,\s*/);
      searchByEmail(emailList);
      setSearched(true);
    } catch (error) {
      logError(error);
    }
  });

  useEffect(() => {
    if (open) {
      resetSearchedUsers();
      setSearched(false);
      setRequestsSent(false);
      setFocusInput(true);
    } else {
      setFocusInput(false);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (focusInput && inputRef.current) inputRef.current.focus();
  }, [focusInput]);

  useEffect(() => {
    if (searched && !requestsSent) {
      if (searchedUsers.length) {
        searchedUsers.forEach((user) => sendFriendRequest(user));
        setRequestsSent(true);
      } else {
        const snackbar = {
          severity: "error",
          message: "No user was found with the provided email.",
        };
        addSnackbar(snackbar);
      }

      if (handleClose) handleClose();
    }
    // eslint-disable-next-line
  }, [searchedUsers]);

  return (
    <>
      <TooltipIconButton
        {...props}
        ref={ref}
        title="Add Contacts"
        className={
          location === "navbar" ? classes.iconButton : classes.flipButton
        }
        onClick={handleClick}
      >
        {location === "navbar" ? <Icons.UserPlus /> : <Icons.Plus />}
      </TooltipIconButton>
      {open && (
        <Mui.Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          PaperProps={{ elevation: 0 }}
          classes={{
            root: classes.dialogRoot,
            paper: classes.dialog,
            container: classes.dialogContainer,
          }}
        >
          <Mui.DialogTitle
            disableTypography
            id="form-dialog-title"
            className={classes.dialogHeader}
          >
            <Icons.UserPlus style={{ height: "18px", width: "18px" }} />
            <Mui.Typography variant="h6" className={classes.headerText}>
              Add Contacts
            </Mui.Typography>
            <Mui.IconButton
              className={classes.closeButton}
              onClick={handleClose}
            >
              <Icons.X style={{ strokeWidth: "2.5px" }} />
            </Mui.IconButton>
          </Mui.DialogTitle>
          <Mui.DialogContent className={classes.dialogContent}>
            <Alert
              icon={false}
              classes={{
                root: classes.alert,
                message: classes.alertMessage,
              }}
            >
              Send invitations.
            </Alert>
            <form onSubmit={handleSubmit} id="invite-friends-form">
              <Mui.Typography
                className={classes.label}
                variant="body1"
                component="label"
                name="emails"
                htmlFor="email-list"
              >
                Email addresses
              </Mui.Typography>
              <Mui.TextField
                id="email-list"
                name="emails"
                variant="outlined"
                size="small"
                fullWidth={true}
                autoComplete="off"
                style={{ marginBottom: "1rem" }}
                InputProps={{
                  className: classes.input,
                  notched: false,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    notchedOutline: classes.inputNotchedOutline,
                  },
                }}
                inputProps={{ className: classes.inputBase, ref: inputRef }}
              />

              <Mui.Typography
                className={classes.label}
                variant="body1"
                component="label"
                name="message"
                htmlFor="invitation-msg"
              >
                Invitation message
              </Mui.Typography>
              <Mui.TextField
                id="invitation-msg"
                name="message"
                variant="outlined"
                size="small"
                fullWidth={true}
                autoComplete="off"
                multiline
                style={{ marginBottom: "1rem" }}
                InputProps={{
                  className: clsx(classes.input, classes.inputMultiline),
                  notched: false,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    notchedOutline: classes.inputNotchedOutline,
                  },
                }}
                inputProps={{
                  className: clsx(
                    classes.inputBase,
                    classes.inputBaseMultiline
                  ),
                }}
              />
            </form>
          </Mui.DialogContent>
          <Mui.DialogActions className={classes.dialogFooter}>
            <Mui.Button
              type="submit"
              form="invite-friends-form"
              className={classes.actionButton}
            >
              Submit
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Dialog>
      )}
    </>
  );
});

export default AddFriendsButton;
