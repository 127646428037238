// import env from "../env";
import { getLogger } from "XmarsJitsiMeetJS/jitsi-logger";

const getLoggers = function (color) {
  // const fn = () => () => { };
  // if (env.DEVELOPMENT) return [fn, fn, fn];
  const group = getLogger(
    {
      color,
      "font-weight": "bold",
    },
    "groupCollapsed"
  );
  const main = getLogger({
    color,
    "font-weight": "bold",
  });
  return [group, main];
};

export const [groupLog, mainLog] = getLoggers("#734d26");
