import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { Tooltip } from "../../prestyledComponents";
import CircularProgressIcon from "../CircularProgressIcon";

import useStyles from "./IconSubmitButton.styles";

export const IconSubmitButton = function ({
  children,
  TooltipProps,
  disabled,
  loading,
  CircularProgressIconProps,
  ...props
}) {
  const classes = useStyles();
  return disabled || loading ? (
    <Mui.IconButton
      {...props}
      type="submit"
      disabled={true}
      children={
        !loading ? (
          children
        ) : (
          <CircularProgressIcon {...CircularProgressIconProps} />
        )
      }
      classes={{ disabled: classes.disabled }}
    />
  ) : (
    <Tooltip title="Send" {...TooltipProps}>
      <Mui.IconButton
        {...props}
        type="submit"
        children={
          !loading ? (
            children
          ) : (
            <CircularProgressIcon {...CircularProgressIconProps} />
          )
        }
      />
    </Tooltip>
  );
};

export default IconSubmitButton;

IconSubmitButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  CircularProgressIconProps: PropTypes.object,
};

IconSubmitButton.defaultProps = {
  disabled: false,
  loading: false,
  CircularProgressIconProps: {},
  color: "primary",
  children: <Icons.Check />,
};
