import * as Icons from "react-feather";
import * as Mui from "@mui/material";
import clsx from "clsx";

import { TooltipIconButton } from "../../prestyledComponents";
import useStyles from "./ChatsPanelButton.styles";

export const ChatsPanelButton = function ({
  setPanel,
  openPanels,
  panel,
  totalUnreadMessages,
  ...props
}) {
  const classes = useStyles();
  const { className } = props;

  const handleClick = () => {
    setPanel("chats");
    if (openPanels) openPanels();
  };

  return (
    <TooltipIconButton
      {...props}
      className={
        panel === "chats" ? clsx(className, classes.selected) : className
      }
      title="conversations"
      onClick={handleClick}
    >
      <Mui.Badge
        badgeContent={totalUnreadMessages}
        color="secondary"
        variant="standard"
        classes={{
          badge: classes.badge,
          anchorOriginTopRightRectangle: classes.numberPosition,
        }}
      >
        {true ? (
          <Icons.MessageCircle /> // In case a different icon for unread messages is desired
        ) : (
          <Icons.MessageCircle />
        )}
      </Mui.Badge>
    </TooltipIconButton>
  );
};

export default ChatsPanelButton;
