import React, { useMemo, useState } from "react";
import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import {
  Tooltip,
  LinearSubmitButton,
  TooltipIconButton,
} from "../../prestyledComponents";
import { FilesInput } from "../../inputs";
import { useMounted } from "../../../hooks/useMounted";

import { blobToDataUrl } from "../../../utils/converters/blobToDataUrl";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

export const ChangeAvatarForm = function ({
  user,
  removeAvatar,
  changeAvatar,
  onSubmit,
}) {
  const mounted = useMounted();

  const [processing, setProcessing] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const uploading = useMemo(() => {
    return !!avatar;
  }, [avatar]);

  const removing = useMemo(() => {
    if (avatar) return false;
    return !!user && !!user.avatar;
  }, [avatar, user]);

  const handleCancel = () => setAvatar(null);

  const handleChange = async function (event) {
    const file = event.target.files[0];
    if (!file) return;
    const newAvatar = await blobToDataUrl(file);
    setAvatar(newAvatar);
  };

  const handleUpload = async function () {
    return await getBoundActionPromise(changeAvatar, { avatar });
  };

  const handleRemove = async function () {
    return await getBoundActionPromise(removeAvatar);
  };

  const handleSubmit = async function (event) {
    event.preventDefault();
    event.persist();
    try {
      setProcessing(true);
      if (uploading) {
        await handleUpload();
      } else if (removing) {
        await handleRemove();
      }
      if (onSubmit) onSubmit(event);
    } catch (error) {
      logError(error);
    } finally {
      if (mounted.current) {
        setProcessing(false);
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      component="form"
      onSubmit={handleSubmit}
    >
      <Grid item container direction="row">
        <Grid item>
          <Mui.ListItemAvatar>
            <Mui.Avatar src={avatar} />
          </Mui.ListItemAvatar>
        </Grid>
        <Grid item>
          <Tooltip title="Upload">
            <div>
              <FilesInput
                color={avatar ? "primary" : "default"}
                children={<Icons.Upload />}
                inputProps={{
                  onChange: handleChange,
                  accept: "image/*",
                }}
              />
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <TooltipIconButton
            title="Cancel"
            disabled={!avatar}
            color="secondary"
            onClick={handleCancel}
          >
            <Icons.X />
          </TooltipIconButton>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item>
          <LinearSubmitButton
            disabled={!uploading}
            loading={uploading && processing}
            children="Change Avatar"
          />
        </Grid>
        <Grid item>
          <LinearSubmitButton
            disabled={!removing}
            loading={removing && processing}
            color="secondary"
            children="Remove avatar"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangeAvatarForm;

ChangeAvatarForm.propTypes = {
  user: PropTypes.object.isRequired,
  removeAvatar: PropTypes.func.isRequired,
  changeAvatar: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};
