import React, { useEffect, useMemo, useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import {
  CircularProgressIcon,
  TooltipIconButton,
} from "../../prestyledComponents";
import { BackgroundSelect } from "../../BackgroundSelect";
import { effects } from "../../../JitsiMeetJS";
import { useJitsiEffect } from "../../../hooks/useJitsiEffect";

import { tryOrLog } from "../../../utils/errors/tryOrLog";

// eslint-disable-next-line
import { Props } from "./VideoBackgroundButton.propTypes";

/**
 * @param {Props} props
 */
export const VideoBackgroundButton = function ({
  disabled,
  onClick,
  localParticipant,
  videoEffects,
  updateVideoEffects,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [badge, setBadge] = useState(false);

  const backgroundEffect = useMemo(
    () => new effects.BackgroundEffect(videoEffects.backgroundCurrent),
    // eslint-disable-next-line
    []
  );

  const [backgroundStatus, backgroundError] = useJitsiEffect(backgroundEffect);

  useEffect(() => {
    if (backgroundStatus === "ready") {
      updateVideoEffects({ backgroundEffect });
    }
    // eslint-disable-next-line
  }, [backgroundStatus]);

  useEffect(() => {
    const track = localParticipant.video;
    if (track) {
      tryOrLog(async () => {
        if (videoEffects.backgroundActive) {
          updateVideoEffects({ handtrackActive: false });
          await track.setEffect(backgroundEffect);
        } else if (!videoEffects.handtrackActive) {
          await track.setEffect();
        }
      }, true);
    }
    // eslint-disable-next-line
  }, [videoEffects.backgroundActive]);

  useEffect(() => {
    backgroundEffect.background = videoEffects.backgroundCurrent;
    // eslint-disable-next-line
  }, [videoEffects.backgroundCurrent]);

  const handleClick = function (event) {
    updateVideoEffects({
      backgroundActive: !videoEffects.backgroundActive,
    });
    if (onClick) onClick(event);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleMouseEnter = () => setBadge(true);
  const handleMouseLeave = () => setBadge(false);

  const renderBadgeContent = function () {
    switch (backgroundStatus) {
      case "loading":
        return (
          <Mui.Icon>
            <CircularProgressIcon />
          </Mui.Icon>
        );
      case "error":
        return (
          <Mui.Tooltip arrow placement="top" title={`${backgroundError}`}>
            <Mui.Icon color="secondary">
              <Icons.AlertCircle />
            </Mui.Icon>
          </Mui.Tooltip>
        );
      case "ready":
        return !badge ? (
          0
        ) : (
          <TooltipIconButton
            size="small"
            title="Background settings"
            TooltipProps={{ placement: "top" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleOpen}
          >
            <Icons.Settings />
          </TooltipIconButton>
        );
      default:
        return 0;
    }
  };

  return (
    <>
      <Mui.Badge overlap="circular" badgeContent={renderBadgeContent()}>
        <TooltipIconButton
          {...props}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          title={`${
            videoEffects.backgroundActive ? "Deactivate" : "Activate"
          } background`}
          // color={videoEffects.backgroundActive ? "secondary" : undefined}
          disabled={disabled || backgroundStatus !== "ready"}
          onClick={handleClick}
        >
          <Icons.Archive
            style={
              videoEffects.backgroundActive
                ? null
                : {
                    color: videoEffects.backgroundEnabled
                      ? "#000000"
                      : "currentColor",
                    fill: videoEffects.backgroundEnabled
                      ? "#ffffff"
                      : "transparent",
                  }
            }
          />
        </TooltipIconButton>
      </Mui.Badge>
      <Mui.Dialog open={open} onClose={handleClose}>
        <Mui.DialogTitle>Change background</Mui.DialogTitle>
        <Mui.DialogContent>
          <BackgroundSelect />
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button
            variant="contained"
            color="secondary"
            children="Exit"
            onClick={handleClose}
          />
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default VideoBackgroundButton;

VideoBackgroundButton.propTypes = {
  videoEffects: PropTypes.object.isRequired,
  updateVideoEffects: PropTypes.func.isRequired,
};
