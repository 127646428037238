import { createLocalTracks, getMediaDevices } from "XmarsJitsiMeetJS/index";
import { fork, put, race, select, take, call } from "redux-saga/effects";
import * as actions from "XmarsStore/actions";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";
import { listenChangeLocalAudioTrack } from "./listenChangeLocalAudioTrack";

export const jitsiCallInitLocalTracks = function* (jitsiConference) {
  yield jitsiCallInitLocalTracksGuts(jitsiConference);
};

export const jitsiCallInitLocalTracksGuts = function* (jitsiConference) {
  const onlyAudio = yield select((state) => state.video.onlyAudio);
  try {
    if (!onlyAudio) {
      yield fork(jitsiCreateTracks, jitsiConference, "video");
    }
    yield fork(jitsiCreateTracks, jitsiConference, "audio");

    yield race([
      take(actions.setLocalAudioTrackReady),
      take(actions.setLocalVideoTrackReady),
    ]);

    const localAudioTrackReady = yield select(
      (state) => state.jitsi.localTracksData.localAudioTrackReady
    );
    const localVideoTrackReady = yield select(
      (state) => state.jitsi.localTracksData.localVideoTrackReady
    );
    if (localAudioTrackReady || localVideoTrackReady) {
      jitsiConference.join();
    } else {
      yield put(actions.jitsiCallHang());
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
};

function* jitsiCreateTracks(jitsiConference, media) {
  try {
    const track = yield createLocalTracks[media]();

    const devices = yield getMediaDevices();
    yield put(actions.setDevices(devices));

    if (devices.some((device) => device.deviceId === track.deviceId)) {
      yield jitsiConference.addTrack(track);
      if (media === "audio") {
        yield fork(listenChangeLocalAudioTrack, jitsiConference);
      }
    }
  } catch (error) {
    console.log("jitsiCreateTracks error: ");
    console.log(error);
    if (media === "audio") {
      yield put(actions.setLocalAudioTrackReady(false));
    }
    if (media === "video") {
      yield put(actions.setLocalVideoTrackReady(false));
    }

    yield put(
      actions.addSnackbar({
        severity: "error",
        message: `${error.message}`,
      })
    );
  }
}
