import { actionChannel, take, put, fork, race } from "redux-saga/effects";
import * as actions from "XmarsStore/actions";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";

export function* videoTrackControl(track) {
  const channel = yield actionChannel(actions.localVideoTrackController);
  while (true) {
    try {
      const { signal, endJitsiCall } = yield race({
        signal: take(channel),
        endJitsiCall: take(actions.jitsiCallHang),
      });
      if (endJitsiCall) {
        channel.close();
      } else {
        const { payload } = signal;
        yield fork(videoControlHandler, { track, payload });
      }
    } catch (error) {
      yield handleSagaError(error);
    }
  }
}

function* videoControlHandler(obj) {
  const { track, payload } = obj;
  const { command } = payload;
  if (command === "mute") {
    track.mute();
    yield put(actions.setLocalVideoIsMuted(true));
  }
  if (command === "unmute") {
    track.unmute();
    yield put(actions.setLocalVideoIsMuted(false));
  }
  if (command === "toggle") {
    if (track.isMuted()) {
      track.unmute();
      yield put(actions.setLocalVideoIsMuted(false));
    } else {
      track.mute();
      yield put(actions.setLocalVideoIsMuted(true));
    }
  }
  if (command === "status") {
    console.log("muted", track.isMuted());
    console.log("ended", track.isEnded());
  }
}
