import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "@mui/icons-material";

import { TooltipIconButton } from "../../prestyledComponents";
import { useDependency, useMounted } from "XmarsCustomHooks/index";

import {
  registerEventHandlers,
  unregisterEventHandlers,
} from "../../../JitsiMeetJS";

import JitsiMeetJS from "../../../JitsiMeetJS/jitsi";

// eslint-disable-next-line
import { Props } from "./ShareScreenButton.propTypes.js";

/**
 * @param {Props} props
 */

export const ShareScreenButton = function ({
  disabled: externalDisabled,
  sharedScreen,
  localParticipant,
  meetingCode,
  screenTrack,
  screenConnection,
  user,
  addSnackbar,
  startSharing,
  stopSharing,
  closeMoreMenu,
  ...props
}) {
  const [disabled, setDisabled] = useState(false);
  const [toggleUpdater, toggleDependency] = useDependency();

  const mounted = useMounted();

  useEffect(() => {
    if (screenTrack && screenTrack.videoType === "desktop") {
      registerEventHandlers("track", screenTrack, trackEventHandlers);
      return () =>
        unregisterEventHandlers("track", screenTrack, trackEventHandlers);
    }
  }, [screenTrack]);

  useEffect(() => {
    if (sharedScreen) {
      const onKicked = () => {
        addSnackbar({
          severity: "info",
          message: "Your screen was stopped",
        });
        stopSharing({
          conference: sharedScreen,
          screenTrack,
          connection: screenConnection,
        });
        closeMoreMenu();
      };
      sharedScreen.addEventListener(
        JitsiMeetJS.events.conference.KICKED,
        onKicked
      );
    }
  }, [sharedScreen]);

  useEffect(() => {
    if (toggleDependency) {
      handle();
    }
  }, [toggleDependency]);

  const handleClick = toggleUpdater;

  const handle = async function () {
    if (mounted.current) setDisabled(true);
    try {
      if (!localParticipant.sharingScreen) {
        startSharing({ meetingCode, user });
        closeMoreMenu();
      } else {
        stopSharing({
          conference: sharedScreen,
          screenTrack,
          connection: screenConnection,
        });
        closeMoreMenu();
      }
    } catch (error) {
      const canceled = "User canceled screen sharing prompt";

      const message = `${error}`;
      if (!message.match(canceled)) {
        const snackbar = {
          severity: "error",
          message,
        };
        addSnackbar(snackbar);
      }
    }
    if (mounted.current) setDisabled(false);
  };

  const trackEventHandlers = {
    onLocalTrackStopped: toggleUpdater,
  };

  return (
    <TooltipIconButton
      {...props}
      title={localParticipant.sharingScreen ? "Stop sharing" : "Share screen"}
      disabled={externalDisabled || disabled}
      onClick={handleClick}
    >
      <Mui.Icon>
        {localParticipant.sharingScreen ? (
          <Icons.StopScreenShareRounded />
        ) : (
          <Icons.ScreenShareRounded />
        )}
      </Mui.Icon>
    </TooltipIconButton>
  );
};

export default ShareScreenButton;
