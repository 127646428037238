import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SearchUsersForm from "./SearchUsersForm.component";

import { searchByUsername } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchByUsername,
    },
    dispatch
  );

const SearchUsersFormContainer = connect(
  null,
  mapDispatchToProps
)(SearchUsersForm);

export default SearchUsersFormContainer;
