import client from "../../../client";
import mutation from "./changeUsername.mutation";

/**
 * @param {string} username
 * @returns {Promise<{ username: string }>}
 */
export const changeUsername = async function (username) {
  const { data } = await client.mutate({
    mutation,
    variables: {
      username,
    },
  });
  return data.updateUser;
};

export default changeUsername;
