import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FromTextField from "./FromTextField.component";
import { setFilters } from "XmarsStore/actions";

const mapStateToProps = (state) => {
  const { rooms, friends } = state.chat;

  return {
    rooms,
    friends,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setFilters }, dispatch);

const FromTextFieldContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FromTextField);

export default FromTextFieldContainer;
