import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { getUid } from "../../../utils/getUid";
import useStyles from "./UsernameTextField.styles";

export const UsernameTextField = function ({ inputProps, ...props }) {
  const classes = useStyles();

  return (
    <Mui.TextField
      id={getUid()}
      {...props}
      type="text"
      InputProps={{
        className: classes.input,
        notched: false,
        classes: {
          root: classes.inputRoot,
          focused: classes.inputFocused,
          notchedOutline: classes.inputNotchedOutline,
        },
      }}
      inputProps={{ className: classes.inputBase, ...inputProps }}
    />
  );
};

export default UsernameTextField;

UsernameTextField.propTypes = {
  /**
   * The name to be used in the form
   */
  name: PropTypes.string,
  /**
   * The label to be displayed
   */
  label: PropTypes.string,
  /**
   * The placeholder to be displayed
   */
  placeholder: PropTypes.string,
  /**
   * Input variant
   */
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
};

UsernameTextField.defaultProps = {
  name: "username",
  placeholder: "Username",
  variant: "outlined",
  size: "small",
  fullWidth: true,
  autoComplete: "off",
  required: true,
};
