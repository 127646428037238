import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AddUsersToGroupButton from "./AddUsersToGroupButton.component";

import { addUsersToRoom as addUsersToChannel } from "XmarsStore/actions";
import {
  selectActiveChannel,
  selectActiveWorkspaceMembers,
} from "XmarsStore/selectors";

const mapStateToProps = (state) => {
  const currentChannel = selectActiveChannel(state);
  const workspaceMembers = selectActiveWorkspaceMembers(state);
  return { currentChannel, workspaceMembers };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addUsersToChannel,
    },
    dispatch
  );

const AddUsersToGroupButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUsersToGroupButton);

export default AddUsersToGroupButtonContainer;
