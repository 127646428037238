import client from "../../../client";
import { addBearer } from "../../../utils";
import mutation from "./updateCategory.mutation";

/**
 * @param {string} newTitle
 * @param {string} categoryId
 * @returns {Promise<{
 *  id: string,
 *  title: string,
 *  createdAt: date,
 *  description: string
 * }>}
 */
export const updateCategory = async function (newTitle, categoryId) {
  categoryId = addBearer(categoryId);

  const { data } = await client.mutate({
    mutation,
    variables: {
      input: {
        newTitle,
        categoryId,
      },
    },
  });
  return data.updateCategory;
};

export default updateCategory;
