import { gql } from "../../../utils/apollo";

export const getUserByEmail = gql`
  query GetUserByEmail($email: String) {
    getUserByEmail(input: { email: $email }) {
      id
      username
      avatar
    }
  }
`;

export default getUserByEmail;
