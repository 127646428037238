import { mainLog, groupLog } from "XmarsJitsiMeetJS/loggers";
import {
  registerEventHandlers,
  unregisterEventHandlers,
} from "XmarsJitsiMeetJS/eventHandlers";
// eslint-disable-next-line
import { JitsiConnection, JitsiTrack } from "XmarsJitsiMeetJS/typeDefs";

/**
 * @param {JitsiConnection} jitsiConnection
 * @param {string} meetingCode
 * @param {Object<string,any>} conferenceOptions
 */

const defaultConferenceOptions = {
  p2p: {
    enabled: true,
    stunServers: [
      { urls: "stun:stun.l.google.com:19302" },
      { urls: "stun:stun1.l.google.com:19302" },
      { urls: "stun:stun2.l.google.com:19302" },
    ],
  },
};

export const getJitsiConference = function (
  jitsiConnection,
  meetingCode,
  conferenceOptions = defaultConferenceOptions
) {
  mainLog("Creating Jitsi Conference...");
  /** @type {Set<JitsiTrack>} */
  const localTracks = new Set();
  const jitsiConference = jitsiConnection.initJitsiConference(
    meetingCode,
    conferenceOptions
  );
  const cleanTracks = function () {
    for (const track of localTracks) {
      track.dispose();
    }
    localTracks.clear();
    unregisterEventHandlers(
      "conference",
      jitsiConference,
      conferenceEventHandlers
    );
  };
  const conferenceEventHandlers = {
    /** @param {JitsiTrack} track */
    onTrackAdded(track) {
      if (track.isLocal()) localTracks.add(track);
    },
    onKicked: cleanTracks,
    onConferenceLeft: cleanTracks,
    onConferenceFailed: cleanTracks,
  };
  registerEventHandlers("conference", jitsiConference, conferenceEventHandlers);
  groupLog("Jitsi Conference created", () => {
    console.log(jitsiConference);
  });
  return jitsiConference;
};

export default getJitsiConference;
