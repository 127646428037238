import { Button } from "@mui/material";
import * as Icons from "react-feather";

export const OpenSearchBarTabButton = function ({
  searchPanelOpen,
  setSearchPanelOpen,
  ...props
}) {
  function handleClick() {
    setSearchPanelOpen(!searchPanelOpen);
  }
  return (
    <Button
      {...props}
      onClick={handleClick}
      variant="outlined"
      endIcon={<Icons.Search />}
    >
      Search
    </Button>
  );
};

export default OpenSearchBarTabButton;
