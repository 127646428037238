import { connect } from "react-redux";

import SideWorkspacesButtons from "./SideWorkspacesButtons.component";
import { selectActiveWorkspace } from "../../../store/selectors";

// import {
//     setCurrentWorkspace

//   } from "XmarsStore/actions";

const mapStateToProps = (state) => {
  const ws = selectActiveWorkspace(state);
  const { workspaces } = state.chat;
  return {
    currentWorkspace: ws,
    workspaces,
  };
};

const SideWorkspacesButtonsContainer = connect(mapStateToProps)(
  SideWorkspacesButtons
);

export default SideWorkspacesButtonsContainer;
