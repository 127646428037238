/**
 * @param {Blob} blob
 * @returns {Promise<string>}
 */
export const blobToDataUrl = function (blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export default blobToDataUrl;
