import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  // loadingLabel: {
  //   textAlign: "center",
  //   color: "black",
  //   fontFamily: theme.typography.fontFamily,
  //   background: "#d9f4fa",
  //   marginBottom: "-20px",
  //   position: "relative",
  //   zIndex: "12",
  // },
  // linearProgress: {
  //   marginBottom: "-4px",
  //   zIndex: "12",
  // },
  // messagesContainer: {
  //   height: "calc(100% - 1em)",
  //   minHeight: "calc(100vh - 107px)",
  //   padding: 0,
  // },
  // floating: {
  //   position: "absolute",
  //   bottom: "12em",
  //   right: "1.5em",
  //   [theme.breakpoints.down("md")]: {
  //     bottom: "10em",
  //   },
  // },
  // dateLabelContainer: {
  //   display: "flex",
  //   justifyContent: "center",
  //   position: "-webkit-sticky",
  //   position: "sticky",
  //   top: "0",
  //   zIndex: "10",
  //   width: "180px",
  //   margin: "auto",
  //   marginBottom: "19px",
  // },
  // dateLabel: {
  //   textAlign: "center",
  //   padding: "6px 18px",
  //   fontSize: "11px",
  //   color: "black",
  //   borderRadius: "14px",
  //   letterSpacing: "0.5px",
  //   fontFamily: theme.typography.fontFamily,
  // },
  // todayBackground: {
  //   background: "#d9f4fb",
  // },
  // yesterdayBackground: {
  //   background: "#abecfd",
  // },
  // otherDateBackground: {
  //   background: "#b8ccff",
  // },
  // sameMUMessages: {
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "space-between",
  // },
}));
