import JitsiMeetJS from "XmarsJitsiMeetJS/index";
import { mainLog, groupLog } from "XmarsJitsiMeetJS/loggers";

export const createLocalTracks = async function (
  options = { devices: ["audio", "video"] }
) {
  mainLog(`Creating ${options.devices} local tracks...`);
  const tracks = await JitsiMeetJS.createLocalTracks(options);
  groupLog("Local tracks created", () => {
    console.log(tracks);
  });
  return tracks;
};

createLocalTracks.audio = async function (options = {}) {
  const [track] = await createLocalTracks({
    ...options,
    devices: ["audio"],
  });
  return track;
};

createLocalTracks.video = async function (options = {}) {
  const [track] = await createLocalTracks({
    ...options,
    devices: ["video"],
  });
  return track;
};

createLocalTracks.screen = async function (options = {}) {
  const tracks = await createLocalTracks({
    ...options,
    devices: ["desktop"],
  });
  let desktopTrack;
  for (let track of tracks) {
    if (track.videoType === "desktop") {
      desktopTrack = track;
    } else {
      await track.dispose();
    }
  }
  return desktopTrack;
};

export default createLocalTracks;
