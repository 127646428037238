import * as Mui from "@mui/material";
import clsx from "clsx";
import {
  getCurrentDate,
  organizeMessages,
  sameTimeInterval,
} from "../utils/index";
import { Message } from "../../../collectionItems";

/**
 * Organizes the same day messages by minute and user and renders it
 * @param {Array} sameDayMessages
 * @returns {React.Component}
 */
function renderSameDayMessages(props) {
  const { sameDayMessages, ref, isDrawer } = props;
  const byMinuteAndUser = [];

  sameDayMessages.forEach((message) => {
    if (!byMinuteAndUser.length) {
      byMinuteAndUser.push([message]);
    } else {
      let groupMessage = byMinuteAndUser[byMinuteAndUser.length - 1][0];
      let sameUserMinute =
        sameTimeInterval(groupMessage.createdAt, message.createdAt) &&
        groupMessage.owner?.id === message.owner.id;

      if (sameUserMinute) {
        byMinuteAndUser[byMinuteAndUser.length - 1].push(message);
      } else {
        byMinuteAndUser.push([message]);
      }
    }
  });

  return byMinuteAndUser.map((sameMUMessages) => (
    <Mui.Box key={sameMUMessages[0].createdAt}>
      {sameMUMessages.map((message, index) => {
        let position;
        if (sameMUMessages.length === 1) {
          position = "unique";
        } else if (index === 0) {
          position = "start";
        } else if (index === sameMUMessages.length - 1) {
          position = "end";
        } else {
          position = "middle";
        }

        return (
          <Message
            key={index}
            message={message}
            containerRef={ref}
            position={position}
            isDrawer={isDrawer}
          />
        );
      })}
    </Mui.Box>
  ));
}

/**
 * Renders the messages of the current room.
 * @returns {React.ReactElement} List with the messages.
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const renderMessages = function (props) {
  const { messages, classes, ref, isDrawer } = props;

  //if (messages[Symbol.for("loading")]) return <Mui.LinearProgress />;
  if (!messages.length)
    return (
      <Mui.ListItem>
        <Mui.ListItemText primary="" />
      </Mui.ListItem>
    );

  let organizedMessages = organizeMessages(messages);

  return organizedMessages.map((sameDayMessages) => {
    let currentDate = getCurrentDate(sameDayMessages[0].createdAt);
    let dateBackground = classes.otherDateBackground;
    if (currentDate === "Today") {
      dateBackground = classes.todayBackground;
    } else if (currentDate === "Yesterday") {
      dateBackground = classes.yesterdayBackground;
    }
    return (
      <Mui.Box key={sameDayMessages[0].createdAt}>
        <div className={classes.dateLabelContainer}>
          <div className={clsx(classes.dateLabel, dateBackground)}>
            {currentDate}
          </div>
        </div>
        {renderSameDayMessages({ sameDayMessages, ref, isDrawer })}
      </Mui.Box>
    );
  });
};
