import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CheckoutForm from "./CheckoutForm.component";

import {
  retrievePaymentIntent,
  confirmCardPayment,
} from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrievePaymentIntent,
      confirmCardPayment,
    },
    dispatch
  );

const CheckoutFormContainer = connect(null, mapDispatchToProps)(CheckoutForm);

export default CheckoutFormContainer;
