import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import RemoteParticipant from "./RemoteParticipant.component";

import {
  removeParticipantMessage,
  updateRemoteParticipant,
} from "XmarsStore/actions/index";

const mapStateToProps = (state) => {
  const { user } = state.chat;
  const { conference, videoGrid } = state.jitsi;
  const { activeChannel, isSmallVideoOpen, sharing } = state.video;
  return {
    user,
    conference,
    videoGrid,
    activeChannel,
    isSmallVideoOpen,
    sharing,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeParticipantMessage,
      updateRemoteParticipant,
    },
    dispatch
  );

const RemoteParticipantContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoteParticipant);

export default RemoteParticipantContainer;
