import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import DrawableCanvas from "../DrawableCanvas";
import { IconSubmitButton } from "../prestyledComponents";

import { useMounted } from "../../hooks/index";
import { getBoundActionPromise } from "../../utils/getBoundActionPromise";
import { logError } from "../../utils/errors/logError";

// ------- ------- ------- ------- ------- ------- -------

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export default function ScribbleCanvas({
  room,
  sendScribbleMessage,
  open,
  setOpen,
}) {
  const [processing, setProcessing] = useState(false);
  const ref = useRef(null);
  const mounted = useMounted();

  const handleScribble = async function (event) {
    event.preventDefault();
    try {
      setProcessing(true);
      const scribble = ref.current.toDataURL("image/png", 1.0);
      await getBoundActionPromise(sendScribbleMessage, {
        room,
        scribble,
      });
    } catch (error) {
      if (error) logError(error);
    } finally {
      if (mounted.current) {
        handleClose();
        setProcessing(false);
      }
    }
  };

  const handleClose = () => setOpen(false);

  // ------- ------- ------- ------- ------- ------- -------

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Draw</DialogTitle>
      <DialogContent>
        {!open ? null : <DrawableCanvas ref={ref} />}
      </DialogContent>
      <DialogActions>
        <IconSubmitButton
          loading={processing}
          onClick={handleScribble}
          children={<Icons.Send />}
        />
        <IconButton
          color="primary"
          onClick={handleClose}
          type="submit"
          children={<Icons.X />}
        />
      </DialogActions>
    </Dialog>
  );
}

ScribbleCanvas.propTypes = {
  room: PropTypes.object.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  sendScribbleMessage: PropTypes.func.isRequired,
};

ScribbleCanvas.defaultProps = {
  open: false,
};
