import chat from "./chat";
import form from "./form";
import general from "./general";
import jitsi from "./jitsi";
import messages from "./messages";
import notifications from "./notifications";
import snackbars from "./snackbars";
import video from "./video";
import search from "./search";

const reducers = {
  chat,
  form,
  general,
  jitsi,
  messages,
  notifications,
  snackbars,
  video,
  search,
};

export default reducers;
