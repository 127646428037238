export const USER_JOINED = "USER_JOINED";
export const USER_LEFT = "USER_LEFT";

export const TRACK_ADDED = "TRACK_ADDED";
export const TRACK_REMOVED = "TRACK_REMOVED";

export const CONFERENCE_JOINED = "CONFERENCE_JOINED";
export const CONFERENCE_LEFT = "CONFERENCE_LEFT";
export const CONFERENCE_ERROR = "CONFERENCE_ERROR";
export const CONFERENCE_FAILED = "CONFERENCE_FAILED";

export const KICKED = "KICKED";
export const RECORDER_STATE_CHANGED = "RECORDER_STATE_CHANGED";
export const DISPLAY_NAME_CHANGED = "DISPLAY_NAME_CHANGED";
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
