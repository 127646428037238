import * as actions from "XmarsStore/actions";
import { put, take, delay, race, call, select } from "redux-saga/effects";
import { initLocalTrack } from "../tracks/initLocalTrack";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";
import { stopSharing } from "../screenSharing";

export function* conferenceOnUserJoined(payload) {
  try {
    const { id, participant } = payload;

    const remoteParticipant = {
      local: false,
      focused: true,
      id,
      name: participant.getDisplayName(),
      audio: null,
      video: null,
      messages: [],
    };
    yield put(actions.addRemoteParticipant(remoteParticipant));
  } catch (error) {
    yield call(handleSagaError, error);
  }
}

export function* conferenceOnUserLeft(payload) {
  try {
    const { id, participant } = payload;
    yield put(actions.removeRemoteParticipant({ id }));
    const isDesktop = participant.getProperty("isDesktop");
    const name = participant.getDisplayName();
    if (isDesktop === "true") {
      yield put(
        actions.addSnackbar({
          severity: "info",
          message: "Screen sharing stopped",
        })
      );
    } else {
      yield put(
        actions.addSnackbar({
          severity: "info",
          message: `${name} left the call`,
        })
      );
    }
    const { id: sharingUser } = yield select((state) => state.video.sharing);
    if (id === sharingUser) {
      const { meetingCode } = yield select((state) => state.video.ongoingCall);
      yield put(
        actions.stopSharingScreen({
          channelId: meetingCode,
          sharingUserId: sharingUser,
        })
      );
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}

export function* conferenceOnTrackAdded(track) {
  try {
    if (track.isLocal()) {
      yield initLocalTrack(track);
    } else {
      yield put(actions.addRemoteTrack(track));
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}

export function* conferenceOnTrackRemoved(track) {
  try {
    if (track.isLocal()) {
      yield put(actions.localAudioTrackRemoved(track));
    } else {
      yield put(actions.removeRemoteTrack(track));
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}

export function* conferenceOnConferenceFailed(errorCode) {
  try {
    yield put(
      actions.addSnackbar({
        severity: "error",
        message: `Conference Failed: ${errorCode}`,
      })
    );

    yield put(actions.jitsiCallHang());
  } catch (error) {
    yield call(handleSagaError, error);
  }
}
export function* conferenceOnKicked() {
  try {
    yield put(
      actions.addSnackbar({
        severity: "info",
        message: "You were kicked from the call",
      })
    );
    const sharedScreen = yield select((state) => state.jitsi.sharedScreen);
    const screenTrack = yield select((state) => state.jitsi.screenTrack);
    if (sharedScreen) {
      yield call(stopSharing, sharedScreen, screenTrack);
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}

export function* waitForPeople(call) {
  try {
    const { ghosted, callCanceled } = yield race({
      callAnwered: take(actions.addRemoteParticipant),
      ghosted: delay(10000, true),
      callCanceled: take(actions.jitsiCallHang),
    });
    if (ghosted) {
      yield put(
        actions.addSnackbar({
          severity: "info",
          message: "ending call because lack of participants",
        })
      );
      yield put(actions.jitsiCallHang());
      yield put(actions.endOngoingCall(call));
    } else if (callCanceled) {
      yield put(actions.endOngoingCall(call));
    } else {
      yield put(actions.updateCallStatus({ successful: true, dialing: false }));
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}

export function* conferenceOnMessageReceived(id, jsonMessage) {
  // if (id === conference.myUserId()) return;
  // const message = JSON.parse(jsonMessage);
  // if (Date.now() - message.date < 10000) {
  //   yield put(
  //     setJitsiMessageObject({
  //       id,
  //       message,
  //     })
  //   );
  // }
}
