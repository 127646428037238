import { gql } from "../../../utils/apollo";

export const createGuest = gql`
  mutation CreateGuest($username: String, $email: String) {
    createGuest(input: { username: $username, email: $email }) {
      username
    }
  }
`;

export default createGuest;
