import { put } from "redux-saga/effects";

import * as actions from "XmarsStore/actions";

export const stopSharing = function* ({ payload }) {
  const { conference, screenTrack, connection } = payload;

  yield put(actions.setScreenTrack(null));
  yield put(actions.setScreenConnection(null));
  yield put(actions.setSharedScreen(null));
  yield put(
    actions.updateLocalParticipant({
      sharingScreen: false,
    })
  );
  yield conference.removeTrack(screenTrack);
  yield screenTrack.dispose();
  if (conference.myUserId()) {
    yield conference.leave();
  }
  yield connection.disconnect();
};
