import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import File from "./File.component";

import { deleteFile } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteFile,
    },
    dispatch
  );

const FileContainer = connect(null, mapDispatchToProps)(File);

export default FileContainer;
