import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  numRepliesButton: {
    padding: "0",
    textTransform: "lowercase",
    float: "right",
    marginTop: "3px",
    marginBottom: "0px",
    marginLeft: "6px",
    marginRight: "0px",
    height: "20px",
    minWidth: "54px",
  },
  ownNumRepliesButton: {
    position: "absolute",
    right: "-61px",
  },
  numberUnreadText: {
    fontSize: "12px",
    position: "absolute",
  },
  unreadInThreads: {
    color: theme.palette.secondary.dark,
  },

  unreadRepliesOther: {
    position: "relative",
    marginLeft: "-56px",
    marginTop: "-22px",
  },
  unreadRepliesOtherBody: {
    position: "relative",
    marginLeft: "-56px",
    marginTop: "-2px",
  },
  unreadRepliesOwn: {
    right: "0px",
  },

  unreadOfFirstPlace: {
    top: "39px",
  },
  unreadOfMiddle: {
    top: "19px",
  },
  unreadOfLastPlace: {
    top: "17px",
  },
}));
