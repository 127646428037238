export default {
  page: "landing",
  connected: true,
  generic: null,
  recoverParams: "",
  files: [],
  unreadRepliesPanelOpen: false,
  repliesPanelOpen: false,
  isGroupChatsOpen: false,
  isOneToOneChatsOpen: false,
  //newFiles: [],
  temporalMessages: [],
};
