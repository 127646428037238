import { gql } from "../../../utils/apollo";

export const updateMedicalRoomPayment = gql`
  mutation UpdateMedicalRoomPayment($id: ID) {
    updateMedicalRoomPayment(input: { id: $id }) {
      cost
    }
  }
`;

export default updateMedicalRoomPayment;
