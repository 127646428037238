import client from "../../../client";
import query from "./searchByEmail.query";

/**
 * @param {Array<string>} emailList
 * @returns {Promise<Array<{
 *  id: string,
 *  username: string,
 *  avatar: string,
 * }>>}
 */
export const searchByEmail = async function (emailList) {
  const { data } = await client.query({
    query,
    variables: {
      emailList,
    },
  });
  return data.searchByEmail;
};

export default searchByEmail;
