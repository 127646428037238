import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import * as Icons from "react-feather";
import PropTypes from "prop-types";
import clsx from "clsx";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

import { MultilineInput } from "../../inputs";
import { EmojisButton } from "../../pseudoInputs";
import { IconSubmitButton } from "../../prestyledComponents";
import { useMounted, useSize } from "../../../hooks/index";
import { TooltipIconButton } from "../../prestyledComponents";

import useStyles from "./EditReplyForm.styles";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const EditReplyForm = function ({
  onSubmit,
  message,
  inputRef,
  setIsEditingReply,
  setEditingReply,
  room,
  editReply,
  threadingMessageId,
  setInputHeight,
  ...props
}) {
  const [value, setValue] = useState(message?.text);

  useEffect(() => {
    setValue(message?.text);
  }, [message]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();

        handleClose();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const [processing, setProcessing] = useState(false);
  const mounted = useMounted();

  const classes = useStyles();

  const handleChange = function (event) {
    setValue(event.target.value);
  };

  const handleEmoji = function (emoji) {
    setValue(value + emoji);
  };

  const handleSubmit = formSubmitHandler(async ({ text }, event) => {
    text = text.trim();
    if (!text || message.text === text) return setIsEditingReply(false);
    try {
      setProcessing(true);
      await getBoundActionPromise(editReply, {
        messageId: message.id,
        text,
        threadingMessageId,
      });
      if (onSubmit) onSubmit(event);
      setValue("");
    } catch (error) {
      logError(error);
    } finally {
      if (mounted.current) {
        setProcessing(false);
      }
    }
    setIsEditingReply(false);
  });

  const handleClose = function () {
    setIsEditingReply(false);
    setValue("");
    setEditingReply(null);
  };

  const size = useSize(inputRef);

  useEffect(() => {
    setInputHeight(size?.height);
  }, [size?.height]);

  return (
    <Box
      {...props}
      component="form"
      display="flex"
      onSubmit={handleSubmit}
      //onBlur={onBlur}
      className={classes.fullContainer}
    >
      <Box flexGrow={1}>
        <MultilineInput
          name="text"
          placeholder="Write to edit"
          disabled={processing}
          //className={classes.editInput}
          inputRef={inputRef}
          value={value}
          onChange={handleChange}
          autoFocus={true}
          startComponent={
            <EmojisButton
              disabled={processing}
              color="primary"
              onEmoji={handleEmoji}
              className={classes.emojiButton}
            />
          }
        />
      </Box>

      <Box>
        {value !== message?.text && value !== "" ? (
          <IconSubmitButton
            loading={processing}
            children={<Icons.Send />}
            className={clsx(classes.chatButton, classes.sendButton)}
          />
        ) : (
          <TooltipIconButton
            color="primary"
            disabled={processing}
            title="Cancel"
            onClick={handleClose}
            className={clsx(classes.chatButton, classes.cancelButton)}
          >
            <Icons.X />
          </TooltipIconButton>
        )}
      </Box>
    </Box>
  );
};

export default EditReplyForm;

EditReplyForm.propTypes = {
  message: PropTypes.object.isRequired,
  editReply: PropTypes.func.isRequired,
};
