import React, { useEffect, useState, useCallback } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { Tooltip } from "../../prestyledComponents";
import { LeaveRoomButton } from "../../buttons";
import { StatusIndicator } from "./StatusIndicator";
import { LastUpdateDate } from "./LastUpdateDate";
import { TypingIndicator } from "../TypingIndicator/TypingIndicator.component";
import { stringAvatar } from "../../../utils/defaultAvatar/defaultAvatar";
import useStyles from "./SidebarItem.styles";
import clsx from "clsx";
import { setRoomProperties } from "../../../store/actions";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const SidebarItem = function ({
  room,
  mainRoom,
  friends,
  filtered,
  userBadgeProps,
  roomBadgeProps,
  setRoom,
  unfriend,
  type,
  closePanels,
  lastUpdate,
  user,
  draftText,
  panel,
  lastMessage,
  deleteConversation,
  leaveRoom,
  unreadMessages,
  unreadReplies,
  ...props
}) {
  const [title, setTitle] = useState("loading");
  const [roomInProperty, setRoomInProperty] = useState(undefined);
  const [open, setOpen] = useState(false);
  //const [anchorEl, setAnchorEl] = useState(null);
  //const [leaveRoomAnchorEl, setLeaveRoomAnchorEl] = useState(null);
  const [hovering, setHovering] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  // FIX: polling *
  const [online, setOnline] = useState(false);
  const [pending, setPending] = useState(false);
  const [typing, setTyping] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const title = room.username || room.title;
    setTitle(title);
    const actualRoom = room.room || room;
    setRoomInProperty(actualRoom);
  }, [room]);
  useEffect(() => {
    const actualRoom = type === "friends" ? roomInProperty : room;
    const friend = type === "friends" ? room : room.friend;
    if (friend) {
      const friendData = friends.find((element) => friend.id === element.id);
      if (friendData) {
        setOnline(friendData.online);
      }
    }
    if (actualRoom) {
      setPending(actualRoom.pending);
      setTyping(actualRoom.typing);
    }
  }, [type, room.friend, room, friends, roomInProperty]);

  const handleClick = useCallback(
    function () {
      if (contextMenu) return;
      const actualRoom = type === "friends" ? roomInProperty : room;

      if (actualRoom) {
        if (actualRoom.pending) actualRoom.pending = false;
        setRoom(actualRoom);
      } else {
        setRoom({ title, id: room.id });
      }

      closePanels && closePanels();
      // eslint-disable-next-line
    },
    [type, room, contextMenu, roomInProperty]
  );

  const handleContextMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
    setHovering(false);
  };

  const handleLeaveRoomMenuClose = function () {
    setHovering(false);
  };

  const handleDialogOpen = function () {
    setOpen(true);
    setContextMenu(null);
  };

  const handleDialogClose = function () {
    setOpen(false);
    setHovering(false);
  };

  const handleUnfriend = function () {
    setOpen(false);
    setContextMenu(null);
    setHovering(false);
    unfriend(type === "friends" ? room : room.friend);
  };

  const renderIcon = function () {
    const isFriend = type === "friends" || room.friend;
    // let clickHandler = handleAvatarClick(isFriend);
    const friend = type === "friends" ? room : room.friend;
    // let roomName = type === "friends" ? room.username : room.title;
    // const friend = room.friend || room;
    let roomName = title;

    if (isFriend) {
      return (
        <StatusIndicator status={online} {...userBadgeProps}>
          <Mui.Avatar
            src={friend.avatar}
            //onClick={clickHandler}
            {...stringAvatar(roomName)}
          />
        </StatusIndicator>
      );
    }
    return (
      <Mui.Avatar
        src={room.avatar}
        //onClick={clickHandler}
        {...stringAvatar(roomName)}
      >
        {/* <Icons.Users /> */}
      </Mui.Avatar>
    );
  };

  function handleDeleteConversation() {
    setContextMenu(null);
    deleteConversation(room);
  }

  function handleLeaveConversation() {
    setContextMenu(null);
    leaveRoom(room);
  }

  const ContextMenu = function () {
    const isFriend = !room.title;
    return (
      <Mui.Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        transitionDuration="0"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {type !== "friends" ? (
          <Mui.Box>
            <Mui.MenuItem
              classes={{ root: classes.conversationMenuOptionItem }}
            >
              <Mui.ListItemIcon
                classes={{ root: classes.conversationMenuOptionIcon }}
              >
                <Icons.Star />
              </Mui.ListItemIcon>
              <Mui.ListItemText
                primary="Archive conversation"
                disableTypography
                className={classes.conversationMenuOptionText}
              />
            </Mui.MenuItem>
            <Mui.MenuItem
              classes={{ root: classes.conversationMenuOptionItem }}
            >
              <Mui.ListItemIcon
                classes={{ root: classes.conversationMenuOptionIcon }}
              >
                <Icons.Mic />
              </Mui.ListItemIcon>
              <Mui.ListItemText
                primary="Mute notifications"
                disableTypography
                className={classes.conversationMenuOptionText}
              />
            </Mui.MenuItem>
            <Mui.MenuItem
              classes={{ root: classes.conversationMenuOptionItem }}
            >
              <Mui.ListItemIcon
                classes={{ root: classes.conversationMenuOptionIcon }}
              >
                <Icons.Check />
              </Mui.ListItemIcon>
              <Mui.ListItemText
                primary="Mark as unread"
                disableTypography
                className={classes.conversationMenuOptionText}
              />
            </Mui.MenuItem>
            {!room.group ? (
              <Mui.MenuItem
                classes={{ root: classes.conversationMenuOptionItem }}
                onClick={handleDeleteConversation}
              >
                <Mui.ListItemIcon
                  classes={{ root: classes.conversationMenuOptionIcon }}
                >
                  <Icons.Trash />
                </Mui.ListItemIcon>
                <Mui.ListItemText
                  primary="Delete conversation"
                  disableTypography
                  className={classes.conversationMenuOptionText}
                />
              </Mui.MenuItem>
            ) : (
              <Mui.MenuItem
                classes={{ root: classes.conversationMenuOptionItem }}
                onClick={handleLeaveConversation}
              >
                <Mui.ListItemIcon
                  classes={{ root: classes.conversationMenuOptionIcon }}
                >
                  <Icons.Trash />
                </Mui.ListItemIcon>
                <Mui.ListItemText
                  primary="Leave conversation"
                  disableTypography
                  className={classes.conversationMenuOptionText}
                />
              </Mui.MenuItem>
            )}
          </Mui.Box>
        ) : !isFriend ? (
          <LeaveRoomButton
            showIcon
            afterAction={handleLeaveRoomMenuClose}
            roomToLeave={room}
          />
        ) : (
          <Mui.MenuItem onClick={handleDialogOpen}>
            <Mui.ListItemIcon>
              <Icons.MinusCircle color="secondary" />
            </Mui.ListItemIcon>
            <Mui.ListItemText primary="Unfriend?" />
          </Mui.MenuItem>
        )}
      </Mui.Menu>
    );
  };

  const renderDialog = function () {
    if (type !== "friends" && !room.friend) return null;
    return (
      <Mui.Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <Mui.DialogTitle id="form-dialog-title">
          {`Are your sure you want to unfriend ${
            type === "friends" ? title : room.friend.username
          }?`}
        </Mui.DialogTitle>
        <Mui.DialogActions>
          <Tooltip title="Yes">
            <Mui.IconButton color="secondary" onClick={handleUnfriend}>
              <Icons.MinusCircle />
            </Mui.IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <Mui.IconButton color="primary" onClick={handleDialogClose}>
              <Icons.X />
            </Mui.IconButton>
          </Tooltip>
        </Mui.DialogActions>
      </Mui.Dialog>
    );
  };
  const typingText = typing
    ? type === "friends" || room.friend
      ? "Typing"
      : room.usernameTyping
    : "";
  const typingComponent = (
    <TypingIndicator
      text={typingText}
      style={{ marginTop: "-12px" }}
    ></TypingIndicator>
  );

  // type = friends || chats
  let secondaryText;
  if (type === "chats") {
    if (draftText && room?.id !== mainRoom?.id) {
      secondaryText = `[Draft] ${draftText}`;
    } else {
      secondaryText = lastMessage;
    }
  }

  //const secondaryText = type === "friends" || room.friend ? "" : "Group";
  const actualRoom = type === "friends" ? roomInProperty : room;

  function handleMouseMove() {
    if (!hovering) setHovering(true);
  }

  return (
    <>
      <Mui.ListItem
        {...props}
        button
        selected={mainRoom === actualRoom}
        className={classes.item}
        classes={{ root: classes.root, selected: classes.selected }}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => setHovering(false)}
        onContextMenu={handleContextMenu}
      >
        <Mui.ListItemAvatar>{renderIcon()}</Mui.ListItemAvatar>
        <Mui.ListItemText
          primary={type === "friends" ? title : room.title}
          primaryTypographyProps={{
            className: clsx(
              classes.primaryText,
              type !== "friends" && room.unreadMessages?.total && classes.bold
            ),
          }}
          secondary={typing ? typingComponent : secondaryText}
          secondaryTypographyProps={{
            className: clsx(
              mainRoom === actualRoom
                ? classes.secondarySelected
                : classes.secondary
            ),
          }}
          className={classes.itemText}
        />
        {/* {!pending ? (
          <Mui.ListItemIcon>
            <Mui.Badge
              color="primary"
              {...roomBadgeProps}
              variant="dot"
              invisible={!pending}
            />
          </Mui.ListItemIcon>
        ) : null} */}

        <Mui.Box className={classes.extraInfo}>
          {!filtered ? null : (
            <Mui.ListItemText
              secondary="Filtered"
              secondaryTypographyProps={{
                className: clsx(
                  classes.filteredText,
                  mainRoom === actualRoom
                    ? classes.filteredTextSelected
                    : classes.filteredTextNoSelected
                ),
              }}
            />
          )}

          {type !== "friends" && <LastUpdateDate lastUpdate={lastUpdate} />}
        </Mui.Box>
        {type !== "friends" && unreadMessages ? (
          <Mui.Box
            className={clsx(classes.numberUnread, classes.numberUnreadMessages)}
          >
            {unreadMessages < 100 ? unreadMessages : "99+"}
          </Mui.Box>
        ) : null}

        {type !== "friends" && unreadReplies ? (
          <Mui.Box
            className={clsx(classes.numberUnread, classes.numberUnreadReplies)}
          >
            {unreadReplies < 100 ? unreadReplies : "99+"}
          </Mui.Box>
        ) : null}

        {(hovering && type === "friends") || type !== "friends" ? (
          <Mui.IconButton
            className={clsx(
              classes.messageMenu,
              type === "friends"
                ? classes.contactsButton
                : classes.conversationsButton
            )}
            onClick={handleContextMenu}
          >
            <Icons.MoreHorizontal classes={{ root: classes.messageMenuIcon }} />
          </Mui.IconButton>
        ) : null}
        <ContextMenu />
      </Mui.ListItem>
      {renderDialog()}
    </>
  );
};

export default SidebarItem;

SidebarItem.propTypes = {
  room: PropTypes.object.isRequired,
  mainRoom: PropTypes.object,
  roomBadgeProps: PropTypes.object,
  userBadgeProps: PropTypes.object,
  setRoom: PropTypes.func.isRequired,
  unfriend: PropTypes.func.isRequired,
  filtered: PropTypes.bool.isRequired,
};

SidebarItem.defaultProps = {
  roomBadgeProps: {},
  userBadgeProps: {},
};
