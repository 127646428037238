import * as Mui from "@mui/material";
import { Skeleton } from "@mui/material";
import clsx from "clsx";
import useStyles from "./FoundItemLoading.styles";

export const FoundItemLoading = function ({ contact, searchedValue, user }) {
  const classes = useStyles();

  return (
    <Mui.Card variant="outlined">
      <Mui.CardContent>
        <Mui.Box className={classes.contactContainer}>
          <Mui.ListItemAvatar
            classes={{
              root: clsx(classes.avatar),
            }}
          >
            <Skeleton variant="circle" width={40} height={40} />
          </Mui.ListItemAvatar>
          <Mui.ListItem>
            <Skeleton
              variant="rect"
              width={210}
              height={50}
              classes={{ text: classes.textLoading }}
            />
          </Mui.ListItem>
        </Mui.Box>
      </Mui.CardContent>
    </Mui.Card>
  );
};

export default FoundItemLoading;
