import React, { createRef, useEffect } from "react";
import PropTypes from "prop-types";

import {
  registerEventHandlers,
  unregisterEventHandlers,
} from "../../../JitsiMeetJS";
import { useDependency } from "../../../hooks/index";

export const RemoteAudio = function ({ track }) {
  /** @type {React.RefObject<HTMLAudioElement>} */
  const ref = createRef(null);

  const [updater, dependency] = useDependency();

  //mount
  useEffect(() => {
    if (track) {
      registerEventHandlers("track", track, trackEventHandlers);
      updater();
      //unmount
      return () => {
        // eslint-disable-next-line
        track.detach(ref.current);
        unregisterEventHandlers("track", track, trackEventHandlers);
      };
    }
    // eslint-disable-next-line
  }, [track]);

  useEffect(() => {
    if (track) {
      const audio = ref.current;
      if (track.isMuted()) {
        track.detach(audio);
      } else {
        track.attach(audio);
      }
    }
    // eslint-disable-next-line
  }, [dependency, track]);

  const trackEventHandlers = {
    onTrackMuteChanged: updater,
  };

  return <audio autoPlay ref={ref} />;
};

export default RemoteAudio;

RemoteAudio.propTypes = {
  track: PropTypes.object,
};
