import { connect } from "react-redux";
import DisconnectedBackdrop from "./DisconnectedBackdrop.component";

const mapStateToProps = ({ general }) => ({ connected: general.connected });

const DisconnectedBackdropContainer = connect(
  mapStateToProps,
  null
)(DisconnectedBackdrop);

export default DisconnectedBackdropContainer;
