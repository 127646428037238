import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  video: {
    width: "100%",
    height: "100%",
    "&::-webkit-media-controls-enclosure": {
      display: "none",
    },
  },
  mirror: {
    transform: "scaleX(-1)",
  },
}));
