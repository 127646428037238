import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  backdrop: {
    zIndex: 10000,
  },
  icon: {
    transform: "scale(4)",
  },
  shading: {
    animation: `$shading 3s ease-in-out infinite`,
  },
  "@keyframes shading": {
    "0%, 100%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.7,
    },
  },
}));
