import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ScribbleCanvas from "./ScribbleCanvas.component";

import { sendScribbleMessage } from "../../store/actions/index";
import { selectActiveChannel } from "../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  return { room };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendScribbleMessage,
    },
    dispatch
  );

const ScribbleCanvasContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScribbleCanvas);

export default ScribbleCanvasContainer;
