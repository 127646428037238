import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AppUser from "./AppUser.component";

import {
  deleteAppUser,
  resetSearchedUsers,
} from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { searchedUsers } = state.general;
  return { searchedUsers };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteAppUser,
      resetSearchedUsers,
    },
    dispatch
  );

const AppUserContainer = connect(mapStateToProps, mapDispatchToProps)(AppUser);

export default AppUserContainer;
