import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import VideoBackgroundButton from "./VideoBackgroundButton.component";

import { updateVideoEffects } from "../../../store/slices/jitsi/index";

const mapStateToProps = (state) => {
  const { localParticipant, videoEffects } = state.jitsi;
  return { localParticipant, videoEffects };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateVideoEffects,
    },
    dispatch
  );

const VideoBackgroundButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoBackgroundButton);

export default VideoBackgroundButtonContainer;
