import { useEffect } from "react";
import PropTypes from "prop-types";
import { LoadingBackdrop } from "../../prestyledComponents";

export const LandingPage = function ({ parseLink }) {
  useEffect(() => {
    parseLink();
    // eslint-disable-next-line
  }, []);

  return <LoadingBackdrop />;
};

export default LandingPage;

LandingPage.propTypes = {
  parseLink: PropTypes.func.isRequired,
};
