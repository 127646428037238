import getHandtrackModel from "./getHandtrackModel";
import HandtrackWebWorkerModel from "./handtrack.WebWorkerModel";

export class HandtrackModel {
  model = null;
  #local = false;

  async init({ onLocal = true, ...params } = {}) {
    let model;
    if (onLocal) {
      model = await getHandtrackModel(params);
    } else {
      model = new HandtrackWebWorkerModel();
      await model.init(params);
    }
    this.#local = onLocal;
    this.model = model;
  }

  async predict(media) {
    const model = this.model;
    return this.#local ? await model.detect(media) : await model.predict(media);
  }

  getFPS() {
    return this.model.getFPS();
  }

  dispose() {
    this.model.dispose();
  }
}

export default HandtrackModel;
