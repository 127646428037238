import JitsiMeetJS from "XmarsJitsiMeetJS/index";
import { mainLog, groupLog } from "XmarsJitsiMeetJS/loggers";

export const getMediaDevices = async function () {
  mainLog("Getting media devices...");
  const devices = await new Promise(JitsiMeetJS.mediaDevices.enumerateDevices);
  groupLog("Media devices gotten", () => {
    console.log(devices);
  });
  return devices;
};

export default getMediaDevices;
