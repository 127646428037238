import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { readMessage, setMessageToJumpTo } from "../../../store/actions/index";

import MessageWithReplies from "./MessageWithReplies.component";

const mapStateToProps = (state) => {
  const { user } = state.chat;

  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ readMessage, setMessageToJumpTo }, dispatch);

const MessageWithRepliesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageWithReplies);

export default MessageWithRepliesContainer;
