import { forwardRef, useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { RoomUsers } from "../../collections";
import useStyles from "./RoomInfoButton.styles";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const RoomInfoButton = forwardRef(function (
  { room, afterAction, ...props },
  ref
) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClose = () => setOpen(false);
  const handleClick = () => {
    setOpen(true);
    afterAction();
  };

  return (
    <>
      <Mui.ListItem button onClick={handleClick} ref={ref}>
        {/* <Mui.ListItemIcon>
          <Mui.IconButton color="primary">
            <Mui.Badge classes={{ badge: indicatorClasses.active }} variant="dot" overlap="rectangle" badgeContent=" ">
            </Mui.Badge>
          </Mui.IconButton>
        </Mui.ListItemIcon> */}
        <Mui.ListItemText primary={"Group members"} />
      </Mui.ListItem>
      <Mui.Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{ elevation: 0 }}
        classes={{
          root: classes.dialogRoot,
          paper: classes.dialog,
          container: classes.dialogContainer,
        }}
      >
        <Mui.DialogTitle
          disableTypography
          id="form-dialog-title"
          className={classes.dialogHeader}
        >
          <Icons.Users style={{ height: "18px", width: "18px" }} />
          <Mui.Typography variant="h6" className={classes.headerText}>
            {room.title}
          </Mui.Typography>
          <Mui.IconButton className={classes.closeButton} onClick={handleClose}>
            <Icons.X style={{ strokeWidth: "2.5px" }} />
          </Mui.IconButton>
        </Mui.DialogTitle>
        <Mui.DialogContent className={classes.dialogContent}>
          <RoomUsers />
        </Mui.DialogContent>
        <Mui.DialogActions className={classes.dialogFooter}>
          <Mui.Button onClick={handleClose} className={classes.actionButton}>
            Close
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
});

export default RoomInfoButton;

RoomInfoButton.propTypes = {
  room: PropTypes.object.isRequired,
};
