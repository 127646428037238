import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ReceiveVideoCallButton from "./ReceiveVideoCallButton.component";

import {
  setPage,
  setMeetingCode,
  setActiveChannel,
  setIncomingCall,
  setOnlyAudio,
} from "XmarsStore/actions/index";

const mapStateToProps = (state) => {
  const { incomingCall } = state.video;
  return { incomingCall };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPage,
      setMeetingCode,
      setActiveChannel,
      setIncomingCall,
      setOnlyAudio,
    },
    dispatch
  );

const ReceiveVideoCallButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiveVideoCallButton);

export default ReceiveVideoCallButtonContainer;
