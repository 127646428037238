import client from "../../../client";
import mutation from "./changePassword.mutation";

/**
 * @param {string} password
 * @param {string} newPassword
 */
export const changePassword = async function (password, newPassword) {
  await client.mutate({
    mutation,
    variables: {
      password,
      newPassword,
    },
  });
};

export default changePassword;
