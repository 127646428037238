import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => {
  const colors = theme.palette;
  return {
    repliesElement: {
      float: "right",
      marginTop: "-1.45em",
      marginBottom: "1.45em",
    },
    repliesContainer: {
      border: "solid",
      background: colors.primary.light,
    },
    closeButton: {
      border: `1px solid ${theme.palette.buttons.medium}`,
      padding: "9px",

      "&:hover": {
        color: `${theme.palette.buttons.darkSecondary} !important`,
        backgroundColor: theme.palette.buttons.light,
      },
    },
    repliesHeader: {
      background: theme.palette.bgGray.sidebar,
      height: "64px",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingRight: "24px",
      paddingTop: "8px",
    },
    headerText: {
      display: "flex",
      flexDirection: "column",
    },
    threadTitle: {
      margin: "0",
      fontSize: "16px",
      fontWeight: "600",
      fontFamily: theme.typography.fontFamily,
      paddingLeft: "8px",
    },
    headerInfo: {
      color: theme.palette.textGray.main,
      fontSize: "0.8em",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    divider: {
      backgroundColor: theme.palette.buttons.light,
    },
    replies: {
      height: "100%",
      paddingBottom: "10px",
    },
    reply: {
      maxWidth: "80%",
      wordBreak: "break-word",
      borderRadius: "15px",
      marginBottom: "2px",
      background: theme.palette.messages.lightOther,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
    },
    dateDivider: {
      fontSize: "12px",
      letterSpacing: ".5px",
      fontFamily: theme.typography.fontFamily,
      marginLeft: "-11px",
      marginBottom: "11px",
      marginTop: "6px",
    },
    ownReply: {
      background: theme.palette.primary.light,
    },
    closeReplies: {
      height: "10%",
    },

    repliesPanel: {
      width: "351px",
      overflowY: "hidden",
    },
    topDivider: {
      marginTop: "-20px",
      fontSize: "12px",
      letterSpacing: ".5px",
      fontFamily: theme.typography.fontFamily,
      marginLeft: "-11px",
    },
    numReplies: {
      marginTop: "0.25rem",
      marginLeft: "3rem",
    },
  };
});
