import { call, put, select } from "redux-saga/effects";

import api from "../../../../dataApi";

import * as actions from "../../../actions";
import { handleSagaError } from "../../sagas.utils";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const getMessages = function* ({ payload }) {
  try {
    const { room, pagination, gettingUnread } = payload;
    const roomId = payload.roomId || room.id;

    const messages = yield call(
      api.getMessages,
      roomId,
      pagination,
      gettingUnread
    );
    yield put(actions.addToMessageList({ roomId, temporalMessages: messages }));
    yield put(actions.setIsLoadingMessages(false));
    yield put(actions.getDraft({ roomId }));
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getMessagesUpToDate = function* ({ payload }) {
  try {
    const {
      room,
      pagination,
      message: { messageDate, id: messageId },
    } = payload;
    const messages = yield call(
      api.getMessagesUpToDate,
      room.id,
      pagination,
      messageDate
    );
    const count = messages.length;

    yield put(actions.addToMessageList({ temporalMessages: messages, room }));
    yield put(actions.setIsLoadingMessages(false));
    yield put(actions.getDraft({ roomId: room.id }));

    yield put(
      actions.setMessageToJumpTo({ beforeFetch: false, id: messageId, count })
    );
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const sendTextMessage = function* ({ payload }) {
  const { id } = yield select((state) => state.chat.user);
  const { text, room, promise, avatar } = payload;
  try {
    const temporalMessage = new api.mocks.Message({
      username: "You",
      userId: id,
      text,
      avatar,
    });

    yield put(actions.addMessage(temporalMessage));
    let msg;
    try {
      msg = yield call(api.sendMessage, "text", text, room.id);
    } catch (error) {
      yield put(
        actions.updateMessage({
          message: temporalMessage,
          properties: { status: "error" },
        })
      );
      throw error;
    }

    yield call(api.saveDraft, room.id, "", "");
    const newDraft = { room: room.id, text: "", messageId: "" };
    yield put(actions.addDraft(newDraft));

    yield put(
      actions.updateMessage({ message: temporalMessage, properties: msg })
    );

    yield call(promise.resolve);
    yield put(
      actions.setRoomProperties({
        room,
        properties: { lastUpdate: msg.createdAt },
      })
    );
    yield put(actions.updateRooms());
    yield put(actions.updateFriends());
  } catch (error) {
    yield handleSagaError(error);
    yield call(promise.reject, error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const sendScribbleMessage = function* ({ payload }) {
  const { scribble, room, promise } = payload;
  try {
    const msg = yield call(api.sendMessage, "scribble", scribble, room.id);
    promise.resolve();
    room.lastUpdate = msg.createdAt;
    yield put(actions.updateRooms());
    yield put(actions.updateFriends());
  } catch (error) {
    promise.reject(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const sendBotMessage = function* ({ payload }) {
  try {
    const { secret, room, form } = payload;
    yield call(api.sendBotMessage, secret, room.id, form);
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const answerBotMessage = function* ({ payload }) {
  try {
    const { message, room, answer } = payload;
    yield call(api.answerBotMessage, message.id, room.id, answer);
    const form = JSON.parse(message.form);
    form.answer = answer;
    yield put(
      actions.updateMessage({
        message,
        properties: {
          form: JSON.stringify(form),
        },
      })
    );
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const editReply = function* ({ payload }) {
  const { promise, replyId, text, threadingMessageId } = payload;
  try {
    yield call(api.editReply, replyId, text, threadingMessageId);
    promise.resolve();
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const editMessage = function* ({ payload }) {
  const { message, text, replying, promise } = payload;
  try {
    yield call(api.editMessage, message.id, text, replying);
    promise.resolve();
    //const editedAt = Date.now();
    const { room } = yield select((state) => state.chat);
    //const filteredRoom = rooms[rooms.findIndex((r) => r.id === room.id)];
    //room.lastUpdate = editedAt;
    //filteredRoom.lastUpdate = editedAt;

    yield call(api.saveDraft, room.id, "", "");
    const newDraft = { room: room.id, text: "", messageId: "" };
    yield put(actions.addDraft(newDraft));

    yield put(actions.setEditingMessage(null));
    yield put(actions.updateRooms());
    yield put(actions.updateFriends());
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const replyMessage = function* ({ payload }) {
  const { message, text, promise } = payload;
  try {
    yield call(api.replyMessage, message.id, text);
    promise.resolve();
    const editedAt = Date.now();
    const { room } = yield select((state) => state.chat);
    yield put(
      actions.setRoomProperties({ room, properties: { lastUpdate: editedAt } })
    );
    yield put(actions.updateRooms());
    yield put(actions.updateFriends());
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const readMessage = function* ({ payload }) {
  const { message, promise, reply } = payload;
  try {
    const updatedRoom = yield call(api.readMessage, message.id, reply?.id);

    // const { rooms } = yield select((state) => state.chat);
    // const filteredRoom = rooms[rooms.findIndex((r) => r.id === updatedRoom.id)];
    const { room } = yield select((state) => state.chat);

    yield put(
      actions.setRoomProperties({
        room,
        properties: {
          unreadMessages: updatedRoom.unreadMessages,
          dateOldestUnreadMessage: updatedRoom.dateOldestUnreadMessage,
        },
      })
    );

    yield put(actions.updateRooms());
    yield put(actions.updateFriends());

    // TODO check if this saga ever receives a promise
    if (promise) {
      yield call(promise.resolve);
    }
  } catch (error) {
    yield handleSagaError(error);
    if (promise) {
      yield call(promise.reject, error);
    }
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const deleteMessage = function* ({ payload }) {
  const { message, promise } = payload;
  try {
    if (message.status === "error") {
      return yield put(actions.removeMessage(message));
    }
    yield call(api.deleteMessage, message.id);
    promise.resolve();
    //const editedAt = Date.now();
    //const { rooms, room } = yield select((state) => state.chat);
    //const filteredRoom = rooms[rooms.findIndex((r) => r.id === room.id)];
    //room.lastUpdate = editedAt;
    //filteredRoom.lastUpdate = editedAt;
    yield put(actions.updateRooms());
    yield put(actions.updateFriends());
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const deleteReply = function* ({ payload }) {
  const { reply, repliedMessage, promise } = payload;
  try {
    yield call(api.deleteReply, reply.id, repliedMessage.id);

    promise.resolve();

    yield put(actions.updateRooms());
    yield put(actions.updateFriends());
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const sendFiles = function* ({ payload }) {
  try {
    const { fileList, room, setProgress } = payload;
    const { id, avatar } = yield select((state) => state.chat.user);

    const temporalMessages = [];
    for (const file of fileList) {
      const temporalMessage = new api.mocks.Message({
        username: "You",
        userId: id,
        avatar,
        messageType: "file",
        text: JSON.stringify({
          mimeType: "application/octet-stream",
          name: file.name,
          size: file.size,
        }),
      });
      temporalMessage.room = room;
      temporalMessages.push(temporalMessage);
      yield put(actions.addMessage(temporalMessage));
    }
    let i = 0;
    let lastMessage;
    for (const file of fileList) {
      try {
        const responseMessages = yield call(
          api.sendFiles,
          [file],
          room.id,
          setProgress
        );
        lastMessage = responseMessages[0];
        yield put(
          actions.updateMessage({
            message: temporalMessages[i],
            properties: {
              ...lastMessage,
              status: "sent",
            },
          })
        );
      } catch (error) {
        yield put(
          actions.updateMessage({
            message: temporalMessages[i],
            properties: { status: "error" },
          })
        );
        // yield put(actions.removeTemporalMessage(temporalMessages[i]));
        // const newTemporalMessage = { ...temporalMessages[i], status: "error" };
        // yield put(actions.addTemporalMessage(newTemporalMessage));
        // yield put(actions.addMessage(newTemporalMessage));
        yield handleSagaError(error);
      } finally {
        i++;
      }

      if (lastMessage) {
        yield put(
          actions.setRoomProperties({
            room,
            properties: { lastUpdate: lastMessage.createdAt },
          })
        );
        yield put(actions.updateRooms());
        yield put(actions.updateFriends());
      }
    }

    //yield call(api.sendFiles, files, room.id, setProgress);
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const getFile = function* ({ payload }) {
  try {
    const { file, setProgress } = payload;
    yield call(api.getFile, file, setProgress);
  } catch (error) {
    yield handleSagaError(error);
  }
};
