import { createAction } from "@reduxjs/toolkit";

export const channelCall = createAction("saga/channelCall");
export const endOngoingCall = createAction("saga/endOngoingCall");
export const startSharingScreen = createAction("saga/startSharingScreen");
export const stopSharingScreen = createAction("saga/stopSharingScreen");
export const cleanConferenceRequest = createAction(
  "saga/cleanConferenceRequest"
);

export const inviteUsersToCall = createAction("saga/inviteUsersToCall");

export const getRecordings = createAction("saga/getRecordings");
export const startRecording = createAction("saga/startRecording");
export const stopRecording = createAction("saga/stopRecording");
export const sendJitsiMessage = createAction("saga/sendJitsiMessage");
