import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  inputBase: {
    color: theme.palette.textGray.input,
    //height: "auto !important",

    "&::placeholder": {
      color: `${theme.palette.textGray.inputPlaceholder} !important`,
      opacity: 1,
    },
  },
  adornment: {
    marginRight: "0px",
  },
  input: {
    fontSize: "14px",
    padding: "0.375rem 0.75rem 0.375rem 0 !important",
    lineHeight: 1.5,
    borderRadius: "5px",
    border: `1px solid #ced4da`,
    boxSizing: "border-box",
    backgroundClip: "padding-box",
    // pick one?
    minHeight: "calc(1.5em + 0.75rem + 2px)",
    height: "calc(1.5em + 0.75rem + 2px)",
  },
  inputRoot: {
    "&$focused": {},
    "&$notchedOutline": {},
  },
  inputFocused: {
    backgroundColor: theme.palette.bgGray.sidebar,
  },
  inputNotchedOutline: {
    display: "none",
  },
}));
