import { takeEvery, put, call } from "redux-saga/effects";
import { format } from "date-fns"; //TODO change to datejs
import jwt from "jwt-decode";
import api from "../../../dataApi";
import * as actions from "../../actions";
import { actions as act } from "../../actions";
import { changeOrganizationChats } from "../../../dataApi/gql/mutations";

import { getMainQueryParam } from "../../../utils";

export default function* interceptor() {
  yield takeEvery(act.sagas.parseLink, parseLink);
  yield takeEvery(act.sagas.setShowingOrganizedAction, setShowingOrganized);
  yield takeEvery(
    act.sagas.updateIsOneToOneChatsOpen,
    updateIsOneToOneChatsOpen
  );
  yield takeEvery(act.sagas.updateIsGroupChatsOpen, updateIsGroupChatsOpen);
}

const setShowingOrganized = function* ({ payload }) {
  yield call(changeOrganizationChats, payload.organizedChats);
};

const updateIsOneToOneChatsOpen = function* ({ payload }) {
  const isOpen = yield call(api.updateIsOneToOneChatsOpen, payload);
  yield put(actions.setIsOneToOneChatsOpen(isOpen));
};

const updateIsGroupChatsOpen = function* ({ payload }) {
  const isOpen = yield call(api.updateIsGroupChatsOpen, payload);
  yield put(actions.setIsGroupChatsOpen(isOpen));
};

const parseLink = function* () {
  // Normal link
  try {
    // const { promise } = payload;
    const params = getMainQueryParam();

    if (!params) {
      yield put(act.sagas.handshake());
    } else {
      // Custom link
      //TODO find a way to make this secure. Send the signed JWT back to the sever for verification
      const data = jwt(params);
      switch (data.type) {
        case "medical":
        case "guest":
          yield put(act.general.setGeneric(data));
          yield put(act.general.setPage("guestAuth"));
          break;
        case "recovery":
          yield put(
            act.general.setGeneric({
              id: data.id,
              params,
            })
          );
          yield put(act.general.setPage("recoverPassword"));
          break;
        default:
          throw new Error("Invalid link");
      }
    }
    // promise.resolve();
  } catch (error) {
    let message;
    switch (error.message) {
      case "jwt expired":
        message = "Link expired";
        break;
      case "jwt malformed":
        message = "Invalid link";
        break;
      case "jwt not active":
        message = `Link not useful until ${format(
          error.date,
          "d LLL, h:mm a"
        )}`;
        break;
      default:
        message = `${error}`;
    }
    yield put(act.general.setPage("error"));
    yield put(act.general.setGeneric(message));
  }
};
