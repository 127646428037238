import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SearchedUsers from "./SearchedUsers.component";

import { resetSearchedUsers } from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { searchedUsers } = state.chat;

  return { users: searchedUsers };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetSearchedUsers,
    },
    dispatch
  );

const SearchedUsersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchedUsers);

export default SearchedUsersContainer;
