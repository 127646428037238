import getOffscreenCanvas from "./getOffscreenCanvas";

/**
 * @callback callback
 * @param {CanvasRenderingContext2D} ctx
 * @return {void}
 */

/**
 * Takes several draws to ummounted optimized canvas
 * @param {CanvasRenderingContext2D} ctx ctx of the canvas where you want the drawing
 * @param {callback} callback Callback to execute all the drawing operations
 */

export const drawFromOffscreenCanvas = function (ctx, callback) {
  const {
    canvas: { width, height },
  } = ctx;
  const offScreenCanvas = getOffscreenCanvas(width, height);
  const offScreenCtx = offScreenCanvas.getContext("2d");
  callback(offScreenCtx);
  ctx.drawImage(offScreenCanvas, 0, 0);
};

export default drawFromOffscreenCanvas;
