import { gql } from "../../../utils/apollo";

export const changeAvatar = gql`
  mutation ChangeAvatar($avatar: String) {
    updateUser(input: { avatar: $avatar }) {
      avatar
    }
  }
`;

export default changeAvatar;
