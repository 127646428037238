import { gql } from "../../../utils/apollo";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getGroupRooms = gql`
  query GetGroupRooms {
    getGroupRooms {
      id
      isPublic
      group
      title
      admin
      meetingCode
      kind
      lastUpdate
      lastMessage
      dateOldestUnreadMessage
      category {
        id
      }
      messagesWithReplies {
        id
        messageType
        scribble
        owner {
          id
          username
          avatar
        }
        text
        createdAt
        lastReplyDate
        status
        readers {
          id
          avatar
          username
        }
        replies {
          id
          messageType
          scribble
          owner {
            id
            username
            avatar
          }
          text
          createdAt
          status
          readers {
            id
            avatar
            username
          }
        }
        unreadReplies
      }
      unreadMessages {
        total
        replies
      }
      users {
        id
      }
      draft {
        room
        message
        text
      }
    }
  }
`;

export default getGroupRooms;
