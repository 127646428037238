import { makeStyles } from "@mui/styles";

const width = "0.25em";
const importantWidth = `${width} !important`;

export default makeStyles(() => ({
  root: {
    width: importantWidth,
    height: "100%",
  },
  slider: {
    width: importantWidth,
    borderRadius: `${width} ${width} 0 0`,
  },
}));
