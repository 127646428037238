import clsx from "clsx";
import { TooltipIconButton } from "../../prestyledComponents";
import useStyles from "./WorkspacesButton.styles";
import { useMounted } from "../../../hooks/useMounted";

import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";
import { useState } from "react";
import { setRoom } from "../../../store/slices/chat/chatReducers";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const WorkspacesButton = function ({
  wsData,
  currentWorkspace,
  setCurrentWorkspace,
  setCategories,
  getCategories,
  ...props
}) {
  const mounted = useMounted();
  const classes = useStyles();
  const { className } = props;
  const [processing, setProcessing] = useState(false);
  const handleClick = async () => {
    if (currentWorkspace?.id !== wsData.id) {
      // setCategories([])
      // setRoom(null)
      setCurrentWorkspace(wsData);
      // try {
      //   setProcessing(true);

      //   await getBoundActionPromise(getCategories, {workspaceId: wsData.id });

      // } catch (error) {
      //   logError(error);
      // } finally {
      //   if (mounted.current) {
      //     setProcessing(false);
      //   }
      // }
    }
  };

  return (
    <>
      <TooltipIconButton
        {...props}
        className={clsx(className, classes.selected)}
        onClick={handleClick}
        title={wsData.title}
      >
        {/*         
        <Mui.Badge
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          badgeContent={
            <Mui.Icon className={classes.badgeIcon}>
              <Icons.Hash/>
            </Mui.Icon>
          }
        > */}
        {wsData.title[0]}
        {/* </Mui.Badge> */}
      </TooltipIconButton>
    </>
  );
};

export default WorkspacesButton;
