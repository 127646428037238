import { gql } from "../../../utils/apollo";

export const replyMessage = gql`
  mutation ReplyMessage($message_id: ID, $text: String) {
    replyMessage(input: { message_id: $message_id, text: $text }) {
      null
    }
  }
`;

export default replyMessage;
