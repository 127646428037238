import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import { Alert } from "@mui/material";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

import { Card } from "../../pseudoInputs";
import {
  CircularProgressIcon,
  LinearSubmitButton,
} from "../../prestyledComponents";
import { useMounted } from "../../../hooks/useMounted";

import { WithStripe } from "../../../stripe";
import { formatUsdCents } from "../../../utils/formatUsdCents";
import { getBoundActionPromise } from "../../utils/getBoundActionPromise";

import useStyles from "./CheckoutForm.styles";

export const CheckoutForm = WithStripe(function ({
  clientSecret,
  retrievePaymentIntent,
  confirmCardPayment,
  onSubmit,
}) {
  const mounted = useMounted();
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);

  const [retrievementError, setRetrievementError] = useState(null);
  const [paymentError, setPaymentError] = useState(null);

  const [paymentIntent, setPaymentIntent] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const classes = useStyles();

  const shouldSubmit = useMemo(
    () =>
      !disabled &&
      !processing &&
      !success &&
      !retrievementError &&
      !!paymentIntent &&
      !!stripe &&
      !!elements,
    [
      disabled,
      processing,
      success,
      retrievementError,
      paymentIntent,
      stripe,
      elements,
    ]
  );

  useEffect(() => {
    if (!!stripe && !paymentIntent) {
      updatePaymentData();
    }
    // eslint-disable-next-line
  }, [stripe]);

  const updatePaymentData = async function () {
    try {
      setRetrievementError(null);
      setPaymentIntent(null);
      const retrieved = await getBoundActionPromise(retrievePaymentIntent, {
        stripe,
        clientSecret,
      });
      if (mounted.current) {
        if (retrieved.status === "succeeded") setSuccess(true);
        setPaymentIntent(retrieved);
      }
    } catch (error) {
      setRetrievementError(error);
    }
  };

  const handleSubmit = async function (event) {
    event.preventDefault();
    if (!shouldSubmit) return;
    try {
      setProcessing(true);
      await getBoundActionPromise(confirmCardPayment, {
        stripe,
        clientSecret,
        options: {
          payment_method: {
            card: elements.getElement(Card.element),
          },
        },
      });
      if (onSubmit) onSubmit(event);
      if (mounted.current) {
        setSuccess(true);
      }
    } catch (error) {
      if (mounted.current) {
        setPaymentError(error);
      }
    } finally {
      if (mounted.current) {
        setProcessing(false);
      }
    }
  };

  const handleChange = async function (event) {
    const { empty, error, complete } = event;
    setDisabled(!!empty || !!error || !complete);
  };

  const renderPaymentIntent = function () {
    if (retrievementError)
      return (
        <Alert
          variant="filled"
          severity="error"
          children={retrievementError.message}
        />
      );
    if (!paymentIntent)
      return (
        <Mui.ListItem>
          <Mui.ListItemIcon>
            <CircularProgressIcon />
          </Mui.ListItemIcon>
          <Mui.ListItemText primary="Loading payment data..." />
        </Mui.ListItem>
      );
    let status = "Pending";
    if (success || paymentIntent.status === "succeeded") status = "Paid";
    if (paymentIntent.status === "processing") status = "In process";
    return (
      <>
        <Mui.ListItem selected>
          <Mui.ListItemIcon>
            <Mui.Icon color="primary">
              <Icons.DollarSign />
            </Mui.Icon>
          </Mui.ListItemIcon>
          <Mui.ListItemText
            primary={formatUsdCents(paymentIntent.amount, {
              currencyDisplay: "name",
            })}
            secondary={paymentIntent.description}
          />
        </Mui.ListItem>
        <Mui.ListItem selected>
          <Mui.ListItemText primary={`Status: ${status}`} />
        </Mui.ListItem>
      </>
    );
  };

  const renderPaymentAlert = function () {
    if (success)
      return (
        <Alert
          variant="filled"
          severity="success"
          children="Payment successfull"
        />
      );
    if (processing)
      return (
        <Alert
          variant="filled"
          severity="info"
          icon={<CircularProgressIcon className={classes.processingProgress} />}
          children="Processing payment..."
        />
      );
    if (paymentError)
      return (
        <Alert
          variant="filled"
          severity="error"
          children={paymentError.message}
        />
      );
  };

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid item>{renderPaymentIntent()}</Grid>
        <Grid item>{renderPaymentAlert()}</Grid>
        {!!success || !paymentIntent || !stripe || !elements ? null : (
          <Grid
            item
            container
            direction="column"
            spacing={1}
            component="form"
            onSubmit={handleSubmit}
          >
            <Grid item>
              <Card onChange={handleChange} />
            </Grid>
            <Grid item>
              <LinearSubmitButton
                disabled={!shouldSubmit}
                loading={processing}
                children="Pay"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Mui.Backdrop open={processing} className={classes.backdrop} />
    </>
  );
});

export default CheckoutForm;

CheckoutForm.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  retrievePaymentIntent: PropTypes.func.isRequired,
  confirmCardPayment: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};
