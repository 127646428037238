import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { stringAvatar } from "../../../utils/defaultAvatar";
import { hightLight } from "../../utils";

import clsx from "clsx";
import useStyles from "./FoundContact.styles";
import { StatusIndicator } from "../SidebarItem";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const FoundContact = function ({
  contact,
  searchedValue,
  setIsResultsPanelOpen,
  setProfileOpen,
  setRoom,
  status,
  user,
}) {
  const classes = useStyles();
  const [online, setOnline] = useState(contact.online);
  //const online = contact.online;

  useEffect(() => {
    setOnline(status);
  }, [status]);

  const handleClick = () => {
    setRoom(contact.room);
    setIsResultsPanelOpen(false);
  };

  const handleViewProfile = (event) => {
    event.stopPropagation();
    setProfileOpen(contact);
  };

  return (
    <Mui.Card variant="outlined" onClick={handleClick}>
      <Mui.CardActionArea>
        <Mui.CardContent>
          <Mui.Box className={classes.contactContainer}>
            <Mui.ListItemAvatar
              classes={{
                root: clsx(classes.avatar),
              }}
            >
              <StatusIndicator status={online}>
                <Mui.Avatar
                  src={contact.avatar}
                  //classes={{ root: classes.avatar }}
                  {...stringAvatar(contact.username)}
                />
              </StatusIndicator>
            </Mui.ListItemAvatar>
            <Mui.ListItem disableGutters>
              {hightLight(contact.username, searchedValue, classes.foundText)}
              {contact.id === user.id && (
                <span className={classes.isYouLabel}>(You)</span>
              )}
            </Mui.ListItem>
          </Mui.Box>
        </Mui.CardContent>
      </Mui.CardActionArea>
      <Mui.Button
        classes={{ root: classes.muiButton }}
        onClick={handleViewProfile}
      >
        View Profile
      </Mui.Button>
    </Mui.Card>
  );
};

export default FoundContact;
