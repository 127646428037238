// eslint-disable-next-line
import HandtrackWorker from "worker-loader!./handtrack.worker.js";
import { asyncPostMessage, getImageDataFromMedia } from "../../../utils";

export class HandtrackWebWorkerModel {
  #worker = null;

  constructor() {
    this.#worker = new HandtrackWorker();
  }

  async init(params) {
    const worker = this.#worker;
    await asyncPostMessage(worker, { type: "init", params });
  }

  async predict(video) {
    const worker = this.#worker;
    const media = getImageDataFromMedia(video);
    return await asyncPostMessage(worker, { type: "predict", media });
  }

  async dispose() {
    const worker = this.#worker;
    await asyncPostMessage(worker, { type: "dispose" });
    worker.terminate();
    this.#worker = null;
  }

  async getFPS() {
    const worker = this.#worker;
    return await asyncPostMessage(worker, { type: "fps" });
  }
}

export default HandtrackWebWorkerModel;
