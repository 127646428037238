import { createAction } from "@reduxjs/toolkit";

export const startUserSubscription = createAction("saga/startUserSubscription");

export const startFriendSubscription = createAction(
  "saga/startFriendSubscription",
  (friend: any) => {
    return { payload: friend };
  }
);

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const startRoomSubscription = createAction(
  "saga/startRoomSubscription",
  (room: any) => {
    return { payload: room };
  }
);

export const startWorkspaceSubscription = createAction(
  "saga/startWorkspaceSubscription",
  (workspace: any) => {
    return { payload: workspace };
  }
);

export const cancelUserSubscription = createAction(
  "saga/cancelUserSubscription"
);

export const cancelFriendSubscription = createAction(
  "saga/cancelFriendSubscription",
  (friend: any) => {
    return { payload: friend };
  }
);

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const cancelRoomSubscription = createAction(
  "saga/cancelRoomSubscription",
  (room: any) => {
    return { payload: room };
  }
);

export const cancelWorkspaceSubscription = createAction(
  "saga/cancelWorkspaceSubscription",
  (workspace: any) => {
    return { payload: workspace };
  }
);
