import { groupLog } from "../utils";

const electron = window.electron;

const fallback = function (...args) {
  const error = new Error("Electron not supported in this device");
  if (!args.length) throw error;
  groupLog("Electron args", () => {
    for (const arg of args) console.log(arg);
  });
  throw error;
};

export const isSupported = !!electron;

// All of the functions below should be called after confirmation with "isSupported" and in a try/catch block

/**
 * @callback callback
 */

/**
 * @typedef Notification
 * @type {Object}
 */
/**
 * @type {(notification: Notification) => void}
 */
export const showNotification = isSupported
  ? electron.showNotification
  : fallback;

/**
 * @typedef ElectronMessage
 * @type {Object}
 */
/** @type {(message: ElectronMessage) => any} */
export const showMessageBox = isSupported ? electron.showMessageBox : fallback;

/** @type {(contents: number) => void} */
export const setAppBadge = isSupported ? electron.setAppBadge : fallback;

/** @type {(callback: callback) => any} */
export const onMessage = isSupported ? electron.onMessage : fallback;

/** @type {(status: boolean) => void} */
export const blinkingIconSwitch = isSupported
  ? electron.blinkingIconSwitch
  : fallback;
