import client from "../../../client";
import mutation from "./kickUserFromWorkspace.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} usernameId
 * @param {string} workspaceId
 */
export const kickUserFromWorkspace = async function (userId, workspaceId) {
  userId = addBearer(userId);
  workspaceId = addBearer(workspaceId);
  await client.mutate({
    mutation,
    variables: {
      input: {
        userId,
        workspaceId,
      },
    },
  });
};

export default kickUserFromWorkspace;
