export function clear(state: any[], payload: any): any[] {
  return [];
}

type IdPayloadType = {
  id: string;
};

const invalidPayload = function (payload: any): boolean {
  if (payload === undefined || payload.id === undefined) {
    return true;
  }
  return false;
};

export function get(
  state: IdPayloadType[],
  payload: IdPayloadType
): IdPayloadType | null {
  if (invalidPayload(payload)) {
    return null;
  }
  const { id } = payload;
  const foundItem = state.find((item) => item.id === id);
  return foundItem;
}

export function add(
  state: IdPayloadType[],
  payload: IdPayloadType
): IdPayloadType[] {
  if (invalidPayload(payload)) {
    return state;
  }
  const newArray = [...state, payload];
  return newArray;
}

export function addMany(
  state: IdPayloadType[],
  payload: IdPayloadType[] = []
): IdPayloadType[] {
  if (payload.some((item) => invalidPayload(item))) {
    return state;
  }
  return [...state, ...payload];
}

export function remove(
  state: IdPayloadType[],
  payload: IdPayloadType
): IdPayloadType[] {
  if (invalidPayload(payload)) {
    return state;
  }
  const { id } = payload;
  return state.filter((item) => item.id !== id);
}

export function updateItem(
  state: IdPayloadType[],
  payload: {
    item: IdPayloadType;
    props: {
      [key: string]: any;
    };
  }
): IdPayloadType[] {
  if (!payload) {
    return state;
  }
  const { item: payloadItem, props } = payload;
  if (invalidPayload(payloadItem) || !props) {
    return state;
  }
  const { id } = payloadItem;
  const index = state.findIndex((item) => item.id === id);
  if (index < 0) return state;
  const updatedItem = { ...state[index], ...props };
  const newArray = [...state];
  newArray[index] = updatedItem;
  return newArray;
}
