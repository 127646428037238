import client from "../../../client";
import mutation from "./addUserToRoom.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} roomId Id of the room to which the user will be added
 * @param {string} userId Id of the user who will be added
 * @param {string} userRoomId Id of the individual room with the user
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const addUsersToRoom = async function (usersIds, userRoomsIds, roomId) {
  roomId = addBearer(roomId);
  usersIds = usersIds.map(addBearer);
  userRoomsIds = userRoomsIds.map(addBearer);
  await client.mutate({
    mutation,
    variables: {
      usersIds,
      userRoomsIds,
      roomId,
    },
  });
};

export default addUsersToRoom;
