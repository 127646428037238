import React, { useState } from "react";
import * as Mui from "@mui/material";
import useStyles from "./TimeSelector.styles";
import DateSelector from "../DateSelector";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { getDateRange } from "../../../utils";

export const TimeSelector = function ({
  setOnSelectorOpen,
  date,
  setDate,
  setFilters,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dateSelector, setDateSelector] = useState("");
  const [idString, setIdString] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handlePreset = (e) => {
    const id = e.target.id;
    const title = e.target.innerText;
    setDate(title);
    const { before, after } = getDateRange(id);
    setFilters({ before, after });
    handleClose();
  };

  const handleManualSet = (e) => {
    const title = e.target.innerText.slice(0, -3);
    handleClose();
    setDateSelector(title);
    switch (title) {
      case "On":
        setIdString("0,1,1");
        break;
      case "Before":
        setIdString("-∞,1,1");
        break;
      case "After":
        setIdString("0,∞,1");
        break;
      case "Range":
        setIdString("0,1,2");
        break;

      default:
        break;
    }
    setOnSelectorOpen(true);
  };

  return (
    <Mui.Box>
      <Mui.Typography>Date</Mui.Typography>
      <Mui.FormControl fullWidth>
        <Mui.Button
          id="demo-simple-select"
          variant="outlined"
          endIcon={<ExpandMoreOutlinedIcon />}
          onClick={handleOpen}
          className={classes.inputBase}
        >
          {date}
        </Mui.Button>
      </Mui.FormControl>
      <Mui.Modal open={open} onClose={handleClose}>
        <Mui.Box className={classes.modalElements}>
          <Mui.Box onClick={handlePreset}>
            <Mui.MenuItem classes={{ root: classes.rootItem }} id={"-∞,∞,0"}>
              Any time
            </Mui.MenuItem>
            <Mui.MenuItem classes={{ root: classes.rootItem }} id={"0,1,0"}>
              Today
            </Mui.MenuItem>
            <Mui.MenuItem classes={{ root: classes.rootItem }} id={"-1,0,0"}>
              Yesterday
            </Mui.MenuItem>
            <Mui.MenuItem classes={{ root: classes.rootItem }} id={"-7,1,0"}>
              Last 7 days
            </Mui.MenuItem>
            <Mui.MenuItem classes={{ root: classes.rootItem }} id={"-30,1,0"}>
              Last 30 days
            </Mui.MenuItem>
            <Mui.MenuItem classes={{ root: classes.rootItem }} id={"-90,1,0"}>
              Last 3 months
            </Mui.MenuItem>
            <Mui.MenuItem classes={{ root: classes.rootItem }} id={"-365,1,0"}>
              Last 12 months
            </Mui.MenuItem>
          </Mui.Box>
          <Mui.Divider variant="middle" />
          <Mui.Box onClick={handleManualSet}>
            <Mui.MenuItem classes={{ root: classes.rootItem }}>
              On...
            </Mui.MenuItem>
            <Mui.MenuItem classes={{ root: classes.rootItem }}>
              Before...
            </Mui.MenuItem>
            <Mui.MenuItem classes={{ root: classes.rootItem }}>
              After...
            </Mui.MenuItem>
            <Mui.MenuItem classes={{ root: classes.rootItem }}>
              Range...
            </Mui.MenuItem>
          </Mui.Box>
        </Mui.Box>
      </Mui.Modal>
      <DateSelector title={dateSelector} id={idString} />
    </Mui.Box>
  );
};

export default TimeSelector;
