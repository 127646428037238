import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  item: {
    padding: "6px 13px",
    borderRadius: "7px",
  },

  containerInfo: {
    fontSize: 15,
    fontFamily: "'Inter', sans-serif",
    fontWeight: "600",
    marginBottom: "10px",
  },

  creationDate: {
    fontSize: 14,
    fontWeight: "400",
  },
  otherMessage: {
    padding: 0,
    paddingTop: "2px",
    marginBottom: "-4px",
    marginTop: "4px",
  },

  date: {
    fontSize: 12,
    fontStyle: "italic",
    marginTop: "2px",
    color: theme.palette.messages.time,
    lineHeight: 1.5,
  },

  adminMessage: {
    backgroundColor: theme.palette.messages.lightOther,
    color: theme.palette.common.black,
    opacity: 0.8,
    "&:last-of-type": {
      marginBottom: "1rem",
    },
  },
  content: {
    maxWidth: `${(100 * 4) / 5}%`,
    padding: 0,
    marginRight: "-11px",
  },

  messageWidthNoPanel: {
    maxWidth: "40vw",
  },
  messageWidthPanel: {
    maxWidth: "20vw",
  },

  otherText: {
    color: "#393939",
    width: "max-content",
    paddingRight: "20px",
    borderRadius: "7px",
  },

  ownBackground: { backgroundColor: theme.palette.primary.light },
  otherBackground: { backgroundColor: theme.palette.messages.lightOther },

  editedLabel: {
    fontSize: "0.85em",
    color: "gray",
  },

  text: {
    fontSize: 14,
    fontWeight: 400,
  },
  messageInfo: {
    padding: 0,
    marginBottom: "0px",
  },

  messageContentPadding: {
    padding: "0px",
  },
  textMessageBody: {
    margin: 0,
    marginRight: "4px",
    wordBreak: "break-word",
  },

  messageHeader: {
    fontSize: "12px",
    color: "#656566",
    fontWeight: 500,
    fontFamily: "Inter, sans-serif",
    marginBottom: "5px",
  },
  avatar: {
    marginRight: "-8px",
  },

  usernameStyle: {
    textTransform: "capitalize",
  },
  secondLevel: {
    display: "flex",
  },

  ownTextContainer: {},
  foundText: {
    backgroundColor: theme.palette.messages.highlightedBg,
    //fontWeight: "500",
    border: `1px solid ${theme.palette.messages.highlightedBg}`,
  },
}));
