import { getLinePath, calcAngle } from "../../../utils";
import { isInsideBbox, parseBoundingBox } from "../handTracking.utils";

// eslint-disable-next-line
import { HandType, BoundingBoxType } from "../handTracking.utils";
// eslint-disable-next-line
import {
  SwipeArgsType,
  SwipePathType,
  onSideType,
} from "./PagingTracker.typeDefs";

/** @type {SwipeArgsType} */
const defaultSwipeArgs = {
  deltaX: 0,
  deltaY: 0,
  angle: 0,
};

export class PagingTracker {
  /** @type {BoundingBoxType} */
  #start = {};

  /** @type {number} */
  #lostFrames = 0;

  /** @type {SwipePathType} */
  #swipePath = {
    path: null,
    swipeArgs: { ...defaultSwipeArgs },
  };

  /** @type {number} */
  time;

  /** @type {boolean} */
  #fromLeft;
  /** @type {number} */
  #leftStartTime;

  /** @type {boolean} */
  #fromRight;
  /** @type {number} */
  #rightStartTime;

  /** @type {onSideType} */
  onLeft;

  /** @type {onSideType} */
  onRight;

  /**
   * @param {Object} options
   * @param {number} options.time
   * @param {number} options.maxFrameLoss
   * @param {onSideType} options.onLeft
   * @param {onSideType} options.onRight
   */
  constructor({ time = 1500, maxFrameLoss = 0, onLeft, onRight } = {}) {
    this.time = time;
    this.maxFrameLoss = maxFrameLoss;
    this.onLeft = onLeft;
    this.onRight = onRight;
    // ---
    this.reset();
  }

  /**
   * @private
   * @param {CanvasImageSource} media
   * @param {BoundingBoxType} end
   * @returns {SwipeArgsType}
   */
  _getSwipeArgs(media, end) {
    const start = this.#start;
    const deltaX = Math.floor((100 / media.width) * Math.abs(start.x - end.x));
    const deltaY = Math.floor((100 / media.height) * Math.abs(start.y - end.y));
    const angle = calcAngle(deltaX, deltaY);
    const swipeArgs = { start, end, deltaX, deltaY, angle };
    return swipeArgs;
  }

  // public
  reset() {
    this.#start = {};
    this.#swipePath = {
      path: null,
      swipeArgs: { ...defaultSwipeArgs },
    };
    this.#lostFrames = 0;
    this.#fromLeft = false;
    this.#fromRight = false;
    this.#leftStartTime = 0;
    this.#rightStartTime = 0;
  }

  /**
   * @param {Object} predictions
   * @param {CanvasImageSource} predictions.media
   * @param {Array<HandType>} predictions.point
   * @param {Object} areas
   * @param {BoundingBoxType} areas.leftIn
   * @param {BoundingBoxType} areas.leftOut
   * @param {BoundingBoxType} areas.rightIn
   * @param {BoundingBoxType} areas.rightOut
   * @param {BoundingBoxType} areas.bbox
   * @returns {[HandType?, SwipePathType, boolean, boolean]}
   */
  handlePredictions(
    { point, media },
    { leftIn, leftOut, rightIn, rightOut, bbox }
  ) {
    const swipeHands = point;
    const currentSwipe = swipeHands[0];
    const swipePath = this.#swipePath;
    const fromLeft = this.#fromLeft;
    const fromRight = this.#fromRight;
    if (swipeHands.length === 1) {
      const swipingPoint = parseBoundingBox(currentSwipe.bbox);
      const currentPoint = {
        x: swipingPoint.x,
        y: swipingPoint.top,
      };
      const isOnLeftOut = isInsideBbox(currentPoint, leftOut);
      const isOnLeftIn = isInsideBbox(currentPoint, leftIn);
      const isOnRightIn = isInsideBbox(currentPoint, rightIn);
      const isOnRightOut = isInsideBbox(currentPoint, rightOut);
      if (!isOnLeftOut && !isOnLeftIn && !isOnRightIn && !isOnRightOut) {
        this.reset();
      } else if (fromLeft || fromRight) {
        // continuing swipe
        this.#lostFrames = 0;
        const start = this.#start;
        if (fromLeft || fromRight) {
          swipePath.path = getLinePath(start, currentPoint);
        } else {
          swipePath.path = null;
        }
        // eslint-disable-next-line

        const swipeArgs = this._getSwipeArgs(media, currentPoint);
        if (fromLeft && isOnRightOut) {
          this.onRight(swipeArgs);
          this.reset();
        } else if (fromRight && isOnLeftOut) {
          this.onLeft(swipeArgs);
          this.reset();
        }
        swipePath.swipeArgs = swipeArgs;
      } else {
        // starting swipe
        if (isOnLeftIn || isOnRightIn) {
          // todo: Use some timed lock
          if (isOnLeftIn) {
            if (this.#rightStartTime) {
              // Changed side, reset
              this.reset();
            } else if (!this.#leftStartTime) {
              // Start locking
              this.#leftStartTime = Date.now();
            } else if (this.time < Date.now() - this.#leftStartTime) {
              // Lock reached, start
              this.#fromLeft = true;
              this.#start = {
                x: bbox.left,
                y: currentPoint.y,
              };
            }
          }
          if (isOnRightIn) {
            if (this.#leftStartTime) {
              // Changed side, reset
              this.reset();
            } else if (!this.#rightStartTime) {
              // Start locking
              this.#rightStartTime = Date.now();
            } else if (this.time < Date.now() - this.#rightStartTime) {
              // Lock reached, start
              this.#fromRight = true;
              this.#start = {
                x: bbox.right,
                y: currentPoint.y,
              };
            }
          }
        }
      }
    } else {
      if (fromLeft || fromRight) {
        // Lost frame while swiping
        this.#lostFrames++;
        if (this.maxFrameLoss < this.#lostFrames) {
          // too many frames lost, finish swipe
          this.reset();
        }
        // else {
        //     // skip frame, continue swipe
        // };
      }
      // else {
      //     // Just doing nothing
      // };
    }
    return [currentSwipe, this.#swipePath, this.#fromLeft, this.#fromRight];
  }
}

export default PagingTracker;
