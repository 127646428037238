import env from "./env";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { disableExitsOnBack } from "./utils";

import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";
import api from "./dataApi";
import store from "./store";
import { theme } from "./style";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//TODO remove this in favor of Mui Themes
// import "./index.css";

if (env.DEVELOPMENT) {
  api.logs(true);
  // api.mock(true);
}
const root = createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <ErrorBoundary>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <App />
        </LocalizationProvider>
      </ErrorBoundary>
    </Provider>
  </ThemeProvider>
);

disableExitsOnBack();

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess(registration) {
    console.log("Service worker updated");
  },
  async onUpdate(registration) {
    await registration.unregister();
    console.log("Service worker update found");
    alert("Update available, refresh to enable");
  },
});
