import { gql } from "../../../utils/apollo";

export const pingStatus = gql`
  mutation pingStatus {
    pingStatus {
      null
    }
  }
`;

export default pingStatus;
