import React, { useEffect, useState, useRef } from "react";
import * as Mui from "@mui/material";
import { VerticalScrollbars } from "../../prestyledComponents";
import FoundMessage from "../../collectionItems/FoundMessage";
import PaginationControls from "../../PaginationControls";
import FoundItemsLoading from "../FoundItemsLoading";

import useStyles from "./FoundMessages.styles";

const scrollBarsStyles = { padding: "30px", height: "calc(100vh - 140px)" };

export const FoundMessages = function ({
  messages,
  resultsPerPage,
  totalMessages,
  searchedValue,
  searchPageAndSection,
}) {
  const count = Math.ceil(totalMessages / resultsPerPage);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const ref = useRef();

  const handleChange = (_, value) => {
    setPage(value);
    setIsLoadingMessages(true);
    ref.current.scrollTop = 0;
    searchPageAndSection({ searchedValue, page: value, section: ["Messages"] });
  };

  useEffect(() => {
    setIsLoadingMessages(false);
  }, [messages]);

  /**
   * Renders the found messages.
   * @returns {React.ReactElement} List with the messages.
   */
  const renderMessages = function () {
    if (!messages.length)
      return (
        <Mui.ListItem>
          <Mui.ListItemText primary="No results" />
        </Mui.ListItem>
      );

    return (
      <Mui.Box>
        {isLoadingMessages ? (
          <FoundItemsLoading style={{ padding: "0px", marginTop: "-8px" }} />
        ) : (
          <>
            {messages.map((msg, index) => (
              <FoundMessage
                key={`found-message-${index}`}
                message={msg}
                position={"unique"}
              />
            ))}
          </>
        )}
      </Mui.Box>
    );
  };

  return (
    <VerticalScrollbars
      viewStyle={scrollBarsStyles}
      ref={(s) => (ref.current = s && s.view)}
    >
      <Mui.List className={classes.messagesContainer}>
        {renderMessages()}
        {count > 1 && (
          <PaginationControls
            count={count}
            page={page}
            handleChange={handleChange}
            disabled={isLoadingMessages}
          />
        )}
      </Mui.List>
    </VerticalScrollbars>
  );
};

export default FoundMessages;
