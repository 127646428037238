// eslint-disable
import React, { createRef, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { useAvatarImageDataUrl } from "../../../hooks/index";

import {
  isInsideBbox,
  parseBoundingBox,
} from "../../../imageProcessing/handTracking";
import * as canvasUtils from "../../../utils/canvas";
import { createElement } from "../../../utils/createElement";

import useStyles from "./LocalVideo.styles";

// eslint-disable-next-line
import { BoundingBoxType } from "../../../imageProcessing/handTracking";
// eslint-disable-next-line
import { Props } from "./LocalVideo.propTypes";

/**
 * @param {Props} props
 */
export const LocalVideo = function (props) {
  const {
    localVideoTrackReady,
    videoEffects,
    jitsiRegisterVideoComponent,
    jitsiUnregisterVideoComponent,
  } = props;
  /** @type {React.RefObject<HTMLVideoElement>} */
  const ref = createRef(null);

  const poster = useAvatarImageDataUrl();

  const classes = useStyles();

  useEffect(() => {
    const videoComponent = ref.current;
    if (localVideoTrackReady) {
      jitsiRegisterVideoComponent(videoComponent);
      return () => {
        jitsiUnregisterVideoComponent();
      };
    }
  }, [localVideoTrackReady]);

  useEffect(() => {
    const slidesEffect = videoEffects.handtrackEffect || {};
    if (localVideoTrackReady) {
      const video = ref.current;
      if (!videoEffects.handtrackActive) {
        slidesEffect.onDraw = null;
        slidesEffect.onEdge = null;
        slidesEffect.onLockChange = null;
        slidesEffect.onLockForced = null;
      } else {
        const canvas = createElement("canvas");
        const canvasCtx = canvas.getContext("2d");
        let canvasResizePending = true;
        video.srcObject = canvas.captureStream();
        const lineWidth = 5;
        const lineDash = [1, 8];

        let swipeOverflowPath = null;

        const lock = {
          locked: false,
          show: false,
          blink() {
            this.show = true;
            setTimeout(() => (this.show = false), 2000);
          },
        };

        /**
         * @param {boolean} locked
         */
        slidesEffect.onLockChange = function (locked) {
          lock.locked = locked;
          lock.blink();
        };

        slidesEffect.onLockForced = function () {
          lock.blink();
        };

        /**
         * @param {BoundingBoxType} imgBox
         */
        slidesEffect.onEdge = function (imgBox) {
          const radius = Math.min(imgBox.height, imgBox.width) / 3;
          const side = radius * Math.cos(Math.PI / 4);
          const path = canvasUtils.getCirclePath({
            ...imgBox,
            radius,
          });
          path.moveTo(imgBox.x + side, imgBox.y - side);
          path.lineTo(imgBox.x - side, imgBox.y + side);
          swipeOverflowPath = path;
          setTimeout(() => (swipeOverflowPath = null), 2000);
        };

        /**
         * @param {Object} predictions
         * @param {Object} drawingsObject
         * @param {HTMLCanvasElement} drawingsObject.canvas
         * @param {BoundingBoxType} drawingsObject.imgBox
         */
        slidesEffect.onDraw = function (
          { pickHand, swipeHand, swipePath, lockHand, lockValue },
          { canvas: effectCanvas, imgBox }
        ) {
          if (canvasResizePending) {
            canvas.height = effectCanvas.height;
            canvas.width = effectCanvas.width;
            canvasResizePending = false;
          }
          canvasUtils.drawFromOffscreenCanvas(canvasCtx, (ctx) => {
            ctx.drawImage(effectCanvas, 0, 0);
            if (swipeHand) {
              const bbox = parseBoundingBox(swipeHand.bbox);
              const drawCirleArgs = {
                x: bbox.x,
                y: bbox.top,
                configCallback() {
                  ctx.lineWidth = lineWidth;
                  ctx.strokeStyle = "blue";
                },
              };
              canvasUtils.ctxDrawCircle(ctx, {
                ...drawCirleArgs,
                radius: lineWidth * 1.5,
              });
              canvasUtils.ctxDrawCircle(ctx, {
                ...drawCirleArgs,
                radius: lineWidth * 3.5,
              });
            }
            if (swipePath.path) {
              const rate = Math.floor((swipePath.rate / 100) * 255);
              const color = `rgb(${255 - rate},${rate},0)`;
              canvasUtils.ctxDrawPath(ctx, swipePath.path, {
                configCallback() {
                  ctx.lineCap = "round";
                  ctx.lineWidth = lineWidth;
                  ctx.strokeStyle = color;
                },
              });
              canvasUtils.ctxDrawRectangle(ctx, {
                ...imgBox,
                configCallback() {
                  ctx.lineWidth = lineWidth / 2;
                  ctx.strokeStyle = color;
                },
              });
              canvasUtils.ctxDrawRectangle(ctx, {
                left: imgBox.left,
                top: imgBox.bottom,
                width: imgBox.width,
                height: lineWidth * 2,
                full: true,
                configCallback() {
                  ctx.lineWidth = lineWidth / 2;
                  ctx.strokeStyle = color;
                  ctx.fillStyle = color;
                },
              });
            }
            if (pickHand) {
              const bbox = parseBoundingBox(pickHand.bbox);
              const radius = (imgBox.width + imgBox.height) * 0.025;
              if (isInsideBbox(imgBox, bbox)) {
                const color = "lightGreen";
                canvasUtils.ctxDrawRectangle(ctx, {
                  ...imgBox,
                  configCallback() {
                    ctx.lineCap = "round";
                    ctx.lineWidth = lineWidth;
                    ctx.setLineDash(lineDash);
                    ctx.strokeStyle = color;
                  },
                });
                canvasUtils.ctxDrawCircle(ctx, {
                  ...imgBox,
                  radius,
                  full: true,
                  strike: false,
                  configCallback() {
                    ctx.fillStyle = color;
                  },
                });
              } else {
                const color = "red";
                canvasUtils.ctxDrawRectangle(ctx, {
                  ...imgBox,
                  configCallback() {
                    ctx.lineCap = "round";
                    ctx.lineWidth = lineWidth;
                    ctx.setLineDash(lineDash);
                    ctx.strokeStyle = color;
                  },
                });
                canvasUtils.ctxDrawCircle(ctx, {
                  ...bbox,
                  radius,
                  full: true,
                  strike: false,
                  configCallback() {
                    ctx.fillStyle = color;
                  },
                });
              }
            }
            if (lockHand && lockValue && !lock.show) {
              let rate = Math.floor((lockValue / 100) * 200);
              const color = `rgb(${rate},${200 - rate},0)`;
              canvasUtils.ctxDrawRectangle(ctx, {
                ...parseBoundingBox(lockHand.bbox),
                configCallback() {
                  ctx.lineCap = "round";
                  ctx.lineWidth = lineWidth;
                  ctx.strokeStyle = color;
                },
              });
              canvasUtils.ctxDrawLock(ctx, {
                ...imgBox,
                color,
                value: lockValue,
                side: Math.min(imgBox.width, imgBox.height) / 3,
              });
            }
            if (swipeOverflowPath) {
              canvasUtils.ctxDrawPath(ctx, swipeOverflowPath, {
                configCallback() {
                  ctx.lineWidth = lineWidth * 3;
                  ctx.strokeStyle = "orange";
                },
              });
            }
            if (lock.show) {
              canvasUtils.ctxDrawLock(ctx, {
                ...imgBox,
                color: lock.locked ? "red" : "green",
                value: lock.locked ? 100 : 70,
                side: Math.min(imgBox.width, imgBox.height) / 3,
              });
            }
          });
        };
      }
    }
    // eslint-disable-next-line
  }, [localVideoTrackReady, videoEffects.handtrackActive]);

  return (
    <video
      autoPlay
      ref={ref}
      poster={poster}
      className={clsx(
        classes.video,
        // track && track.videoType === "camera" ? classes.mirror : null
        classes.mirror
      )}
    />
  );
};

export default LocalVideo;
