import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.buttons.main1,
    marginTop: "6px",
    marginBottom: "6px",

    "& .MuiSvgIcon-root": {
      height: "18px !important",
      width: "18px !important",
    },
  },
  dialogContainer: {
    display: "block",
  },
  dialog: {
    width: "auto",
    margin: "0.5rem",
    borderRadius: "7px",

    [theme.breakpoints.up("sm")]: {
      margin: "1.75rem auto",
      maxWidth: "500px",
    },
  },
  dialogRoot: {
    "& .MuiBackdrop-root": {
      backdropFilter: "blur(2px)",
    },
  },
  dialogHeader: {
    backgroundColor: theme.palette.tabs.primarySelected,
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "flex-start",
    padding: "20px 30px",
    alignItems: "center",
    columnGap: "0.5rem",
  },
  headerText: {
    lineHeight: "13px",
    fontSize: "1.25rem",
    fontWeight: 500,
    flexGrow: 1,
  },
  closeButton: {
    padding: "0 !important",
    height: "30px",
    width: "30px",
    background: "rgba(255, 255, 255, 0.3)",
    color: theme.palette.common.white,
  },
  dialogContent: {
    padding: "12px 30px 10px",
    overflow: "hidden",
  },
  label: {
    // paddingTop: "calc(0.375rem + 1px)",
    // paddingBottom: "calc(0.375rem + 1px)",
    padding: "calc(0.375rem + 1px)",
    marginBottom: "10px",
    lineHeight: 1.5,
    color: theme.palette.textGray.main,
    display: "inline-block",
    marginTop: "10px",
  },
  nonFormLabel: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: "0.5rem",
  },
  inputBase: {
    color: theme.palette.textGray.input,
    padding: "0.375rem 0.75rem",
    height: "auto",

    "&::placeholder": {
      color: `${theme.palette.textGray.inputPlaceholder} !important`,
      opacity: 1,
    },
  },
  inputBaseMultiline: {
    resize: "both",
    height: "53px !important",
    padding: "0.375rem 0.75rem",
    boxSizing: "border-box",
  },
  input: {
    fontSize: "14px",
    lineHeight: 1.5,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.borderGray.input}`,
    boxSizing: "border-box",
    backgroundClip: "padding-box",
  },
  inputMultiline: {
    padding: "0 !important",
  },
  inputRoot: {
    "&$focused": {},
    "&$notchedOutline": {},
  },
  inputFocused: {
    backgroundColor: theme.palette.bgGray.sidebar,
  },
  inputNotchedOutline: {
    display: "none",
  },

  actionButton: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.light}`,
    padding: "9px",
    color: theme.palette.common.white,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
    textTransform: "none",
    transition: "color 0.15s ease-in-out, background-color 0.15s ease-in-out",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  avatars: {
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    width: "90%",
  },
  defaultAvatarColors: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));
