import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import IncomingCallBackdrop from "./IncomingCallBackdrop.component";

import { setPage } from "XmarsStore/actions/index";

const mapStateToProps = (state) => {
  const { incomingCall } = state.video;
  return { incomingCall };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPage,
    },
    dispatch
  );

const IncomingCallBackdropContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IncomingCallBackdrop);

export default IncomingCallBackdropContainer;
