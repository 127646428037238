import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";
import clsx from "clsx";

import { useDragging, useDrop } from "../../../hooks/index";

import useStyles from "./DropZone.styles";

export const DropZone = function ({
  children,
  onDrop: handleDrop,
  className,
  ...props
}) {
  const ref = useRef(null);

  const [dragging, stopDragging] = useDragging(ref);
  const files = useDrop(ref);

  const classes = useStyles();

  useEffect(() => {
    if (files && files.length) {
      handleDrop(files);
    }
    stopDragging();
    // eslint-disable-next-line
  }, [files]);

  return (
    <div {...props} ref={ref} className={clsx(className, classes.root)}>
      {!dragging ? null : (
        <div className={classes.dropZone}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            className={classes.fullHeight}
          >
            <Grid item className={classes.iconContainer}>
              <Mui.Icon className={classes.icon}>
                <Icons.Paperclip />
              </Mui.Icon>
            </Grid>
          </Grid>
        </div>
      )}
      {children}
    </div>
  );
};

export default DropZone;

DropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
};
