import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import RejectCallButton from "./RejectCallButton.component";

import { setIncomingCall, setPage } from "XmarsStore/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setIncomingCall,
      setPage,
    },
    dispatch
  );

const RejectCallButtonContainer = connect(
  null,
  mapDispatchToProps
)(RejectCallButton);

export default RejectCallButtonContainer;
