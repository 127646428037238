import { makeStyles } from "@mui/styles";

const bigger = {
  minWidth: "0.8rem",
  minHeight: "0.8rem",
  borderRadius: "50%",
  border: "3px solid white",
  right: "5px",
  bottom: "5px",
};
export const indicatorStyles = makeStyles((theme) => ({
  active: {
    ...bigger,
    backgroundColor: "green",
  },
  shortInactive: {
    ...bigger,
    backgroundColor: "white",
    borderWidth: "2px",
    outline: "2px solid green",
    outlineOffset: "-4px",
  },
  longInactive: {
    ...bigger,
    backgroundColor: "orange",
  },
  dontDisturb: {
    ...bigger,
    backgroundColor: "red",
  },
}));
