import client from "../../../client";
import mutation from "./sendBotMessage.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} secret
 * @param {string} room_id
 * @param {string} form json form
 * @returns {Promise<{ id: string }>}
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const sendBotMessage = async function (secret, room_id, form) {
  room_id = addBearer(room_id);
  const { data } = await client.mutate({
    mutation,
    variables: {
      secret,
      room_id,
      form,
    },
  });
  return data.sendBotMessage;
};

export default sendBotMessage;
