import { gql } from "../../../utils/apollo";

export const updateIsOneToOneChatsOpen = gql`
  mutation updateIsOneToOneChatsOpen($isOneToOneChatsOpen: Boolean) {
    updateIsOneToOneChatsOpen(
      input: { isOneToOneChatsOpen: $isOneToOneChatsOpen }
    )
  }
`;

export default updateIsOneToOneChatsOpen;
