import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { Tooltip } from "../../prestyledComponents";
import { stringAvatar } from "../../../utils/defaultAvatar/defaultAvatar";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const SearchedFriend = function ({
  room,
  friend,
  selected,
  onClick,
  sendRoomInvitation,
}) {
  const handleClick = () => sendRoomInvitation(friend, room);
  const [isMember, setIsMember] = useState(false);

  useEffect(() => {
    let membersIds = room.users.map((room) => room.id);
    if (membersIds.includes(friend.id)) {
      setIsMember(true);
    }
  }, [room, friend]);

  return (
    <Mui.ListItem button divider selected={selected} onClick={onClick}>
      <Mui.ListItemAvatar>
        <Mui.Avatar src={friend.avatar} {...stringAvatar(friend.username)} />
      </Mui.ListItemAvatar>
      <Mui.ListItemText primary={friend.username} />
      <Mui.ListItemIcon>
        {friend.roomInvitationSent || isMember ? (
          <Mui.Icon color="primary">
            <Icons.Check />
          </Mui.Icon>
        ) : (
          <Tooltip title="Invite">
            <Mui.IconButton color="primary" onClick={handleClick}>
              <Icons.Users />
              <Icons.Send />
            </Mui.IconButton>
          </Tooltip>
        )}
      </Mui.ListItemIcon>
    </Mui.ListItem>
  );
};

export default SearchedFriend;

SearchedFriend.propTypes = {
  room: PropTypes.object.isRequired,
  friend: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  sendRoomInvitation: PropTypes.func.isRequired,
};
