import client from "../../../client";
import { addBearer } from "../../../utils";
import query from "./getWorkspaceAdmins.query";

/**
 * @returns {Promise<{
 *  id: string,
 *  title: string,
 *  description: string
 * }>}
 */
export const getWorkspaceAdmins = async function (id) {
  id = addBearer(id);
  const { data } = await client.query({
    query,
    variables: {
      input: {
        id,
      },
    },
  });
  return data.getWorkspaceAdmins;
};

export default getWorkspaceAdmins;
