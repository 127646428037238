import PropTypes from "prop-types";

import { Snackbar } from "../../collectionItems";

export const Snackbars = function ({ snackbars }) {
  return snackbars.length ? <Snackbar snackbar={snackbars[0]} /> : null;
};

export default Snackbars;

Snackbars.propTypes = {
  snackbars: PropTypes.array.isRequired,
};
