import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import FoundContact from "./FoundContact.component";

import { setIsResultsPanelOpen, setRoom } from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { user } = state.chat;
  const { searchedValue } = state.search;
  return { user, searchedValue };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setIsResultsPanelOpen,
      setRoom,
    },
    dispatch
  );

const FoundContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FoundContact);

export default FoundContactContainer;
