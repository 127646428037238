import React, { useState } from "react";
import * as Icons from "@mui/icons-material";

import ScribbleCanvas from "../../ScribbleCanvas";
import { TooltipIconButton } from "../../prestyledComponents";

export const ScribbleButton = function ({ closeMenu, ...props }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    closeMenu && closeMenu();
  };

  return (
    <>
      <TooltipIconButton
        {...props}
        title="Draw"
        onClick={handleClick}
        children={<Icons.GestureRounded />}
      />
      <ScribbleCanvas open={open} setOpen={setOpen} />
    </>
  );
};

export default ScribbleButton;
