import { gql } from "../../../utils/apollo";

export const removeMemberWorkspace = gql`
  mutation RemoveMember($input: removeMemberInput!) {
    removeMember(input: $input) {
      createdAt
      description
      id
      title
      admin
      users {
        id
        username
        avatar
        token
      }
    }
  }
`;

export default removeMemberWorkspace;
