import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Replies from "./Replies.component";

const mapStateToProps = (state) => {
  const { user, currentMessage } = state.chat;
  return {
    userId: user.id,
    message: currentMessage,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
const RepliesContainer = connect(mapStateToProps, mapDispatchToProps)(Replies);

export default RepliesContainer;
