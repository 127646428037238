import { createSlice } from "@reduxjs/toolkit";
import notificationsInitialState from "./initialState";
import * as reducers from "./reducers";

const slice = createSlice({
  name: "notifications",
  initialState: notificationsInitialState,
  reducers,
});

const { reducer, actions } = slice;

export default reducer;
export const {
  getStateNotificationList,
  resetNotifications,
  setNotifications,
  addNotification,
  removeNotification,
} = actions;
