import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { getUid } from "../../../utils/getUid";
import useStyles from "./PasswordTextField.styles";

export const PasswordTextField = function ({
  disabled,
  inputProps,
  InputProps,
  ...props
}) {
  const classes = useStyles();

  return (
    <Mui.TextField
      id={getUid()}
      {...props}
      // Not overridable
      disabled={disabled}
      type="password"
      inputProps={{
        minLength: 7,
        maxLength: 50,
        className: classes.inputBase,
        ...inputProps,
      }}
      InputProps={{
        className: classes.input,
        notched: false,
        classes: {
          root: classes.inputRoot,
          focused: classes.inputFocused,
          notchedOutline: classes.inputNotchedOutline,
        },
        ...InputProps,
      }}
    />
  );
};

export default PasswordTextField;

PasswordTextField.propTypes = {
  /**
   * The name to be used in the form
   */
  name: PropTypes.string,
  /**
   * The label to be displayed
   */
  label: PropTypes.string,
  /**
   * The placeholder to be displayed
   */
  placeholder: PropTypes.string,
  /**
   * Input variant
   */
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
};

PasswordTextField.defaultProps = {
  disabled: false,
  name: "password",
  placeholder: "Password",
  variant: "outlined",
  size: "small",
  fullWidth: true,
  required: true,
  autoComplete: "off",
  inputProps: {},
  InputProps: {},
};
