import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  const typing = {
    display: "inline-block",
    marginTop: "-20px",
    animation: "$dotpop .3s infinite alternate-reverse both",
  };
  return {
    typing,
    typing2: {
      ...typing,
      animationDelay: ".1s",
    },
    typing3: {
      ...typing,
      animationDelay: ".2s",
    },
    "@keyframes dotpop": {
      "0%": {
        fontWeight: "100",
      },
      "100%": {
        fontWeight: "700",
      },
    },
  };
});

const ThreeDots = () => {
  const classes = useStyles();
  return (
    <>
      <span className={classes.typing}>.</span>
      <span className={classes.typing2}>.</span>
      <span className={classes.typing3}>.</span>
    </>
  );
};

export const TypingIndicator = ({ text, style = {} }) => {
  return (
    <Typography
      variant="button"
      style={{
        textTransform: "none",
        lineHeight: "1.5",
        //fontWeight: "bold",
        ...style,
      }}
    >
      {text} <ThreeDots />
    </Typography>
  );
};
