import { makeStyles } from "@mui/styles";
import { classes } from "../../style";

export default makeStyles((theme) => ({
  ...classes,
  dialogContainer: {
    display: "block",
  },
  dialog: {
    width: "auto",
    margin: "0.5rem",
    maxHeight: "calc(100% - 1rem)",
    borderRadius: "7px",

    [theme.breakpoints.up("sm")]: {
      margin: "1.5rem auto",
      maxWidth: "500px",
      maxHeight: "calc(100% - 3rem)",
    },
  },
  dialogRoot: {
    "& .MuiBackdrop-root": {
      backdropFilter: "blur(2px)",
    },
  },
  dialogHeader: {
    backgroundColor: theme.palette.tabs.primarySelected,
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "flex-start",
    padding: "20px 30px",
    alignItems: "center",
    columnGap: "0.5rem",
  },
  headerText: {
    lineHeight: "13px",
    fontSize: "1.25rem",
    fontWeight: 500,
    flexGrow: 1,
  },
  closeButton: {
    padding: "0 !important",
    height: "30px",
    width: "30px",
    background: "rgba(255, 255, 255, 0.3)",
    color: theme.palette.common.white,
  },
  dialogContent: {
    padding: "15px",
  },
  content: {
    fontSize: 14,
  },
  usersContainer: {
    height: "calc(50vh - 70px - 50px)",
  },
  listItemRoot: {
    "&$selected": {
      borderRadius: "15px",
      backgroundColor: theme.palette.primary.light,

      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },

    "&:hover": {
      borderRadius: 0,
      backgroundColor: "transparent",
    },
  },
  listItemSelected: {},
  primaryText: {
    fontSize: 14,
    color: theme.palette.sidebarItems.primary,
    lineHeight: 1.2,
    marginBottom: "0.2rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  item: {
    padding: "17px 30px",
    fontSize: 14,
    alignItems: "center",
    marginBottom: "-1px",
  },
  itemText: {
    marginTop: "0px",
    marginBottom: "0px",
  },
  dialogFooter: {
    padding: "15px",
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.light}`,
    padding: "9px",
    color: theme.palette.common.white,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
    textTransform: "none",
    transition: "color 0.15s ease-in-out, background-color 0.15s ease-in-out",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  alreadyMember: {
    color: theme.palette.secondary.light,
  },
  secondaryAction: {
    right: "40px",
  },
}));
