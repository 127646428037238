import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ReplyMessageForm from "./ReplyMessageForm.component";

import { replyMessage } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      replyMessage,
    },
    dispatch
  );

const ReplyMessageFormContainer = connect(
  null,
  mapDispatchToProps
)(ReplyMessageForm);

export default ReplyMessageFormContainer;
