import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import initialState, { formInitialState } from "./initialState";
import * as reducers from "./reducers";

type SubmitFailArgs = { name: string; error: any };

const slice = createSlice({
  name: "form",
  initialState,
  reducers,
});

const { reducer, actions } = slice;

export default reducer;
export const { initForm, submit, submitSuccess, submitFail, clear } = actions;
