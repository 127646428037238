import client from "../../../client";
import query from "./getMessage.query";
import { addBearer } from "../../../utils";

/**
 * @param {string} room_id
 * @returns {Promise{
 *  id: string,
 *  messageType: string,
 *  edited: boolean,
 *  status: string,
 *  text: string,
 *  scribble: string,
 *  form: string,
 *  createdAt: number,
 *  owner: {
 *      id: string,
 *      avatar: string,
 *      username: string,
 *  },
 *  readers: Array<{
 *      id: string,
 *      avatar: string,
 *      username: string,
 *  }>,
 * }}
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getMessage = async function (message_id) {
  message_id = addBearer(message_id);
  const { data } = await client.query({
    query,
    variables: {
      message_id,
    },
  });
  return data.getMessage;
};

export default getMessage;
