import * as apollo from "../utils/apollo";

import env from "../../env";

const httpLink = new apollo.HttpLink({
  uri: `/data`,
  credentials: "same-origin",
});

const wsLink = new apollo.GraphQLWsLink(
  apollo.createClient({
    //TODO replace this with webpack replacements
    url: env.DEVELOPMENT
      ? `ws://localhost:3200/ws`
      : `wss://${window.location.host}/data/ws`,
    connectionParams: {
      authorization: env.BEARER_SECRET,
    },
    options: {
      lazy: true,
      retryAttempts: Infinity,
      shouldRetry: () => true,
    },
  })
);

const authLink = apollo.setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: env.BEARER_SECRET,
  },
}));

export const link = apollo.split(
  ({ query }) => {
    const { kind, operation } = apollo.getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

export default link;
