import client from "../../../client";
import mutation from "./requestRecovery.mutation";

/**
 * @param {string} email
 */
export const requestRecovery = async function (email) {
  await client.mutate({
    mutation,
    variables: {
      email,
    },
  });
};

export default requestRecovery;
