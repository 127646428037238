import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SendAudioCallButton from "./SendVideoCallButton.component";

import {
  channelCall,
  updateCallStatus,
  setOnlyAudio,
} from "XmarsStore/actions/index";
import { selectActiveChannel } from "XmarsStore/selectors/index";

const mapStateToProps = (state) => {
  const channel = selectActiveChannel(state);
  const currentWorkspace = state.chat.currentWorkspace;
  return { channel, currentWorkspace };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      channelCall,
      updateCallStatus,
      setOnlyAudio,
    },
    dispatch
  );

const SendAudioCallButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SendAudioCallButton);

export default SendAudioCallButtonContainer;
