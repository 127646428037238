import React, { useState } from "react";
import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { EmailTextField, UsernameTextField } from "../../inputs";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

export const CreateGuestForm = function CreateGuestForm({
  createGuest,
  ...props
}) {
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = formSubmitHandler(async ({ username, email }) => {
    try {
      setDisabled(true);
      await getBoundActionPromise(createGuest, {
        username,
        email,
      });
    } catch (error) {
      logError(error);
    } finally {
      setDisabled(false);
    }
  });

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      component="form"
      onSubmit={handleSubmit}
    >
      <Grid item>
        <UsernameTextField {...props} disabled={disabled} />
      </Grid>
      <Grid item>
        <EmailTextField {...props} disabled={disabled} />
      </Grid>
      <Grid item>
        <Mui.Button
          disabled={disabled}
          variant="contained"
          fullWidth={true}
          type="submit"
          color="primary"
        >
          {"Create Guest"}
        </Mui.Button>
      </Grid>
    </Grid>
  );
};

export default CreateGuestForm;

CreateGuestForm.propTypes = {
  createGuest: PropTypes.func.isRequired,
};
