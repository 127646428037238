import React, { useState } from "react";
import {
  Avatar,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";
import clsx from "clsx";

import { stringAvatar } from "../../utils/defaultAvatar/defaultAvatar";
import { VerticalScrollbars } from "../prestyledComponents";

import useStyles from "./InviteToRoom.styles";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const InviteToRoom = function ({
  user,
  friends,
  checked,
  handleToggle,
}) {
  const classes = useStyles();
  const [filter, setFilter] = useState("");

  const renderFriends = () => {
    if (friends.length) {
      //const regex = RegExp(filter, "i");
      const uncaseFilter = filter.toLowerCase();
      let shownFriends = [...friends];
      if (filter) {
        // shownFriends = shownFriends.filter((friend) =>
        //   regex.test(friend.username)
        // );
        shownFriends = shownFriends.filter((friend) =>
          friend.username.toLowerCase().includes(uncaseFilter)
        );
      }

      if (shownFriends.length)
        return shownFriends.map((friend) => {
          const toggleHandler = handleToggle(friend.id);

          return (
            <ListItem
              key={friend.id}
              button
              selected={false}
              className={classes.item}
              classes={{
                root: classes.listItemRoot,
                selected: classes.listItemSelected,
              }}
              onClick={toggleHandler}
            >
              <ListItemAvatar>
                <Avatar
                  src={friend.avatar}
                  {...stringAvatar(friend.username)}
                />
              </ListItemAvatar>
              <ListItemText
                primary={friend.username}
                primaryTypographyProps={{
                  className: classes.primaryText,
                }}
                className={classes.itemText}
              />
              <ListItemSecondaryAction
                classes={{ root: classes.secondaryAction }}
              >
                <Checkbox
                  edge="end"
                  color="primary"
                  onChange={toggleHandler}
                  checked={checked.indexOf(friend.id) !== -1}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        });

      return (
        <ListItem>
          <ListItemIcon>
            <Icons.Search />
          </ListItemIcon>
          <ListItemText primary={`No match for the search "${filter}"`} />
        </ListItem>
      );
    }

    if (user.role === "guest") return null;
    return (
      <ListItem>
        <ListItemText primary={"There are no contacts to show"} />
      </ListItem>
    );
  };

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Box>
      <TextField
        margin="dense"
        autoComplete="off"
        variant="outlined"
        onChange={handleChange}
        className={clsx(classes.searchBar, classes.background)}
        placeholder="Search Contacts"
        InputProps={{
          className: classes.input,
          notched: false,
          classes: {
            root: classes.inputRoot,
            focused: classes.inputFocused,
            notchedOutline: classes.inputNotchedOutline,
          },
        }}
        inputProps={{ className: classes.inputBase }}
      />
      <Box className={classes.usersContainer}>
        <VerticalScrollbars style={{ height: 200 }} alwaysVisible={true}>
          <List
            style={{ paddingTop: 0, paddingBottom: 30 }}
            className={classes.content}
          >
            {renderFriends()}
          </List>
        </VerticalScrollbars>
      </Box>
    </Box>
  );
};

export default InviteToRoom;

InviteToRoom.propTypes = {
  user: PropTypes.object,
};
