import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import clsx from "clsx";

import useStyles from "./SearchBarMember.styles";

export default function SearchMembersForm({ leftButton, setSearchQuery }) {
  const [focused, setFocused] = useState(false);
  const [searchedStr, setSearchedStr] = useState("");

  useEffect(() => {
    setSearchedStr("");
  }, []);
  const classes = useStyles();

  const handleChange = function (event) {
    const title = event.target.value;
    setSearchedStr(title);
  };

  const handleClose = function () {
    setSearchedStr("");
    setSearchQuery("");
  };

  const handleFocus = () => {
    //setFocused(true)
  };
  const handleBlur = () => {
    //setFocused(false)
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
  };

  return (
    <form style={{ display: "flex", alignItems: "center" }}>
      <Mui.TextField
        margin="dense"
        autoComplete="off"
        variant="outlined"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onInput={(e) => {
          setSearchQuery(e.target.value);
        }}
        value={searchedStr}
        className={clsx(classes.searchBar, classes.background)}
        placeholder="Search for a member"
        InputProps={{
          className: leftButton ? classes.inputShowingButton : classes.input,
          notched: false,
          classes: {
            root: classes.inputRoot,
            focused: classes.inputFocused,
            notchedOutline: classes.inputNotchedOutline,
            adornedEnd: classes.inputAdornedEnd,
          },
          endAdornment: searchedStr && (
            <Mui.IconButton
              type="submit"
              aria-label="close-search"
              onClick={handleClose}
              className={classes.clearButton}
            >
              <Icons.X />
            </Mui.IconButton>
          ),
        }}
        inputProps={{ className: classes.inputBase }}
      />
    </form>
  );
}
