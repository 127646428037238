import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import OpenSearchBarButton from "./OpenSearchBarButton.component";

import { setSearchPanelOpen } from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { searchPanelOpen } = state.search;
  return { searchPanelOpen };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSearchPanelOpen,
    },
    dispatch
  );

const OpenSearchBarButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenSearchBarButton);

export default OpenSearchBarButtonContainer;
