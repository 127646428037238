import client from "../../../client";
import { addBearer } from "../../../utils";
import mutation from "./updateWorkspace.mutation";

/**
 * @param {string} newTitle
 * @param {string} newDescription
 * @param {string} workspaceId
 * @param {string} newAvatar
 * @returns {Promise<{
 *  id: string,
 *  title: string,
 *  createdAt: date,
 *  description: string
 *  avatar: string
 * }>}
 */
export const updateWorkspace = async function (
  newTitle,
  newDescription,
  workspaceId,
  newAvatar
) {
  workspaceId = addBearer(workspaceId);

  const { data } = await client.mutate({
    mutation,
    variables: {
      input: {
        newTitle,
        newDescription,
        newAvatar,
        workspaceId,
      },
    },
  });
  return data.updateWorkspace;
};

export default updateWorkspace;
