const cache = new Map();

/**
 * @template T
 * @param {T | keyof HTMLElementTagNameMap} name
 * @param {Object<string,any> | HTMLElementTagNameMap[T]} props
 * @returns {HTMLElementTagNameMap[T]}
 */
export const createElement = function (name, props) {
  let cloner;
  if (cache.has(name)) {
    cloner = cache.get(name);
  } else {
    cloner = document.createElement(name);
    cache.set(name, cloner);
  }
  const element = cloner.cloneNode(false);
  if (props) {
    for (const key of Object.getOwnPropertyNames(props)) {
      element[key] = props[key];
    }
  }
  return element;
};

export default createElement;
