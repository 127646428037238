import { gql } from "../../../utils/apollo";

export const changeStatus = gql`
  mutation ChangeStatus($status: String) {
    changeStatus(input: { status: $status }) {
      status
    }
  }
`;

export default changeStatus;
