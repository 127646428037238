import initialState from "./initialState";

export const setActiveChannel = function (state, { payload }) {
  state.activeChannel = payload;
};

export const setMeetingCode = function (state, { payload }) {
  state.meetingCode = payload;
};

export const setDevices = function (state, { payload }) {
  state.devices = payload;
};

export const setMicId = function (state, { payload }) {
  state.micId = payload;
};

export const setVideoId = function (state, { payload }) {
  state.videoId = payload;
};

export const setSpeakerId = function (state, { payload }) {
  state.speakerId = payload;
};

export const setIncomingCall = function (state, { payload }) {
  const incomingCall = payload;
  if (!incomingCall) {
    state.incomingCall = null;
  } else if (!(state.incomingCall || state.activeChannel)) {
    state.incomingCall = incomingCall;
  }
};

export const setOnlyAudio = function (state, { payload }) {
  state.onlyAudio = payload;
};

export const setRecordings = function (state, { payload }) {
  state.recordings = payload;
};

export const updateSmallVideoOpen = function (state, { payload }) {
  state.isSmallVideoOpen = payload;
};

export const resetCallStatus = (state) => {
  state.callStatus = initialState.callStatus;
};

export const setCallStatus = (state, { payload }) => {
  state.callStatus = payload;
};

export const updateCallStatus = (state, { payload }) => {
  state.callStatus = { ...state.callStatus, ...payload };
};
export const setOngoingCall = (state, { payload }) => {
  state.ongoingCall = payload;
};

export const setSharing = (state, { payload }) => {
  state.sharing = payload;
};
