import { getJitsiConference } from "XmarsJitsiMeetJS/index";

import {
  put,
  call,
  race,
  take,
  fork,
  select,
  delay,
  cancel,
} from "redux-saga/effects";

import { groupLog } from "XmarsUtils/groupLog";
import * as actions from "XmarsStore/actions";

import { handleSagaError } from "XmarsStore/sagas/sagas.utils/handleSagaError";
import { jitsiCallInitLocalTracks } from "../tracks/jitsiCallInitLocalTracks";

import { jitsiConferenceChannel } from "./jitsiConferenceChannel";

import { conferenceObjectControl } from "./conferenceObjectControl";

import { switchAudioOutputDeviceControl } from "../tracks/audio/switchAudioOutputDeviceControl";
import { processConferenceSignal } from "./processConferenceSignal";

export const startJitsiCallConference = function* (payload) {
  const { jitsiConnection, meetingCode } = payload;
  const jitsiConference = getJitsiConference(jitsiConnection, meetingCode);
  const conferenceControlTask = yield fork(
    conferenceObjectControl,
    jitsiConference
  );
  const userName = yield select((state) => state.chat.user.username);
  jitsiConference.setDisplayName(userName);
  const channel = yield call(jitsiConferenceChannel, jitsiConference);
  const tracksTask = yield fork(jitsiCallInitLocalTracks, jitsiConference);
  const switchAudioOutputDeviceTask = yield fork(
    switchAudioOutputDeviceControl
  );
  const conferenceTasksList = [
    tracksTask,
    conferenceControlTask,
    switchAudioOutputDeviceTask,
  ];
  try {
    while (true) {
      const { data, endJitsiConference } = yield race({
        data: take(channel),
        endJitsiConference: take(actions.jitsiEndConference),
      });
      if (endJitsiConference) {
        yield put(actions.stopRecording({ jitsiConference }));
        const remainingParticipants = jitsiConference.getParticipants().length;
        if (
          remainingParticipants === 0 &&
          jitsiConference.myUserId() !== null
        ) {
          yield put(
            actions.endOngoingCall({
              id: jitsiConference.options.name,
            })
          );
        }
        channel.close();
      } else {
        const conferenceTask = yield fork(processConferenceSignal, {
          data,
          jitsiConference,
        });
        conferenceTasksList.push(conferenceTask);
      }
    }
  } catch (error) {
    console.log("startJitsiCallConference error: ");
    yield handleSagaError(error);
  } finally {
    // another delay that helps ending the tracks
    yield delay(0);
    yield cancel(conferenceTasksList);
    groupLog("finished jitsiConference");
  }
};
