import client from "../../../client";
import { addBearer } from "../../../utils";
import mutation from "./updateRoom.mutation";

/**
 * @param {string} newTitle
 * @param {string} roomId
 * @returns {Promise<{
 *  id: string,
 *  title: string,
 *  createdAt: date,
 *  description: string
 * }>}
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const updateRoom = async function (newTitle, roomId) {
  roomId = addBearer(roomId);

  const { data } = await client.mutate({
    mutation,
    variables: {
      input: {
        newTitle,
        roomId,
      },
    },
  });
  return data.updateRoom;
};

export default updateRoom;
