import ctxSaveExecRestore from "./ctxSaveExecRestore";
import ctxDrawRectangle from "./ctxDrawRectangle";

/**
 * @callback ctxConfigCallback
 * @param {CanvasRenderingContext2D} ctx
 */

/**
 * @param {CanvasRenderingContext2D} ctx
 * @param {Object} [options]
 * @param {number} [options.x]
 * @param {number} [options.y]
 * @param {number} [options.side]
 * @param {number} [options.value]
 * @param {string} [options.color]
 * @param {ctxConfigCallback} [options.configCallback]
 */
export const ctxDrawLock = function (
  ctx,
  { x = 0, y = 0, side = 10, value = 100, color = "black", configCallback }
) {
  ctxSaveExecRestore(ctx, () => {
    if (configCallback) configCallback(ctx);
    ctx.lineWidth = side / 5;
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.arc(x, y - side / 4, side / 3, 0, (-Math.PI / 100) * (1 + value), true);
    ctx.stroke();
  });
  ctxDrawRectangle(ctx, {
    left: x - side / 2,
    top: y - side / 4,
    width: side,
    height: (side * 2) / 3,
    full: true,
    strike: false,
    configCallback() {
      if (configCallback) configCallback(ctx);
      ctx.fillStyle = color;
    },
  });
};

export default ctxDrawLock;
