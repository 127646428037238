import client from "../../../client";
import mutation from "./createUser.mutation";

/**
 * @param {string} username
 * @param {string} email
 * @param {string} password
 * @returns {Promise<{ username: string }>}
 */
export const createUser = async function (username, email, password) {
  const { data } = await client.mutate({
    mutation,
    variables: {
      username,
      email,
      password,
    },
  });
  return data.createUser;
};

export default createUser;
