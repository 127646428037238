import React, { useRef } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { Tooltip } from "../../prestyledComponents";
export const FilesInput = function ({
  component: Component,
  TooltipProps,
  disabled,
  onClick,
  inputProps,
  ...props
}) {
  /** @type {React.Ref<HTMLInputElement>} */
  const ref = useRef(null);

  const handleClick = function (event) {
    ref.current.click();
    if (onClick) onClick(event);
  };

  return (
    <>
      <Tooltip title="Upload file" {...TooltipProps} arrow={false}>
        <Component {...props} disabled={disabled} onClick={handleClick} />
      </Tooltip>
      <input
        data-testid="filesInput"
        name="files"
        {...inputProps}
        hidden
        ref={ref}
        type="file"
        disabled={disabled}
      />
    </>
  );
};

export default FilesInput;

FilesInput.propTypes = {
  disabled: PropTypes.bool,
  component: PropTypes.elementType,
  onClick: PropTypes.func,
  inputProps: PropTypes.object,
};

FilesInput.defaultProps = {
  disabled: false,
  component: Mui.IconButton,
  children: <Icons.Paperclip />,
  inputProps: {},
};
