import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  files: {
    // border: "solid 2px gray",
    // borderRadius: "0 0 0.5em 0.5em",
    height: "115px",
    padding: "10px",
    borderRadius: "10px 10px 0 0",
    overflowX: "hidden",
    overflowY: "auto",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "center",
  },
  progress: {
    background: theme.palette.common.white,
    padding: "0.5em",
  },
  uploadButton: {
    height: "80px",
    backgroundColor: theme.palette.common.white,
  },
}));
