import { createAction } from "@reduxjs/toolkit";

export const getNotifications = createAction("saga/getNotifications");
export const rejectNotification = createAction("saga/rejectNotification");
export const sendFriendRequest = createAction("saga/sendFriendRequest");
export const acceptFriendRequest = createAction("saga/acceptFriendRequest");
export const sendRoomInvitation = createAction("saga/sendRoomInvitation");
export const sendWorkspaceInvitation = createAction(
  "saga/sendWorkspaceInvitation"
);
export const acceptRoomInvitation = createAction("saga/acceptRoomInvitation");
export const acceptWorkspaceInvitation = createAction(
  "saga/acceptWorkspaceInvitation"
);
