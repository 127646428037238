import { CustomError } from "XmarsUtils/index";

export class JitsiError extends CustomError {
  constructor(message) {
    super({
      message,
      name: "Jitsi Error",
    });
  }
}

export default JitsiError;
