import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as act } from "../store/actions";

export function useForm(name) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(act.form.initForm(name));
    return () => {
      dispatch(act.form.clear(name));
    };
  }, []);
  return useSelector(({ form }) => form[name] ?? {});
}
