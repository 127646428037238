import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { RemoteParticipant, LocalParticipant } from "../../collectionItems";

// eslint-disable-next-line
import { Participant } from "../../../typeDefs";
import { SortableContext, useSortable } from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";
import { ThreeDots } from "react-loader-spinner";

const useStyles = makeStyles((theme) => ({
  noParticipantsText: {
    color: "#7ea6f7",
  },
}));

/**
 * @param {Participant} participant
 */
const SortableParticipant = function ({ participant, height, flexBasis } = {}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: participant.id, data: { type: "GALLERY" } });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Box
      height={height}
      flexBasis={flexBasis}
      textAlign="center"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {participant.local ? (
        <LocalParticipant participant={participant} />
      ) : (
        <RemoteParticipant participant={participant} />
      )}
    </Box>
  );
};

/**
 * @param {Object} props
 * @param {Array<Participant>} props.participants
 * @returns
 */
export const ParticipantsGallery = function ({
  participants,
  callStatus,
  isSmallVideoOpen,
}) {
  const classes = useStyles();
  const theme = Mui.useTheme();
  const sm_down = Mui.useMediaQuery(theme.breakpoints.down("sm"));
  const sm_md = Mui.useMediaQuery(theme.breakpoints.between("sm", "md"));
  const md_up = Mui.useMediaQuery(theme.breakpoints.up("md"));

  const [height, flexBasis] = useMemo(() => {
    let divider, rows, height, flexBasis;
    if (participants.length <= 16 && md_up) {
      divider = !participants.length
        ? 1
        : Math.ceil(Math.sqrt(participants.length));
      rows = Math.ceil(participants.length / divider);
    } else if (participants.length > 16 && md_up) {
      divider = rows = 4;
    } else if (sm_md && participants.length > 1) {
      divider = rows = 2;
    } else if (sm_md && participants.length === 1) {
      divider = rows = 1;
    } else if (sm_down) {
      divider = rows = 1;
    }
    height = `${100 / rows}%`;
    flexBasis = `${100 / divider}%`;

    return [height, flexBasis];
  }, [participants.length, sm_md, sm_down, md_up]);

  const renderParticipants = function () {
    return participants.map((participant) => (
      <SortableParticipant
        key={participant.id}
        participant={participant}
        height={height}
        flexBasis={flexBasis}
      />
    ));
  };

  if (!participants.length)
    return (
      <Box
        height="100%"
        display="flex"
        flexWrap="wrap"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          width="100%"
          height="100%"
          display="flex"
          // textAlign="center"
          alignItems="center"
          justifyContent="center"
          // color="white"
        >
          <Mui.Typography className={classes.noParticipantsText} variant="h6">
            {callStatus.dialing ? "Waiting for other participants" : ""}
          </Mui.Typography>
          <ThreeDots
            visible={true}
            height="20"
            width="20"
            color="#7ea6f7"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              alignItems: "flex-end",
              paddingTop: "10px",
              paddingLeft: "5px",
            }}
            wrapperClass=""
          />
        </Box>
      </Box>
    );

  return (
    <SortableContext items={participants} id="GALLERY">
      <Box
        height={isSmallVideoOpen ? "100%" : "100%"}
        display="flex"
        flexWrap="wrap"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        overflow="auto"
      >
        {renderParticipants()}
      </Box>
    </SortableContext>
  );
};

export default ParticipantsGallery;

ParticipantsGallery.propTypes = {
  participants: PropTypes.array.isRequired,
};
