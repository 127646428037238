import {
  call,
  put,
  takeEvery,
  delay,
  select,
  fork,
  takeLeading,
} from "redux-saga/effects";

import api from "../../../../dataApi";

import {
  registerFriend,
  registerRoom,
  unregisterFriend,
  unregisterRoom,
} from "../utils";
import * as pushNotification from "../../../../serviceWorkerSubscription";

import { actions as act } from "../../../actions";
import * as actions from "../../../actions";

import { checkFirstTime, handleSagaError } from "../../sagas.utils";
import { ACTIVE, OFFLINE } from "../../../types/status";
import { detectInactivity } from "../../../../utils/inactivity/detectInactivity";

export * from "./inviteUsersToCall";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const guestSignIn = function* ({ payload } = {}) {
  try {
    const { email, id, room } = payload;
    yield call(api.logout);
    yield call(api.guestLogin, id, email);
    yield put(actions.getUser());
    yield put(actions.setPage("home"));
    yield put(actions.setRoom(room));
    yield put(actions.setMeetingCode(room.id));
    yield put(actions.setGeneric(null));
    yield call(registerRoom, room);
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const createUser = function* ({ payload }) {
  const { username, email, password, formName } = payload;
  try {
    yield put(act.form.submit(formName));
    const user = yield call(api.createUser, username, email, password);
    yield put(act.form.submitSuccess(formName));
    yield put(act.general.setPage("login"));
    const snackbar = {
      severity: "success",
      message: `${user.username}"s account created`,
    };
    yield put(act.snackbars.addSnackbar(snackbar));
  } catch (error) {
    yield put(act.form.submitFail(formName, error));
    yield handleSagaError(error);
  }
};

export const createGuest = function* ({ payload }) {
  const { username, email, promise } = payload;
  try {
    const user = yield call(api.createGuest, username, email);
    promise.resolve();
    const snackbar = {
      severity: "success",
      message: `${user.username} guest created`,
    };
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    promise.reject(error);
    yield handleSagaError(error);
  }
};

export const login = function* ({ payload }) {
  const { email, password, formName } = payload;
  try {
    yield put(act.form.submit(formName));
    yield call(api.login, email, password);
    yield put(act.form.submitSuccess(formName));
    window.location.reload();
  } catch (error) {
    yield put(act.form.submitFail(formName, error));
    yield handleSagaError(error);
  }
};

export const logout = function* () {
  try {
    yield call(api.changeStatus, OFFLINE);
    yield call(api.logout);
    // const userStatus = yield call(api.changeStatus, OFFLINE);
    // yield put (actions.setStatus(userStatus.status));
    pushNotification.unsubscribe();
    window.location = "/";
    window.location.reload();
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const deleteUser = function* ({ payload }) {
  const { promise } = payload;
  try {
    const user = yield call(api.deleteUser);
    promise.resolve();
    const snackbar = {
      severity: "success",
      message: `${user.username}"s account deleted`,
    };
    yield put(actions.addSnackbar(snackbar));
    yield put(actions.setPage("login"));
  } catch (error) {
    promise.reject(error);
    yield handleSagaError(error);
  }
};

export const deleteAppUser = function* ({ payload }) {
  try {
    const user = payload;
    yield call(api.deleteAppUser, user.id);
    const snackbar = {
      severity: "success",
      message: `${user.username}"s account deleted`,
    };
    yield put(actions.removeSearchedUser(user));
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const changeUsername = function* ({ payload }) {
  const { username, promise } = payload;
  try {
    const user = yield call(api.changeUsername, username);
    promise.resolve();
    yield put(actions.setUsername(user.username));
    const snackbar = {
      severity: "success",
      message: `Username changed to: ${user.username}`,
    };
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    promise.reject(error);
    yield handleSagaError(error);
  }
};

export const changeEmail = function* ({ payload }) {
  const { email, password, promise } = payload;
  try {
    yield call(api.changeEmail, email, password);
    promise.resolve();
    const snackbar = {
      severity: "success",
      message: "Email updated",
    };
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    promise.reject(error);
    yield handleSagaError(error);
  }
};

export const changePassword = function* ({ payload }) {
  const { password, newPassword, promise } = payload;
  try {
    yield call(api.changePassword, password, newPassword);
    promise.resolve();
    const snackbar = {
      severity: "success",
      message: "Password updated",
    };
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    promise.reject(error);
    yield handleSagaError(error);
  }
};

export const requestRecovery = function* ({ payload }) {
  const { email, formName } = payload;
  try {
    yield put(act.form.submit(formName));
    yield call(api.requestRecovery, email);
    yield put(act.form.submitSuccess(formName));
    const snackbar = {
      severity: "success",
      message: "Password recovery link sent to your mail",
    };
    yield put(act.snackbars.addSnackbar(snackbar));
  } catch (error) {
    yield put(act.form.submitFail(formName, error));
    yield handleSagaError(error);
  }
};

export const recoverPassword = function* ({ payload }) {
  const { id, params, password, promise } = payload;
  try {
    yield call(api.recoverPassword, id, params, password);
    promise.resolve();
    const snackbar = {
      severity: "success",
      message: "Password updated",
    };
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    promise.reject(error);
    yield handleSagaError(error);
  }
};

export const changeAvatar = function* ({ payload }) {
  const { avatar, promise } = payload;
  try {
    const user = yield call(api.changeAvatar, avatar);
    yield put(actions.setAvatar(user.avatar));
    promise.resolve();
    const snackbar = {
      severity: "success",
      message: "Avatar updated",
    };
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    promise.reject(error);
    yield handleSagaError(error);
  }
};

export const removeAvatar = function* ({ payload }) {
  const { promise } = payload;
  try {
    yield call(api.removeAvatar);
    yield put(actions.setAvatar(null));
    promise.resolve();
    const snackbar = {
      severity: "success",
      message: "Avatar removed",
    };
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    promise.reject(error);
    yield handleSagaError(error);
  }
};

export const getUser = function* () {
  try {
    yield call(api.changeStatus, ACTIVE);
    const user = yield call(api.getUser);
    yield put(actions.setUser(user));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const getFriends = function* () {
  try {
    const friends = yield api.getFriends();
    for (const friend of friends) {
      yield call(registerFriend, friend);
    }
    yield call(api.pingStatus);
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const searchByUsername = function* ({ payload }) {
  try {
    const users = yield call(api.searchByUsername, payload);
    yield put(actions.setSearchedUsers(users));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const searchByEmail = function* ({ payload }) {
  try {
    const userList = yield call(api.searchByEmail, payload);
    yield call(console.log, userList);
    yield put(actions.setSearchedUsers(userList));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const getUserByEmail = function* ({ payload }) {
  try {
    const user = yield call(api.getUserByEmail, payload);
    yield put(actions.resetSearchedUsers());
    if (!user) return;
    yield put(actions.addSearchedUser(user));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const searchFriends = function* ({ payload }) {
  const regExp = new RegExp(payload, "i");
  const friends = yield select((store) => store.friends);
  const matchingFriends = friends.filter((friend) =>
    friend.username.match(regExp)
  );
  yield put(actions.setSearchedUsers(matchingFriends));
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const unfriend = function* ({ payload }) {
  try {
    const friend = payload;
    yield call(api.unfriend, friend.id);
    yield call(unregisterRoom, friend.room);
    yield call(unregisterFriend, friend);
    const snackbar = {
      severity: "success",
      message: `You and ${friend.username} are no longer friends`,
    };
    yield put(actions.addSnackbar(snackbar));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const changeStatus = function* ({ payload }) {
  try {
    const status = payload;
    if (status === ACTIVE) {
      yield call(detectInactivity);
    }
    yield call(api.changeStatus, status);
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const startedTyping = function* ({ payload }) {
  // const room = payload;
  const { room, isWriting } = payload;
  try {
    yield call(api.startedTyping, room.id, isWriting);
  } catch (error) {
    yield handleSagaError(error);
  }
};
