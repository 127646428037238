import { grey } from "@mui/material/colors";

import { primary, secondary } from "./main";

export const colors = {
  lightPrimary: primary[50],
  lightSecondary: secondary[50],
  //
  lightGrey: grey[50],
  grey: grey[200],
  darkGrey: grey[500],
  lightDark: grey[800],
  dark: grey[900],
};

export default colors;
