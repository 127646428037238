import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  progress: {
    position: "absolute",
    zIndex: 10,
  },
}));
