const placeholder = (arg) =>
  console.warn("Recording signal was not expected", arg);

export const recordingState = {
  recording: false,
  /** @type {string} */
  sessionID: null,
  resolver: placeholder,
  rejecter: placeholder,
  /** @param {string} sessionID */
  recordingHasStarted(sessionID) {
    this.sessionID = sessionID;
    return new Promise((resolve, reject) => {
      this.resolver = (value) => {
        resolve(value);
        this.recording = true;
      };
      this.rejecter = (reason) => {
        reject(reason);
      };
    });
  },
  reset(reason = "The recording state was reseted") {
    this.rejecter(reason);
    this.recording = false;
    this.sessionID = null;
    this.resolver = placeholder;
    this.rejecter = placeholder;
  },
};

export default recordingState;
