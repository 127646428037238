import { gql } from "../../../utils/apollo";

export const setWorkspaceAdmin = gql`
  mutation SetWorkspaceAdmin($input: setWorkspaceAdminInput!) {
    setWorkspaceAdmin(input: $input) {
      null
    }
  }
`;

export default setWorkspaceAdmin;
