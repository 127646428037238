import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import MessageFileContent from "./MessageFileContent.component";

import { getFile } from "../../../store/actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFile,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { isResultsPanelOpen } = state.search;

  return {
    isResultsPanelOpen,
  };
};

const MessageFileContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageFileContent);

export default MessageFileContentContainer;
