import React, { useState } from "react";
import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import { Alert } from "@mui/material";
import PropTypes from "prop-types";

import { DoublePassword } from "../../inputs";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";

import useStyles from "./RecoverPasswordForm.styles";

import logo from "../../../assets/images/logo-sb.png";

export const RecoverPasswordForm = function ({
  id,
  recoverParams,
  recoverPassword,
}) {
  const [success, setSuccess] = useState(false);

  const classes = useStyles();

  const handleSubmit = formSubmitHandler(({ password }) => {
    const changed = new Promise((resolve, reject) => {
      recoverPassword(id, recoverParams, password, { resolve, reject });
    });
    changed.then(() => setSuccess(true)).catch(() => {});
  });

  const handleClick = () => (window.location = "/");

  if (success)
    return (
      <Mui.Paper variant="outlined" elevation={0} className={classes.formBox}>
        <Mui.Box className={classes.logo}>
          <img src={logo} alt="Sibatel logo" className={classes.logoImg} />
        </Mui.Box>
        <Mui.Typography
          variant="h5"
          align="center"
          gutterBottom
          className={classes.title}
        >
          Create account
        </Mui.Typography>

        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            <Alert>Password recovery successful</Alert>
          </Grid>
          <Grid item>
            <Mui.Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.actionButton}
              onClick={handleClick}
            >
              Login
            </Mui.Button>
          </Grid>
        </Grid>
      </Mui.Paper>
    );

  return (
    <Mui.Paper variant="outlined" elevation={0} className={classes.formBox}>
      <Mui.Box className={classes.logo}>
        <img src={logo} alt="Sibatel logo" className={classes.logoImg} />
      </Mui.Box>
      <Mui.Typography
        variant="h5"
        align="center"
        gutterBottom
        className={classes.title}
      >
        Create account
      </Mui.Typography>

      <Grid
        container
        direction="column"
        component="form"
        spacing={3}
        onSubmit={handleSubmit}
      >
        <DoublePassword
          Wrapper={Grid}
          WrapperProps={{ item: true }}
          placeholder1="New password"
          placeholder2="Confirm new password"
        />
        <Grid item>
          <Mui.Button
            fullWidth
            color="primary"
            variant="contained"
            disableElevation
            type="submit"
            className={classes.actionButton}
          >
            Reset Password
          </Mui.Button>
        </Grid>
      </Grid>
    </Mui.Paper>
  );
};

export default RecoverPasswordForm;

RecoverPasswordForm.propTypes = {
  id: PropTypes.string.isRequired,
  recoverParams: PropTypes.string.isRequired,
  recoverPassword: PropTypes.func.isRequired,
};
