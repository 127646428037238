import React, { useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import SettingsPage from "../../pages/SettingsPage";
import { TooltipIconButton } from "../../prestyledComponents";

import useStyles from "./UserMenuButton.styles";
import { indicatorStyles } from "../../collectionItems";
import {
  ACTIVE,
  DONT_DISTURB,
  LONG_INACTIVE,
} from "../../../store/types/status";
import { removeInactivityDetection } from "../../../utils/inactivity/detectInactivity";

export const UserMenuButton = function ({
  user,
  logout,
  changeStatus,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => setAnchorEl(null);

  const handleClick = (event) => setAnchorEl(event.target);

  const handleLogout = () => logout();

  const handleCloseDialog = () => setOpen(false);

  const handleOpenDialog = () => setOpen(true);

  const classes = useStyles();
  const indicatorClasses = indicatorStyles();
  const handleSetActive = () => {
    changeStatus(ACTIVE);
    handleClose();
  };
  const handleSetInactive = () => {
    changeStatus(DONT_DISTURB);
    removeInactivityDetection();
    handleClose();
  };
  const handleSetAway = () => {
    changeStatus(LONG_INACTIVE);
    removeInactivityDetection();
    handleClose();
  };
  return (
    <>
      <TooltipIconButton {...props} title="Menu" onClick={handleClick}>
        <Icons.Menu />
      </TooltipIconButton>
      <Mui.Menu
        keepMounted
        id="simple-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {user.role === "guest" ? null : (
          <Mui.ListItem button onClick={handleOpenDialog}>
            <Mui.ListItemIcon>
              <Mui.IconButton color="primary">
                <Icons.Settings />
              </Mui.IconButton>
            </Mui.ListItemIcon>
            <Mui.ListItemText primary={"Settings"} />
          </Mui.ListItem>
        )}
        <Mui.ListItem button onClick={handleLogout}>
          <Mui.ListItemIcon>
            <Mui.IconButton color="primary">
              <Icons.LogOut />
            </Mui.IconButton>
          </Mui.ListItemIcon>
          <Mui.ListItemText primary={"Logout"} />
        </Mui.ListItem>
        <Mui.ListItem button onClick={handleSetActive}>
          <Mui.ListItemIcon>
            <Mui.IconButton color="primary">
              <Mui.Badge
                classes={{ badge: indicatorClasses.active }}
                variant="dot"
                overlap="rectangle"
                badgeContent=" "
              ></Mui.Badge>
            </Mui.IconButton>
          </Mui.ListItemIcon>
          <Mui.ListItemText primary={"Active"} />
        </Mui.ListItem>
        <Mui.ListItem button onClick={handleSetInactive}>
          <Mui.ListItemIcon>
            <Mui.IconButton color="primary">
              <Mui.Badge
                classes={{ badge: indicatorClasses.dontDisturb }}
                variant="dot"
                overlap="rectangle"
                badgeContent=" "
              />
            </Mui.IconButton>
          </Mui.ListItemIcon>
          <Mui.ListItemText primary={"Don't disturb"} />
        </Mui.ListItem>
        <Mui.ListItem button onClick={handleSetAway}>
          <Mui.ListItemIcon>
            <Mui.IconButton color="primary">
              <Mui.Badge
                classes={{ badge: indicatorClasses.longInactive }}
                variant="dot"
                overlap="rectangle"
                badgeContent=" "
              ></Mui.Badge>
            </Mui.IconButton>
          </Mui.ListItemIcon>
          <Mui.ListItemText primary={"Away"} />
        </Mui.ListItem>
      </Mui.Menu>
      <Mui.Dialog
        open={open}
        onClose={handleCloseDialog}
        classes={{
          paper: classes.settings,
        }}
        aria-labelledby="form-dialog-title"
      >
        <Mui.DialogContent>
          <SettingsPage />
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.IconButton color="primary" onClick={handleCloseDialog}>
            <Icons.X />
          </Mui.IconButton>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default UserMenuButton;

UserMenuButton.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};
