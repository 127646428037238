import { createElement, useEffect, useState, useRef } from "react";
import { Avatar } from "@mui/material";
import { createRoot } from "react-dom/client";

import { tryOrLog } from "../utils/errors/tryOrLog";
import { current } from "immer";

const avatarImagePromise = new Promise((res) => {
  tryOrLog(() => {
    const container = document.createElement("div");
    const muiAvatar = createElement(() => {
      const ref = useRef(null);
      //TODO check if this work-around works
      useEffect(() => {
        if (ref.current) {
          console.log("REF:", ref.current);
          tryOrLog(() => {
            const svg = container.firstElementChild.firstElementChild;
            const serializedSvg = new XMLSerializer().serializeToString(svg);
            const base64Data = window.btoa(serializedSvg);
            const dataUrl = `data:image/svg+xml;base64,${base64Data}`;
            res(dataUrl);
          });
        }
      }, [ref.current]);
      return <Avatar />;
    });
    const root = createRoot(container);
    root.render(muiAvatar);
    // root.render(muiAvatar, () => {
    //     tryOrLog(() => {
    //         const svg = container.firstElementChild.firstElementChild;
    //         const serializedSvg = new XMLSerializer().serializeToString(svg);
    //         const base64Data = window.btoa(serializedSvg);
    //         const dataUrl = `data:image/svg+xml;base64,${base64Data}`;
    //         res(dataUrl);
    //     });
    // });
  });
});

export const useAvatarImageDataUrl = function () {
  const [image, setImage] = useState(undefined);

  useEffect(() => {
    avatarImagePromise.then(setImage);
    // eslint-disable-next-line
  }, []);

  return image;
};

export default useAvatarImageDataUrl;
