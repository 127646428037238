import * as Mui from "@mui/material";
import useStyles from "./TextDivider.styles";

function TextDivider({ text, className }) {
  const classes = useStyles();
  return (
    <Mui.Grid
      container
      alignItems="flex-start"
      spacing={1}
      className={className}
    >
      <Mui.Grid item>{text}</Mui.Grid>
      <Mui.Grid item xs>
        <Mui.Divider classes={{ root: classes.textDividerLine }} />
      </Mui.Grid>
    </Mui.Grid>
  );
}

export default TextDivider;
