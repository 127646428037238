/**
 * @returns {Promise<void>}
 */
export const sleep = function (time = 0) {
  return new Promise((res) => {
    setTimeout(res, time);
  });
};

export default sleep;
