import { connect } from "react-redux";

import { selectAllActiveChannelMessages } from "XmarsStore/selectors/index";

import Threads from "./Threads.component";

function sortingFunction(messaA, messaB) {
  if (messaA.lastReplyDate > messaB.lastReplyDate) {
    return -1;
  }
  if (messaA.lastReplyDate < messaB.lastReplyDate) {
    return 1;
  }
  return 0;
}

const mapStateToProps = (state) => {
  
  // const room = activeChatRoomOriginal(state);
  const allActiveChannelMessages = selectAllActiveChannelMessages(state);

  const messagesWithUnreadReplies = allActiveChannelMessages?.filter(
    (message) => {
      if (message.unreadReplies) {
        return message.unreadReplies.length > 0;
      } else {
        return false;
      }
    }
  );
  // const messagesWithUnreadReplies = room?.filter(
  //   (message) => {
  //     if (message.unreadReplies){
  //       return message.unreadReplies.length>0
  //     }else{
  //     return false
  //     }
  //   }
  // );
  
  const messages = allActiveChannelMessages.filter((message) => {
    if (message.replies) {
      return message.replies.length > 0;
    } else {
      return false;
    }
  });
//   const messages = room.filter(
//     (message) => {
//       if (message.replies){
//         return message.replies.length>0
//       }else{
//         return false
//       }
//     }
//  );
  messages.sort(sortingFunction);
  

  return {
    messages,
    messagesWithUnreadReplies,
  };
};

const ThreadsContainer = connect(mapStateToProps)(Threads);

export default ThreadsContainer;

/////////////
// const allActiveChannelMessages = selectAllActiveChannelMessages(state);

  // const messagesWithUnreadReplies = allActiveChannelMessages?.filter(
  //   (message) => {
  //     if (message.unreadReplies) {
  //       return message.unreadReplies.length > 0;
  //     } else {
  //       return false;
  //     }
  //   }
  // );

  // const messages = allActiveChannelMessages.filter((message) => {
  //   if (message.replies) {
  //     return message.replies.length > 0;
  //   } else {
  //     return false;
  //   }
  // });
  // messages.sort(sortingFunction);

//   return {
//     messages,
//     messagesWithUnreadReplies,
//   };
// };

// const ThreadsContainer = connect(mapStateToProps)(Threads);

// export default ThreadsContainer;

