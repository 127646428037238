const fn = () => {};

export const JitsiMockJS = {
  events: {},
  logLevels: {},
  init: fn,
  setLogLevel: fn,
};

export default JitsiMockJS;
