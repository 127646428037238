import api from "../../../../dataApi";
import * as pushNotification from "../../../../serviceWorkerSubscription";
import { checkFirstTime, handleSagaError } from "../../sagas.utils";

import { actions as act } from "../../../actions";
import * as actions from "../../../actions";
import { call, put, delay } from "redux-saga/effects";
let duringHandshake = false;

export const handshake = function* () {
  try {
    console.log("starting handshake");
    yield call(api.handshake);
  } catch (error) {
    yield put(act.general.setPage("login"));
    if (!error.message.match("Please authenticate")) handleSagaError(error);
    return;
  }
  yield put(act.general.setPage("home"));
  yield put(actions.getUser());
  yield put(actions.startUserSubscription());
  // yield put(actions.getFriends());
  yield put(actions.getWorkspaces());
  // yield put(actions.getRooms());
  yield put(actions.getNotifications());
  yield call(api.onReconnected, () => {
    console.warn("onReconnected");
    // store.dispatch(actions.setConnected(true));
  });
  yield call(api.onDisconnected, () => {
    console.warn("onDisconnected");
    // store.dispatch(actions.setConnected(false));
  });
  yield call(pushNotification.subscribe);
  yield call(checkFirstTime);
  duringHandshake = true;
  // yield takeEvery(act.chat.addRoom, waitForAllRooms);
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
const waitForAllRooms = function* () {
  if (duringHandshake) {
    //TODO test this further
    yield delay(200);
    yield put(actions.setFirstRoom());
    duringHandshake = false;
  }
};
