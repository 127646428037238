import * as chat from "../slices/chat";
import * as form from "../slices/form";
import * as general from "../slices/general";
import * as jitsi from "../slices/jitsi";
import * as messages from "../slices/messages";
import * as notifications from "../slices/notifications";
import * as snackbars from "../slices/snackbars";
import * as video from "../slices/video";
import * as search from "../slices/search";
import * as sagas from "./sagas";

export * from "../slices/chat";
export * from "../slices/form";
export * from "../slices/general";
export * from "../slices/jitsi";
export * from "../slices/messages";
export * from "../slices/notifications";
export * from "../slices/snackbars";
export * from "../slices/video";
export * from "../slices/search";

export * from "./sagas";

export const actions = {
  chat,
  form,
  general,
  jitsi,
  messages,
  notifications,
  snackbars,
  video,
  search,
  sagas,
};
