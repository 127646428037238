import { connect } from "react-redux";

import GetAppUserButton from "./GetAppUserButton.component";

const mapStateToProps = ({ chat }) => {
  return { user: chat.user };
};

const mapDispatchToProps = () => ({});

const GetAppUserButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GetAppUserButton);

export default GetAppUserButtonContainer;
