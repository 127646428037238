import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { UsernameTextField } from "../../inputs";
import { SearchSubmitButton } from "../../prestyledComponents";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";

export const SearchFriendsForm = function ({ searchFriends }) {
  const handleSubmit = formSubmitHandler(({ username }) => {
    searchFriends(username);
  });

  return (
    <form onSubmit={handleSubmit}>
      <UsernameTextField
        InputProps={{
          endAdornment: (
            <Mui.InputAdornment>
              <SearchSubmitButton />
            </Mui.InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default SearchFriendsForm;

SearchFriendsForm.propTypes = {
  searchFriends: PropTypes.func.isRequired,
};
