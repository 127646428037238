import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import env from "../env";
import logger from "./middleware/logger";
import rootSaga from "./sagas";
import reducer from "./slices";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer,
  middleware(getDefaultMiddleware) {
    const mw = getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware);
    if (env.DEVELOPMENT) {
      mw.push(logger);
    }
    return mw;
  },
});

sagaMiddleware.run(rootSaga);

//FOR STORYBOOK

// export const Reducer = reducer

// export const storee =  store

// export default {store, reducer};

//NORMAL

export default store;
