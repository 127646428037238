import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    "&$selected": {
      borderRadius: "15px",
      backgroundColor: theme.palette.primary.light,

      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },

    "&:hover": {
      borderRadius: 0,
      backgroundColor: "transparent",
    },
  },
  selected: {},
  primaryText: {
    fontSize: 14,
    color: theme.palette.sidebarItems.primary,
    lineHeight: 1.2,
    marginBottom: "0.2rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 400,
    maxHeight: "34px",
    textTransform: "capitalize",
  },
  secondary: {
    color: theme.palette.sidebarItems.secondary,
    lineHeight: 1.5,
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "89%",
  },
  secondarySelected: {
    color: theme.palette.sidebarItems.secondarySelected,
    fontWeight: 600,
    lineHeight: 1.5,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "90%",
  },
  filteredText: {
    fontSize: "80%",
    lineHeight: 1.5,
    position: "absolute",
    right: "94px",
    top: "12px",
  },
  filteredTextSelected: {
    color: theme.palette.textGray.inputPlaceholder,
  },
  filteredTextNoSelected: {
    color: theme.palette.textGray.inputPlaceholder,
  },
  item: {
    padding: "8px 20px",
    fontSize: 14,
    alignItems: "normal",
  },
  itemText: {
    marginTop: "0px",
    marginBottom: "0px",
    maxWidth: "141px",
    wordBreak: "break-word",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("md")]: {
      maxWidth: "29%",
    },
  },
  messageMenu: {
    // display: "flex",
    // flexDirection: "row-reverse",
    position: "absolute",
    right: "15px",

    height: "12px",
    // "&:hover": {
    //   backgroundColor: "rgba(255, 255, 255, 0.0)",
    // },
  },

  bold: {
    fontWeight: "bold",
  },

  contactsButton: {
    top: "8px",
  },
  conversationsButton: {
    top: "31px",
  },
  messageMenuIcon: {
    transform: "scale(1.5)",
  },
  conversationMenuOptionItem: {
    padding: "0px 10px",
  },
  conversationMenuOptionIcon: {
    minWidth: "24px",
  },
  conversationMenuOptionText: {
    fontSize: "13px",
    fontWeight: "300",
    color: theme.palette.buttons.main1,
  },

  numberUnread: {
    color: "white",
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    display: "flex",
    textAlign: "center",
    verticalAlign: "middle",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 10,

    //marginRight: "45px",
    position: "absolute",

    top: "30px",
  },

  numberUnreadMessages: {
    backgroundColor: theme.palette.sidebarItems.primarySelected,
    right: "71px",
  },
  numberUnreadReplies: {
    backgroundColor: theme.palette.secondary.main,
    right: "50px",
  },

  extraInfo: {},
}));
