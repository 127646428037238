import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FoundContacts from "./FoundContacts.component";
import { searchPageAndSection } from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { searchedValue, foundContacts, totalResults } = state.search;
  const { status, user } = state.chat;
  return {
    status,
    userId: user.id,
    searchedValue,
    foundContacts,
    totalContacts: totalResults.contacts,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ searchPageAndSection }, dispatch);

const FoundContactsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FoundContacts);

export default FoundContactsContainer;
