import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SidebarItem from "./SidebarItem.component";

import {
  deleteConversation,
  leaveRoom,
  // setRoom,
  unfriend,
} from "../../../store/actions/sagas";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const { user, panel, friends } = state.chat;
  return {
    mainRoom: room,
    user,
    panel,
    friends,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteConversation,
      leaveRoom,
      // setRoom,
      unfriend,
    },
    dispatch
  );

const SidebarItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarItem);

export default SidebarItemContainer;
