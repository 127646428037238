import client from "../../../client";
import query from "./getClientSecret.query";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 * @returns {Promise<{ clientSecret: string }>}
 */
export const getClientSecret = async function (id) {
  id = addBearer(id);
  const { data } = await client.query({
    query,
    variables: {
      id,
    },
  });
  return data.getClientSecret;
};

export default getClientSecret;
