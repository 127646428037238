import { connect } from "react-redux";

import LocalParticipant from "./LocalParticipant.component";

const mapStateToProps = (state) => {
  const { sharingScreen } = state.jitsi.localParticipant;

  return { sharingScreen };
};

const LocalParticipantContainer = connect(
  mapStateToProps,
  null
)(LocalParticipant);

export default LocalParticipantContainer;
