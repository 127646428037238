import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
  },
  processingProgress: {
    color: theme.palette.common.white,
  },
}));
