import client from "../../../client";
import mutation from "./readMessage.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 */
export const readMessage = async function (id, replyId) {
  id = addBearer(id);
  replyId = addBearer(replyId);

  const {
    data: { readMessage },
  } = await client.mutate({
    mutation,
    variables: {
      id,
      replyId,
    },
  });

  return readMessage;
};

export default readMessage;

///////////////////////////

// import client from "../../../client";
// import { addBearer } from "../../../utils";
// import mutation from "./startedTyping.mutation";

// export const startedTyping = async function (id, isWriting) {
//     id = addBearer(id)
//     await client.mutate({
//         mutation,
//         variables: {
//             id,
//             isWriting
//         },
//     });
// };

// export default startedTyping;
