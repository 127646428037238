import { gql } from "../../../utils/apollo";

export const searchByUsername = gql`
  query SearchByUsername($username: String) {
    searchByUsername(input: { username: $username }) {
      id
      username
      avatar
    }
  }
`;

export default searchByUsername;
