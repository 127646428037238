import { groupLog } from "XmarsUtils/groupLog";
import * as signalNames from "./signalNames";

export const createConferenceHandlers = (emitter) => {
  return {
    onUserJoined(id, participant) {
      if (participant._statsID.startsWith("jibri")) {
        console.log("panic: jibri joined");
      }

      groupLog("conference => onUserJoined", () => {
        console.log("id:", id);
        console.log("participant:", participant);
      });

      emitter({
        signal: signalNames.USER_JOINED,
        payload: {
          id,
          participant,
        },
      });
    },
    onUserLeft(id, participant) {
      groupLog("conference => onUserLeft", () => {
        console.log("id:", id);
        console.log("participant:", participant);
      });
      emitter({
        signal: signalNames.USER_LEFT,
        payload: {
          id,
          participant,
        },
      });
    },
    onTrackAdded(track) {
      groupLog("conference => onTrackAdded", () => {
        console.log("track:", track);
      });
      emitter({
        signal: signalNames.TRACK_ADDED,
        payload: track,
      });
    },
    onTrackRemoved(track) {
      groupLog("conference => onTrackRemoved", () => {
        console.log("track:", track);
      });
      emitter({
        signal: signalNames.TRACK_REMOVED,
        payload: track,
      });
    },
    onConferenceJoined() {
      groupLog("conference => onConferenceJoined");
      emitter({
        signal: signalNames.CONFERENCE_JOINED,
        payload: "congratulations you joined a conference",
      });
    },
    onConferenceLeft() {
      groupLog("conference => onConferenceLeft");
      emitter({
        signal: signalNames.CONFERENCE_LEFT,
      });
    },
    onConferenceError(errorCode) {
      groupLog("conference => onConferenceError", () => {
        console.log("errorCode:", errorCode);
      });
      emitter({
        signal: signalNames.CONFERENCE_JOINED,
        payload: "there was an error, is the call ending? should be ending?",
      });
    },
    onConferenceFailed(errorCode) {
      groupLog("conference => onConferenceFailed", () => {
        console.log("errorCode:", errorCode);
      });
      emitter({
        signal: signalNames.CONFERENCE_FAILED,
        payload: errorCode,
      });
    },
    onKicked(myself) {
      groupLog("You were kicked", () => {
        console.log("myself:", myself);
      });
      emitter({
        signal: signalNames.KICKED,
        payload: myself,
      });
    },
    onRecorderStateChanged(session) {
      groupLog("conference => onRecorderStateChanged", () => {
        console.log(session);
        console.log("status", session._status || session._error);
      });
      emitter({
        signal: signalNames.RECORDER_STATE_CHANGED,
        payload: "",
      });
    },
    onDisplayNameChanged(id, name) {
      groupLog("conference => onDisplayNameChanged", () => {
        console.log("id:", id);
        console.log("name:", name);
      });
      emitter({
        signal: signalNames.RECORDER_STATE_CHANGED,
        payload: {
          id,
          name,
        },
      });
    },
    onMessageReceived(id, jsonMessage) {
      groupLog("conference => onMessageReceived", () => {
        console.log("id:", id);
        console.log("message:", jsonMessage);
      });
      emitter({
        signal: signalNames.MESSAGE_RECEIVED,
        payload: {
          id,
          jsonMessage,
        },
      });
    },
  };
};
