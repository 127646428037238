import { gql } from "../../../utils/apollo";

export const removeAvatar = gql`
  mutation RemoveAvatar {
    removeAvatar {
      null
    }
  }
`;

export default removeAvatar;
