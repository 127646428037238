import { gql } from "../../../utils/apollo";

export const readMessage = gql`
  mutation ReadMessage($id: ID, $replyId: ID) {
    readMessage(input: { id: $id, replyId: $replyId }) {
      id
      dateOldestUnreadMessage
      unreadMessages {
        total
        replies
      }
    }
  }
`;

export default readMessage;
