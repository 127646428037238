import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => {
  const color = theme.palette.primary.main;
  return {
    root: {
      display: "inline-block",
      position: "relative",
      width: "100%",
      height: "100%",
      padding: 0,
    },
    dropZone: {
      border: `dashed ${color} 0.25em`,
      backgroundColor: theme.palette.common.white,
      opacity: 0.8,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 9999,
    },
    fullHeight: {
      height: "100%",
    },
    iconContainer: {
      transform: "scale(2)",
    },
    icon: {
      color,
    },
  };
});
