import client from "../../../client";
import mutation from "./createWorkspace.mutation";

/**
 * @param {string} title
 * @param {string} description
 * @param {string} avatar
 * @returns {Promise<{
 *  id: string,
 *  title: string,
 *  createdAt: date,
 *  description: string,
 *  avatar: string
 * }>}
 */
export const createWorkspace = async function (title, description, avatar) {
  const { data } = await client.mutate({
    mutation,
    variables: {
      title,
      description,
      avatar,
    },
  });
  console.log(data);
  return data.createWorkspace;
};

export default createWorkspace;
