import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  DoublePassword,
  EmailTextField,
  UsernameTextField,
} from "../../inputs";
import { LinearSubmitButton } from "../../prestyledComponents";
import { useForm } from "../../../hooks/useForm";
import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import useStyles from "./CreateUserForm.styles";
import logo from "../../../assets/images/logo-sb.png";

const formName = Symbol("createUser");

export const CreateUserForm = function ({ createUser, setPage, onSubmit }) {
  const { submitting } = useForm(formName);
  const classes = useStyles();

  const handleSubmit = formSubmitHandler(
    async ({ username, email, password }, event) => {
      createUser({ username, email, password, formName });
      if (onSubmit) onSubmit(event);
    }
  );

  const handleLogin = () => setPage("login");

  return (
    <Mui.Paper variant="outlined" elevation={0} className={classes.formBox}>
      <Mui.Box className={classes.logo}>
        <img src={logo} alt="Sibatel logo" className={classes.logoImg} />
      </Mui.Box>
      <Mui.Typography
        variant="h5"
        align="center"
        gutterBottom
        className={classes.title}
      >
        Create account
      </Mui.Typography>

      <Grid
        container
        direction="column"
        spacing={3}
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid item>
          <UsernameTextField
            disabled={submitting}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item>
          <EmailTextField disabled={submitting} />
        </Grid>
        <DoublePassword
          disabled={submitting}
          Wrapper={Grid}
          WrapperProps={{ item: true }}
          placeholder1="Password"
          placeholder2="Confirm password"
        />
        <Grid item className={classes.removeBottomPadding}>
          <LinearSubmitButton
            loading={submitting}
            children="Register"
            className={classes.actionButton}
          />
        </Grid>
        <Mui.Divider className={classes.divider} variant="middle" />
        <Grid
          item
          className={clsx(
            classes.linkBox,
            classes.removeVPadding,
            classes.addMarginBottom
          )}
        >
          <Mui.Typography>{"Already have an account?"}</Mui.Typography>
        </Grid>
        <Grid item className={clsx(classes.linkBox, classes.removeTopPadding)}>
          <Mui.Link
            onClick={handleLogin}
            variant="button"
            className={classes.routeButton}
          >
            Sign in!
          </Mui.Link>
        </Grid>
      </Grid>
    </Mui.Paper>
  );
};

export default CreateUserForm;

CreateUserForm.propTypes = {
  createUser: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};
