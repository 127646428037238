import { gql } from "../../../utils/apollo";

export const createUser = gql`
  mutation CreateUser($username: String, $email: String, $password: String) {
    createUser(
      input: { username: $username, email: $email, password: $password }
    ) {
      username
    }
  }
`;

export default createUser;
