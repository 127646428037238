import * as dayjs from "dayjs";

const getNumbers = (substract, add, dates) => {
  let substactNumber,
    addNumber,
    datesNumber = Number(dates);
  if (substract === "-∞") {
    substactNumber = Infinity;
  } else {
    substactNumber = -Number(substract);
  }

  if (add === "∞") {
    addNumber = Infinity;
  } else {
    addNumber = Number(add);
  }
  return [substactNumber, addNumber, datesNumber];
};

const getDateBounds = (numberOfDates, initDate, finalDate) => {
  let init, final;
  switch (numberOfDates) {
    case 0: {
      init = dayjs().startOf("day");
      break;
    }

    case 1: {
      init = dayjs(initDate).startOf("day");
      break;
    }
    case 2: {
      init = dayjs(initDate).startOf("day");
      final = dayjs(finalDate);
      break;
    }

    default:
      break;
  }
  return [init, final];
};

const getMsBounds = (initDate, finalDate, substract, add) => {
  let after, before;
  if (finalDate !== undefined) {
    after = initDate.valueOf();
    before = finalDate.add(add, "day").valueOf();
    return { after, before };
  }

  if (substract === Infinity) {
    after = 0;
  } else {
    after = initDate.subtract(substract, "day").valueOf();
  }

  if (add === Infinity) {
    before = Number.MAX_VALUE;
  } else {
    before = initDate.add(add, "day").valueOf();
  }
  return { after, before };
};

export const getDateRange = (inputString, initDate, finalDate) => {
  const [substract, add, dates] = inputString.split(",");

  const [substactNumber, addNumber, datesNumber] = getNumbers(
    substract,
    add,
    dates
  );

  const [init, final] = getDateBounds(datesNumber, initDate, finalDate);

  return getMsBounds(init, final, substactNumber, addNumber);
};

export default getDateRange;
