import client from "../../../client";
import query from "./getUser.query";

/**
 * @returns {Promise<{
 *  id: string,
 *  username: string,
 *  avatar: string,
 *  role: string,
 * }>}
 */
export const getUser = async function () {
  const { data } = await client.query({ query });
  return data.getUser;
};

export default getUser;
