import { gql } from "../../../utils/apollo";

export const searchByEmail = gql`
  query SearchByEmail($emailList: [String]) {
    searchByEmail(input: { emailList: $emailList }) {
      id
      username
      avatar
    }
  }
`;

export default searchByEmail;
