import React, { useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import clsx from "clsx";
import { TooltipIconButton } from "../../prestyledComponents";
import { Scrollbars } from "react-custom-scrollbars";
import { stringAvatar } from "../../../utils/defaultAvatar/defaultAvatar";
import useStyles from "./AddToGroupButton.styles";
import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";
import AddUserToRooms from "../../AddUserToRooms";
import { useMounted } from "../../../hooks/index";

/**
 *
 * @returns React.component
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const AddToGroupButton = function ({
  userToAddName,
  rooms,
  currentRoom,
  addUserToRooms,
}) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [groupsToAdd, setGroupsToAdd] = useState([]);
  const [checkedGroups, setCheckedGroups] = useState([]);

  const [filter, setFilter] = useState("");

  const mounted = useMounted();

  const handleToggle = (id) => () => {
    const currentIndex = checkedGroups.indexOf(id);
    const newCheckedGroups = [...checkedGroups];
    if (currentIndex === -1) {
      newCheckedGroups.push(id);
    } else {
      newCheckedGroups.splice(currentIndex, 1);
    }
    setCheckedGroups(newCheckedGroups);
    const checkedRooms = rooms.filter(({ id }) =>
      newCheckedGroups.includes(id)
    );
    setGroupsToAdd(checkedRooms);
  };

  const handleCloseMenu = function () {
    setMenuOpen(false);
    setGroupsToAdd([]);
    setCheckedGroups([]);
  };

  const handleSubmit = formSubmitHandler(async (_, event) => {
    try {
      setProcessing(true);
      if (!groupsToAdd.length) return;
      await getBoundActionPromise(addUserToRooms, {
        user: currentRoom.friend,
        rooms: groupsToAdd,
        userRoomId: currentRoom.id,
      });
    } catch (error) {
      logError(error);
    } finally {
      handleCloseMenu();
      if (mounted.current) {
        setProcessing(false);
      }
    }
  });

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <Mui.ListItemIcon className={classes.marginLeftBottom}>
        <TooltipIconButton
          title="Add to group"
          onClick={() => {
            setMenuOpen(true);
          }}
          className={classes.iconButton}
          children={<Icons.UserPlus />}
        />
      </Mui.ListItemIcon>
      {menuOpen && (
        <Mui.Dialog
          open={menuOpen}
          onClose={handleCloseMenu}
          aria-labelledby="form-dialog-title"
          PaperProps={{ elevation: 0 }}
          classes={{
            root: classes.dialogRoot,
            paper: classes.dialog,
            container: classes.dialogContainer,
          }}
        >
          <Mui.DialogTitle
            disableTypography
            id="form-dialog-title"
            className={classes.dialogHeader}
          >
            <Icons.Users style={{ height: "18px", width: "18px" }} />
            <Mui.Typography variant="h6" className={classes.headerText}>
              Add {userToAddName} to groups
            </Mui.Typography>
            <Mui.IconButton
              className={classes.closeButton}
              onClick={handleCloseMenu}
            >
              <Icons.X style={{ strokeWidth: "2.5px" }} />
            </Mui.IconButton>
          </Mui.DialogTitle>

          <Mui.DialogContent className={classes.dialogContent}>
            <Mui.TextField
              margin="dense"
              autoComplete="off"
              autoFocus
              variant="outlined"
              onChange={handleChange}
              className={clsx(classes.searchBar, classes.background)}
              placeholder="Search Groups"
              InputProps={{
                className: classes.input,
                notched: false,
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  notchedOutline: classes.inputNotchedOutline,
                },
              }}
              inputProps={{ className: classes.inputBase }}
            />
            <form onSubmit={handleSubmit} id="add-to-group-form">
              <Scrollbars
                autoHeight
                autoHeightMin={50}
                autoHeightMax={450}
                //autoWidth
              >
                <Mui.Grid
                  container
                  spacing={1}
                  className={clsx(
                    classes.avatars,
                    !groupsToAdd.length ? classes.avatarsEmpty : null
                  )}
                  wrap="nowrap"
                >
                  {!groupsToAdd.length ? (
                    <Mui.Grid item>
                      <Mui.Avatar
                        alt="default icon"
                        className={clsx(
                          classes.avatarUnit,
                          classes.avatarListSize
                        )}
                        classes={{
                          colorDefault: classes.defaultAvatarColors,
                        }}
                      >
                        <Icons.Users />
                      </Mui.Avatar>
                    </Mui.Grid>
                  ) : (
                    groupsToAdd.map((group) => {
                      const toggleHandler = handleToggle(group.id);
                      return (
                        <Mui.Grid
                          item
                          key={group.id}
                          component={Mui.Tooltip}
                          title={group.title}
                          placement="top"
                        >
                          <Mui.Badge
                            badgeContent={<Icons.X />}
                            overlap="circle"
                            onClick={toggleHandler}
                            style={{
                              cursor: "pointer",
                            }}
                            classes={{
                              badge: classes.avatarBadge,
                            }}
                          >
                            <Mui.Avatar
                              src={group.avatar}
                              alt={group.title}
                              {...stringAvatar(group.title)}
                              className={clsx(
                                classes.avatarUnit,
                                classes.avatarListSize
                              )}
                            ></Mui.Avatar>
                          </Mui.Badge>
                        </Mui.Grid>
                      );
                    })
                  )}
                </Mui.Grid>
              </Scrollbars>
              <AddUserToRooms
                checked={checkedGroups}
                handleToggle={handleToggle}
                filter={filter}
              />
            </form>
          </Mui.DialogContent>

          {
            <Mui.DialogActions className={classes.dialogFooter}>
              <Mui.Button
                type="submit"
                form="add-to-group-form"
                className={classes.actionButton}
                disabled={processing}
              >
                Add to groups
              </Mui.Button>
            </Mui.DialogActions>
          }
        </Mui.Dialog>
      )}
    </>
  );
};

export default AddToGroupButton;
