import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CreateWorkspaceButton from "./CreateWorkspaceButton.component";

import { createWorkspace, updateWorkspace } from "../../../store/actions/index";
import { selectActiveWorkspace } from "../../../store/selectors";
// import { activeChatRoom } from "XmarsStore/selectors";

const mapStateToProps = (state) => {
  const currentWorkspace = selectActiveWorkspace(state);
  return { currentWorkspace };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // createCustomRoom,
      // addUsersToRoom,
      createWorkspace,
      updateWorkspace,
    },
    dispatch
  );

const CreateWorkspaceButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(CreateWorkspaceButton);

export default CreateWorkspaceButtonContainer;
