import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import RemoveUserFromCall from "./RemoveUserFromCall.component";
import { conferenceObjectControl } from "../../store/actions/index";
import { selectActiveWorkspace } from "XmarsStore/selectors";

const mapStateToProps = (state) => {
  const currentWorkspace = selectActiveWorkspace(state);

  return {
    currentWorkspace,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      conferenceObjectControl,
    },
    dispatch
  );

const RemoveUserFromCallContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveUserFromCall);

export default RemoveUserFromCallContainer;
