import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  avatar: {},
  contactContainer: {
    display: "flex",
    marginBottom: "5px",
  },
  isYouLabel: {
    fontSize: "12px",
    color: theme.palette.textGray.main,
    marginLeft: "5px",
  },
  foundText: {
    backgroundColor: theme.palette.messages.highlightedBg,
    //fontWeight: "500",
    border: `1px solid ${theme.palette.messages.highlightedBg}`,
  },
  muiButton: {
    textTransform: "none",
    width: "110px",
  },
}));
