import { useEffect } from "react";
import PropTypes from "prop-types";

export default function IncomingCallBackdrop({ incomingCall, setPage }) {
  useEffect(() => {
    if (incomingCall) setPage("videoCall");
  }, [incomingCall]);

  return null;
}

IncomingCallBackdrop.propTypes = {
  incomingCall: PropTypes.object,
};
