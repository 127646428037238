import { takeEvery, call } from "redux-saga/effects";
import * as actions from "XmarsStore/actions";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";

export function* conferenceObjectControl(conference) {
  yield takeEvery(
    actions.conferenceObjectControl,
    objectControlHandler,
    conference
  );
}

function* objectControlHandler(conference, { payload }) {
  try {
    const { method, args } = payload;
    if (method === "setReceiverConstraints") {
      conference.setReceiverConstraints(args);
      return;
    }

    if (method === "removeFromConference") {
      for (let userId of args) {
        conference.kickParticipant(userId);
      }
      return;
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}
