import state from "../state";
import mockApi from "../mockApi";
import {
  queryGroupLog,
  queryMainLog,
  mutationGroupLog,
  mutationMainLog,
  subscriptionGroupLog,
  subscriptionMainLog,
  logGqlInfo,
} from "./loggers";

// ------------------------------------------------------------------------------------------------------------------------
export const wrapQuery = function (client) {
  const clientQuery = client.query;
  client.query = async function ({ query, variables }) {
    if (state.log)
      logGqlInfo("Query", query, variables, queryMainLog, queryGroupLog);
    if (state.mock) return mockApi(query, variables);
    return await clientQuery({ query, variables });
  };
};
// ------------------------------------------------------------------------------------------------------------------------
export const wrapMutation = function (client) {
  const clientMutate = client.mutate;
  client.mutate = async function ({ mutation, variables }) {
    if (state.log)
      logGqlInfo(
        "Mutation",
        mutation,
        variables,
        mutationMainLog,
        mutationGroupLog
      );
    if (state.mock) return mockApi(mutation, variables);
    return await clientMutate({ mutation, variables });
  };
};
// ------------------------------------------------------------------------------------------------------------------------
export const wrapSubscribe = function (client) {
  const clientSubscribe = client.subscribe.bind(client);
  client.subscribe = function ({ query, variables }) {
    if (state.log)
      logGqlInfo(
        "Subscription",
        query,
        variables,
        subscriptionMainLog,
        subscriptionGroupLog
      );
    if (state.mock) return mockApi(query, variables);
    return clientSubscribe({ query, variables });
  };
};
