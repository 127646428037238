export const isInsideBbox = function (
  smallObj,
  bigBox,
  { type = "dot" /*box*/ } = {}
) {
  switch (type) {
    case "box":
      return (
        smallObj.left >= bigBox.left &&
        smallObj.right <= bigBox.right &&
        smallObj.top >= bigBox.top &&
        smallObj.bottom <= bigBox.bottom
      );
    case "dot":
      return (
        smallObj.x >= bigBox.left &&
        smallObj.x <= bigBox.right &&
        smallObj.y >= bigBox.top &&
        smallObj.y <= bigBox.bottom
      );
    default:
      console.warn("isInsideBbox", `unhandled type ${type}`);
      return false;
  }
};

export default isInsideBbox;
