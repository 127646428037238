import * as actions from "XmarsStore/actions";
import { createVideoTrackEventHandlers } from "./createVideoTrackEventHandlers";

import { take, takeEvery, call } from "redux-saga/effects";

import {
  registerEventHandlers,
  unregisterEventHandlers,
} from "XmarsJitsiMeetJS/index";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";

export function* listenRegisterVideoComponent(track) {
  try {
    yield takeEvery(
      actions.jitsiRegisterVideoComponent,
      registerVideoComponent,
      track
    );
  } catch (error) {
    yield call(handleSagaError, error);
  }
}

function* registerVideoComponent(track, { payload }) {
  const videoComponent = payload;
  try {
    const trackEventHandlers = createVideoTrackEventHandlers(
      videoComponent,
      track
    );
    registerEventHandlers("track", track, trackEventHandlers);
    track.attach(videoComponent);
    yield take(actions.jitsiUnregisterVideoComponent);
    unregisterEventHandlers("track", track, trackEventHandlers);
  } catch (error) {
    yield call(handleSagaError, error);
  }
}
