import client from "../../../client";
import query from "./subscribeToRoom.subscription";
import { addBearer } from "../../../utils";

/**
 * @typedef {Object} RoomSubscriptionEvent
 * @property {string} signalType
 * @property {{
 *  id: string,
 *  messageType: string,
 *  edited: string,
 *  status: string,
 *  text: string,
 *  scribble: string,
 *  form: string,
 *  createdAt: number,
 *  owner: {
 *      avatar: string,
 *      username: string,
 *  },
 *  readers: Array<{
 *      id: string,
 *      avatar: string,
 *      username: string,
 *  }>,
 *  form: string,
 * }} message
 * @property {{
 *  title: string,
 *  admin: boolean,
 * }} room
 */

/**
 * @param {Object} args
 * @param {string} args.id
 * @param {Object} subcription
 * @param {(event: RoomSubscriptionEvent) => void} subcription.next
 * @param {(error: Error) => void} [subcription.error]
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const subscribeToRoom = function (
  { id },
  { next, error = console.error }
) {
  id = addBearer(id);
  const observable = client.subscribe({
    query,
    variables: {
      id,
    },
  });
  return observable.subscribe({
    next({ data }) {
      next(data.subscribeToRoom);
    },
    error,
  });
};

export default subscribeToRoom;
