import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CreateDirectMessageButton from "./CreateDirectMessageButton.component";

import { getOneVsOneRoom, getRoom } from "../../../store/actions/index";
import {
  selectActiveWorkspace,
  selectAllActiveWorkspaceCategories,
  selectUser,
  selectUserStatus,
} from "XmarsStore/selectors";

const mapStateToProps = (state) => {
  const categories = selectAllActiveWorkspaceCategories(state);
  const currentWorkspace = selectActiveWorkspace(state);
  const status = selectUserStatus(state);
  const user = selectUser(state);
  return {
    categories,
    currentWorkspace,
    user,
    status,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOneVsOneRoom,
      getRoom,
    },
    dispatch
  );

const CreateDirectMessageButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDirectMessageButton);

export default CreateDirectMessageButtonContainer;
