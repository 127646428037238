import { forwardRef, useState, useEffect, useRef } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";

import {
  TooltipIconButton,
  LinearSubmitButton,
  Tooltip,
} from "../../prestyledComponents";
import { FilesInput } from "../../inputs";
import { EmojisButton } from "../../pseudoInputs";

import useStyles from "./CreateWorkspaceButton.styles";
import { useMounted } from "../../../hooks/useMounted";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";
import { blobToDataUrl } from "../../../utils/converters/blobToDataUrl";

export const CreateWorkspaceButton = forwardRef(function (
  { currentWorkspace, createWorkspace, updateWorkspace, isCreating, ...props },
  ref
) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [processing, setProcessing] = useState(false);
  const [focusTitle, setFocusTitle] = useState(false);
  const [avatarImg, setAvatarImg] = useState(null);

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  const classes = useStyles();
  const mounted = useMounted();

  useEffect(() => {
    if (open) {
      setValue(isCreating ? "" : currentWorkspace.title);
      setDescriptionValue(isCreating ? "" : currentWorkspace.description);
      setAvatarImg(isCreating ? null : currentWorkspace.avatar);
      setFocusTitle(true);
    } else {
      setFocusTitle(false);
    }
  }, [open]);

  useEffect(() => {
    if (focusTitle && titleRef.current) titleRef.current.focus();
  }, [focusTitle]);

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = formSubmitHandler(async (_, event) => {
    try {
      setProcessing(true);
      if (isCreating) {
        if (value !== "") {
          await getBoundActionPromise(createWorkspace, {
            title: value,
            description: descriptionValue,
            avatar: avatarImg,
          });
        }
      } else {
        if (value !== "") {
          await getBoundActionPromise(updateWorkspace, {
            newTitle: value,
            newDescription: descriptionValue,
            newAvatar: avatarImg,
            workspaceId: currentWorkspace.id,
          });
        }
      }

      if (handleClose) handleClose(event);
    } catch (error) {
      logError(error);
    } finally {
      if (mounted.current) {
        setProcessing(false);
      }
    }
  });

  const handleTitleChange = function (event) {
    setValue(event.target.value);
  };

  const handleDescriptionChange = function (event) {
    setDescriptionValue(event.target.value);
  };

  const handleEmoji = function (emoji) {
    setValue(value + emoji);
  };

  const inputImage = async function (event) {
    // event.preventDefault()
    const file = event.target.files[0];
    if (!file) return;
    const newAvatar = await blobToDataUrl(file);
    setAvatarImg(newAvatar);
  };

  const handleCancelImg = (e) => {
    // e.preventDefault();
    setAvatarImg(null);
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {isCreating ? (
        <TooltipIconButton
          {...props}
          ref={ref}
          title={isCreating ? "new workspace" : "edit workspace"}
          className={classes.iconButton}
          onClick={handleClick}
        >
          <Icons.Plus />
        </TooltipIconButton>
      ) : (
        <Mui.MenuItem onClick={handleClick}>Edit workspace</Mui.MenuItem>
      )}

      {open && (
        <Mui.Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          PaperProps={{ elevation: 0 }}
          classes={{
            root: classes.dialogRoot,
            paper: classes.dialog,
            container: classes.dialogContainer,
          }}
        >
          <Mui.DialogTitle
            disableTypography
            id="form-dialog-title"
            className={classes.dialogHeader}
          >
            {isCreating ? (
              <Icons.Briefcase style={{ height: "18px", width: "18px" }} />
            ) : (
              <Icons.Edit3 style={{ height: "18px", width: "18px" }} />
            )}
            <Mui.Typography variant="h6" className={classes.headerText}>
              {isCreating ? "New Workspace" : "Edit Workspace"}
            </Mui.Typography>
            <Mui.IconButton
              className={classes.closeButton}
              onClick={handleClose}
            >
              <Icons.X style={{ strokeWidth: "2.5px" }} />
            </Mui.IconButton>
          </Mui.DialogTitle>
          <Mui.DialogContent className={classes.dialogContent}>
            <form onSubmit={handleSubmit} id="create-ws-form">
              <Mui.Grid classes={{ root: classes.avatarContainer }}>
                {avatarImg ? (
                  <Mui.Avatar
                    src={avatarImg}
                    classes={{ root: classes.avatar }}
                  />
                ) : (
                  <Mui.Avatar src="" classes={{ root: classes.avatar }} />
                )}
              </Mui.Grid>
              <Mui.Typography
                className={classes.label}
                variant="body1"
                component="label"
                htmlFor="ws-name"
              >
                Workspace Avatar
              </Mui.Typography>

              <Mui.TextField
                id="ws-avatar"
                name="avatarImg"
                onChange={inputImage}
                type="file"
                style={{ width: "100%", display: "none" }}
                InputProps={{
                  className: classes.input,
                  notched: false,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    notchedOutline: classes.inputNotchedOutline,
                  },
                  style: {
                    paddingRight: 0,
                  },
                }}
                inputProps={{
                  className: classes.inputBase,
                }}
              />
              <Mui.FormLabel className={classes.formLabel} for="ws-avatar">
                Select a file
              </Mui.FormLabel>
              {avatarImg && (
                <Mui.FormLabel
                  className={classes.cancelImgUpload}
                  onClick={handleCancelImg}
                >
                  {isCreating ? "Cancel" : "Delete image"}
                </Mui.FormLabel>
              )}

              {/* <Mui.Grid item sx={{width:"10%", display: "inline"}}>
                <TooltipIconButton
                  title="Cancel"
                  disabled={!avatarImg}
                  color="secondary"
                  onClick={handleCancelImg}
                >
                  <Icons.X />
                </TooltipIconButton>
              </Mui.Grid> */}

              <Mui.Typography
                className={classes.label}
                variant="body1"
                component="label"
                htmlFor="ws-name"
              >
                Workspace Name
              </Mui.Typography>

              <Mui.TextField
                id="ws-name"
                name="title"
                placeholder="New Workspace Name"
                variant="outlined"
                value={value}
                onChange={handleTitleChange}
                onKeyDown={handleKeyDown}
                size="small"
                fullWidth={true}
                autoComplete="off"
                style={{ marginBottom: "0rem" }}
                InputProps={{
                  className: classes.input,
                  notched: false,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    notchedOutline: classes.inputNotchedOutline,
                  },
                  style: {
                    paddingRight: 0,
                  },
                  endAdornment: (
                    <EmojisButton
                      disabled={processing}
                      className={classes.emojiButton}
                      onEmoji={handleEmoji}
                      TooltipProps={{ placement: "top" }}
                    />
                  ),
                }}
                inputProps={{
                  className: classes.inputBase,
                  maxLength: 30,
                  ref: titleRef,
                }}
              />

              <Mui.Typography
                className={classes.label}
                variant="body1"
                component="label"
                htmlFor="ws-description"
              >
                Workspace Description
              </Mui.Typography>
              <Mui.TextField
                id="ws-description"
                name="title"
                placeholder="New Workspace Description"
                variant="outlined"
                value={descriptionValue}
                onChange={handleDescriptionChange}
                onKeyDown={handleKeyDown}
                size="small"
                height="30%"
                fullWidth={true}
                autoComplete="off"
                style={{ marginBottom: "0rem" }}
                InputProps={{
                  className: classes.input,
                  notched: false,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    notchedOutline: classes.inputNotchedOutline,
                  },
                  style: {
                    paddingRight: 0,
                  },
                }}
                inputProps={{
                  className: classes.inputBase,
                  maxLength: 1000,
                  ref: descriptionRef,
                }}
              />
            </form>
          </Mui.DialogContent>

          <Mui.DialogActions className={classes.dialogFooter}>
            <Mui.Button
              type="submit"
              form="create-ws-form"
              className={classes.actionButton}
              disabled={processing == true || value === "" ? true : false}
              // disabled={processing}
            >
              {isCreating ? "Create" : "Accept"}
            </Mui.Button>
            {/* { isCreating?null:
             <Mui.Button
             form="create-ws-form"
             className={classes.deleteButton}
             disabled={processing}
           >
            <Icons.Trash/>
           </Mui.Button>
             } */}
          </Mui.DialogActions>
        </Mui.Dialog>
      )}
    </>
  );
});

export default CreateWorkspaceButton;
