import store from "../../../store";
import { addSnackbar } from "../../actions";

export const handleAppInstalled = function () {
  localStorage.setItem("installed", true);
};

export const checkFirstTime = function () {
  const firstTime = localStorage.getItem("installed");
  const isAndroid = /Android/.test(navigator.userAgent);
  const isStandALone = window.matchMedia("(display-mode: standalone)").matches;

  if (isAndroid && isStandALone && firstTime) {
    const snackbar = {
      severity: "info",
      message: `VERIFY "APP ICON BADGES" IS ENABLED`,
    };
    store.dispatch(addSnackbar(snackbar));
    localStorage.removeItem("installed");
  }
};
