import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  video: {
    width: "100%",
    height: "100%",
  },
  smallVideos: {
    width: "20vw",
  },
}));
