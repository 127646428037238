import clsx from "clsx";
import { TooltipIconButton } from "../../prestyledComponents";
import useStyles from "./NotificationsButton.styles";
import { useMounted } from "../../../hooks/useMounted";
import * as React from "react";
import * as Mui from "@mui/material";
import { getBoundActionPromise } from "../../../utils/index";
import { logError } from "../../../utils/errors/logError";
import * as Icons from "react-feather";

export const NotificationsButton = function ({
  notifData,
  rejectNotification,
  acceptWorkspaceInvitation,
  ...props
}) {
  const mounted = useMounted();
  const classes = useStyles();
  const { className } = props;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAccept = async (event) => {
    try {
      await getBoundActionPromise(acceptWorkspaceInvitation, { notifData });

      if (handleClose) handleClose(event);
    } catch (error) {
      logError(error);
    }
  };

  const handleDecline = async (event) => {
    try {
      await getBoundActionPromise(rejectNotification, { notifData });

      if (handleClose) handleClose(event);
    } catch (error) {
      logError(error);
    }
  };

  return (
    <>
      <Mui.Badge variant="dot" color="primary">
        <TooltipIconButton
          {...props}
          className={clsx(className, classes.selected)}
          onClick={handleClick}
          title={`Invitation to: ${notifData.workspace.title}`}
        >
          {notifData.workspace.title[0]}
        </TooltipIconButton>
      </Mui.Badge>

      {open && (
        <Mui.Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          PaperProps={{ elevation: 0 }}
          classes={{
            root: classes.dialogRoot,
            paper: classes.dialog,
            container: classes.dialogContainer,
          }}
        >
          <Mui.DialogTitle
            disableTypography
            id="form-dialog-title"
            className={classes.dialogHeader}
          >
            <Mui.Typography variant="h6" className={classes.headerText}>
              Workspace Invitation
            </Mui.Typography>
            <Mui.IconButton
              className={classes.closeButton}
              onClick={handleClose}
            >
              <Icons.X style={{ strokeWidth: "2.5px" }} />
            </Mui.IconButton>
          </Mui.DialogTitle>
          <Mui.DialogContent className={classes.dialogContentTitle}>
            <Mui.Typography
              variant="subtitle1"
              component="label"
              // htmlFor="category-title"
              className={classes.notificationTitle}
            >
              You have been invited to join: {notifData.workspace.title}
            </Mui.Typography>
          </Mui.DialogContent>

          <Mui.DialogContent
            className={classes.dialogContent}
            sx={{ marginTop: "0" }}
          >
            <Mui.Typography
              variant="body1"
              component="label"
              htmlFor="category-title"
              className={classes.notificationMessageTitle}
            >
              {notifData.invitationMessage}
            </Mui.Typography>
          </Mui.DialogContent>

          <Mui.DialogActions className={classes.dialogFooter}>
            <Mui.Button onClick={handleAccept} className={classes.actionButton}>
              Join
            </Mui.Button>

            <Mui.Button
              onClick={handleDecline}
              className={classes.actionButton}
            >
              Decline
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Dialog>
      )}
    </>
  );
};

export default NotificationsButton;
