import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// import {
//   logout,
//   setRoom,
//   setRepliesPanelOpen,
//   setUnreadRepliesPanelOpen,
// } from "../../store/actions/index";

import MoreHeaderButton from "./MoreHeaderButton.component";
import { selectActiveChannel } from "../../../store/selectors/index";
import {
  setRepliesPanelOpen,
  setUnreadRepliesPanelOpen,
} from "../../../store/actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUnreadRepliesPanelOpen,
      setRepliesPanelOpen,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  return { room };
};

const MoreHeaderButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreHeaderButton);

// const MoreHeaderButtonContainer = connect(mapStateToProps, mapDispatchToProps, () => ({}))(
//   MoreHeaderButton
// );

export default MoreHeaderButtonContainer;

////////////////////////

// const ChatHeaderContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ChatHeader);

// export default ChatHeaderContainer;
