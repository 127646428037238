import { load as handtrackLoad } from "handtrackjs";

// const predictionClasses = {
//     1: "open",
//     2: "closed",
//     3: "pinch",
//     4: "point",
//     5: "face",
//     6: "tip",
//     7: "pinchtip",
// };

// const libDefaultParams = {
//     flipHorizontal: false,
//     outputStride: 16,
//     imageScaleFactor: 1,
//     maxNumBoxes: 20,
//     iouThreshold: 0.2,
//     scoreThreshold: 0.6,
//     modelType: "ssd320fpnlite",
//     modelSize: "large",
//     bboxLineWidth: "2",
//     fontSize: 17,
// };

const defaultParams = {
  flipHorizontal: true,
  maxNumBoxes: 5,
  scoreThreshold: 0.4,
};

export const getHandtrackModel = async function (modelParams = {}) {
  const extendedModelParams = {
    ...defaultParams,
    ...modelParams,
  };
  return await handtrackLoad(extendedModelParams);
};

export default getHandtrackModel;
