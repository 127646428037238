import { gql } from "../../../utils/apollo";

export const deleteCategory = gql`
  mutation DeleteCategory($input: deleteCategoryInput!) {
    deleteCategory(input: $input) {
      null
    }
  }
`;

export default deleteCategory;
