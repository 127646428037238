import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DateSelector from "./DateSelector.component";

import { setOnSelectorOpen, setDate, setFilters } from "XmarsStore/actions";

const mapStateToProps = (state) => {
  const { onSelectorOpen, date } = state.search;

  return {
    onSelectorOpen,
    date,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setOnSelectorOpen,
      setDate,
      setFilters,
    },
    dispatch
  );

const DateSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateSelector);

export default DateSelectorContainer;
