import client from "../../../client";
import mutation from "./addRecording.mutation";

/**
 * @param {string} sessionID
 */
export const addRecording = async function (sessionID) {
  await client.mutate({
    mutation,
    variables: {
      sessionID,
    },
  });
};

export default addRecording;
