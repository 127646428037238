import * as actions from "XmarsStore/actions/index";
import JitsiMeetJS from "XmarsJitsiMeetJS/index";
import { actionChannel, fork, take, race } from "redux-saga/effects";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";

export function* switchAudioOutputDeviceControl() {
  const channel = yield actionChannel(actions.switchAudioOutputDevice);
  try {
    while (true) {
      const { payload, endJitsiCall } = yield race({
        payload: take(channel),
        endJitsiCall: take(actions.jitsiCallHang),
      });
      if (endJitsiCall) {
        channel.close();
      } else {
        yield fork(setAudioOutput, payload);
      }
    }
  } catch (error) {
    yield handleSagaError(error);
  }
}

function* setAudioOutput(payload) {
  try {
    yield JitsiMeetJS.mediaDevices.setAudioOutputDevice(payload);
  } catch (error) {
    yield handleSagaError(error);
  }
}
