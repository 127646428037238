import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  customPadding: {
    paddingTop: 0,
    paddingBottom: "4px",
  },

  root: {
    "&:hover": {
      borderRadius: 0,
      backgroundColor: "transparent",
    },
  },
  groupDate: {
    color: "black",
    alignContent: "center",
    textAlign: "center",
  },
  primaryText: {
    fontSize: 14,
    color: theme.palette.sidebarItems.primary,
    lineHeight: 1.2,
    marginBottom: "0.2rem",
    textOverflow: "ellipsis",
  },
  item: {
    padding: "17px 30px",
    fontSize: 14,
    alignItems: "normal",
  },
  itemText: {
    marginTop: "0px",
    marginBottom: "0px",
  },
  chatsContainer: {
    border: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  chatsContainerText: {
    fontSize: 14,
    lineHeight: 1.2,
    color: theme.palette.sidebarItems.primary,
    marginBottom: "0.2rem",
    textOverflow: "ellipsis",
  },
  rootListItem: {
    paddingBottom: "1px",
  },

  organizingIcon: {
    width: "26px",
    minWidth: "26px",
  },
  back: {
    //border: "solid",
    //height: "100vh",
    marginBottom: "40px",
  },
  front: {
    //border: "solid",
    //maxHeight: "80vh",
    marginBottom: "40px",
  },
  friendsContainer: {
    marginBottom: "50px",
  },
}));
