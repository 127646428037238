//TODO fix or remove sensenv
import { getEnv, required } from "sensenv";

const processEnv = process.env;

if (processEnv.NODE_ENV !== "test")
  console.log("Checking environment variables");

export const env = {
  ...getEnv(
    {
      // * General
      PUBLIC_URL: processEnv.PUBLIC_URL,
      //inactivity
      SHORT_INACTIVITY: "120000",
      LONG_INACTIVITY: "600000",
      // * Misc
      JITSI_URI: window.location.hostname,
      JWT_PUBLIC: required,
      BEARER_SECRET: required,
      STRIPE_PUBLIC_KEY: required,
      //* Push Notifications
      VAPID_PUBLIC_KEY: required,
    },
    (name) => processEnv[`REACT_APP_${name}`]
  ),
  NODE_ENV: processEnv.NODE_ENV,
  DEVELOPMENT: processEnv.NODE_ENV === "development",
  PRODUCTION: processEnv.NODE_ENV === "production",
  TESTING: processEnv.NODE_ENV === "test",
};

export default env;
