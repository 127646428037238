import client from "../../../client";
import mutation from "./unfriend.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 * @returns {Promise<{ username: string }>}
 */
export const unfriend = async function (id) {
  id = addBearer(id);
  const { data } = await client.mutate({
    mutation,
    variables: {
      id,
    },
  });
  return data.unfriend;
};

export default unfriend;
