import React, { useState } from "react";
import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";
import bytes from "bytes";
import { hightLight } from "../../utils/hightLight";

import { MessageStatusIcon } from "../../pseudoItems";
import { useJsonParsed } from "../../../hooks/useJsonParsed";

import useStyles from "./MessageFileContent.styles";
import clsx from "clsx";

export const MessageFileContent = function ({
  getFile,
  header,
  message,
  searchedValue,
  isResultsPanelOpen,
}) {
  const [progress, setProgress] = useState(0);

  const classes = useStyles();

  const own = message.owner.username === "You";

  const file = useJsonParsed(message.text);

  const handleDownload = function (event) {
    event.preventDefault();
    event.stopPropagation();
    if (progress) return;
    getFile({ file, setProgress });
  };

  const renderFileIcon = function () {
    if (progress) return <Icons.Download />;
    switch (file.mimeType.split("/")[0]) {
      case "image":
        return <Icons.Image />;
      case "audio":
        return <Icons.Music />;
      case "video":
        return <Icons.Film />;
      case "application":
        return <Icons.File />;
      default:
        return <Icons.File />;
    }
  };

  return (
    <Grid
      container
      direction="column"
      className={
        header
          ? classes.headerFile
          : clsx(
              classes.fileMessageContainer,
              own &&
                file.thumbnail &&
                !message.replies?.length &&
                classes.ownImageNoReplies
            )
      }
    >
      {file.thumbnail && !file.thumbnail?.slice(0, 18)?.includes("svg") ? (
        <Mui.List>
          <Mui.ListItem>
            {/* <Mui.ListItemText
              className={classes.imageFileName}
              //primaryTypographyProps={{ className: classes.fileName }}
              primary={
                searchedValue
                  ? hightLight(file.name, searchedValue, classes.foundText)
                  : file.name
              }
              //secondaryTypographyProps={{ className: classes.fileSize }}
              // secondary={bytes(file.size)}
            /> */}
            <img
              alt={file.name}
              src={file.thumbnail}
              className={classes.imageFile}
              onClick={handleDownload}
            />
            {!own ? null : (
              <>
                <div style={{ minWidth: "1em" }} />
                <MessageStatusIcon message={message} />
              </>
            )}
          </Mui.ListItem>
        </Mui.List>
      ) : (
        <Grid item className={clsx(classes.width100, classes.fileBox)}>
          <Mui.List>
            <Mui.ListItem>
              <Mui.ListItemText
                className={classes.text}
                primaryTypographyProps={{ className: classes.fileName }}
                primary={
                  searchedValue
                    ? hightLight(file.name, searchedValue, classes.foundText)
                    : file.name
                }
                secondaryTypographyProps={{ className: classes.fileSize }}
                secondary={bytes(file.size)}
              />
              {!own ? null : (
                <>
                  <div style={{ minWidth: "1em" }} />
                  <MessageStatusIcon message={message} />
                </>
              )}
            </Mui.ListItem>
            <Mui.ListItem>
              <Mui.ListItemIcon className={classes.progressIconWrapper}>
                <Mui.Icon className={classes.fileIcon}>
                  {renderFileIcon()}
                </Mui.Icon>
              </Mui.ListItemIcon>
              <Mui.ListItemText
                className={classes.text}
                primaryTypographyProps={{
                  className: classes.fileSize,
                  style: {
                    textTransform: "capitalize",
                  },
                }}
                primary={
                  ["audio", "video", "image"].includes(
                    file.mimeType.split("/")[0]
                  )
                    ? file.mimeType.split("/")[0]
                    : "file"
                }
              />
              {!progress ? null : (
                <Mui.CircularProgress
                  variant="determinate"
                  value={progress}
                  className={classes.progress}
                  classes={{ svg: classes.progressSize }}
                />
              )}
            </Mui.ListItem>
          </Mui.List>
          {!isResultsPanelOpen ? (
            <Mui.Button
              className={classes.downloadButton}
              onClick={handleDownload}
            >
              Download
            </Mui.Button>
          ) : null}

          {/* {message.status === "sent" || message.status === "read" ? (
          <Mui.Button
            className={classes.downloadButton}
            onClick={handleDownload}
          >
            Download
          </Mui.Button>
        ) : null} */}
        </Grid>
      )}
    </Grid>
  );
};

export default MessageFileContent;

MessageFileContent.propTypes = {
  message: PropTypes.object.isRequired,
  getFile: PropTypes.func.isRequired,
};
