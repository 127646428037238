import { gql } from "../../../utils/apollo";

export const kickUser = gql`
  mutation KickUser($id: ID, $user_id: ID) {
    kickUser(input: { id: $id, user_id: $user_id }) {
      null
    }
  }
`;

export default kickUser;
