import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SearchTextField from "./SearchTextField.component";

import { setFilters } from "XmarsStore/actions";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFilters,
    },
    dispatch
  );

const SearchTextFieldContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchTextField);

export default SearchTextFieldContainer;
