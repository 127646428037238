import { call, put, select } from "redux-saga/effects";

import api from "../../../../dataApi";
import {
  registerCategory,
  registerWorkpace,
  unregisterWorkpace,
  registerRoom,
  unregisterCurrentWorkspace,
} from "../utils";

import * as actions from "../../../actions";

import { handleSagaError } from "../../sagas.utils";

export const getWorkspaces = function* () {
  try {
    const workspaces = yield call(api.getWorkspaces);
    for (const ws of workspaces) {
      yield put(actions.addWorkspace(ws));
      yield registerWorkpace(ws);
    }
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const getWorkspace = function* ({ payload }) {
  const { id } = payload;
  try {
    const workspace = yield call(api.getWorkspace, id);
    yield put(actions.addWorkspace(workspace));
    yield registerWorkpace(workspace);
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const createWorkspace = function* ({ payload }) {
  const { title, description, avatar, promise } = payload;
  try {
    const workspace = yield call(
      api.createWorkspace,
      title,
      description,
      avatar
    );
    yield call(promise.resolve);
    yield put(actions.addWorkspace(workspace));
    yield registerWorkpace(workspace);
    yield put(actions.setCurrentWorkspace(workspace));
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

export const setCurrentWorkspace = function* ({ payload }) {
  try {
    const workspace = { ...payload };
    if (workspace) {
    }
    try {
      const categories = yield call(api.getCategories, workspace.id);
      const isAdmin = yield call(api.getAdmin, workspace.id);
      const workspaceAdmins = yield call(api.getWorkspaceAdmins, workspace.id);
      // workspace.adminsList = workspaceAdmins;
      let adminsArray = workspaceAdmins.users;
      let workspaceUsers = workspace.users;
      let users = [];
      let usersObject = {};

      for (let user of workspaceUsers) {
        usersObject[user.id] = { ...user };
      }

      for (let user of adminsArray) {
        let objectToMerge = {
          admin: user.admin,
          entryDate: user.entryDate,
        };

        usersObject[user.id] = {
          ...usersObject[user.id],
          ...objectToMerge,
        };
      }

      for (let user of workspaceUsers) {
        users.push(usersObject[user.id]);
      }

      workspace.users = users;
      workspace.admin = isAdmin;
      yield put(actions.setCategories(categories));
    } catch (error) {
      yield handleSagaError(error);
    }

    // yield registerWorkpace(workspace)
    yield put(actions.setWorkspace(workspace));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const getCategories = function* ({ payload }) {
  const { workspaceId } = payload;
  try {
    const categories = yield call(api.getCategories, workspaceId);
    yield put(actions.setCategories(categories));
    // for(const cat of categories){
    //   yield registerCategory(cat)
    // }
  } catch (error) {
    yield handleSagaError(error);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const createCategory = function* ({ payload }) {
  const { title, workspaceId, promise } = payload;
  try {
    const category = yield call(api.createCategory, title, workspaceId);
    yield call(promise.resolve);
    // yield registerCategory(category)

    //  try {
    //   const defaultRoom = yield call(api.createCustomRoom, "New channel", category.id, true);

    //   yield call(promise.resolve);
    //   yield registerRoom(defaultRoom);

    //   yield put(actions.setRoom(defaultRoom));
    // } catch (error) {
    //   yield handleSagaError(error);
    //   yield call(promise.reject, error);
    // }
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

export const updateWorkspace = function* ({ payload }) {
  const { newTitle, newDescription, newAvatar, workspaceId, promise } = payload;
  try {
    yield call(api.updateWorkspace,newTitle,newDescription,workspaceId,newAvatar);
    yield call(promise.resolve);
    yield put(actions.updateWorkspaceParameters({newTitle, newDescription, newAvatar, workspaceId}));
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

export const replaceWorkspaceForUpdated = function* ({ payload }) {
  const { id } = payload;
  try {
    const workspace = yield call(api.getWorkspace, id);
    yield put(actions.addWorkspace(workspace));
  } catch (error) {
    yield handleSagaError(error);
  }
};

export const updateCategory = function* ({ payload }) {
  const { newTitle, categoryId, promise } = payload;
  try {
    yield call(api.updateCategory, newTitle, categoryId);
    yield call(promise.resolve);
    yield put(actions.updateCategoryParameters({newTitle, categoryId}));
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

export const deleteWorkspace = function* ({ payload }) {
  const { id, promise } = payload;
  try {
    yield call(api.deleteWorkspace, id);
    yield call(promise.resolve);
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

export const deleteCategory = function* ({ payload }) {
  const { id, promise } = payload;
  try {
    yield call(api.deleteCategory, id);
    yield call(promise.resolve);
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

export const kickUserFromWorkspace = function* ({ payload }) {
  const { userId, workspaceId, promise } = payload;
  try {
    yield call(api.kickUserFromWorkspace, userId, workspaceId);
    yield call(promise.resolve);
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

export const setWorkspaceAdmin = function* ({ payload }) {
  const { userId, workspaceId, promise } = payload;
  try {
    yield call(api.setWorkspaceAdmin, workspaceId, userId);
    yield call(promise.resolve);
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};

export const unsetWorkspaceAdmin = function* ({ payload }) {
  const { userId, workspaceId, promise } = payload;
  try {
    yield call(api.unsetWorkspaceAdmin, workspaceId, userId);
    yield call(promise.resolve);
  } catch (error) {
    yield handleSagaError(error);
    promise.reject(error);
  }
};
