export const setSharedScreen = function (state, { payload }) {
  state.sharedScreen = payload;
};

export const setScreenConnection = function (state, { payload }) {
  state.screenConnection = payload;
};

export const setScreenTrack = function (state, { payload }) {
  state.screenTrack = payload;
};
