import { call, put, putResolve, select } from "redux-saga/effects";

import api from "../../../../dataApi";

import * as actions from "../../../actions";
import { handleSagaError } from "../../sagas.utils";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const registerRoom = function* (room) {
  yield put(actions.startRoomSubscription(room));
};
// export const registerCategory = function* (category) {
//   // yield put(actions.startRoomSubscription(room));
//   yield put(actions.addCategory(category));

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const unregisterRoom = function* (room) {
  yield put(actions.cancelRoomSubscription(room));
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const unregisterCurrentRoom = function* () {
  const currentRoom = yield select((state) => state.chat.room);
  yield put(actions.cancelRoomSubscription(currentRoom));
  // yield put(actions.setRoom(null));
};

export const registerWorkpace = function* (workspace) {
  yield put(actions.startWorkspaceSubscription(workspace));
  // yield put(actions.addWorkspace(workspace));
};

export const unregisterWorkpace = function* (workspace) {
  yield put(actions.cancelWorkspaceSubscription(workspace));
  // yield put(actions.addWorkspace(workspace));
};

export const unregisterCurrentWorkspace = function* () {
  const currentWorkspace = yield select((state) => state.chat.currentWorkspace);
  if (currentWorkspace)
    yield put(actions.cancelWorkspaceSubscription(currentWorkspace));
  // yield put(actions.setRoom(null));
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const registerFriend = function* (friend) {
  yield put(actions.startFriendSubscription(friend));
  yield putResolve(actions.addFriend(friend));
  let room;
  try {
    room = yield api.getFriendRoom(friend.id);
    if (room) {
      yield put(actions.setFriendProperties({ friend, properties: { room } }));
      yield registerRoom(room);
      yield put(actions.setRoomProperties({ room, properties: { friend } }));
    }
  } catch (error) {
    yield handleSagaError(error);
  }

  const status = yield select((store) => store.chat.status);

  yield api.changeStatus(status);
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const unregisterFriend = function* (friend) {
  yield put(actions.cancelFriendSubscription(friend));
  // if (friend.room) {
  //   yield call(unregisterRoom, friend.room);
  // }
  yield put(actions.removeFriend(friend));
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const focusRoom = async function* (id) {
  const room = await api.getRoom(id);
  yield put(actions.setRoom(room));
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const registerCategory = function* (category) {
  // yield put(actions.startRoomSubscription(room));
  yield put(actions.addCategory(category));
};
