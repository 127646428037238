import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { UsernameTextField } from "../../inputs";
import { SearchSubmitButton } from "../../prestyledComponents";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";

export const SearchUsersForm = function ({ searchByUsername }) {
  const handleSubmit = formSubmitHandler(({ username }) => {
    searchByUsername(username);
  });

  return (
    <form onSubmit={handleSubmit}>
      <UsernameTextField
        InputProps={{
          endAdornment: (
            <Mui.InputAdornment>
              <SearchSubmitButton />
            </Mui.InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default SearchUsersForm;

SearchUsersForm.propTypes = {
  searchByUsername: PropTypes.func.isRequired,
};
