import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { CheckoutForm } from "../../forms";
import { CircularProgressIcon } from "../../prestyledComponents";

import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const CheckoutButton = function ({
  user,
  room,
  getClientSecret,
  ...props
}) {
  const [render, setRender] = useState(false);
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    setRender(user && user.role === "guest" && room && room.kind === "medical");
  }, [user, room]);

  const handleClose = function () {
    setOpen(false);
    setClientSecret("");
  };

  const handleClick = async function () {
    if (!room) return;
    try {
      setProcessing(true);
      const newClientSecret = await getBoundActionPromise(getClientSecret, {
        room,
      });
      setClientSecret(newClientSecret);
      setOpen(true);
    } catch (error) {
      logError(error);
    } finally {
      setProcessing(false);
    }
  };

  return !render ? null : (
    <>
      <Mui.Fab {...props} disabled={processing} onClick={handleClick}>
        <Icons.CreditCard />
        {!processing ? null : <CircularProgressIcon />}
      </Mui.Fab>
      <Mui.Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Mui.DialogTitle id="form-dialog-title">
          Medical Consultation Payment
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <CheckoutForm clientSecret={clientSecret} />
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.IconButton color="primary" onClick={handleClose}>
            <Icons.X />
          </Mui.IconButton>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default CheckoutButton;

CheckoutButton.propTypes = {
  user: PropTypes.object,
  room: PropTypes.object,
};
