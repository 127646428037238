import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  inputBase: {
    color: theme.palette.textGray.input,
    padding: "0.375rem 0.75rem",
    height: "auto",

    "&::placeholder": {
      color: `${theme.palette.textGray.inputPlaceholder} !important`,
      opacity: 1,
    },
  },
  input: {
    fontSize: "14px",
    lineHeight: 1.5,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.borderGray.input}`,
    boxSizing: "border-box",
    backgroundClip: "padding-box",
  },
  inputRoot: {},
  inputFocused: {
    backgroundColor: theme.palette.bgGray.sidebar,
  },
  inputNotchedOutline: {
    display: "none",
  },
  modalElements: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, 0%)",
    width: "auto",
    background: "white",
    boxShadow: "5px 5px 15px -4px #000000",
    borderRadius: "12px",
    "&:focus": {
      outline: "none",
    },
    paddingRight: "5px",
    paddingLeft: "1em",
    [theme.breakpoints.down("md")]: {
      top: "1%",
    },
  },
  title: {
    fontWeight: 500,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "1.25rem",
    lineHeight: 1.2,
    marginBottom: "0.7em",
    marginTop: "0.7em",
    marginLeft: "1em",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "1em",
  },
  dateSelector: {
    marginRight: "1em",
  },
  dateSelectorContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));
