import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  linkBox: {
    alignSelf: "center",
    color: `${theme.palette.textGray.inputPlaceholder} !important`,
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.light}`,
    padding: "9px",
    color: theme.palette.common.white,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
    textTransform: "none",
    transition: "color 0.15s ease-in-out, background-color 0.15s ease-in-out",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  routeButton: {
    display: "inline-flex",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.buttons.light}`,
    borderRadius: "30px",
    padding: "8px 25px",
    color: theme.palette.buttons.main1,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "14px",
    textTransform: "none",
    cursor: "pointer",
    transition: "color 0.15s ease-in-out, background-color 0.15s ease-in-out",

    "&:hover": {
      backgroundColor: theme.palette.buttons.light,
      color: theme.palette.buttons.dark,
      textDecoration: "none",
    },
  },
  removeTopPadding: {
    paddingTop: "0 !important",
  },
  removeBottomPadding: {
    paddingBottom: "0 !important",
  },
  removeVPadding: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  addMarginBottom: {
    marginBottom: "1rem",
  },
  divider: {
    margin: "2rem 12px",
    background: "rgba(0, 0, 0, .1) !important",
  },
  title: {
    marginBottom: "2rem",
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: 1.2,
  },
  formBox: {
    padding: "3rem",
    color: "inherit",
    maxWidth: "430px",
    margin: "50px auto",
    border: `1px solid ${theme.palette.buttons.light}`,

    [theme.breakpoints.only("xs")]: {
      maxWidth: "unset",
      width: "100%",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginBottom: "20px",
    },
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    width: "100px",
    padding: "20px",
    marginTop: "-100px",
    marginRight: "auto",
    marginLeft: "auto",
    borderRadius: "50%",
    backgroundColor: "#fff",
  },
  logoImg: {
    verticalAlign: "middle",
  },
}));
