import React from "react";
import * as Mui from "@mui/material";

import { stringAvatar } from "../../../utils/defaultAvatar";
import useStyles from "./FoundGroup.styles";
import clsx from "clsx";
import { hightLight } from "../../utils";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const FoundGroup = function ({
  group,
  searchedValue,
  setIsResultsPanelOpen,
  setRoom,
}) {
  const classes = useStyles();

  const handleClick = () => {
    setRoom(group);
    setIsResultsPanelOpen(false);
  };

  return (
    <Mui.Card variant="outlined" onClick={handleClick}>
      <Mui.CardActionArea>
        <Mui.CardContent>
          <Mui.Box className={classes.groupContainer}>
            <Mui.ListItemAvatar
              classes={{
                root: clsx(classes.avatar),
              }}
            >
              <Mui.Avatar
                src={group.avatar}
                classes={{ root: classes.avatar }}
                {...stringAvatar(group.title)}
              />
            </Mui.ListItemAvatar>
            <Mui.ListItem>
              {hightLight(group.title, searchedValue, classes.foundText)}
            </Mui.ListItem>
          </Mui.Box>
          <Mui.Typography
            sx={{ fontSize: 10 }}
            //color="text.secondary"
            gutterBottom
          >
            {group.users.length + 1} member
            {group.users.length === 0 ? null : "s"}
          </Mui.Typography>
        </Mui.CardContent>
      </Mui.CardActionArea>
    </Mui.Card>
  );
};

export default FoundGroup;
