import { format } from "date-fns";

/**
 * Returns the date in a correct format: Today | Yesterday | d MMMM yyyy
 *
 * @param messagesDateN {Number}
 * @returns {String}
 *
 */
export function getCurrentDate(messagesDateN) {
  const today = new Date();
  const messageDate = new Date(messagesDateN);

  if (today.toDateString() === messageDate.toDateString()) {
    return "Today";
  }
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (yesterday.toDateString() === messageDate.toDateString()) {
    return "Yesterday";
  }

  return format(messagesDateN, "EE, d MMMM yyyy");
}

/**
 * Checks if two dates are in the same minute
 * @param {Number} date1
 * @param {Number} date2
 * @returns {Boolean} true if both dates are in the same minute
 */
export function sameTimeInterval(date1, date2) {
  let d1 = new Date(date1);
  let d2 = new Date(date2);
  let areInTheSame =
    d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes();
  return areInTheSame;
}

/**
 * Organize the messages based on their creation date.
 *
 * @param messages {Array}
 * @returns {Array}
 *
 */
export function organizeMessages(messages) {
  const returningMessagesObj = {};

  if (!messages) return;
  messages.forEach((message) => {
    const currentDate = new Date(message.createdAt);
    const currentDateStr = currentDate.toDateString();

    if (currentDateStr in returningMessagesObj) {
      returningMessagesObj[currentDateStr].push(message);
    } else {
      returningMessagesObj[currentDateStr] = [message];
    }
  });

  const returningMessages = [];
  for (let k in returningMessagesObj) {
    returningMessages.push(returningMessagesObj[k]);
  }

  returningMessages.sort(
    (messagesA, messagesB) => messagesA[0].createdAt - messagesB[0].createdAt
  );
  return returningMessages;
}
