import createElement from "./createElement";

/**
 * @param {Blob} blob
 * @param {string} name
 */
export const downloadBlob = function (blob, name = "download") {
  const url = URL.createObjectURL(blob);
  const link = createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  link.remove();
};

export default downloadBlob;
