import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  dialogContainer: {
    display: "block",
  },
  dialog: {
    width: "auto",
    margin: "0.5rem",
    borderRadius: "7px",
    maxHeight: "initial",

    [theme.breakpoints.up("sm")]: {
      margin: "1.75rem auto",
      maxWidth: "500px",
    },
  },
  dialogRoot: {
    "& .MuiBackdrop-root": {
      backdropFilter: "blur(2px)",
    },
  },
  dialogHeader: {
    backgroundColor: theme.palette.tabs.primarySelected,
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "flex-start",
    padding: "20px 30px",
    alignItems: "center",
    columnGap: "0.5rem",
  },
  headerText: {
    lineHeight: "13px",
    fontSize: "1.25rem",
    fontWeight: 500,
    flexGrow: 1,
  },
  closeButton: {
    padding: "0 !important",
    height: "30px",
    width: "30px",
    background: "rgba(255, 255, 255, 0.3)",
    color: theme.palette.common.white,
  },
  dialogContent: {
    padding: "30px",
    maxHeight: "70vh",
  },
  dialogFooter: {
    padding: "0 30px 30px",
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.light}`,
    padding: "9px",
    color: theme.palette.common.white,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
    textTransform: "none",
    transition: "color 0.15s ease-in-out, background-color 0.15s ease-in-out",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
}));
