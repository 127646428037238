import { fork } from "redux-saga/effects";
import videoSagas from "./video";
import chatSagas from "./chat";
import generalSagas from "./general";
import subscriptionSagas from "./subscriptions";
import searchSagas from "./search";
import jitsiSagas from "./jitsi";

export default function* rootSaga() {
  yield fork(videoSagas);
  yield fork(chatSagas);
  yield fork(generalSagas);
  yield fork(subscriptionSagas);
  yield fork(searchSagas);
  yield fork(jitsiSagas);
}
