import client from "../../../client";
import mutation from "./changeStatus.mutation";

/**
 * @param {string} status
 */
export const changeStatus = async function (status) {
  const { data } = await client.mutate({
    mutation,
    variables: {
      status,
    },
  });
  return data.changeStatus;
};

export default changeStatus;
