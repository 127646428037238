import React, { useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import clsx from "clsx";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { TooltipIconButton } from "../../prestyledComponents";
import { Scrollbars } from "react-custom-scrollbars";
import { stringAvatar } from "../../../utils/defaultAvatar/defaultAvatar";
import useStyles from "./AddUsersToGroupButton.styles";
import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";
import { useMounted } from "../../../hooks/index";
import AddUsersToRoom from "../../AddUsersToRoom";

/**
 *
 * @returns React.component
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const AddUsersToGroupButton = function ({
  workspaceMembers,
  currentChannel,
  addUsersToChannel,
  isInList,
  title,
  channel,
}) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [usersToAdd, setUsersToAdd] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState([]);

  const [filter, setFilter] = useState("");

  const mounted = useMounted();

  const handleToggle = (id) => () => {
    const currentIndex = checkedUsers.indexOf(id);
    const newCheckedUsers = [...checkedUsers];
    if (currentIndex === -1) {
      newCheckedUsers.push(id);
    } else {
      newCheckedUsers.splice(currentIndex, 1);
    }

    setCheckedUsers(newCheckedUsers);

    const checkedFriends = workspaceMembers.filter((friend) => {
      return newCheckedUsers.includes(friend.id);
    });

    setUsersToAdd(checkedFriends);
  };

  const handleCloseMenu = function () {
    setMenuOpen(false);
    setUsersToAdd([]);
    setCheckedUsers([]);
  };
  const handleSubmit = formSubmitHandler(async (_, event) => {
    try {
      setProcessing(true);
      if (!usersToAdd.length) return;
      addUsersToChannel({
        room: channel,
        users: usersToAdd,
      });
    } catch (error) {
      logError(error);
    } finally {
      handleCloseMenu();
      if (mounted.current) {
        setProcessing(false);
      }
    }
  });

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <Mui.ListItemIcon className={classes.marginLeftBottom}>
        {isInList ? (
          <Mui.ListItem
            key="1"
            button
            onClick={() => {
              setMenuOpen(true);
            }}
          >
            <Mui.ListItemText primary={"Add Participants"} />
          </Mui.ListItem>
        ) : (
          <TooltipIconButton
            title="Add to group"
            button
            onClick={() => {
              setMenuOpen(true);
            }}
            className={classes.iconButton}
            children={<PersonAddAltIcon />}
          />
        )}
      </Mui.ListItemIcon>

      {/* <TooltipIconButton

                onClick={() => {
                    setMenuOpen(true);
                }}
            >
                <Mui.Badge
                    anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                    }}
                    badgeContent={
                        <Mui.Icon className={classes.badgeIcon}>
                            <Icons.Plus />
                        </Mui.Icon>
                    }
                >
                    <Icons.User />
                </Mui.Badge>
            </TooltipIconButton> */}

      {menuOpen && (
        <Mui.Dialog
          open={menuOpen}
          onClose={handleCloseMenu}
          aria-labelledby="form-dialog-title"
          PaperProps={{ elevation: 0 }}
          classes={{
            root: classes.dialogRoot,
            paper: classes.dialog,
            container: classes.dialogContainer,
          }}
        >
          <Mui.DialogTitle
            disableTypography
            id="form-dialog-title"
            className={classes.dialogHeader}
          >
            <Icons.Users style={{ height: "18px", width: "18px" }} />
            <Mui.Typography variant="h6" className={classes.headerText}>
              Add users to {!currentChannel ? title : currentChannel.title}
            </Mui.Typography>
            <Mui.IconButton
              className={classes.closeButton}
              onClick={handleCloseMenu}
            >
              <Icons.X style={{ strokeWidth: "2.5px" }} />
            </Mui.IconButton>
          </Mui.DialogTitle>

          <Mui.DialogContent className={classes.dialogContent}>
            <Mui.TextField
              margin="dense"
              autoComplete="off"
              autoFocus
              variant="outlined"
              onChange={handleChange}
              className={clsx(classes.searchBar, classes.background)}
              placeholder="Search Users"
              InputProps={{
                className: classes.input,
                notched: false,
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  notchedOutline: classes.inputNotchedOutline,
                },
              }}
              inputProps={{ className: classes.inputBase }}
            />
            <form onSubmit={handleSubmit} id="add-to-group-form">
              <Scrollbars
                autoHeight
                autoHeightMin={50}
                autoHeightMax={450}
                //autoWidth
              >
                <Mui.Grid
                  container
                  spacing={1}
                  className={clsx(
                    classes.avatars,
                    !usersToAdd.length ? classes.avatarsEmpty : null
                  )}
                  wrap="nowrap"
                >
                  {!usersToAdd.length ? (
                    <Mui.Grid item>
                      <Mui.Avatar
                        alt="default icon"
                        className={clsx(
                          classes.avatarUnit,
                          classes.avatarListSize
                        )}
                        classes={{
                          colorDefault: classes.defaultAvatarColors,
                        }}
                      >
                        <Icons.Users />
                      </Mui.Avatar>
                    </Mui.Grid>
                  ) : (
                    usersToAdd.map((user) => {
                      const toggleHandler = handleToggle(user.id);

                      return (
                        <Mui.Grid
                          item
                          key={user.id}
                          component={Mui.Tooltip}
                          title={user.title}
                          placement="top"
                        >
                          <Mui.Badge
                            badgeContent={<Icons.X />}
                            overlap="circle"
                            onClick={toggleHandler}
                            style={{
                              cursor: "pointer",
                            }}
                            classes={{
                              badge: classes.avatarBadge,
                            }}
                          >
                            <Mui.Avatar
                              src={user.friend?.avatar}
                              alt={user.title}
                              {...stringAvatar(user.username)}
                              className={clsx(
                                classes.avatarUnit,
                                classes.avatarListSize
                              )}
                            ></Mui.Avatar>
                          </Mui.Badge>
                        </Mui.Grid>
                      );
                    })
                  )}
                </Mui.Grid>
              </Scrollbars>
              <AddUsersToRoom
                checked={checkedUsers}
                handleToggle={handleToggle}
                filter={filter}
              />
            </form>
          </Mui.DialogContent>

          <Mui.DialogActions className={classes.dialogFooter}>
            <Mui.Button
              type="submit"
              form="add-to-group-form"
              className={classes.actionButton}
              disabled={processing}
            >
              Add to group
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Dialog>
      )}
    </>
  );
};

export default AddUsersToGroupButton;
