import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CreateGroupRoomForm from "./CreateGroupRoomForm.component";

import { createCustomRoom } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createCustomRoom,
    },
    dispatch
  );

const CreateGroupRoomFormContainer = connect(
  null,
  mapDispatchToProps
)(CreateGroupRoomForm);

export default CreateGroupRoomFormContainer;
