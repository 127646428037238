import React, { forwardRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";

import useStyles from "./VerticalScrollbars.styles";

export const VerticalScrollbars = forwardRef(function (
  { children, viewStyle, alwaysVisible, ...props },
  ref
) {
  const classes = useStyles();

  return (
    <Scrollbars
      ref={ref}
      autoHide={!alwaysVisible}
      autoHeight
      autoHeightMax="100%"
      renderView={({ style, ...viewProps }) => (
        <div
          style={{ ...style, overflowX: "hidden", ...viewStyle }}
          {...viewProps}
        />
      )}
      renderTrackVertical={({ style, ...trackProps }) => (
        <div
          style={{ ...style, width: "4px" }}
          className={classes.scrollTrack}
          {...trackProps}
        />
      )}
      renderThumbVertical={(thumbProps) => (
        <div className={classes.scrollThumb} {...thumbProps} />
      )}
      {...props}
    >
      {children}
    </Scrollbars>
  );
});

export default VerticalScrollbars;
