import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.light}`,
    padding: "9px",
    color: theme.palette.common.white,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
    textTransform: "none",
    transition: "color 0.15s ease-in-out, background-color 0.15s ease-in-out",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  title: {
    marginBottom: "2rem",
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: 1.2,
  },
  formBox: {
    padding: "3rem",
    color: "inherit",
    maxWidth: "430px",
    margin: "50px auto",
    border: `1px solid ${theme.palette.buttons.light}`,

    [theme.breakpoints.only("xs")]: {
      maxWidth: "unset",
      width: "100%",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginBottom: "20px",
    },
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    width: "100px",
    padding: "20px",
    marginTop: "-100px",
    marginRight: "auto",
    marginLeft: "auto",
    borderRadius: "50%",
    backgroundColor: "#fff",
  },
  logoImg: {
    verticalAlign: "middle",
  },
}));
