import React, { useEffect, useRef, useState } from "react";
import * as Mui from "@mui/material";
import { VerticalScrollbars } from "../../prestyledComponents";
import { PaginationControls } from "../../PaginationControls";
import { FoundContact } from "../../collectionItems";
import { FoundItemsLoading } from "../FoundItemsLoading";

import useStyles from "./FoundContacts.styles";

const scrollBarsStyles = { padding: "30px", height: "calc(100vh - 125px)" };
export const FoundContacts = function ({
  foundContacts,
  resultsPerPage,
  totalContacts,
  searchedValue,
  searchPageAndSection,
  setProfileOpen,
  status,
  userId,
}) {
  const classes = useStyles();
  const count = Math.ceil(totalContacts / resultsPerPage);
  const [page, setPage] = useState(1);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const ref = useRef();

  const handleChange = (_, value) => {
    setPage(value);
    setIsLoadingContacts(true);
    ref.current.scrollTop = 0;
    searchPageAndSection({ searchedValue, page: value, section: ["Contacts"] });
  };

  useEffect(() => {
    setIsLoadingContacts(false);
  }, [foundContacts]);

  return (
    <VerticalScrollbars
      viewStyle={scrollBarsStyles}
      ref={(s) => (ref.current = s && s.view)}
    >
      <Mui.List className={classes.contactsContainer}>
        <Mui.Box>
          {isLoadingContacts ? (
            <FoundItemsLoading style={{ padding: "0px", marginTop: "-8px" }} />
          ) : (
            <>
              {foundContacts.length ? (
                foundContacts.map((contact, i) => (
                  <FoundContact
                    contact={contact}
                    status={userId === contact.id ? status : contact.online}
                    key={`found-contact-${i}`}
                    setProfileOpen={setProfileOpen}
                  />
                ))
              ) : (
                <Mui.ListItem>
                  <Mui.ListItemText primary="No results" />
                </Mui.ListItem>
              )}
            </>
          )}
        </Mui.Box>
        {count > 1 && (
          <PaginationControls
            count={count}
            page={page}
            handleChange={handleChange}
            disabled={isLoadingContacts}
          />
        )}
      </Mui.List>
    </VerticalScrollbars>
  );
};

export default FoundContacts;
