import React, { useRef } from "react";
import * as Mui from "@mui/material";

//import { sameTimeInterval } from "XmarsUtils";
import { VerticalScrollbars } from "../../prestyledComponents";
import { MessageWithReplies } from "../../collectionItems";
import useStyles from "./Threads.styles";

// import { useAutoScroll, useDependency } from "../../../hooks/index";

/**
 * List of messages with unread replies.
 * @component
 * @returns {React.ReactElement}
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */

function Threads({ messages, handleMessageClick, messagesWithUnreadReplies }) {
  const classes = useStyles();
  const ref = useRef(null);
  
  // const handleTopScroll = useDependency()[0];
  // const { scrollToBottom } = useAutoScroll(ref, {
  //   onTopScroll: handleTopScroll,
  // });

  // useEffect(() => {
  //   scrollToBottom();
  // }, []);

  return (
    <VerticalScrollbars
      viewStyle={{
        padding: "10px 17px 30px 25px",
        marginBottom: "5px",
        height: "calc(100vh - 65px)",
      }}
      ref={(s) => (ref.current = s && s.view)}
    >
      <Mui.List className={classes.replies} ref={ref}>
        {messages.length + messagesWithUnreadReplies.length ? (
          <>
            <>
              {messagesWithUnreadReplies.map((message) => {
                return (
                  <MessageWithReplies
                    message={message}
                    position="unique"
                    openReplies={handleMessageClick(message)}
                    key={message.id}
                  />
                );
              })}
            </>
            <>
              {messages.map((message) => {
                return (
                  <MessageWithReplies
                    message={message}
                    position="unique"
                    openReplies={handleMessageClick(message)}
                    key={message.id}
                  />
                );
              })}
            </>
          </>
        ) : (
          <Mui.Typography variant="body1">
            This group has no threads.
          </Mui.Typography>
        )}
      </Mui.List>
    </VerticalScrollbars>
  );
}

export default Threads;
