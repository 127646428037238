import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  settings: {
    minWidth: "50vw",
  },
  iconButton: {
    border: `1px solid ${theme.palette.buttons.medium}`,
    color: theme.palette.buttons.main1,
  },
}));
