import { gql } from "../../../utils/apollo";

export const channelCall = gql`
  mutation ChannelCall($input: channelCallInput!) {
    channelCall(input: $input) {
      null
    }
  }
`;

export default channelCall;
