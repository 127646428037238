import { createAction } from "@reduxjs/toolkit";

export const startJitsiCall = createAction("saga/startJitsiCall");
export const jitsiCallHang = createAction("saga/jitsiCallHang");
export const jitsiEndConference = createAction("saga/jitsiEndConference");
export const jitsiRegisterVideoComponent = createAction(
  "saga/jitsiRegisterVideoComponent"
);
export const jitsiUnregisterVideoComponent = createAction(
  "saga/jitsiUnregisterVideoComponent"
);

export const endLocalAudioTrack = createAction("saga/endLocalAudioTrack");
export const endLocalVideoTrack = createAction("saga/endLocalVideoTrack");

export const localAudioTrackController = createAction(
  "saga/localAudioTrackController"
);

export const localVideoTrackController = createAction(
  "saga/localVideoTrackController"
);

export const changeLocalAudioTrack = createAction("saga/changeLocalAudioTrack");

export const localAudioTrackRemoved = createAction(
  "saga/localAudioTrackRemoved"
);

export const switchAudioOutputDevice = createAction(
  "saga/switchAudioOutputDevice"
);

export const conferenceObjectControl = createAction(
  "saga/conferenceObjectControl"
);

export const removeUsersFromCall = createAction("saga/removeUsersFromCall");
export const startSharing = createAction("saga/startSharing");
export const stopSharing = createAction("saga/stopSharing");
