import client from "../../../client";
import mutation from "./acceptNotification.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 */
export const acceptNotification = async function (id) {
  id = addBearer(id);
  await client.mutate({
    mutation,
    variables: {
      id,
    },
  });
};

export default acceptNotification;
