import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import * as reducers from "./reducers";

const slice = createSlice({
  name: "chat",
  initialState,
  reducers,
});

const { reducer, actions } = slice;

export default reducer;
export const {
  addLocalDraft,
  setUser,
  setAvatar,
  setStatus,
  setUsername,
  setWorkspaces,
  setCategories,
  setFriends,
  setFriendProperties,
  updateFriends,
  updateRoomUsers,
  updateChannelTitle,
  addFriend,
  removeFriend,
  setRoom,
  setWorkspace,
  removeWorkspace,
  hideRoom,
  setIsLoadingMessages,
  setDraft,
  setIsEditingMessage,
  setIsEditingReply,
  setEditingMessage,
  setEditingReply,
  setFirstRoom,
  updateRooms,
  setCurrentMessage,
  searchRoom,
  addChannel,
  addWorkspace,
  updateWorkspaces,
  updateWorkspaceParameters,
  updateCategoryParameters,
  addCategory,
  setRoomProperties,
  removeChannel,
  changeStatus,
  resetSearchedUsers,
  setSearchedUsers,
  addSearchedUser,
  removeSearchedUser,
  updateSearchedUser,
  setMessageToJumpTo,
} = actions;
