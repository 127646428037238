import React, { useRef, useState, useEffect } from "react";
import clsx from "clsx";
import { Box, Grid } from "@mui/material";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { FilesInput, MultilineInput } from "../../inputs";
import { EmojisButton } from "../../pseudoInputs";
import { IconSubmitButton, Tooltip } from "../../prestyledComponents";
import { ScribbleButton } from "../../buttons";
import { useMounted, useViewport } from "../../../hooks/index";
// import { useViewport } from "../../../hooks/index";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

import useStyles from "./ChatInputForm.styles";
import { Files } from "../../collections";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const ChatInputForm = function ({
  conference,
  isDrawer = false,
  user,
  room,
  files,
  activeChannel,
  sendJitsiMessage,
  sendTextMessage,
  setFiles,
  startedTyping,
  setSendingFiles,
  value,
  setValue,
  repliesPanelOpen,
  ...props
}) {
  const [anchor, setAnchor] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [inputText, setInputText] = useState("");
  const inputRef = useRef(null);

  const mounted = useMounted();
  const viewport = useViewport();

  const classes = useStyles();

  const handleOpen = (event) => setAnchor(event.target);
  const handleClose = () => setAnchor(null);

  useEffect(() => {
    if (room && inputRef.current) inputRef.current.focus();
  }, [room]);

  useEffect(() => {
    if (files.length && inputRef.current) inputRef.current.focus();
  }, [files]);

  const isGuest = user.role === "guest";
  useEffect(() => {
    if (files.length === 0 && value?.trim() === "") setValue("");
    if (files.length > 0 && value === "") setValue(" ");
  }, [files, value]);

  const handleSubmit = formSubmitHandler(async ({ text }) => {
    if (files.length) {
      setSendingFiles(true);
    }
    text = text.trim();
    if (!text) {
      setValue("");
      return;
    }
    try {
      setProcessing(true);
      await getBoundActionPromise(sendTextMessage, {
        room,
        text,
        avatar: user.avatar,
      });
      if (conference && room.id === activeChannel.id) {
        const message = { type: "text", text };
        await getBoundActionPromise(sendJitsiMessage, { conference, message });
      }
      if (mounted.current) {
        setValue("");
      }
    } catch (error) {
      logError(error);
    } finally {
      if (mounted.current) {
        setProcessing(false);
        inputRef.current.focus();
      }
      startedTyping({ room, isWriting: false });
    }
    setInputText("");
  });

  const handlePaste = async function (event) {
    const { files } = event.clipboardData;
    if (files.length) {
      event.preventDefault();
      setFiles(files);
    }
  };

  const handleChange = function (event) {
    if (event.target.value.length > 0 && inputText.length === 0) {
      startedTyping({ room, isWriting: true });
    } else if (event.target.value.length === 0) {
      startedTyping({ room, isWriting: false });
    }
    // if(event.target.value.length === 0){
    //   startedTyping({room, isWriting: false});
    // }
    // else {
    //   // startedTyping(room, true);
    //   startedTyping({room, isWriting: true});
    // }
    setInputText(event.target.value);
    setValue(event.target.value);
  };

  const handleEmoji = function (emoji) {
    setValue(value + emoji);
  };

  const handleFileChange = function (event) {
    const { files } = event.target;
    setFiles(files);
    event.target.value = "";
    if (anchor) handleClose();
  };

  const specialButtons = (
    <>
      <Box>
        <FilesInput
          disabled={processing}
          className={clsx(
            classes.chatButton,
            repliesPanelOpen && classes.chatButtonRepliesOpen
          )}
          TooltipProps={{ placement: "top" }}
          inputProps={{
            multiple: true,
            onChange: handleFileChange,
          }}
        />
      </Box>
      {value?.length || isGuest ? null : (
        <Box>
          <ScribbleButton
            TooltipProps={{ placement: "top" }}
            disabled={processing}
            className={clsx(
              classes.chatButton,
              repliesPanelOpen && classes.chatButtonRepliesOpen
            )}
            closeMenu={anchor ? handleClose : null}
          />
        </Box>
      )}
    </>
  );

  return (
    <Grid
      {...props}
      container
      component="form"
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      className={clsx(
        classes.chatFormBox,
        files.length ? classes.chatFormBoxFiles : null
      )}
      onSubmit={handleSubmit}
    >
      <Box flexGrow={1}>
        <Files
          classes={{
            root: classes.filesRoot,
            files: classes.files,
          }}
          isDrawer={isDrawer}
        />
        <MultilineInput
          name="text"
          placeholder="Write a message."
          ref={inputRef}
          value={value}
          disabled={processing}
          onChange={handleChange}
          onPaste={handlePaste}
          className={classes.messageInput}
          startComponent={
            <EmojisButton
              disabled={processing}
              className={clsx(classes.emojiButton)}
              onEmoji={handleEmoji}
              TooltipProps={{ placement: "top" }}
            />
          }
        />
      </Box>

      {viewport === "xs" ||
      viewport === "sm" ||
      (viewport === "md" && repliesPanelOpen) ? (
        value?.length ? null : (
          <Box>
            <Tooltip title="More" placement="top">
              <Mui.IconButton
                disabled={processing}
                className={clsx(
                  classes.chatButton,
                  repliesPanelOpen && classes.chatButtonRepliesOpen
                )}
                onClick={handleOpen}
              >
                <Icons.MoreHorizontal />
              </Mui.IconButton>
            </Tooltip>
            <Mui.Menu
              open={!!anchor}
              anchorEl={anchor}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Mui.MenuItem>{specialButtons}</Mui.MenuItem>
            </Mui.Menu>
          </Box>
        )
      ) : (
        specialButtons
      )}
      <Box>
        <IconSubmitButton
          disabled={!value?.length && !files.length}
          loading={processing}
          className={clsx(
            classes.chatButton,
            classes.sendButton,
            repliesPanelOpen && classes.chatButtonRepliesOpen
          )}
          TooltipProps={{ placement: "top" }}
          children={<Icons.Send />}
        />
      </Box>
    </Grid>
  );
};

export default ChatInputForm;

ChatInputForm.propTypes = {
  user: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  activeChannel: PropTypes.object,
  sendTextMessage: PropTypes.func.isRequired,
  setFiles: PropTypes.func.isRequired,
};
