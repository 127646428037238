import { useEffect, useRef, useState } from "react";

import { ping } from "../utils/network/ping";

const inBrowser = () => typeof navigator !== "undefined";

const supportNavOnline = () =>
  inBrowser() && typeof navigator.onLine === "boolean";

const shouldPoll = function () {
  const unsupportedUserAgents =
    /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;
  return inBrowser() && unsupportedUserAgents.test(navigator.userAgent);
};

export const useOnline = function ({
  polling = shouldPoll(),
  pollingInterval = 10000,
} = {}) {
  const [online, setOnline] = useState(
    supportNavOnline() ? navigator.onLine : true
  );
  const poller = useRef(null);

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    if (polling) startPolling();
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
      stopPolling();
    };
    // eslint-disable-next-line
  }, []);

  const updateOnlineStatus = () => setOnline(navigator.onLine);

  const pingAndUpdate = () =>
    ping()
      .then(() => setOnline(true))
      .catch(() => setOnline(false));

  const startPolling = () =>
    (poller.current = setInterval(pingAndUpdate, pollingInterval));
  const stopPolling = () => clearInterval(poller.current);

  return online;
};

export default useOnline;
