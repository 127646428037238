import client from "../../../client";
import query from "./subscribeToMyself.subscription";

/**
 * @typedef {Object} UserSubscriptionEvent
 * @property {string} signalType
 * @property {{
 *  id: string,
 *  username: string,
 *  avatar: string,
 * }} user
 * @property {{
 *  id: string,
 *  invitationMessage: string,
 *  notificationType: string,
 *  sender: {
 *    id: string,
 *    username: string,
 *    avatar: string,
 *  },
 *  room: {
 *    id: string,
 *    title: string,
 *    meetingCode: string,
 *    group: boolean,
 *    admin: boolean,
 *  },
 * }} notification
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */

/**
 * @param {null} [_args=null]
 * @param {Object} subcription Subscription options
 * @param {(event: UserSubscriptionEvent) => void} subcription.next
 * @param {(error: Error) => void} [subcription.error]
 */
export const subscribeToMyself = function (
  _args = null,
  { next, error = console.error }
) {
  const observable = client.subscribe({ query });
  return observable.subscribe({
    next({ data }) {
      next(data.subscribeToUser);
    },
    error,
  });
};

export default subscribeToMyself;
