import { connect } from "react-redux";

import ParticipantsQueue from "./ParticipantsQueue.component";

const mapStateToProps = (state) => {
  const { callStatus } = state.video;

  return { callStatus };
};

const ParticipantsQueueContainer = connect(
  mapStateToProps,
  null
)(ParticipantsQueue);

export default ParticipantsQueueContainer;
