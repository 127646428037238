import React, { useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import { Skeleton } from "@mui/material";
import PropTypes from "prop-types";

import SettingsPage from "../../pages/SettingsPage";

import { indicatorStyles, StatusIndicator } from "../../collectionItems";
import {
  ACTIVE,
  DONT_DISTURB,
  LONG_INACTIVE,
} from "../../../store/types/status";
import { stringAvatar } from "../../../utils/defaultAvatar/defaultAvatar";
import { removeInactivityDetection } from "../../../utils/inactivity/detectInactivity";
import useStyles from "./User.styles";
import InstantMeetingHeaderButton from "../../buttons/InstantMeetingHeaderButton/InstantMeetingHeaderButton.component";

export const User = function ({
  user,
  status,
  openMenu,
  logout,
  changeStatus,
  openProfile,
  panelsOpen,
  setPanelsOpen,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => setAnchorEl(null);

  const handleClick = (event) => setAnchorEl(event.target);

  const handleLogout = () => logout();

  const handleCloseDialog = () => setOpen(false);

  const handleOpenDialog = () => {
    setOpen(true);
    handleClose();
  };

  const classes = useStyles();
  const indicatorClasses = indicatorStyles();
  const handleSetActive = async () => {
    await changeStatus(ACTIVE);
    handleClose();
  };
  const handleSetInactive = async () => {
    await changeStatus(DONT_DISTURB);
    removeInactivityDetection();
    handleClose();
  };
  const handleSetAway = async () => {
    await changeStatus(LONG_INACTIVE);
    removeInactivityDetection();
    handleClose();
  };
  const handleOpenProfile = () => {
    openProfile(user);
    handleClose();
    panelsOpen && setPanelsOpen(false);
  };

  return (
    <>
      <Mui.ListItem disableGutters classes={{ root: classes.userInfo }}>
        <Mui.ListItemAvatar
          className={classes.userAvatar}
          onClick={openMenu ? handleClick : null}
          aria-label="openMenu"
        >
          {user.username !== Symbol.for("loading") ? (
            <StatusIndicator status={user.status}>
              <Mui.Avatar src={user.avatar} {...stringAvatar(user.username)} />
            </StatusIndicator>
          ) : (
            <Skeleton variant="circle" children={<Mui.Avatar />} />
          )}
        </Mui.ListItemAvatar>
        {user.username !== Symbol.for("loading") ? (
          <Mui.ListItemText
            primary={user.username}
            primaryTypographyProps={{
              className: classes.userText,
              variant: "h6",
            }}
          />
        ) : (
          <Skeleton
            width="50%"
            height="1.5em"
            children={<Mui.ListItemText />}
          />
        )}
      </Mui.ListItem>
      <InstantMeetingHeaderButton />
      {openMenu ? (
        <>
          <Mui.Menu
            keepMounted
            id="simple-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            {user.role === "guest"
              ? null
              : [
                  <Mui.ListItem key="1" button onClick={handleOpenProfile}>
                    <Mui.ListItemIcon>
                      <Mui.IconButton color="primary">
                        <Icons.User />
                      </Mui.IconButton>
                    </Mui.ListItemIcon>
                    <Mui.ListItemText primary={"Profile"} />
                  </Mui.ListItem>,
                  <Mui.ListItem key="2" button onClick={handleOpenDialog}>
                    <Mui.ListItemIcon>
                      <Mui.IconButton color="primary">
                        <Icons.Settings />
                      </Mui.IconButton>
                    </Mui.ListItemIcon>
                    <Mui.ListItemText primary={"Settings"} />
                  </Mui.ListItem>,
                ]}
            <Mui.ListItem button onClick={handleLogout}>
              <Mui.ListItemIcon>
                <Mui.IconButton color="primary">
                  <Icons.LogOut />
                </Mui.IconButton>
              </Mui.ListItemIcon>
              <Mui.ListItemText primary={"Logout"} />
            </Mui.ListItem>
            <Mui.ListItem aria-label="handleSetActive" button onClick={handleSetActive}>
              <Mui.ListItemIcon>
                <Mui.IconButton color="primary">
                  <Mui.Badge
                    classes={{ badge: indicatorClasses.active }}
                    variant="dot"
                    overlap="rectangle"
                    badgeContent=" "
                  />
                </Mui.IconButton>
              </Mui.ListItemIcon>
              <Mui.ListItemText primary={"Active"} />
            </Mui.ListItem>
            <Mui.ListItem aria-label="handleSetInactive" button onClick={handleSetInactive}>
              <Mui.ListItemIcon>
                <Mui.IconButton color="primary">
                  <Mui.Badge
                    classes={{ badge: indicatorClasses.dontDisturb }}
                    variant="dot"
                    overlap="rectangle"
                    badgeContent=" "
                  />
                </Mui.IconButton>
              </Mui.ListItemIcon>
              <Mui.ListItemText primary={"Don't disturb"} />
            </Mui.ListItem>
            <Mui.ListItem button onClick={handleSetAway}>
              <Mui.ListItemIcon>
                <Mui.IconButton color="primary">
                  <Mui.Badge
                    classes={{ badge: indicatorClasses.longInactive }}
                    variant="dot"
                    overlap="rectangle"
                    badgeContent=" "
                  />
                </Mui.IconButton>
              </Mui.ListItemIcon>
              <Mui.ListItemText primary={"Away"} />
            </Mui.ListItem>
          </Mui.Menu>
          <Mui.Dialog
            open={open}
            onClose={handleCloseDialog}
            classes={{
              paper: classes.settings,
            }}
            aria-labelledby="form-dialog-title"
          >
            <Mui.DialogContent>
              <SettingsPage />
            </Mui.DialogContent>
            <Mui.DialogActions>
              <Mui.IconButton color="primary" onClick={handleCloseDialog}>
                <Icons.X />
              </Mui.IconButton>
            </Mui.DialogActions>
          </Mui.Dialog>
        </>
      ) : null}
    </>
  );
};

export default User;

User.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};
