import { forwardRef } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const LeaveRoomButton = forwardRef(function (
  {
    room,
    leaveRoom,
    afterAction,
    showIcon = false,
    roomToLeave = null,
    ...props
  },
  ref
) {
  const handleClick = () => {
    leaveRoom(roomToLeave || room);
    afterAction();
  };

  return (
    <Mui.ListItem button onClick={handleClick} ref={ref}>
      {showIcon ? (
        <Mui.ListItemIcon>
          <Icons.MinusCircle color="secondary" />
        </Mui.ListItemIcon>
      ) : null}
      <Mui.ListItemText
        primary={"Leave conversation"}
        primaryTypographyProps={props}
      />
    </Mui.ListItem>
  );
});

export default LeaveRoomButton;

LeaveRoomButton.propTypes = {
  room: PropTypes.object,
  leaveRoom: PropTypes.func.isRequired,
  afterArction: PropTypes.func,
  showIcon: PropTypes.bool,
  roomId: PropTypes.string,
};
