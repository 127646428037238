export function createAudioTrackEventHandlers(emitter) {
  return {
    onLocalTrackStopped() {
      console.log("local audio track => onLocalTrackStopped");
    },
    onTrackAudioLevelChanged(audioLevel) {
      const newVolume = Math.floor(audioLevel * 100);
      emitter({
        trackStatus: "TRACK_AUDIO_LEVEL_CHANGED",
        newVolume,
      });
    },
    onTrackAudioOutputChanged(deviceId) {
      console.log("local audio track => onTrackAudioOutputChanged", deviceId);
    },
    onTrackMuteChanged() {
      console.log("local audio track => onTrackMuteChanged");
    },
    onTrackVideotypeChanged() {
      console.log("local audio track => onTrackVideotypeChanged");
    },
    onNoDataFromSource() {
      console.log("local audio track => onNoDataFromSource");
    },
    onNoAudioInput() {
      console.log("local audio track => onNoAudioInput");
    },
  };
}
