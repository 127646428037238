import { gql } from "../../../utils/apollo";

export const acceptNotification = gql`
  mutation AcceptNotification($id: ID) {
    acceptNotification(input: { id: $id }) {
      null
    }
  }
`;

export default acceptNotification;
