import client from "../../../client";
import mutation from "./changeOrganizationChats.mutation";

/**
 * @param {boolean} isOrganized
 * @returns {null}
 */
export const changeOrganizationChats = async function (isOrganized) {
  await client.mutate({
    mutation,
    variables: {
      isOrganized,
    },
  });
  return;
};

export default changeOrganizationChats;
