import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import { Rings, ThreeDots } from "react-loader-spinner";
import {
  PanToolOutlined as MuiPanTool,
  GestureRounded as MuiGesture,
} from "@mui/icons-material";

import { RemoteVideo, RemoteAudio } from "../../pseudoItems";
import { useMounted } from "XmarsCustomHooks/useMounted";
import { stringAvatar } from "XmarsUtils/defaultAvatar";

// eslint-disable-next-line
import { Props } from "./RemoteParticipant.propTypes";
// eslint-disable-next-line
import { JitsiMessage } from "XmarsJitsiMeetJS/index";
import RemoveUserFromCall from "../../RemoveUserFromCall";

/**
 * @param {Props} props
 */
export const RemoteParticipant = function ({
  participant,
  small,
  user,
  activeChannel,
  conference,
  videoGrid,
  isSmallVideoOpen,
  updateRemoteParticipant,
  removeParticipantMessage,
  sharing,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  /** @type {[JitsiMessage,React.Dispatch<React.SetStateAction<JitsiMessage>>]} */
  const [message, setMessage] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [removeButtonOpen, setRemoveButtonOpen] = useState(false);
  const mounted = useMounted();
  useEffect(() => {
    setShowMessage(false);
    if (message) {
      const currentMessage = message;
      setTimeout(() => {
        if (mounted.current) setShowMessage(true);
      }, 500);
      setTimeout(() => {
        removeParticipantMessage(participant.id, currentMessage);
      }, 2500);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    const currentMessage = participant.messages[0];
    setMessage(currentMessage);
    // eslint-disable-next-line
  }, [participant.messages]);
  useEffect(() => {
    const { status, id: sharingId } = sharing;
    const item = {
      id: participant.id,
    };
    const props = {
      focused: false,
    };
    if (status === true && sharingId === participant.id) {
      props.focused = true;
    }
    if (status === false) props.focused = true;
    updateRemoteParticipant({ item, props });
    // eslint-disable-next-line
  }, [sharing]);

  const kickParticipant = () => {
    conference.kickParticipant(participant.id);
  };

  /**
   * @param {React.MouseEvent} event
   */
  const handleClick = function (event) {
    setAnchorEl(event.target);
  };

  const handleClose = function () {
    setAnchorEl(null);
  };

  const handleDoubleClick = function () {
    // if (videoGrid !== "gallery") return;
    // const item = {
    //   id: participant.id,
    // };
    // const props = {
    //   focused: !participant.focused,
    // };
    // updateRemoteParticipant({ item, props });
  };

  const renderMessageIcon = function () {
    if (!message) return <span />;
    switch (message.type) {
      case "speaker":
        return <MuiPanTool />;
      case "scribble":
        return <MuiGesture />;
      case "file":
        return <Icons.File />;
      case "text":
        return <Icons.MessageSquare />;
      default:
        return <span />;
    }
  };

  const renderChipLabel = function () {
    if (!message) return null;
    switch (message.type) {
      case "speaker":
        return "*Speaker mode requested*";
      case "scribble":
        return "*Scribble*";
      case "file":
        return "*File*";
      case "text":
        return message.text;
      default:
        return null;
    }
  };

  const renderSmallOverlap = function () {
    return (
      <Mui.Zoom in={showMessage && small}>
        <Box position="absolute" top={0} left={0}>
          <Mui.Fab
            color="primary"
            size="small"
            style={{ transform: "scale(0.65)" }}
          >
            {renderMessageIcon()}
          </Mui.Fab>
        </Box>
      </Mui.Zoom>
    );
  };

  const renderBigOverlap = function () {
    return (
      <Mui.Zoom in={showMessage && !small}>
        <Box position="absolute" top="1em" left="1em">
          <Mui.Chip
            color="primary"
            label={renderChipLabel()}
            icon={renderMessageIcon()}
          />
        </Box>
      </Mui.Zoom>
    );
  };

  return (
    <>
      <Box
        width={isSmallVideoOpen ? "auto" : "100%"}
        height={isSmallVideoOpen ? "auto" : "100%"}
        display="inline-block"
        position="relative"
        onDoubleClick={handleDoubleClick}
      >
        {renderBigOverlap()}
        {renderSmallOverlap()}
        <Box
          width={isSmallVideoOpen ? "auto" : "100%"}
          height={isSmallVideoOpen ? "auto" : "100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {!participant.invited ? (
            <RemoteVideo small={small} track={participant.video} />
          ) : (
            <Mui.Box display={"flex"} flexDirection={"column"}>
              <Rings
                visible={true}
                height={sharing?.status ? "40px" : "70px"}
                width={sharing?.status ? "40px" : "70px"}
                color="#7ea6f7"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  position: "absolute",
                  zIndex: "2",
                }}
                wrapperClass=""
              />

              <Mui.Avatar
                src={participant.avatar}
                sx={{
                  width: sharing?.status ? "40px" : "70px",
                  height: sharing?.status ? "40px" : "70px",
                }}
                {...stringAvatar(participant.name)}
              />
              {sharing?.status === false ? (
                <Mui.Box display={"flex"}>
                  <Mui.Typography
                    variant="h6"
                    paddingRight={"5px"}
                    color={"#7ea6f7"}
                  >
                    Calling
                  </Mui.Typography>
                  <ThreeDots
                    visible={true}
                    height="20"
                    width="20"
                    color="#7ea6f7"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ alignItems: "flex-end" }}
                    wrapperClass=""
                  />
                </Mui.Box>
              ) : (
                ""
              )}
            </Mui.Box>
          )}
        </Box>
        <Box display="none">
          <RemoteAudio track={participant.audio} />
        </Box>
        {small ? (
          <Box position="relative" bottom="2em">
            <Mui.Chip
              label={participant.name}
              color="primary"
              size="small"
              sx={{ height: "1.3em" }}
            />
          </Box>
        ) : (
          <Box position="relative" bottom="2em">
            <Mui.Tooltip
              title={participant.invited ? "" : "Kick friend"}
              placement="top"
            >
              <Mui.Button
                size="small"
                variant="contained"
                style={{
                  height: isSmallVideoOpen ? "1em" : "1.5em",
                  width: isSmallVideoOpen ? "1em" : "auto",
                  opacity: isSmallVideoOpen ? "0.5" : "1",
                }}
                onClick={(e) => {
                  if (participant.invited) return;
                  setRemoveButtonOpen(true);
                }}
                endIcon={<Icons.MoreVertical size={15} />}
              >
                {participant.name}
              </Mui.Button>
            </Mui.Tooltip>
            <RemoveUserFromCall
              open={removeButtonOpen}
              handleClose={() => {
                setRemoveButtonOpen(false);
              }}
              username={participant.name}
              userId={participant.id}
            />
          </Box>
        )}
      </Box>
      {user.role === "guest" ||
      !activeChannel ||
      !activeChannel.admin ? null : (
        <Mui.Menu
          onClose={handleClose}
          open={!!anchorEl}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Mui.MenuItem onClick={kickParticipant}>
            <Mui.ListItemIcon>
              <Icons.Slash color="secondary" />
            </Mui.ListItemIcon>
            <Mui.ListItemText primary="Remove from videoCall" />
          </Mui.MenuItem>
        </Mui.Menu>
      )}
    </>
  );
};

export default RemoteParticipant;
