import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ChangePasswordForm from "./ChangePasswordForm.component";

import { changePassword } from "../../../store/actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePassword,
    },
    dispatch
  );

const ChangePasswordFormContainer = connect(
  null,
  mapDispatchToProps
)(ChangePasswordForm);

export default ChangePasswordFormContainer;
