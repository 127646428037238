import { createSlice } from "@reduxjs/toolkit";
import snackbarsInitialState from "./initialState";
import * as reducers from "./reducers";

const slice = createSlice({
  name: "snackbars",
  initialState: snackbarsInitialState,
  reducers,
});

const { reducer, actions } = slice;

export default reducer;
export const { resetSnackbars, setSnackbars, addSnackbar, removeSnackbar } =
  actions;
