/*
    Modulizing Jitsi
    Jitsi has to be initialized to be used. To prevent having to initialize it everytime we want to use it in the other modules,
    we initialize once within this file and export that object. This way, we ensure we are working with the same jitsi instance
    every time.
*/

import JitsiMockJS from "XmarsJitsiMeetJS/JitsiMockJS";
// eslint-disable-next-line
import { Jitsi } from "XmarsJitsiMeetJS/typeDefs";

/** @type {Jitsi} */
export const JitsiMeetJS = window.JitsiMeetJS || JitsiMockJS;
if (window.JitsiMeetJS) delete window.JitsiMeetJS;

JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR); //In this line we specify to Jitsi logger to only log errors
JitsiMeetJS.init();

export default JitsiMeetJS;
