import { ApolloClient, gql, InMemoryCache } from "../utils/apollo";

import link from "./link";
import { wrapMutation, wrapQuery, wrapSubscribe } from "../utils";
// import registerClient from "xmars-graphql-operations/lib/register/registerClient";

export const typeDefs = gql`
  scalar Date
`;

const noCache = { fetchPolicy: "no-cache" };

export const client = new ApolloClient({
  link,
  typeDefs,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: noCache,
    query: noCache,
    mutate: noCache,
  },
});

wrapQuery(client);
wrapSubscribe(client);
wrapMutation(client);

// registerClient(client);

export default client;
