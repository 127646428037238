import { createLocalTracks, getMediaDevices } from "XmarsJitsiMeetJS/index";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";
import { actionChannel, fork, put, race, take } from "redux-saga/effects";
import * as actions from "XmarsStore/actions";

export function* listenChangeLocalAudioTrack(jitsiConference) {
  const changeLocalAudioTrackChannel = yield actionChannel(
    actions.changeLocalAudioTrack
  );
  try {
    while (true) {
      const { action, endLocalAudioTrack } = yield race({
        action: take(changeLocalAudioTrackChannel),
        endLocalAudioTrack: take(actions.jitsiCallHang),
      });
      if (endLocalAudioTrack) {
        changeLocalAudioTrackChannel.close();
      } else {
        const { payload } = action;
        yield fork(changeLocalAudioTrack, payload, jitsiConference);
      }
    }
  } catch (error) {
    console.log("listenChangeLocalAudioTrack error: ");
    yield handleSagaError(error);
  }
}

function* changeLocalAudioTrack(micDeviceId, jitsiConference) {
  try {
    yield put(actions.endLocalAudioTrack());
    yield take(actions.localAudioTrackRemoved);
    const track = yield createLocalTracks.audio({ micDeviceId });
    yield jitsiConference.addTrack(track);
    const devices = yield getMediaDevices();
    yield put(actions.setDevices(devices));
  } catch (error) {
    yield put(
      actions.addSnackbar({
        severity: "error",
        message: `${error.message}`,
      })
    );
  }
}
