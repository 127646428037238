import { getMainDefinition } from "../apollo";

import {
  Message,
  Notification,
  Observable,
  Response,
  Room,
  User,
  Void,
} from "./mocks";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const mockApi = function (query, variables) {
  const definition = getMainDefinition(query);
  const operationName = definition.name.value;
  switch (definition.operation) {
    case "query":
      switch (operationName) {
        case "Handshake":
          return new Response({
            handshake: new Void(),
          });
        case "GetUser":
          return new Response({
            getUser: new User(),
          });
        case "GetFriends":
          return new Response({
            getUser: {
              friends: [new User("Robin")],
            },
          });
        case "SearchByUsername":
          return new Response({
            searchByUsername: [new User("Joker"), new User("Penguin")],
          });
        case "GetRoomUsers":
          return new Response({
            getRoom: {
              users: [new User("Joker"), new User("Penguin", true)],
            },
          });
        case "GetGroupRooms":
          return new Response({
            getGroupRooms: [
              new Room("Gotham", true),
              new Room(
                "Some super ultra hyper mega extremely long group name",
                true
              ),
              new Room("Justice", true, true),
            ],
          });
        case "GetFriendRoom":
          return new Response({
            getFriendRoom: new Room("Robin"),
          });
        case "GetNotifications":
          return new Response({
            getUser: {
              notifications: [
                new Notification(),
                new Notification("roomInvitation"),
              ],
            },
          });
        case "GetMessages":
          return new Response({
            getMessages: [
              new Message(),
              new Message({
                messageType: "form",
                form: JSON.stringify({
                  answer: "Answered",
                }),
              }),
              new Message(),
              new Message({ owner: "You" }),
              new Message({ owner: "You" }),
              new Message(),
              new Message({
                messageType: "file",
                text: JSON.stringify({
                  mimeType: "application/octet-stream",
                  name: "FileFileFileFileFileFileFile",
                  size: 1e8,
                }),
              }),
              new Message(),
              new Message({
                owner: "You",
                messageType: "file",
                text: JSON.stringify({
                  mimeType: "image/jpg",
                  name: "ImageImageImageImageImageImage",
                  size: 1e8,
                  thumbnail: "data:image/jpg;base64,",
                }),
              }),
              new Message(),
              new Message({
                messageType: "file",
                text: JSON.stringify({
                  mimeType: "image/jpg",
                  name: "ImageImageImageImageImageImage",
                  size: 1e8,
                  thumbnail: "data:image/jpg;base64,",
                }),
              }),
              new Message(),
              new Message({
                messageType: "form",
                form: JSON.stringify({
                  type: "multipleOption",
                  options: ["one", "two"],
                }),
              }),
              new Message({ owner: "You" }),
            ],
          });
        case "GetUserByEmail":
          return new Response({
            getUserByEmail: new User(),
          });
        case "GetRecordings":
          return new Response({
            getUser: {
              recordings: [],
            },
          });
        case "GetClientSecret":
          return new Response({
            getClientSecret: {
              clientSecret:
                "pi_123456789012345678901234_secret_5678901234567890123456789",
            },
          });
        case "GetPublicRoomLink":
          return new Response({
            getPublicRoomLink: "room_link",
          });
        default:
          throw new Error(`Query ${operationName} not mocked`);
      }
    case "mutation":
      switch (operationName) {
        case "CreateUser":
          return new Response({
            createUser: new User(),
          });
        case "DeleteUser":
          throw new Error("Delete User is useless in mocked Api");
        case "Login":
          return new Response({
            login: new User(),
          });
        case "Logout":
          throw new Error("Logout is useless in mocked Api");
        case "ChangeUsername":
        case "ChangeEmail":
        case "ChangePassword":
        case "ChangeAvatar":
          return new Response({
            updateUser: new User(),
          });
        case "RemoveAvatar":
          return new Response({
            removeAvatar: new User(),
          });
        case "Unfriend":
          return new Response({
            unfriend: new User(),
          });
        case "SetOnlineStatus":
          return new Response({
            setOnlineStatus: new Void(),
          });
        case "CreateCustomRoom":
          return new Response({
            createCustomRoom: new Room(variables.title, true, true),
          });
        case "CreatePublicRoom":
          return new Response({
            createPublicRoom: new Room(variables.title, true, true),
          });
        case "LeaveRoom":
          return new Response({
            leaveRoom: new Void(),
          });
        case "RoomCall":
          return new Response({
            roomCall: new Void(),
          });
        case "SendNotification":
          return new Response({
            sendNotification: new Void(),
          });
        case "AcceptNotification":
          return new Response({
            acceptNotification: new Void(),
          });
        case "RejectNotification":
          return new Response({
            rejectNotification: new Void(),
          });
        case "SendMessage":
          return new Response({
            sendMessage: new Message({ ...variables, owner: "You" }),
          });
        case "EditMessage":
          return new Response({
            editMessage: new Void(),
          });
        case "DeleteMessage":
          return new Response({
            deleteMessage: new Void(),
          });
        case "KickUser":
          return new Response({
            kickUser: new Void(),
          });
        case "SetRoomAdmin":
          return new Response({
            setRoomAdmin: new Void(),
          });
        case "DeleteAppUser":
          return new Response({
            deleteAppUser: new User(),
          });
        case "AnswerBotMessage":
          return new Response({
            answerBotMessage: new Void(),
          });
        case "ReadMessage":
          return new Response({
            readMessage: new Void(),
          });
        case "AddRecording":
          return new Response({
            addRecording: new Void(),
          });
        case "RecoverPassword":
          return new Response({
            recoverPassword: new Void(),
          });
        case "RequestRecovery":
          return new Response({
            requestRecovery: new Void(),
          });
        case "UpdateMedicalRoomPayment":
          return new Response({
            updateMedicalRoomPayment: { cost: 100 },
          });
        case "ChangeStatus":
          return new Response({
            changeStatus: new Void(),
          });
        case "StartedTyping":
          return new Response({
            startedTyping: new Void(),
          });
        default:
          throw new Error(`Mutation ${operationName} not mocked`);
      }
    case "subscription":
      return new Observable();
    default:
      throw new Error("Mock api malfunction");
  }
};

export default mockApi;
