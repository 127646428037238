// If the length of the element's string is 0 after trim it, value is empty
export const isEmptyField = (value = "") => {
  if (
    typeof value === "object" ||
    typeof value === "function" ||
    typeof value === "boolean"
  )
    throw new TypeError("Input must be text");
  return value.toString().length === 0;
};
