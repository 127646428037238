import client from "../../../client";
import mutation from "./createGuest.mutation";

/**
 * @param {string} username
 * @param {string} email
 * @returns {Promise<{ username: string }>}
 */
export const createGuest = async function (username, email) {
  const { data } = await client.mutate({
    mutation,
    variables: {
      username,
      email,
    },
  });
  return data.createGuest;
};

export default createGuest;
