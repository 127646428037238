import { createSelector, type Selector } from "@reduxjs/toolkit";
import { UserStatus } from "XmarsStore/types/status";

// TODO: Once the state uses TypeScript, the AppState type should be replaced
type AppState = any;

type Role = "user" | "guest" | "admin";

const symbolForLoading: unique symbol = Symbol.for("loading");
type SymbolForLoading = typeof symbolForLoading;

type UserData = {
  id: string;
  username: string | SymbolForLoading;
  avatar: string | null;
  role: Role;
  status: UserStatus;
};

const getUser: Selector<AppState, UserData> = (state) => state.chat.user;

export const selectUser = createSelector(getUser, (user) => user);

export const selectUserId = createSelector(
  [getUser],
  (currentUser) => currentUser.id
);

// NOTE: The user status is currently duplicated in state.chat.user.status and in state.chat.status
// Is there any good reason for this duplication?
// TODO: Find out if is there a real benfit on using `createSelector` in this
// simple selectors. I'm following this pattern because I found it being used in
// old code. (It could help at least if a refactor involves the `user` field)
export const selectUserStatus = createSelector(
  selectUser,
  (user) => user.status
);
