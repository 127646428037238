import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Messages from "./Messages.component";
import {
  setMessages,
  getMessages,
  getMessagesUpToDate,
  saveDraft,
  setIsLoadingMessages,
  getDraft,
  setIsEditingMessage,
  setMessageToJumpTo,
} from "XmarsStore/actions/index";
import {
  selectActiveChannel,
  selectActiveChannelId,
  selectAllActiveChannelMessages,
  selectUser,
} from "XmarsStore/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const activeChatRoomId = selectActiveChannelId(state);
  const user = selectUser(state);
  const { isLoadingMessages, isEditing, editingMessage, messageToJumpTo } =
    state.chat;
  const messages = selectAllActiveChannelMessages(state);

  return {
    messages,
    user,
    room,
    activeChatRoomId,
    isEditing,
    isLoadingMessages,
    editingMessage,
    messageToJumpTo,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setMessages,
      getMessages,
      getMessagesUpToDate,
      saveDraft,
      getDraft,
      setIsLoadingMessages,
      setIsEditing: setIsEditingMessage,
      setMessageToJumpTo,
    },
    dispatch
  );

const MessagesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages);

export default MessagesContainer;
