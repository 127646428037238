import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { Alert } from "@mui/material";
import PropTypes from "prop-types";

export const Snackbar = function ({ snackbar, removeSnackbar }) {
  const [autoHideDuration, setAutoHideDuration] = useState(5000);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setAutoHideDuration(2000);
    setTimeout(setOpen, 150, true);
  }, [snackbar]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
    setTimeout(() => {
      removeSnackbar(snackbar);
    }, 150);
  };
  return (
    <Mui.Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </Alert>
    </Mui.Snackbar>
  );
};

export default Snackbar;

Snackbar.propTypes = {
  snackbar: PropTypes.object.isRequired,
  removeSnackbar: PropTypes.func.isRequired,
};
