import * as actions from "../../actions";
import api from "../../../dataApi";
import { handleSagaError } from "../sagas.utils";
import { eventChannel } from "redux-saga";
import { call, fork, put, take, race } from "redux-saga/effects";
import { unregisterFriend } from "../chat/utils";

function friendChannel(friend) {
  const cleanedSubscription = (emitter) => {
    const subscription = api.subscribeToFriend(friend, {
      next: emitter,
      error: handleSagaError,
    });
    // This weird trick removes a this is undefined error:
    const unsubscribe = subscription.unsubscribe;
    return unsubscribe.bind(subscription);
  };
  return eventChannel(cleanedSubscription);
}

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
function* processSignal(payload) {
  const { friend, signal } = payload;
  try {
    switch (signal.signalType) {
      case "onlineStatus":
        yield put(
          actions.setFriendProperties({
            friend,
            properties: {
              online: signal.online,
            },
          })
        );
        yield put(actions.updateRooms());
        break;
      case "userUpdate":
        {
          const user = signal.user;
          yield put(
            actions.setFriendProperties({
              friend,
              properties: {
                username: user.username,
                avatar: user.avatar,
              },
            })
          );
          yield put(actions.updateFriends());
          if (friend.room) {
            friend.room.title = user.username;
            yield put(actions.updateRooms());
          }
        }
        break;
      case "removeUser":
        const snackbar = {
          severity: "info",
          message: `You and ${friend.username} are no longer friends`,
        };
        yield put(actions.addSnackbar(snackbar));
        yield call(unregisterFriend, friend);
        break;
      default:
        break;
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}

export const startFriendSubscription = function* ({ payload }) {
  const friend = payload;
  const channel = yield call(friendChannel, friend);
  try {
    while (true) {
      const { signal, cancel } = yield race({
        signal: take(channel),
        cancel: take(actions.cancelFriendSubscription),
      });

      if (cancel) {
        if (cancel.payload.id === friend.id) {
          channel.close();
        }
      } else {
        yield fork(processSignal, { friend, signal });
      }
    }
  } catch (error) {
    handleSagaError(error);
  }
};
