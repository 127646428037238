import BackgroundModel from "./BackgroundModel";
import { tryOrLog } from "../../utils";

export class BackgroundTracker {
  constructor(onPredict) {
    this.model = null;
    this.running = false;
    this.onPredict = onPredict;
  }

  // public
  async init(modelParams = {}) {
    const model = new BackgroundModel();
    await model.init(modelParams);
    this.model = model;
  }

  // public
  dispose() {
    this.stop();
    this.model = null;
    this.onPredict = null;
  }

  // public
  start(video) {
    this.running = true;
    const trackSettings = video.srcObject.getTracks()[0].getSettings();
    video.height = trackSettings.height;
    video.width = trackSettings.width;
    const predict = async () => {
      const { model } = this;
      if (!model) return;
      await tryOrLog(async () => {
        const predictions = await model.predict(video);
        this.onPredict(predictions, video);
      }, true);
    };
    const continuousPredict = async () => {
      if (!this.running) return;
      predict();
      const fps = await this.model.getFPS();
      if (fps) setTimeout(continuousPredict, 1000 / fps);
    };
    continuousPredict();
  }

  // public
  stop() {
    this.running = false;
  }
}

export default BackgroundTracker;
