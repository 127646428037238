export const getRectanglePath = function ({
  left = 0,
  top = 0,
  width = 10,
  height = 10,
} = {}) {
  const path2d = new Path2D();
  path2d.rect(left, top, width, height);
  return path2d;
};

export default getRectanglePath;
