import client from "../../../client";
import mutation from "./pingStatus.mutation";

/**
 * @param {string} status
 */
export const pingStatus = async function (status) {
  await client.mutate({
    mutation,
  });
};

export default pingStatus;
