import { Grid, Icon, Typography, Backdrop } from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";
import clsx from "clsx";

import useStyles from "./DisconnectedBackdrop.styles";

export default function DisconnectedBackdrop({ connected }) {
  const classes = useStyles();

  return (
    <Backdrop
      className={clsx(classes.backdrop, classes.shading)}
      open={!connected}
    >
      <Grid container spacing={4} alignItems="center" direction="column">
        <Grid item>
          <Icon color="secondary">
            <Icons.CloudOff className={classes.icon} />
          </Icon>
        </Grid>
        <Grid item>
          <Typography variant="h5" children="Disconnected from server" />
        </Grid>
      </Grid>
    </Backdrop>
  );
}

DisconnectedBackdrop.propTypes = {
  connected: PropTypes.bool.isRequired,
};
