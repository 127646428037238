import { gql } from "../../../utils/apollo";

export const handshake = gql`
  query Handshake {
    handshake {
      null
    }
  }
`;

export default handshake;
