import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { localVideoTrackController } from "../../../store/actions/index";

import MuteVideoButton from "./MuteVideoButton.component";

const mapStateToProps = (state) => {
  const { onlyAudio } = state.video;
  const { localVideoTrackReady, localVideoIsMuted } =
    state.jitsi.localTracksData;
  return {
    onlyAudio,
    localVideoTrackReady,
    localVideoIsMuted,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      localVideoTrackController,
    },
    dispatch
  );

const MuteVideoButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MuteVideoButton);

export default MuteVideoButtonContainer;
