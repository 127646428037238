import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";

import * as Icons from "react-feather";
import PropTypes from "prop-types";

import env from "../../env";
import { blobToDataUrl } from "../../utils/converters/blobToDataUrl";

import { FilesInput } from "../inputs";
import { useLocalStorage, useJsonParsed } from "../../hooks/index";

import useStyles from "./BackgroundSelect.styles";

const defaultBackgrounds = new Set([
  null,
  `${env.PUBLIC_URL}/images/background0.jpg`,
  `${env.PUBLIC_URL}/images/background1.jpg`,
  `${env.PUBLIC_URL}/images/background2.jpg`,
  `${env.PUBLIC_URL}/images/background3.jpg`,
  `${env.PUBLIC_URL}/images/background4.jpg`,
  `${env.PUBLIC_URL}/images/background5.jpg`,
  `${env.PUBLIC_URL}/images/background6.jpg`,
  `${env.PUBLIC_URL}/images/background7.jpg`,
  `${env.PUBLIC_URL}/images/background8.jpg`,
  `${env.PUBLIC_URL}/images/background9.jpg`,
]);

export const BackgroundSelect = function ({
  videoEffects,
  updateVideoEffects,
  addSnackbar,
}) {
  const [backgroundsJson, setBackgroundsJson] = useLocalStorage(
    "backgrounds",
    "[]"
  );

  const storedBackgrounds = useJsonParsed(backgroundsJson);

  const backgrounds = useMemo(
    () => [...defaultBackgrounds, ...storedBackgrounds],
    [storedBackgrounds]
  );

  const classes = useStyles();

  const addBackground = function (background) {
    if (storedBackgrounds.indexOf(background) > -1) return;
    const newBackgrounds = [...storedBackgrounds, background];
    setBackgroundsJson(JSON.stringify(newBackgrounds));
  };

  const selectBackground = function (background) {
    updateVideoEffects({ backgroundCurrent: background });
  };

  const removeBackground = function (background) {
    const newBackgrounds = storedBackgrounds.filter(
      (stored) => stored !== background
    );
    setBackgroundsJson(JSON.stringify(newBackgrounds));
  };

  const handleChange = async function (event) {
    const file = event.target.files[0];
    if (!file) return;
    try {
      const newBackground = await blobToDataUrl(file);
      addBackground(newBackground);
    } catch (error) {
      addSnackbar({
        message: error.message,
        severity: "error",
      });
    }
  };

  return (
    <>
      <Box display="flex">
        <Box margin="auto" minHeight="150px">
          <ListItem>
            <ListItemAvatar>
              <Avatar
                variant={videoEffects.backgroundCurrent ? "rounded" : "circle"}
                className={
                  videoEffects.backgroundCurrent
                    ? classes.selectedImage
                    : classes.selectedBlur
                }
                src={videoEffects.backgroundCurrent}
                children="Blur"
              />
            </ListItemAvatar>
            <ListItemText
              className={classes.current}
              primary="Current background"
            />
          </ListItem>
        </Box>
      </Box>
      <FilesInput
        inputProps={{
          onChange: handleChange,
          accept: "image/*",
        }}
        component={Button}
        fullWidth
        variant="contained"
        color="primary"
        startIcon={<Icons.Image />}
        children="Add background"
      />
      <Box display="flex" flexWrap="wrap">
        {backgrounds.map((background, index) => (
          <Box key={index} margin="0.5em">
            <Avatar
              variant={background ? "rounded" : "circle"}
              className={background ? classes.image : classes.blur}
              onClick={() => selectBackground(background)}
              src={background}
              children="Blur"
            />
            {defaultBackgrounds.has(background) ? null : (
              <Button
                variant="contained"
                onClick={() => removeBackground(background)}
                children="Remove"
              />
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default BackgroundSelect;

BackgroundSelect.propTypes = {
  videoEffects: PropTypes.object.isRequired,
  updateVideoEffects: PropTypes.func.isRequired,
  addSnackbar: PropTypes.func.isRequired,
};
