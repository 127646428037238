/**
 * @callback callback
 * @param {CanvasRenderingContext2D} ctx
 */

/**
 * @param {CanvasRenderingContext2D} ctx
 * @param {callback} callback
 */
export const ctxSaveExecRestore = function (ctx, callback) {
  ctx.save();
  if (callback) callback(ctx);
  ctx.restore();
};

export default ctxSaveExecRestore;
