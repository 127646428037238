import recordingState from "./recordingState";
import { mainLog, groupLog } from "../loggers";
import JitsiError from "../JitsiError";
import { logError } from "../../utils";
// eslint-disable-next-line
import { JitsiConference } from "../typeDefs";

/**
 * @param {JitsiConference} conference
 */
export const stopRecording = async function (conference) {
  mainLog("Stopping recording...");
  try {
    const stopping = await conference.stopRecording(recordingState.sessionID);
    groupLog("Recording stopped", () => {
      console.log(stopping);
    });
    recordingState.reset("Recording stopped");
    return stopping;
  } catch (error) {
    groupLog("Stopping recording failed!", () => {
      logError(error);
    });
    const message =
      error instanceof Element ? error.textContent : error.message;
    throw new JitsiError(message);
  }
};

export default stopRecording;
