import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const font = "'Inter', sans-serif";

const plainTheme = createTheme({
  palette: {
    primary: {
      light: "#eaf1ff",
      main: "#7ea6f7",
      dark: "#0062e1",
    },
    secondary: {
      main: "#fd397a",
      dark: "#df1b5c",
    },
    successAlert: {
      background: "#d1ecf1",
      text: "#0c5460",
    },
    buttons: {
      veryLight: "#fafafa",
      light: "#e6e6e6",
      medium: "#c8c6c6",
      main1: "#6f6f6f",
      main2: "#616264",
      dark: "#212529",
      darkSecondary: "#a71d2a",
    },
    messages: {
      lightOther: "#ebebeb",
      replyOther: "#d5eee8",
      replyOtherText: "#393939",
      replyOwn: "#f0d3ff",
      textOwn: "#3e3d3d",
      time: "#828282",
      highlightedBg: "#fffeca",
    },
    sidebarItems: {
      primary: "#19191a",
      primarySelected: "#888a8c",
      secondary: "#7d7d7d",
      secondarySelected: "#646464",
    },
    textGray: {
      chatHeader: "#4a4a4a",
      input: "#495057",
      inputPlaceholder: "#6c757d",
      main: "#888a8c",
    },
    borderGray: {
      input: "#ced4da",
    },
    bgGray: {
      sidebar: "#f8fafe",
      files: "#f7f3f3",
    },
    tabs: {
      primary: "#000000",
      primarySelected: "#0a80ff",
    },
    headerText: {
      writing: "#6fbbfd",
    },
    videoPage: {
      background: "#e1edf7",
      bottomBg: "#fbfbfd",
    },
  },
  typography: {
    fontFamily: font,
    fontSize: 12,

    h6: {
      fontSize: 16,
      fontWeight: 600,
    },

    body1: {
      fontSize: 14,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      videoSm: 720,
      md: 768,
      lg: 992,
      videoLg: 1140,
      xl: 1200,
    },
  },
});

export const theme = responsiveFontSizes(plainTheme, { factor: 2 });

export default theme;
