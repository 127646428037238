import client from "../../../client";
import { addBearer } from "../../../utils";
import mutation from "./startedTyping.mutation";

/**
 * @param {string} id
 * @param {boolean} isWriting
 */
export const startedTyping = async function (id, isWriting) {
  id = addBearer(id);
  // console.log(isWriting)
  await client.mutate({
    mutation,
    variables: {
      id,
      isWriting,
    },
  });
};

export default startedTyping;
