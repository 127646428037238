import client from "../../../client";
import mutation from "./channelCall.mutation";
import { addBearer } from "../../../utils";

export const channelCall = async function ({ channelId, workspaceId }) {
  channelId = addBearer(channelId);
  workspaceId = addBearer(workspaceId);
  await client.mutate({
    mutation,
    variables: {
      input: {
        channelId,
        workspaceId,
      },
    },
  });
};

export default channelCall;
