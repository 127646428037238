/**
 * @param {HTMLInputElement} element
 */
export const selectInputContent = function (element) {
  const text = element.value;
  element.focus();
  element.setSelectionRange(0, text.length);
  return text;
};

export default selectInputContent;
