import { connect } from "react-redux";

import RoomInfoButton from "./RoomInfoButton.component";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  return { room };
};
const mapDispatchToProps = () => ({});

const RoomInfoButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(RoomInfoButton);

export default RoomInfoButtonContainer;
