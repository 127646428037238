import TfLiteManager from "./TfLiteManager";

export class BackgroundModel {
  fps = 1;
  #manager = null;

  async init(params = {}) {
    const manager = new TfLiteManager();
    await manager.init(params);
    this.#manager = manager;
  }

  async predict(media) {
    const start = Date.now();
    const prediction = await this.#manager.predict(media);
    const end = Date.now();
    this.fps = 1000 / (end - start);
    return prediction;
  }

  getFPS() {
    return this.fps;
  }
}

export default BackgroundModel;
