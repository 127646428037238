import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import LeaveRoomButton from "./LeaveRoomButton.component";

import { leaveRoom } from "../../../store/actions/index";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  return { room };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      leaveRoom,
    },
    dispatch
  );

const LeaveRoomButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(LeaveRoomButton);

export default LeaveRoomButtonContainer;
