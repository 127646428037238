export const setLocalAudioVolume = function (state, { payload }) {
  state.localTracksData.localAudioVolume = payload;
};

export const setLocalVideoTrackReady = (state, { payload }) => {
  state.localTracksData.localVideoTrackReady = payload;
};

export const setLocalAudioTrackReady = (state, { payload }) => {
  state.localTracksData.localAudioTrackReady = payload;
};

export const setLocalAudioIsMuted = (state, { payload }) => {
  state.localTracksData.localAudioIsMuted = payload;
};

export const setLocalVideoIsMuted = (state, { payload }) => {
  state.localTracksData.localVideoIsMuted = payload;
};
