import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import clsx from "clsx";

import { EmojisButton } from "../../pseudoInputs";
import { MultilineInput } from "../../inputs";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { TooltipIconButton } from "../../prestyledComponents";
import useStyles from "./ReplyMessageForm.styles";
import { useSize } from "../../../hooks/useSize";

/**
 * Form to reply a message.
 * @component
 * @param {function} replyMessage - Action to start the reply.
 * @param {Object} message - Contains the information of the message being replied.
 * @param {function} handleCloseReplyForm - Closes the reply form.
 * @param {boolean} [isLocal=false] - Indicates if the form is open to reply a message for first time, or  it is in the replies box.
 * @param {string} [placeholder="Reply the message."] - Sets the placeholder of the form.
 */
export const ReplyMessageForm = ({
  replyMessage,
  message,
  handleCloseReplyForm,
  isLocal = false,
  placeholder = "Reply the message.",
  inputRef,
  setInputHeight,
}) => {
  const [value, setValue] = useState("");
  const classes = useStyles();

  /**
   * Submits the reply.
   * @param {object} event - Synthetic event triggered when the submit button is clicked.
   */
  async function handleSubmit(event) {
    event.preventDefault();
    let text = value.trim();
    if (!text) return;
    if (!isLocal) {
      handleCloseReplyForm();
    }
    setValue("");
    //replyMessage({ message, text });
    await getBoundActionPromise(replyMessage, {
      message,
      text,
    });
  }

  /**
   * Sets the new value of the form.
   * @param {object} event - Synthetic event triggered when something is typed in the form.
   */
  function handleChange(event) {
    setValue(event.target.value);
  }

  /**
   * Adds the provided emoji to the current value of the form.
   * @param {string} emoji - Description.
   */
  const handleEmoji = function (emoji) {
    setValue(value + emoji);
  };

  const size = useSize(inputRef);

  useEffect(() => {
    setInputHeight(size?.height);
  }, [size?.height]);

  return (
    <Mui.Box
      component="form"
      display="flex"
      onSubmit={handleSubmit}
      className={classes.fullContainer}
    >
      <Mui.Box flexGrow={1}>
        <MultilineInput
          name="text"
          placeholder={placeholder}
          className=""
          value={value}
          onChange={handleChange}
          autoFocus={true}
          inputRef={inputRef}
          startComponent={
            <EmojisButton
              color="primary"
              onEmoji={handleEmoji}
              TooltipProps={{ placement: "top" }}
              className={classes.emojiButton}
            />
          }
        />
      </Mui.Box>

      <Mui.Box onClick={() => {}}>
        <TooltipIconButton
          title="Add files"
          placement="top"
          className={classes.formButton}
        >
          <Icons.Paperclip />
        </TooltipIconButton>
      </Mui.Box>
      <Mui.Box onClick={handleSubmit}>
        <Mui.IconButton
          className={clsx(classes.formButton, classes.sendButton)}
        >
          <Icons.Send />
        </Mui.IconButton>
      </Mui.Box>

      {!isLocal && (
        <Mui.Box onClick={handleCloseReplyForm}>
          <Mui.IconButton>
            <Icons.X />
          </Mui.IconButton>
        </Mui.Box>
      )}
    </Mui.Box>
  );
};

export default ReplyMessageForm;
