import env from "../../env";
import store from "XmarsStore/index";
import api from "../../dataApi";
import { actions } from "XmarsStore/actions";
import { ACTIVE, SHORT_INACTIVE, LONG_INACTIVE } from "XmarsStore/types/status";

const SHORT_INACTIVITY = parseInt(env.SHORT_INACTIVITY);
const LONG_INACTIVITY = parseInt(env.LONG_INACTIVITY);

var time;
var time2;
var beenInactive = false;
const events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];
function action() {
  resetTimer();
  if (beenInactive) {
    console.log("imperative: fix /utils/detectInactivity");
    store.dispatch(actions.chat.changeStatus(ACTIVE))
    beenInactive = false;
  }
}
export const detectInactivity = function () {
  resetTimer();
  events.forEach((event) => {
    document.addEventListener(event, action);
  });
};
export const removeInactivityDetection = function () {
  clearTimeout(time);
  clearTimeout(time2);
  events.forEach((event) => {
    document.removeEventListener(event, action);
  });
};
//TODO add a channel to detect inactivity
function dispatchShortInactivity() {
  beenInactive = true;
  store.dispatch(actions.chat.changeStatus(SHORT_INACTIVE))
}
function dispatchLongInactivity() {
  beenInactive = true;
  store.dispatch(actions.chat.changeStatus(LONG_INACTIVE))
}
export function resetTimer() {
  clearTimeout(time);
  time = setTimeout(dispatchShortInactivity, SHORT_INACTIVITY);
  clearTimeout(time2);
  time2 = setTimeout(dispatchLongInactivity, LONG_INACTIVITY);
}
