import client from "../../../client";
import mutation from "./setWorkspaceAdmin.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} workspaceId
 * @param {string} userId
 */
export const setWorkspaceAdmin = async function (workspaceId, userId) {
  workspaceId = addBearer(workspaceId);
  userId = addBearer(userId);
  await client.mutate({
    mutation,
    variables: {
      input: {
        workspaceId,
        userId,
      },
    },
  });
};

export default setWorkspaceAdmin;
