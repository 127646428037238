import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import PropTypes from "prop-types";

export const DatePicker = function ({ disabled, name, InputProps, ...props }) {
  const [date, setDate] = useState(new Date());
  const [parsed, setParsed] = useState(0);

  useEffect(() => {
    setParsed(date.getTime());
  }, [date]);

  return (
    <>
      <input
        data-testid="dateInput"
        type="hidden"
        name={name}
        value={parsed}
        disabled={disabled}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          {...props}
          // Not overridable
          value={date}
          onChange={setDate}
          disabled={disabled}
          InputProps={{
            ...InputProps,
            endAdornment: (
              <Mui.InputAdornment>
                <Icons.Calendar />
              </Mui.InputAdornment>
            ),
          }}
          renderInput={(params) => <Mui.TextField {...params} />}
        />
      </LocalizationProvider>
    </>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  /**
   * The name to be used in the form
   */
  name: PropTypes.string,
  /**
   * The label to be displayed
   */
  label: PropTypes.string,
  /**
   * How should the calendar be displayed?
   */
  variant: PropTypes.oneOf(["dialog", "inline", "static"]),
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  name: "date",
  label: "Date",
  variant: "inline",
  fullWidth: true,
  disabled: false,
  required: true,
  autoOk: true,
  disablePast: true,
  InputProps: {},
};
