import client from "../../../client";
import mutation from "./rejectNotification.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 */
export const rejectNotification = async function (id) {
  id = addBearer(id);
  await client.mutate({
    mutation,
    variables: {
      id,
    },
  });
};

export default rejectNotification;
