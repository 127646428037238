import React from "react";
import * as Mui from "@mui/material";

import { format } from "date-fns";

import { getCurrentDate } from "../../collections/Messages/utils";
import { MessageFileContent } from "../../pseudoItems";

import clsx from "clsx";

import { stringAvatar } from "../../../utils/defaultAvatar";

import useStyles from "./FoundFile.styles";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const FoundFile = function ({
  message,
  searchedValue,
  setFoundReply,
  setIsResultsPanelOpen,
  setMessageToJumpTo,
  setRoom,
  user,
}) {
  const classes = useStyles();

  const username = message.owner.username;
  const own = username === "You";

  const dateTime = format(message.createdAt, "hh:mm a");
  const creationDate = getCurrentDate(message.createdAt);

  const handleClick = () => {
    setRoom(message.room);
    setIsResultsPanelOpen(false);
    if (!message.replyingTo?.id) {
      setMessageToJumpTo({
        beforeFetch: true,
        id: message.id,
        messageDate: message.createdAt,
      });
    } else {
      // setMessageToJumpTo({
      //   beforeFetch: true,
      //   id: message.replyingTo.id,
      //   messageDate: message.replyingTo.createdAt,
      // });
      setFoundReply({
        replyId: message.id,
        repliedMessageId: message.replyingTo?.id,
      });
    }
  };

  return (
    <Mui.Card variant="outlined" onClick={handleClick}>
      <Mui.CardActionArea>
        <Mui.CardContent>
          <Mui.Typography
            classes={{ body1: classes.containerInfo }}
            gutterBottom
          >
            In {message.room?.group ? "the room " : "your conversation with "}"
            {message.room?.title}" -{" "}
            <span className={classes.creationDate}>{creationDate}</span>
          </Mui.Typography>

          <Mui.ListItem className={clsx(classes.item, classes.messageInfo)}>
            <Mui.ListItemAvatar
              classes={{
                root: clsx(classes.avatar),
              }}
            >
              <Mui.Avatar
                src={message.owner.avatar}
                classes={{ root: classes.avatar }}
                {...stringAvatar(own ? user.username : username)}
              />
            </Mui.ListItemAvatar>

            <Mui.Box className={clsx(classes.ownTextContainer)}>
              <Mui.Box className={clsx(classes.messageHeader)}>
                <span className={classes.usernameStyle}>{username},</span>{" "}
                {dateTime}{" "}
              </Mui.Box>

              <Mui.Box className={clsx(classes.secondLevel)}>
                <Mui.Box
                  style={{ maxWidth: "100%" }}
                  className={classes.fileContent}
                >
                  <MessageFileContent
                    message={message}
                    searchedValue={searchedValue}
                  />
                </Mui.Box>
              </Mui.Box>
            </Mui.Box>
          </Mui.ListItem>
        </Mui.CardContent>
      </Mui.CardActionArea>
    </Mui.Card>
  );
};

export default FoundFile;
