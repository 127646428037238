import client from "../../../client";
import { addBearer } from "../../../utils";
import mutation from "./deleteWorkspace.mutation";

/**
 * @returns {Promise<{ username: string }>}
 */
export const deleteWorkspace = async function (id) {
  id = addBearer(id);
  const { data } = await client.mutate({
    mutation,
    variables: {
      input: {
        id,
      },
    },
  });
  return data.deleteWorkspace;
};

export default deleteWorkspace;
