import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  dateContainer: {
    position: "absolute",
    color: "black",
    fontSize: "13px",
    paddingTop: "0px",
    fontWeight: "400",
    fontFamily: "Inter, sans-serif",
    width: "28%",
    textAlign: "right",
    right: "20px",
    top: "10px",
    [theme.breakpoints.down("md")]: {
      width: "36%",
    },
  },
}));
