import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import useStyles from "./DateSelector.styles";
import { getUid } from "XmarsUtils";
import { getDateRange } from "../../../utils";

export const DateSelector = function ({
  InputProps,
  onSelectorOpen,
  setOnSelectorOpen,
  date,
  setDate,
  setFilters,
  id,
  ...props
}) {
  const classes = useStyles();
  const [valueInit, setValueInit] = useState(dayjs());
  const [formattedValueInit, setFormattedValueInit] = useState("");
  const [valueFinal, setValueFinal] = useState(dayjs());
  const [formattedValueFinal, setFormattedValueFinal] = useState("");

  const handleClose = () => setOnSelectorOpen(false);

  const handleSave = () => {
    const title = props.title;
    const formattedDateInit = dayjs(valueInit).format("MMM D, YYYY");
    const formattedDateFinal = dayjs(valueFinal).format("MMM D, YYYY");
    if (title !== "Range") {
      setDate(`${title} ${formattedDateInit}`);
    } else {
      setDate(`${formattedDateInit} - ${formattedDateFinal}`);
    }
    const { after, before } = getDateRange(
      id,
      formattedDateInit,
      formattedDateFinal
    );
    setFilters({ before, after });
    handleClose();
  };

  useEffect(() => {
    setFormattedValueInit(dayjs(valueInit).format("MM-DD-YYYY"));
    setFormattedValueFinal(dayjs(valueFinal).format("MM-DD-YYYY"));
  }, [valueInit, valueFinal]);

  return (
    <Mui.Modal open={onSelectorOpen} onClose={handleClose}>
      <Mui.Box className={classes.modalElements}>
        <Mui.Typography
          variant="h5"
          align="left"
          gutterBottom
          className={classes.title}
        >
          {`${props.title}...`}
        </Mui.Typography>

        <Mui.Box className={classes.dateSelectorContainer}>
          <Mui.Box>
            <Mui.TextField
              id={getUid()}
              {...props}
              type="text"
              InputProps={{
                className: classes.input,
                notched: false,
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  notchedOutline: classes.inputNotchedOutline,
                },
                startAdornment: <Icons.Calendar />,
                ...InputProps,
              }}
              value={formattedValueInit}
              onChange={(newValue) => {
                setFormattedValueInit(newValue);
              }}
              inputProps={{ className: classes.inputBase }}
            />
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              disableFuture
              value={valueInit}
              onChange={(newValue) => {
                setValueInit(newValue);
              }}
              renderInput={(params) => <Mui.TextField {...params} />}
            />
          </Mui.Box>
          {props.title === "Range" ? (
            <Mui.Box className={classes.dateSelector}>
              <Mui.TextField
                id={getUid()}
                {...props}
                type="text"
                InputProps={{
                  className: classes.input,
                  notched: false,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    notchedOutline: classes.inputNotchedOutline,
                  },
                  startAdornment: <Icons.Calendar />,
                  ...InputProps,
                }}
                value={formattedValueFinal}
                onChange={(newValue) => {
                  setFormattedValueFinal(newValue);
                }}
                inputProps={{ className: classes.inputBase }}
              />
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                disableFuture
                value={valueFinal}
                onChange={(newValue) => {
                  setValueFinal(newValue);
                }}
                renderInput={(params) => <Mui.TextField {...params} />}
              />
            </Mui.Box>
          ) : null}
        </Mui.Box>

        <Mui.Box className={classes.buttons}>
          <Mui.Button onClick={handleClose}>Cancel</Mui.Button>
          <Mui.Button onClick={handleSave}>Save</Mui.Button>
        </Mui.Box>
      </Mui.Box>
    </Mui.Modal>
  );
};

DateSelector.propTypes = {
  /**
   * The name to be used in the form
   */
  name: PropTypes.string,
  /**
   * The label to be displayed
   */
  label: PropTypes.string,
  /**
   * The placeholder to be displayed
   */
  placeholder: PropTypes.string,
  /**
   * Input variant
   */
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
};

DateSelector.defaultProps = {
  name: "date-selector",
  placeholder: "ex. 2023-08-25",
  variant: "outlined",
  size: "small",
  fullWidth: true,
  autoComplete: "off",
  InputProps: {},
};

export default DateSelector;
