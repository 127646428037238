import { useEffect, useState } from "react";

import useDependency from "./useDependency";

export const useAutoScroll = function (
  ref,
  { onTopScroll, onBottomScroll = () => {} } = {}
) {
  const [atBottom, setAtBottom] = useState(false);
  const [atTop, setAtTop] = useState(false);
  const [scrollToLast, onScrollToLast] = useDependency();
  const [lastHeight, setLastHeight] = useState(0);

  useEffect(() => {
    const element = ref.current;

    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line
  }, []);

  const getMeasurements = function () {
    const element = ref.current;

    return {
      scrollPosition: element?.scrollTop,
      trueHeight: element?.scrollHeight,
      visibleHeight: element?.offsetHeight,
    };
  };

  const getStatus = function () {
    const { scrollPosition, trueHeight, visibleHeight } = getMeasurements();
    return {
      isAtTop: scrollPosition === 0,
      //isAtBottom: Math.ceil(scrollPosition) >= trueHeight - visibleHeight,
      isAtBottom: Math.ceil(scrollPosition) >= trueHeight - visibleHeight,
    };
  };

  const scrollToBottom = function () {
    const element = ref.current;
    const { trueHeight } = getMeasurements();
    //element.scrollTop = Math.ceil(trueHeight - visibleHeight);
    element.scrollTop = trueHeight;
    //element.scrollTop = 0;
  };

  const scrollToTop = function () {
    const element = ref.current;
    element.scrollTop = 0;
  };

  const scrollToAlmostTop = function () {
    const element = ref.current;
    element.scrollTop = 2;
  };

  useEffect(() => {
    if (onScrollToLast) {
      const element = ref.current;
      const { trueHeight } = getMeasurements();
      element.scrollTop = trueHeight - lastHeight;
      setLastHeight(trueHeight);
    }
    // eslint-disable-next-line
  }, [onScrollToLast]);

  const autoScroll = function () {
    const { isAtTop } = getStatus();

    if (atBottom) return scrollToBottom();
    if (isAtTop) return scrollToLast();
  };

  const handleScroll = function () {
    const { trueHeight } = getMeasurements();
    setLastHeight(trueHeight);
    const { isAtTop, isAtBottom } = getStatus();
    setAtTop(isAtTop);
    setAtBottom(isAtBottom);

    if (isAtBottom && onBottomScroll) onBottomScroll();
    else if (isAtTop && onTopScroll) onTopScroll();
  };

  return {
    atTop,
    atBottom,
    scrollToTop,
    scrollToBottom,
    autoScroll,
    scrollToAlmostTop,
  };
};

export default useAutoScroll;
