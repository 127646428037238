import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import FoundGroup from "./FoundGroup.component";

import { setIsResultsPanelOpen, setRoom } from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { searchedValue } = state.search;
  return {
    searchedValue,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setIsResultsPanelOpen, setRoom }, dispatch);

const FoundGroupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FoundGroup);

export default FoundGroupContainer;
