import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import NotificationsButton from "./NotificationsButton.component";

import {
  rejectNotification,
  acceptWorkspaceInvitation,
} from "../../../store/actions/index";
import { selectActiveWorkspace } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const { user, currentWorkspace } = state.chat;
  return {
    currentWorkspace,
    user,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      rejectNotification,
      acceptWorkspaceInvitation,
    },
    dispatch
  );

const NotificationsButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsButton);

export default NotificationsButtonContainer;
