import client from "../../../client";
import query from "./getWorkspaces.query";

/**
 * @returns {Promise<{
 *  id: string,
 *  title: string,
 *  description: string
 * }>}
 */
export const getWorkspaces = async function () {
  const { data } = await client.query({ query });
  return data.getWorkspaces;
};

export default getWorkspaces;
