import client from "../../../client";
import query from "./getFriendRoom.query";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 * @returns {Promise<{
 *  id: string,
 *  title: string,
 *  group: boolean,
 *  meetingCode: string,
 * }>}
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getFriendRoom = async function (id) {
  id = addBearer(id);
  const { data } = await client.query({
    query,
    variables: {
      id,
    },
  });
  return data.getFriendRoom;
};

export default getFriendRoom;
