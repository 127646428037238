import { gql } from "../../../utils/apollo";

export const deleteUser = gql`
  mutation DeleteUser {
    deleteUser {
      username
    }
  }
`;

export default deleteUser;
