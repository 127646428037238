import env from "../env";
import JitsiMeetJS from "XmarsJitsiMeetJS/index";
import { mainLog, groupLog } from "XmarsJitsiMeetJS/loggers";

/**
 * @param {string} meetingCode
 */
export const getJitsiConnection = function (meetingCode) {
  const connectionOptions = {
    // connection
    hosts: {
      domain: "meet.jitsi",
      muc: "muc.meet.jitsi",
      focus: "focus.meet.jitsi",
    },
    serviceUrl: `wss://${env.JITSI_URI}/xmpp-websocket?channel=${meetingCode}`,
    clientNode: "http://jitsi.org/jitsimeet",
    // video
    resolution: 1080,
    constraints: {
      video: {
        aspectRatio: 16 / 9,
        height: {
          ideal: 1080,
          max: 1080,
          min: 240,
        },
      },
    },
    // recording
    fileRecordingsEnabled: true,
    fileRecordingsServiceEnabled: false,
    fileRecordingsServiceSharingEnabled: false,
  };
  mainLog("Creating Jitsi Connection...");
  const jitsiConnection = new JitsiMeetJS.JitsiConnection(
    null,
    null,
    connectionOptions
  );
  groupLog("Jitsi Connection created", () => {
    console.log(jitsiConnection);
  });
  return jitsiConnection;
};

export default getJitsiConnection;
