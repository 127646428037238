import client from "../../../client";
import mutation from "./logout.mutation";

/**
 * @returns {Promise<{ username: string }>}
 */
export const logout = async function () {
  const { data } = await client.mutate({ mutation });
  return data.logout;
};

export default logout;
