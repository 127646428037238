import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ChangeUsernameForm from "./ChangeUsernameForm.component";

import { changeUsername } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeUsername,
    },
    dispatch
  );

const ChangeUsernameFormContainer = connect(
  null,
  mapDispatchToProps
)(ChangeUsernameForm);

export default ChangeUsernameFormContainer;
