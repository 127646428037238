import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LandingPage from "./LandingPage.component";
import { actions } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      parseLink: actions.sagas.parseLink,
    },
    dispatch
  );

const LandingPageContainer = connect(null, mapDispatchToProps)(LandingPage);

export default LandingPageContainer;
