import { connect } from "react-redux";

import AddUserToRooms from "./AddUserToRooms.component";
import { selectActiveChannel } from "../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const { user, rooms } = state.chat;

  let groupRooms = rooms.filter((room) => room.group);
  groupRooms = groupRooms.filter((room) => room.admin);

  return {
    user,
    rooms: groupRooms,
    currentRoom: room,
  };
};

const AddUserToRoomsContainer = connect(mapStateToProps)(AddUserToRooms);

export default AddUserToRoomsContainer;
