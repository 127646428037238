export function createVideoTrackEventHandlers(videoComponent, track) {
  return {
    onTrackMuteChanged() {
      if (track.isMuted()) {
        track.detach(videoComponent);
      } else {
        track.attach(videoComponent);
      }
    },
  };
}
