import { put, race, take, delay, call } from "redux-saga/effects";

import * as actions from "XmarsStore/actions";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";

export function* waitForAnswer() {
  try {
    const { ghosted } = yield race({
      callAnwered: take(actions.addRemoteParticipant),
      ghosted: delay(15000, true),
    });
    if (ghosted) {
      yield put(
        actions.addSnackbar({
          severity: "info",
          message: "Unanswered call",
        })
      );
      yield put(actions.jitsiCallHang());
    } else {
      yield put(actions.updateCallStatus({ successful: true, dialing: false }));
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}
