import { put, take, fork, cancel, call } from "redux-saga/effects";
import * as actions from "XmarsStore/actions";

import { initLocalAudioTrack } from "./audio";
import { listenRegisterVideoComponent } from "./video/registerVideoComponent";
import { videoTrackControl } from "./video/videoTrackControl";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils";

export function* initLocalTrack(track) {
  try {
    if (track.getType() === "video" && track.videoType !== "desktop") {
      yield fork(videoTrackControl, track);
      const listenRegisterVideoComponentTask = yield fork(
        listenRegisterVideoComponent,
        track
      );
      yield put(actions.setLocalVideoIsMuted(track.isMuted()));
      yield put(actions.setLocalVideoTrackReady(true));
      yield take(actions.endLocalVideoTrack);
      yield cancel([listenRegisterVideoComponentTask]);
      track.dispose();
    }
    if (track.getType() === "audio") {
      yield fork(initLocalAudioTrack, track);
      yield put(actions.setLocalAudioIsMuted(track.isMuted()));
      yield put(actions.setLocalAudioTrackReady(true));
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}
