import { connect } from "react-redux";

import AddUsersToRoom from "./AddUsersToRoom.component";
import {
  selectActiveWorkspaceMembers,
  selectActiveChannel,
} from "../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const friends = selectActiveWorkspaceMembers(state);
  let orderedFriends = [...friends];
  orderedFriends.sort((contactA, contactB) => {
    if (contactA.username.toLowerCase() < contactB.username.toLowerCase()) {
      return -1;
    }
    if (contactA.username.toLowerCase() > contactB.username.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return {
    rooms: orderedFriends,
    currentRoom: room,
  };
};

const AddUsersToRoomContainer = connect(mapStateToProps)(AddUsersToRoom);

export default AddUsersToRoomContainer;
