import snackbarsInitialState from "./initialState";

export const resetSnackbars = (state) => {
  return snackbarsInitialState;
};

export const setSnackbars = (state, { payload }) => {
  return payload;
};

export const addSnackbar = (state, { payload }) => {
  return [...state, payload];
};

export const removeSnackbar = (state, { payload }) => {
  return state.filter(
    (item) =>
      item.severity !== payload.severity && item.message !== payload.message
  );
};
