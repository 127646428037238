import { gql } from "../../../utils/apollo";

export const startedTyping = gql`
  mutation StartedTyping($id: ID, $isWriting: Boolean) {
    startedTyping(input: { id: $id, isWriting: $isWriting }) {
      null
    }
  }
`;

export default startedTyping;
