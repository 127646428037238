import client from "../../../client";
import mutation from "./removeMemberWorkspace.mutation";

/**
 * @param {string} username
 * @param {string} workspaceId
 * @returns {Promise<{
 *  id: string,
 *  title: string,
 *  description: string
 * }>}
 */

export const removeMember = async function (username, workspaceId) {
  const { data } = await client.mutate({
    mutation,
    variables: {
      input: {
        username,
        workspaceId,
      },
    },
  });
  return data.removeMember;
};

export default removeMember;
