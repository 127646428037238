import { makeStyles } from "@mui/styles";

import classes from "../../style/classes";

export default makeStyles(() => ({
  image: classes.avatarThumbnail,
  selectedImage: {
    ...classes.avatarThumbnail,
    maxHeight: "200px",
    maxWidth: "200px",
  },
  blur: {
    width: "75px",
    height: "75px",
  },
  selectedBlur: {
    width: "100px",
    height: "100px",
  },
  current: {
    marginLeft: "1em",
  },
}));
