import { gql } from "../../../utils/apollo";

export const guestLogin = gql`
  mutation GuestLogin($id: ID, $email: String) {
    guestLogin(input: { id: $id, email: $email }) {
      null
    }
  }
`;

export default guestLogin;
