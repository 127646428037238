import React, { useMemo } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import useStyles from "./FabSubmitButton.styles";

export const FabSubmitButton = function ({
  disabled,
  loading,
  size,
  ...props
}) {
  const classes = useStyles();

  const progressSize = useMemo(() => {
    switch (size) {
      case "small":
        return 44;
      case "medium":
        return 56;
      case "large":
        return 68;
      default:
        return 0;
    }
  }, [size]);

  const offset = useMemo(() => {
    switch (size) {
      case "small":
        return -2;
      case "medium":
        return -4;
      case "large":
        return -6;
      default:
        return 0;
    }
  }, [size]);

  return (
    <div className={classes.wrapper}>
      <Mui.Fab
        {...props}
        size={size}
        type="submit"
        disabled={disabled || loading}
      />
      {!loading ? null : (
        <Mui.CircularProgress
          style={{
            top: offset,
            left: offset,
          }}
          size={progressSize}
          className={classes.progress}
        />
      )}
    </div>
  );
};

export default FabSubmitButton;

FabSubmitButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(["large", "medium", "small"]),
};

FabSubmitButton.defaultProps = {
  disabled: false,
  loading: false,
  size: "large",
  color: "primary",
  children: <Icons.Check />,
};
