import { forwardRef, useState, useEffect, useRef } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import { Scrollbars } from "react-custom-scrollbars";
import clsx from "clsx";

import { TooltipIconButton } from "../../prestyledComponents";
import { EmojisButton } from "../../pseudoInputs";

import useStyles from "./CreateCustomRoomButton.styles";
import { useMounted } from "../../../hooks/useMounted";
import { stringAvatar } from "../../../utils/defaultAvatar/defaultAvatar";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";
import InviteToRoom from "../../InviteToRoom";
//import CircularProgressIcon from "../../prestyledComponents/CircularProgressIcon";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const CreateCustomRoomButton = forwardRef(function (
  { friends, room, createCustomRoom, addUsersToRoom, ...props },
  ref
) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState([]);
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [usersToInvite, setUsersToInvite] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [focusTitle, setFocusTitle] = useState(false);

  const titleRef = useRef(null);

  const classes = useStyles();
  const mounted = useMounted();

  useEffect(() => {
    if (usersToInvite.length) {
      getBoundActionPromise(addUsersToRoom, {
        room,
        users: usersToInvite,
      });
    }
    // eslint-disable-next-line
  }, [usersToInvite]);

  useEffect(() => {
    if (open) {
      setValue("");
      setChecked([]);
      setUsersToAdd([]);
      setUsersToInvite([]);
      setFocusTitle(true);
    } else {
      setFocusTitle(false);
    }
  }, [open]);

  useEffect(() => {
    if (focusTitle && titleRef.current) titleRef.current.focus();
  }, [focusTitle]);

  const handleToggle = (id) => () => {
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    const checkedFriends =
      friends?.filter(({ id }) => newChecked.includes(id)) || [];
    setUsersToAdd(checkedFriends);
  };

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = formSubmitHandler(async ({ title }, event) => {
    try {
      setProcessing(true);
      await getBoundActionPromise(createCustomRoom, { title });

      setUsersToInvite(usersToAdd);

      if (handleClose) handleClose(event);
    } catch (error) {
      logError(error);
    } finally {
      if (mounted.current) {
        setProcessing(false);
      }
    }
  });

  const handleTitleChange = function (event) {
    setValue(event.target.value);
  };

  const handleEmoji = function (emoji) {
    setValue(value + emoji);
  };

  return (
    <>
      <TooltipIconButton
        {...props}
        ref={ref}
        title="new group"
        className={classes.iconButton}
        onClick={handleClick}
      >
        <Icons.Users />
      </TooltipIconButton>
      {open && (
        <Mui.Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          PaperProps={{ elevation: 0 }}
          classes={{
            root: classes.dialogRoot,
            paper: classes.dialog,
            container: classes.dialogContainer,
          }}
        >
          <Mui.DialogTitle
            disableTypography
            id="form-dialog-title"
            className={classes.dialogHeader}
          >
            <Icons.Users style={{ height: "18px", width: "18px" }} />
            <Mui.Typography variant="h6" className={classes.headerText}>
              New Group
            </Mui.Typography>
            <Mui.IconButton
              className={classes.closeButton}
              onClick={handleClose}
            >
              <Icons.X style={{ strokeWidth: "2.5px" }} />
            </Mui.IconButton>
          </Mui.DialogTitle>

          <Mui.DialogContent className={classes.dialogContent}>
            <form onSubmit={handleSubmit} id="create-room-form">
              <Mui.Typography
                className={classes.label}
                variant="body1"
                component="label"
                htmlFor="group-name"
              >
                Group Name
              </Mui.Typography>
              <Mui.TextField
                id="group-name"
                name="title"
                placeholder="New Group Name"
                variant="outlined"
                value={value}
                onChange={handleTitleChange}
                size="small"
                fullWidth={true}
                autoComplete="off"
                style={{ marginBottom: "0rem" }}
                InputProps={{
                  className: classes.input,
                  notched: false,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    notchedOutline: classes.inputNotchedOutline,
                  },
                  style: {
                    paddingRight: 0,
                  },
                  endAdornment: (
                    <EmojisButton
                      disabled={processing}
                      className={classes.emojiButton}
                      onEmoji={handleEmoji}
                      TooltipProps={{ placement: "top" }}
                    />
                  ),
                }}
                inputProps={{
                  className: classes.inputBase,
                  maxLength: 30,
                  ref: titleRef,
                }}
              />
              <Scrollbars
                autoHide={usersToAdd.length < 10}
                autoHeight
                autoHeightMin={50}
                autoHeightMax={450}
              >
                <Mui.Grid
                  container
                  spacing={1}
                  className={clsx(
                    classes.avatars,
                    !usersToAdd.length ? classes.avatarsEmpty : null
                  )}
                  wrap="nowrap"
                >
                  {!usersToAdd.length ? (
                    <Mui.Grid item>
                      <Mui.Avatar
                        alt="default icon"
                        className={clsx(
                          classes.avatarUnit,
                          classes.avatarListSize
                        )}
                        classes={{ colorDefault: classes.defaultAvatarColors }}
                      >
                        <Icons.User />
                      </Mui.Avatar>
                    </Mui.Grid>
                  ) : (
                    usersToAdd.map((user) => {
                      const toggleHandler = handleToggle(user.id);
                      return (
                        <Mui.Grid
                          item
                          key={user.id}
                          component={Mui.Tooltip}
                          title={user.username}
                          placement="top"
                        >
                          <Mui.Badge
                            badgeContent={<Icons.X />}
                            overlap="circle"
                            onClick={toggleHandler}
                            style={{ cursor: "pointer" }}
                            classes={{
                              badge: classes.avatarBadge,
                            }}
                          >
                            <Mui.Avatar
                              src={user.avatar}
                              alt={user.username}
                              {...stringAvatar(user.username)}
                              className={clsx(
                                classes.avatarUnit,
                                classes.avatarListSize
                              )}
                            ></Mui.Avatar>
                          </Mui.Badge>
                        </Mui.Grid>
                      );
                    })
                  )}
                </Mui.Grid>
              </Scrollbars>
              <InviteToRoom checked={checked} handleToggle={handleToggle} />
            </form>
          </Mui.DialogContent>

          <Mui.DialogActions className={classes.dialogFooter}>
            <Mui.Button
              type="submit"
              form="create-room-form"
              className={classes.actionButton}
              disabled={processing}
            >
              Create Group
            </Mui.Button>
          </Mui.DialogActions>
        </Mui.Dialog>
      )}
    </>
  );
});

export default CreateCustomRoomButton;
