import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import DeleteAccountForm from "./DeleteAccountForm.component";

import { deleteUser } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteUser,
    },
    dispatch
  );

const DeleteAccountFormContainer = connect(
  null,
  mapDispatchToProps
)(DeleteAccountForm);

export default DeleteAccountFormContainer;
