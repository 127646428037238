import createElement from "../createElement";

const isSupported = typeof OffscreenCanvas !== "undefined";

/**
 * @param {number} width
 * @param {number} height
 */
export const getOffscreenCanvas = (width, height) => {
  if (isSupported) return new OffscreenCanvas(width, height);
  return createElement("canvas", { width, height });
};

export default getOffscreenCanvas;
