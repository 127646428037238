import client from "../../../client";
import mutation from "./editReply.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} replyId: Id of the reply that is being modified
 * @param {string} text: New text of the reply
 * @param {string} threadingMessageId: Message that was replied
 */
export const editReply = async function (replyId, text, threadingMessageId) {
  replyId = addBearer(replyId);
  threadingMessageId = addBearer(threadingMessageId);
  await client.mutate({
    mutation,
    variables: {
      replyId,
      text,
      threadingMessageId,
    },
  });
};

export default editReply;
