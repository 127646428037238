import { gql } from "../../../utils/apollo";

export const deleteUser = gql`
  mutation DeleteWorkspace($input: deleteWorkspaceInput!) {
    deleteWorkspace(input: $input) {
      null
    }
  }
`;

export default deleteUser;
