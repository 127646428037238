import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

export const RejectCallButton = function ({
  setIncomingCall,
  setPage,
  ...props
}) {
  const handleClick = () => {
    setIncomingCall(null);
    setPage("home");
  };

  return (
    <Mui.IconButton {...props} onClick={handleClick} name={"RejectCall"}>
      <Icons.PhoneOff />
    </Mui.IconButton>
  );
};

export default RejectCallButton;

RejectCallButton.propTypes = {
  setIncomingCall: PropTypes.func.isRequired,
};
