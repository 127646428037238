import { ACTIVE } from "../../types/status";

const initialState = {
  user: {
    username: Symbol.for("loading"),
    avatar: null,
    role: "guest",
    subscription: null,
  },
  /**
   * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
   * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
   * still useful. If this function is still relevant, rename its identifiers using the
   * proper entities and remove this deprecation warning
   */
  room: null, // Actual room for messaging
  currentWorkspace: null, //Actual workspace
  /**
   * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
   * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
   * still useful. If this function is still relevant, rename its identifiers using the
   * proper entities and remove this deprecation warning
   */
  roomFilter: "",
  friends: [], // All with personal subscription, and maybe room
  /**
   * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
   * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
   * still useful. If this function is still relevant, rename its identifiers using the
   * proper entities and remove this deprecation warning
   */
  rooms: [], // All with room subscription
  workspaces: [],
  categories: [],
  isLoadingMessages: false,
  currentMessage: null,
  status: ACTIVE,
  isEditing: false,
  isEditingReply: false,
  editingReply: null,
  editingMessage: null,
  draft: "",
  messageToJumpTo: null,
  searchedUsers: [],
};

export default initialState;
