import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  inputBase: {
    color: theme.palette.textGray.input,
    padding: "0.375rem 0.75rem",
    height: "auto",

    "&::placeholder": {
      color: `${theme.palette.textGray.inputPlaceholder} !important`,
      opacity: 1,
    },
  },
  input: {
    fontSize: "14px",
    lineHeight: 1.5,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.borderGray.input}`,
    boxSizing: "border-box",
    backgroundClip: "padding-box",
  },
  inputRoot: {
    "&$focused": {},
    "&$notchedOutline": {},
  },
  inputFocused: {
    backgroundColor: theme.palette.bgGray.sidebar,
  },
  inputNotchedOutline: {
    display: "none",
  },
}));
