import { useCallback, useEffect, useMemo, useState } from "react";

export const useOnScreen = function (ref) {
  const [onScreen, setOnScreen] = useState(false);

  const intersectionCallback = useCallback((entries) => {
    const [entry] = entries;
    setOnScreen(entry.isIntersecting);
  }, []);

  const observer = useMemo(
    () => new IntersectionObserver(intersectionCallback, { threshold: 0.85 }),
    [intersectionCallback]
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      return () => observer.disconnect();
    } else {
      return () => {};
    }
    // eslint-disable-next-line
  }, [observer]);

  return onScreen;
};

export default useOnScreen;
