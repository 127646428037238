import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Notification from "./Notification.component";

import {
  rejectNotification,
  acceptFriendRequest,
  acceptRoomInvitation,
} from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      rejectNotification,
      acceptFriendRequest,
      acceptRoomInvitation,
    },
    dispatch
  );

const NotificationContainer = connect(null, mapDispatchToProps)(Notification);

export default NotificationContainer;
