import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import MessageFormContent from "./MessageFormContent.component";

import { answerBotMessage } from "../../../store/actions/index";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  return { room };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      answerBotMessage,
    },
    dispatch
  );

const MessageFormContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageFormContent);

export default MessageFormContentContainer;
