// import { registerWorkpace, unregisterCurrentWorkspace } from "../../sagas/chat/utils";

import { ContentPasteGoOutlined } from "@mui/icons-material";

const channelSortByUpdate = (channelA, channelB) => {
  return new Date(channelB.lastUpdate) - new Date(channelA.lastUpdate);
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
const friendSortByUpdate = (friendA, friendB) => {
  if (friendA.room && friendB.room) {
    return (
      new Date(friendB.room.lastUpdate) - new Date(friendA.room.lastUpdate)
    );
  } else {
    return parseInt(friendB.id, 16) - parseInt(friendA.id, 16);
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const addLocalDraft = function (state, newDraft) {
  const { rooms } = state;
  const updatedRooms = rooms.map((room) => {
    if (room.id === newDraft.room) {
      if (newDraft.text) {
        room.draft = newDraft;
      } else {
        room.draft = null;
      }
    }
    return room;
  });
  state.rooms = updatedRooms;
};

export const setUser = function (state, { payload }) {
  state.user = payload;
};

export const setAvatar = function (state, { payload }) {
  state.user.avatar = payload;
};

export const setStatus = function (state, { payload }) {
  state.user.status = payload;
};

export const setUsername = function (state, { payload }) {
  state.user.username = payload;
};

export const setWorkspaces = function (state, { payload }) {
  state.workspaces = payload;
};

export const setFriends = function (state, { payload }) {
  state.friends = payload;
};

export const setFriendProperties = function (state, { payload }) {
  const { friend, properties } = payload;
  const index = state.friends.findIndex((element) => element.id === friend.id);
  if (-1 < index) {
    state.friends[index] = { ...state.friends[index], ...properties };
  }
};

export const updateFriends = function (state) {
  const friends = [...state.friends].sort(friendSortByUpdate);
  state.friends = friends;
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const updateRoomUsers = function (state, { payload }) {
  const { roomId, users } = payload;
  const index = state.rooms.findIndex((element) => element.id === roomId);
  if (-1 < index) {
    state.rooms[index].users = users;
  }
};

export const updateChannelTitle = function (state, { payload }) {
  const { channelId, newTitle } = payload;
  const len = state.categories.length;
  for (let i=0;i<len; i++){
      let index= state.categories[i].rooms.findIndex((element) => element.id === channelId);
      if (index > -1) {
        state.categories[i].rooms[index].title = newTitle;
        break;
      }
  }
};

export const addFriend = function (state, { payload }) {
  state.friends = [payload, ...state.friends].sort(friendSortByUpdate);
};

export const removeFriend = function (state, { payload }) {
  state.friends = state.friends.filter((friend) => friend.id !== payload.id);
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const setRoom = function (state, { payload }) {
  if (payload) {
    const room = state.categories
      .reduce(
        (accumulator, category) => [...accumulator, ...category.rooms],
        []
      )
      .find((sRoom) => sRoom.id === payload.id);
    if (room) {
      state.room = payload;
    }
  } else {
    state.room = null;
  }
};

export const setWorkspace = function (state, { payload }) {
  if (payload) {
    const workspace = state.workspaces.find((ws) => ws.id === payload.id);
    if (workspace) {
      state.currentWorkspace = payload;
    }
  } else {
    state.currentWorkspace = null;
  }
};

export const updateCategoryParameters = function (state, { payload }) {
  const {newTitle, categoryId} = payload;
  let categories=state.categories;
  const index = categories.map((category) => category.id).indexOf(categoryId);
  if (index >= 0) {
    categories[index].title=newTitle;    
    state.categories = categories;
  }
};

export const setCategories = function (state, { payload }) {
  if (payload) {
    const categories = payload;
    state.categories = categories;
  } else {
    state.categories = null;
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const hideRoom = function (state, roomId) {
  const { rooms } = state;
  const updatedRooms = rooms.map((cRoom) => {
    if (cRoom.id === roomId) cRoom.hidden = true;
    return cRoom;
  });
  state.rooms = updatedRooms;
};

export const setIsLoadingMessages = function (state, { payload }) {
  state.isLoadingMessages = payload;
};

export const setDraft = function (state, { payload }) {
  state.draft = payload;
};

export const setIsEditingMessage = function (state, { payload }) {
  state.isEditing = payload;
};

export const setIsEditingReply = function (state, { payload }) {
  state.isEditingReply = payload;
};

export const setEditingMessage = function (state, { payload }) {
  state.editingMessage = payload;
};

export const setEditingReply = function (state, { payload }) {
  state.editingReply = payload;
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const setFirstRoom = function (state) {
  if (0 < state.rooms.length) {
    const room = state.rooms[0];
    state.room = room;
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const updateRooms = function (state) {
  // const rooms = state.rooms.sort(roomSortByUpdate);
  // state.rooms = rooms;
  const room = state.room;
  state.room = room;
};

export const setCurrentMessage = function (state, { payload }) {
  state.currentMessage = payload;
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const searchRoom = function (state, { payload }) {
  state.roomFilter = payload;
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const addChannel = function (state, { payload }) {
  const channel = payload;
  const index = state.categories.findIndex((element) => element.id === channel.category.id);
  if ( index > -1) {
    state.categories[index].rooms = [...state.categories[index].rooms, payload];
  }
};

export const addWorkspace = function (state, { payload }) {
  const index = state.workspaces.map((ws) => ws.id).indexOf(payload.id);
  if (index === -1) {
    const workspaces = [payload, ...state.workspaces];
    state.workspaces = workspaces;
  } else {
    const updatedWorkspaces = state.workspaces.map((ws) => {
      if (ws.id === payload.id) return payload;
      else return ws;
    });
    state.workspaces = updatedWorkspaces;
  }
};

export const updateWorkspaceParameters = function (state, { payload }) {
  const {newTitle, newDescription, newAvatar, workspaceId} = payload;
  const index = state.workspaces.map((ws) => ws.id).indexOf(workspaceId);
  if (index >= 0) {
    let workspace=state.workspaces[index];    
    workspace.title=newTitle;
    workspace.description=newDescription;
    workspace.avatar=newAvatar;
    state.workspaces[index]=workspace;
    let currentWorkspace = state.currentWorkspace;
    currentWorkspace.title=newTitle;
    currentWorkspace.description=newDescription;
    currentWorkspace.avatar=newAvatar;
    state.currentWorkspace=currentWorkspace;
  }
};

export const updateWorkspaces = function (state, { payload }) {
  const workspaces = payload;
  state.workspaces = workspaces;
};

export const addCategory = function (state, { payload }) {
  const categories = [payload, ...state.categories];
  state.categories = categories;
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const setRoomProperties = function (state, { payload }) {
  const { room, properties } = payload;
  if (state.room.id === room.id) {
    const room = { ...state.room, ...properties };
    state.room = room;
  }
};

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const removeChannel = function (state, { payload }) {
  const channelId =payload;
  const len = state.categories.length;
  for (let i=0;i<len; i++){
      let index= state.categories[i].rooms.findIndex((element) => element.id === channelId);
      if (index > -1) {
          state.categories[i].rooms = state.categories[i].rooms.filter(
          (room) => room.id !== channelId
        );
        break;
      }
  }
};

export const removeWorkspace = function (state, { payload }) {
  const workspaces = state.workspaces.filter(
    (workspace) => workspace.id !== payload
  );
  state.workspaces = workspaces;
};

export const changeStatus = function (state, { payload }) {
    state.user.status = payload;
};

export const setMessageToJumpTo = function (state, { payload }) {
  state.messageToJumpTo = payload;
};
