const getValue = function (element) {
  if (!element) return;
  switch (element.type) {
    case "file":
      const { files } = element;
      if (element.multiple) return files;
      return files[0];
    case "checkbox":
      return element.checked;
    default:
      return element.value;
  }
};

/**
 * @callback callback
 * @param {Object} values
 * @param {Event} event
 * @returns {void}
 */

/**
 * @param {callback} callback
 */
export const formSubmitHandler = function (callback) {
  /**
   * @param {Event} event
   * @returns {void}
   */
  return function (event) {
    event.preventDefault();
    event.persist();
    const form = event.target;
    const elements = form.elements;
    const values = {};
    for (const name of Object.getOwnPropertyNames(elements)) {
      const element = elements[name];
      values[name] = getValue(element);
    }
    callback(values, event);
  };
};

export default formSubmitHandler;
