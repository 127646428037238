import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import * as reducers from "./reducers";

const slice = createSlice({
  name: "search",
  initialState,
  reducers,
});

const { reducer, actions } = slice;

export default reducer;
export const {
  resetSearchState,
  setFoundMessages,
  setFoundGroups,
  setFoundContacts,
  setFoundFiles,
  setTotalResults,
  setSearchedValue,
  setAreResultsLoaded,
  setDefaultResults,
  setIsResultsPanelOpen,
  setSearchPanelOpen,
  setSearchWithFilters,
  setOnSelectorOpen,
  setDate,
  setFilters,
  resetFilters,
} = actions;
