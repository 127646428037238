import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  scrollTrack: {
    right: "2px",
    top: "2px",
    bottom: "2px",
  },
  scrollThumb: {
    backgroundColor: "rgba(66, 66, 66, 0.2)",
  },
}));
