/**
 * Calculates angle of a right-angle triangle in radians
 * @param {number} adjacent
 * @param {number} opposite
 * @returns {number}
 */
export const calcAngle = function (adjacent, opposite) {
  return (Math.atan2(opposite, adjacent) * 180) / Math.PI;
};

export default calcAngle;
