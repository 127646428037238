import getUid from "../getUid";

/**
 * @param {Worker} worker
 */
export const asyncPostMessage = function (worker, payload) {
  return new Promise((resolve, reject) => {
    const id = getUid();
    const handleMessage = function (event) {
      const { id: dataId, error, response } = event.data;
      if (dataId === id) {
        if (error) {
          reject(error);
        } else {
          resolve(response);
        }
        worker.removeEventListener("message", handleMessage);
      }
    };
    worker.addEventListener("message", handleMessage);
    worker.postMessage({ id, payload });
  });
};

export default asyncPostMessage;
