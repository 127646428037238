import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { EmailTextField } from "../../inputs";
import { SearchSubmitButton } from "../../prestyledComponents";

import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";

export const GetAppUserForm = function ({ getUserByEmail }) {
  const handleSubmit = formSubmitHandler(({ email }) => {
    getUserByEmail(email);
  });

  return (
    <form onSubmit={handleSubmit}>
      <EmailTextField
        InputProps={{
          endAdornment: (
            <Mui.InputAdornment>
              <SearchSubmitButton />
            </Mui.InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default GetAppUserForm;

GetAppUserForm.propTypes = {
  getUserByEmail: PropTypes.func.isRequired,
};
