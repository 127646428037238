import Box from "@mui/material/Box";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";

import { LocalAudio, LocalVideo } from "../../pseudoItems";

// eslint-disable-next-line
import { Participant } from "../../../typeDefs";

/**
 * @param {Object} props
 * @param {Participant} props.participant
 */

export const LocalParticipant = function ({ participant, sharingScreen }) {
  return !participant ? (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Mui.Icon style={{ transform: "scale(1.8)" }}>
        <Icons.Smile />
      </Mui.Icon>
    </Box>
  ) : (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
    >
      <Box flexGrow={1}>
        {!participant.sharingScreen ? (
          <LocalVideo track={participant.video} />
        ) : (
          <LocalVideo track={participant.localVideo} />
        )}
      </Box>
      <Box>
        <LocalAudio />
      </Box>
    </Box>
  );
};

export default LocalParticipant;
