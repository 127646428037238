import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Files from "./Files.component";

import {
  sendFiles,
  setFiles,
  deleteFile,
  setSendingFiles,
} from "../../../store/actions/index";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const { files, sendingFiles } = state.general;

  return { room, files, sendingFiles };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendFiles,
      setFiles,
      deleteFile,
      setSendingFiles,
    },
    dispatch
  );

const FilesContainer = connect(mapStateToProps, mapDispatchToProps)(Files);

export default FilesContainer;
