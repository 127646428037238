import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ChangeAvatarForm from "./ChangeAvatarForm.component";

import { changeAvatar, removeAvatar } from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { user } = state.chat;
  return { user };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeAvatar,
      removeAvatar,
    },
    dispatch
  );

const ChangeAvatarFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeAvatarForm);

export default ChangeAvatarFormContainer;
