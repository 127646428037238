import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import { lightFormat } from "date-fns";
import PropTypes from "prop-types";

import {
  CircularProgressIcon,
  TooltipIconButton,
} from "../../prestyledComponents";
import { useMounted } from "../../../hooks/useMounted";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

import useStyles from "./RecordingButton.styles";

// eslint-disable-next-line
import { Props } from "./RecordingButton.propTypes";

const timezone_diff = new Date(0, 0, 0, 0, 0, 0, 0).getTime();

/**
 * @param {Props} props
 */
export const RecordingButton = function ({
  disabled,
  conference,
  startRecording,
  stopRecording,
  ...props
}) {
  const [recording, setRecording] = useState(false);
  const [loading, setLoading] = useState(false);

  const [timer, setTimer] = useState(null);

  const [start, setStart] = useState(new Date());
  const [time, setTime] = useState(new Date(0));

  const mounted = useMounted();

  const classes = useStyles();

  useEffect(
    () => () => {
      if (timer) clearTimeout(timer);
      // eslint-disable-next-line
    },
    []
  );

  useEffect(() => {
    if (recording) {
      const newTimer = setTimeout(() => {
        const newTime = new Date() - start;
        setTime(new Date(newTime));
      }, 1000);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
    }
    // eslint-disable-next-line
  }, [time, recording]);

  const handleClick = async function () {
    try {
      setLoading(true);
      if (!recording) {
        await getBoundActionPromise(startRecording, { conference });
        if (mounted.current) {
          setTime(new Date(0));
          setStart(new Date());
          setRecording(true);
        }
      } else {
        await getBoundActionPromise(stopRecording, { conference });
        if (mounted.current) {
          setRecording(false);
        }
      }
    } catch (error) {
      logError(error);
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  };

  const formatTime = function (date) {
    const newDate = new Date(date.getTime() + timezone_diff);
    return lightFormat(newDate, "H:mm:ss");
  };

  const renderBadgeContent = function () {
    if (loading)
      return (
        <CircularProgressIcon color={recording ? "secondary" : "primary"} />
      );
    if (recording) return formatTime(time);
    return null;
  };

  const renderTooltipTitle = function () {
    if (loading) return recording ? "Stopping..." : "Starting...";
    return recording ? "Stop recording" : "Start recording";
  };

  return (
    <Mui.Badge
      overlap={loading ? "circle" : "rectangle"}
      badgeContent={renderBadgeContent()}
    >
      <TooltipIconButton
        {...props}
        TooltipProps={{ placement: "top" }}
        title={renderTooltipTitle()}
        disabled={disabled || loading}
        // color={recording ? "secondary" : undefined}
        className={recording ? classes.recording : ""}
        onClick={handleClick}
      >
        <Icons.Circle />
      </TooltipIconButton>
    </Mui.Badge>
  );
};

export default RecordingButton;

RecordingButton.propTypes = {
  conference: PropTypes.object.isRequired,
  startRecording: PropTypes.func.isRequired,
  stopRecording: PropTypes.func.isRequired,
};
