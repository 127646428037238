import { gql } from "../../../utils/apollo";

export const getWorkspaces = gql`
  query GetWorkspaces {
    getWorkspaces {
      createdAt
      description
      avatar
      id
      title
      owner {
        id
        username
      }
      admin
      users {
        id
        username
        token
        avatar
        status
      }
    }
  }
`;

export default getWorkspaces;
