import { gql } from "../../../utils/apollo";

export const editReply = gql`
  mutation EditReply($replyId: ID, $text: String, $threadingMessageId: ID) {
    editReply(
      input: {
        replyId: $replyId
        text: $text
        threadingMessageId: $threadingMessageId
      }
    ) {
      null
    }
  }
`;

export default editReply;
