import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import FoundFile from "./FoundFile.component";

import {
  setFoundReply,
  setIsResultsPanelOpen,
  setMessageToJumpTo,
  setRoom,
} from "../../../store/actions/index";

const mapStateToProps = (state) => {
  const { user } = state.chat;
  const { searchedValue } = state.search;
  return { user, searchedValue };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setFoundReply, setIsResultsPanelOpen, setMessageToJumpTo, setRoom },
    dispatch
  );

const FoundFileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FoundFile);

export default FoundFileContainer;
