import * as Mui from "@mui/material";

export const LoadingBackdrop = function () {
  return (
    <Mui.Backdrop open>
      <Mui.CircularProgress size={100} />
    </Mui.Backdrop>
  );
};

export default LoadingBackdrop;
