import React, { useEffect } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { Tooltip } from "../../prestyledComponents";

export const AppUser = function ({
  searchedUsers,
  deleteAppUser,
  resetSearchedUsers,
}) {
  useEffect(() => {
    resetSearchedUsers();
    // eslint-disable-next-line
  }, []);

  const user = searchedUsers[0];

  const handleDeleteAppUser = () => deleteAppUser(user);

  return !user ? null : (
    <Mui.ListItem>
      <Mui.ListItemAvatar>
        <Mui.Avatar src={user.avatar} />
      </Mui.ListItemAvatar>
      <Mui.ListItemText primary={user.username} />
      <Mui.ListItemIcon>
        <Tooltip title="Delete">
          <Mui.IconButton color="secondary" onClick={handleDeleteAppUser}>
            <Icons.MinusCircle />
          </Mui.IconButton>
        </Tooltip>
      </Mui.ListItemIcon>
    </Mui.ListItem>
  );
};

export default AppUser;

AppUser.propTypes = {
  searchedUsers: PropTypes.array.isRequired,
  deleteAppUser: PropTypes.func.isRequired,
  resetSearchedUsers: PropTypes.func.isRequired,
};
