import { pdfjs } from "react-pdf/dist/esm/entry.webpack";

//run sb

// import { pdfjs } from "pdfjs-dist/build/pdf.js";

import createElement from "../createElement";

/**
 * Converts pdf to images
 * @param {File} pdf File of pdf mime type
 * @returns {Promise<Array<string>>} Array with image data urls
 */
export const pdfFileToUrls = async function (pdf) {
  if (!pdf) throw new Error("No pdf File provided");
  const pdfSource = URL.createObjectURL(pdf);
  const doc = await pdfjs.getDocument(pdfSource).promise;
  const { numPages } = doc;
  const urls = [];
  for (let index = 1; index <= numPages; index++) {
    const page = await doc.getPage(index);
    const viewport = page.getViewport({ scale: 1 });
    const { width, height } = viewport;
    const canvas = createElement("canvas", { width, height });
    const canvasContext = canvas.getContext("2d");
    await page.render({
      canvasContext,
      viewport,
    }).promise;
    const url = canvas.toDataURL();
    urls.push(url);
  }
  return urls;
};

export default pdfFileToUrls;
