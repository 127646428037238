import client from "../../../client";
import mutation from "./kickUser.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} id
 * @param {string} user_id
 */
export const kickUser = async function (id, user_id) {
  id = addBearer(id);
  user_id = addBearer(user_id);
  await client.mutate({
    mutation,
    variables: {
      id,
      user_id,
    },
  });
};

export default kickUser;
