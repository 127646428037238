import React, { useState } from "react";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { TooltipIconButton } from "../../prestyledComponents";
import { AppUser } from "../../standaloneItems";
import { GetAppUserForm } from "../../forms";

export const GetAppUserButton = function ({ user, ...props }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleClick = () => setOpen(true);

  return user.role !== "admin" ? null : (
    <>
      <TooltipIconButton {...props} title="find app user" onClick={handleClick}>
        <Icons.Slash />
      </TooltipIconButton>
      <Mui.Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Mui.DialogTitle id="form-dialog-title">
          Search user by email
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <GetAppUserForm />
          <AppUser />
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.IconButton color="primary" onClick={handleClose}>
            <Icons.X />
          </Mui.IconButton>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default GetAppUserButton;

GetAppUserButton.propTypes = {
  user: PropTypes.object.isRequired,
};
