export class CustomError extends Error {
  /**
   * @param {Error} extendedError
   */
  constructor({ message, stack, ...props }) {
    super(message);
    if (stack) this.stack += stack;
    for (const name of Object.getOwnPropertyNames(props)) {
      this[name] = props[name];
    }
  }
}

export default CustomError;
