import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  avatar: {
    marginRight: "-20px",
  },
  groupContainer: {
    display: "flex",
    marginBottom: "5px",
  },
  foundText: {
    backgroundColor: theme.palette.messages.highlightedBg,
    //fontWeight: "500",
    border: `1px solid ${theme.palette.messages.highlightedBg}`,
  },
}));
