/**
 * @callback callback
 */

/**
 * @param {string} title
 * @param {callback} callback
 * @param {Object} [options]
 * @param {Boolean} [options.log]
 * @param {Boolean} [options.collapsed]
 * @param {Boolean} [options.callbackIf]
 * @param {Object} [options.styles]
 * @returns {void}
 */
export const groupLog = function (
  title,
  callback,
  { log = true, collapsed = true, callbackIf = true, styles } = {}
) {
  if (!log) return;
  const executeCallback = !!callbackIf;
  const loggerArgs = styles ? [`%c${title}`, styles] : [title];
  if (!callback || !executeCallback) return console.log(...loggerArgs);
  if (collapsed) {
    console.groupCollapsed(...loggerArgs);
  } else {
    console.group(...loggerArgs);
  }
  callback();
  console.groupEnd();
};

export default groupLog;
