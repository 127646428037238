import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CreateCustomRoomButton from "./CreateCustomRoomButton.component";

import { createCustomRoom, addUsersToRoom } from "../../../store/actions/index";
import { selectActiveChannel } from "../../../store/selectors/index";

const mapStateToProps = (state) => {
  const room = selectActiveChannel(state);
  const { friends } = state.chat;
  return { friends, room };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createCustomRoom,
      addUsersToRoom,
      //sendRoomInvitation,
    },
    dispatch
  );

const CreateCustomRoomButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(CreateCustomRoomButton);

export default CreateCustomRoomButtonContainer;
