import { gql } from "../../../utils/apollo";

export const changeUsername = gql`
  mutation ChangeUsername($username: String) {
    updateUser(input: { username: $username }) {
      username
    }
  }
`;

export default changeUsername;
