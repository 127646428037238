import {
  HandTracker,
  PagingTracker,
  isInsideBbox,
  parseBoundingBox,
  LockTracker,
} from "../../imageProcessing/handTracking";
import { JitsiTrackEffect } from "XmarsJitsiMeetJS/effects/effects.utils";

import {
  createElement,
  ctxSaveExecRestore,
  getOffscreenCanvas,
} from "XmarsUtils/index";
// eslint-disable-next-line
import { BoundingBoxType, HandType } from "../../imageProcessing/handTracking";

export class SlidesEffect extends JitsiTrackEffect {
  #initialized = false;

  #img = createElement("img");

  /** @type {HandTracker} */
  #handTracker;

  /** @type {PagingTracker} */
  #pagingTracker;

  /** @type {LockTracker} */
  #lockTracker;

  #timeout;

  offset = 20;

  // --- Slides
  #factor = 0.4;
  #slides = [];
  #slideIndex = 0;
  #slideCoords = { x: 0, y: 0 };
  #slideSize = { width: 0, height: 0 };
  #size = { width: 0, height: 0 };
  // ---

  constructor({
    onDraw = null,
    onEdge = null,
    onLockChange = null,
    onLockForced = null,
    slides = [],
    ...options
  } = {}) {
    super();
    this.options = options;
    this.onDraw = onDraw;
    this.onEdge = onEdge;
    this.onLockChange = onLockChange;
    this.onLockForced = onLockForced;
    this.slides = slides;
    this.slideIndex = 0;
  }

  set slide(slide) {
    if (this._isAtEdgePage()) {
      const { width, height } = this.#slideSize;

      const canvas = createElement("canvas", { width, height });
      const ctx = canvas.getContext("2d");

      ctxSaveExecRestore(ctx, () => {
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, width, height);
      });

      // let text = "ERROR";
      // if (this.#slideIndex === -1) {
      //     text = "START";
      // } else if (this.#slideIndex === this.#slides.length) {
      //     text = "END";
      // };
      // ctxWriteCenteredText(ctx, text, {
      //     configCallback() {
      //         ctx.fillStyle = "white";
      //         ctx.font = `bold 0px "Garamond", "Helvetica", "Gill Sans", serif`;
      //     },
      // });

      return (this.#img.src = canvas.toDataURL());
    }
    if (this.#slides.indexOf(slide) === -1) return;
    this.#img.src = slide;
  }
  get slide() {
    return this.#slides[this.#slideIndex];
  }

  set slides(slides = []) {
    if (!slides.length) throw new Error("Empty presentation");
    this.#slides = slides;
    this.slideIndex = 0;
  }
  get slides() {
    return this.#slides;
  }

  set slideIndex(index = 0) {
    if (this._isAtEdgePage(index)) {
      if (index < -1) {
        index = -1;
      } else if (index > this.#slides.length) {
        index = this.#slides.length;
      }
      if (index === this.#slideIndex) {
        if (this.onEdge) this.onEdge(this._getSlidesBbox());
        return;
      }
    }
    this.#slideIndex = index;
    this.slide = this.#slides[index];
  }
  get slideIndex() {
    return this.#slideIndex;
  }

  set factor(value) {
    const { width, height } = this.#size;
    this.#slideSize = {
      width: width * value,
      height: height * value,
    };
    this.#factor = value;
  }
  get factor() {
    return this.#factor;
  }

  _isAtEdgePage(index = this.#slideIndex) {
    const slides = this.#slides;
    return index <= -1 || index >= slides.length;
  }

  _handlePickHand(pickHand) {
    const lockTracker = this.#lockTracker;
    if (lockTracker.locked) {
      if (this.onLockForced) this.onLockForced();
      return;
    }
    const bbox = parseBoundingBox(pickHand.bbox);
    const slidesBbox = this._getSlidesBbox();
    const coords = this.#slideCoords;
    if (isInsideBbox(bbox, slidesBbox)) {
      const { width, height } = this.#size;
      const xLimit = width - slidesBbox.width;
      const yLimit = height - slidesBbox.height;
      let x = bbox.x - slidesBbox.width * 0.5;
      if (x < 0) {
        x = 0;
      } else if (x > xLimit) {
        x = xLimit;
      }
      let y = bbox.y - slidesBbox.height * 0.5;
      if (y < 0) {
        y = 0;
      } else if (y > yLimit) {
        y = yLimit;
      }
      coords.x = x;
      coords.y = y;
    }
  }

  _getSlidesBbox() {
    const slideCoords = this.#slideCoords;
    const slideSize = this.#slideSize;
    const bbox = [
      slideCoords.x,
      slideCoords.y,
      slideSize.width,
      slideSize.height,
    ];
    return parseBoundingBox(bbox);
  }

  /**
   * @private
   * @param {number} width
   * @param {number} height
   * @returns {{
   *      bbox: BoundingBoxType,
   *      leftOut: BoundingBoxType,
   *      leftIn: BoundingBoxType,
   *      rightIn: BoundingBoxType,
   *      rightOut: BoundingBoxType,
   * }}
   */
  _getPagingAreas(width) {
    const bbox = this._getSlidesBbox();
    const { x, left, right, top, bottom } = bbox;
    const offset = (bbox.width * this.offset) / 100;
    return {
      bbox,
      leftOut: {
        left: 0,
        right: left + offset,
        top,
        bottom,
      },
      leftIn: {
        left,
        right: x,
        top,
        bottom,
      },
      rightIn: {
        left: x,
        right,
        top,
        bottom,
      },
      rightOut: {
        left: right - offset,
        right: width,
        top,
        bottom,
      },
    };
  }

  async init(modelParams = {}) {
    if (this.#initialized) return;
    const options = this.options;
    const onLeft = (swipe) => {
      this.slideIndex--;
      if (options.onLeft) options.onLeft(swipe);
    };
    const onRight = (swipe) => {
      this.slideIndex++;
      if (options.onRight) options.onRight(swipe);
    };
    this.#pagingTracker = new PagingTracker({
      onLeft,
      onRight,
      maxFrameLoss: 2,
    });
    // ---
    const onChange = (lock) => {
      if (this.onLockChange) this.onLockChange(lock, this._getSlidesBbox());
    };
    this.#lockTracker = new LockTracker({ onChange });
    // ---
    const handTracker = new HandTracker();
    await handTracker.init(modelParams);
    // ---
    this.#handTracker = handTracker;
    this.#initialized = true;
  }

  dispose() {
    this.stopEffect();
    if (this.#handTracker) {
      this.#handTracker.dispose();
      this.#handTracker = null;
    }
    if (this.#timeout) clearTimeout(this.#timeout);
    this.#initialized = false;
    this.#slideSize = { width: 0, height: 0 };
    this.#slideCoords = { x: 0, y: 0 };
  }

  /**
   *
   * @param {MediaStream} stream
   * @returns {MediaStream}
   */
  startEffect(stream) {
    const { width, height } = stream.getTracks()[0].getSettings();

    const handTracker = this.#handTracker;
    const pagingTracker = this.#pagingTracker;
    const lockTracker = this.#lockTracker;

    const fakeCanvas = getOffscreenCanvas(width, height);
    const fakeCtx = fakeCanvas.getContext("2d");

    const canvas = createElement("canvas", { width, height });
    const ctx = canvas.getContext("2d");

    const video = createElement("video", {
      width,
      height,
      autoplay: true,
      srcObject: stream,
    });

    this.#size = { width, height };
    this.factor = this.#factor;
    this.#slideCoords = {
      x: 0,
      y: 0,
    };

    handTracker.onPredict = (predictions) => {
      const {
        closed: [closedHand],
      } = predictions;
      const areas = this._getPagingAreas(width);
      let [lockHand, lockValue] = lockTracker.handlePredictions(predictions);
      if (lockHand) {
        const bbox = parseBoundingBox(lockHand.bbox);
        const slidesBbox = this._getSlidesBbox();
        const inside = isInsideBbox(bbox, slidesBbox);
        if (!inside) {
          if (lockTracker.reset) lockTracker.reset();
          lockHand = null;
          lockValue = null;
        }
      }
      const imgBox = this._getSlidesBbox();
      const [swipeHand, { swipeArgs, path }, fromLeft, fromRight] =
        pagingTracker.handlePredictions(predictions, areas);
      let rate = 0;
      if (swipeHand && swipeArgs.start) {
        const startX = swipeArgs.start.x;
        const bbox = parseBoundingBox(swipeHand.bbox);
        if (fromLeft) {
          rate = ((bbox.x - startX) / (imgBox.right - startX)) * 100;
        } else if (fromRight) {
          rate = ((bbox.x - startX) / (imgBox.left - startX)) * 100;
        }
        if (rate > 100) rate = 100;
        if (rate < 0) rate = 0;
      }
      const swipePath = {
        path,
        rate,
      };
      const pickHand = closedHand;
      fakeCtx.clearRect(0, 0, width, height);
      fakeCtx.drawImage(video, 0, 0, width, height);
      fakeCtx.drawImage(
        this.#img,
        this.#slideCoords.x,
        this.#slideCoords.y,
        this.#slideSize.width,
        this.#slideSize.height
      );
      ctx.drawImage(fakeCanvas, 0, 0);
      if (pickHand) this._handlePickHand(pickHand);
      if (this.onDraw)
        this.onDraw(
          {
            pickHand,
            swipeHand,
            swipePath,
            lockHand,
            lockValue,
            ...predictions,
          },
          {
            canvas,
            imgBox,
          }
        );
    };

    handTracker.start(video);

    const newStream = canvas.captureStream();

    return newStream;
  }

  stopEffect() {
    const handTracker = this.#handTracker;
    if (handTracker) {
      handTracker.stop();
      handTracker.onPredict = null;
    }
    if (this.#lockTracker) this.#lockTracker.reset();
    if (this.#pagingTracker) this.#pagingTracker.reset();
  }

  isEnabled() {
    return this.#initialized;
  }
}

export default SlidesEffect;
