import { put, call, fork } from "redux-saga/effects";

import * as actions from "XmarsStore/actions";
import { handleSagaError } from "XmarsStore/sagas/sagas.utils/handleSagaError";
import {
  conferenceOnUserJoined,
  conferenceOnUserLeft,
  conferenceOnTrackAdded,
  conferenceOnTrackRemoved,
  conferenceOnConferenceFailed,
  conferenceOnMessageReceived,
  waitForPeople,
  conferenceOnKicked,
} from "./conferenceSagas";
import * as signalNames from "./signalNames";

export function* processConferenceSignal(payload) {
  const { jitsiConference, data } = payload;
  try {
    if (data.signal === signalNames.USER_JOINED) {
      const { id, participant } = data.payload;
      yield conferenceOnUserJoined({ id, participant });
    } else if (data.signal === signalNames.USER_LEFT) {
      const { id, participant } = data.payload;
      yield conferenceOnUserLeft({ id, participant });
    } else if (data.signal === signalNames.TRACK_ADDED) {
      const track = data.payload;
      yield conferenceOnTrackAdded(track);
    } else if (data.signal === signalNames.TRACK_REMOVED) {
      const track = data.payload;
      yield conferenceOnTrackRemoved(track);
    } else if (data.signal === signalNames.CONFERENCE_JOINED) {
      const message = data.payload;
      yield call(console.log, message);
      if (jitsiConference.getParticipants().length === 0) {
        yield fork(waitForPeople, { id: jitsiConference.options.name });
      }
      yield put(actions.setConferenceStatus("active"));
    } else if (data.signal === signalNames.CONFERENCE_LEFT) {
      yield put(actions.cleanConferenceRequest());
    } else if (data.signal === signalNames.CONFERENCE_ERROR) {
      const message = data.payload;
      yield call(console.log, message);
    } else if (data.signal === signalNames.CONFERENCE_FAILED) {
      const errorCode = data.payload;
      yield conferenceOnConferenceFailed(errorCode);
    } else if (data.signal === signalNames.KICKED) {
      yield conferenceOnKicked();
    } else if (data.signal === signalNames.RECORDER_STATE_CHANGED) {
      const message = data.payload;
      yield call(console.log, message);
    } else if (data.signal === signalNames.DISPLAY_NAME_CHANGED) {
      const { id, name } = data.payload;
      yield put(actions.updateRemoteParticipant({ id }, { name }));
    } else if (data.signal === signalNames.MESSAGE_RECEIVED) {
      const { id, jsonMessage } = data.payload;
      yield conferenceOnMessageReceived(id, jsonMessage);
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}
