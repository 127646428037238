import { withStyles } from "@mui/styles";
import {
  Divider,
  IconButton,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import * as Icons from "react-feather";
import { RotateCw as FeatherRotateCw } from "react-feather";
import useStyles from "./DrawableCanvasTools.styles";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export default function CustomizedDividers(props) {
  const {
    drawingType,
    setDrawingType,
    color,
    setColor,
    prevHistory,
    fwdHistory,
    undo,
    redo,
  } = props;

  const handleDrawingType = (event, newDrawingType) => {
    if (newDrawingType) setDrawingType(newDrawingType);
  };

  const handleColor = (event) => {
    setColor(event.target.value);
  };

  const classes = useStyles();

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          value={drawingType}
          exclusive
          onChange={handleDrawingType}
          aria-label="drawing type"
        >
          <ToggleButton
            title="Text"
            value="text"
            aria-label="text type"
            selected={drawingType === "text"}
          >
            <Icons.Type />
          </ToggleButton>
          <ToggleButton
            title="Rectangle"
            value="rectangle"
            aria-label="rectangle type"
            selected={drawingType === "rectangle"}
          >
            <Icons.Square />
          </ToggleButton>
          <ToggleButton
            title="Circle"
            value="circle"
            aria-label="circle type"
            selected={drawingType === "circle"}
          >
            <Icons.Circle />
          </ToggleButton>
          <ToggleButton
            title="Line"
            value="line"
            aria-label="line type"
            selected={drawingType === "line"}
          >
            <Icons.Minus />
          </ToggleButton>
          <ToggleButton
            title="Pencil"
            value="pencil"
            aria-label="pencil type"
            selected={drawingType === "pencil"}
          >
            <Icons.Edit3 />
          </ToggleButton>
          <ToggleButton
            title="Eraser"
            value="eraser"
            aria-label="eraser type"
            selected={drawingType === "eraser"}
          >
            <Icons.Delete />
          </ToggleButton>
        </StyledToggleButtonGroup>

        <Divider flexItem orientation="vertical" className={classes.divider} />
        <IconButton aria-label="undo" disabled={prevHistory} onClick={undo}>
          <Icons.RotateCcw />
        </IconButton>
        <IconButton aria-label="redo" disabled={fwdHistory} onClick={redo}>
          <FeatherRotateCw className="svgIcon" />
        </IconButton>
        <Divider flexItem orientation="vertical" className={classes.divider} />
        <IconButton aria-label="color" size="small">
          <input type="color" value={color} onChange={handleColor} />
        </IconButton>
      </Paper>
    </>
  );
}

// const [alignment, setAlignment] = React.useState("left");
// const [formats, setFormats] = React.useState(() => ["italic"]);

// const handleFormat = (event, newFormats) => {
//   setFormats(newFormats);
// };
