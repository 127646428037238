import { gql } from "../../../utils/apollo";

export const logout = gql`
  mutation Logout {
    logout {
      username
    }
  }
`;

export default logout;
