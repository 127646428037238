import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import { getUid } from "../../../utils/getUid";
import useStyles from "./EmailTextField.styles";

export const EmailTextField = function ({ InputProps, ...props }) {
  const classes = useStyles();

  return (
    <Mui.TextField
      id={getUid()}
      {...props}
      type="email"
      InputProps={{
        className: classes.input,
        notched: false,
        classes: {
          root: classes.inputRoot,
          focused: classes.inputFocused,
          notchedOutline: classes.inputNotchedOutline,
        },
        // endAdornment: <Mui.InputAdornment>@</Mui.InputAdornment>,
        ...InputProps,
      }}
      inputProps={{ className: classes.inputBase }}
    />
  );
};

EmailTextField.propTypes = {
  /**
   * The name to be used in the form
   */
  name: PropTypes.string,
  /**
   * The label to be displayed
   */
  label: PropTypes.string,
  /**
   * The placeholder to be displayed
   */
  placeholder: PropTypes.string,
  /**
   * Input variant
   */
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
};

export default EmailTextField;

EmailTextField.defaultProps = {
  name: "email",
  placeholder: "Email",
  variant: "outlined",
  size: "small",
  fullWidth: true,
  autoComplete: "off",
  required: true,
  InputProps: {},
};
