import { add, map, updateItem, remove } from "../../utils";
import chatInitialState from "./initialState";

export const resetSearchedUsers = (state) => {
  state.searchedUsers = chatInitialState.searchedUsers;
};

export const setSearchedUsers = (state, { payload }) => {
  state.searchedUsers = payload;
};

// TODO this code may be bugged. All of the three.
export const addSearchedUser = (state, { payload }) => {
  state.searchedUsers = add(state.searchedUsers, payload);
};

export const removeSearchedUser = (state, { payload }) => {
  state.searchedUsers = remove(state.searchedUsers, payload);
};

export const updateSearchedUser = (state, { payload }) => {
  state.searchedUsers = updateItem(state.searchedUsers, payload);
};
