import * as electron from "../../../electron";

import api from "../../../dataApi";
import { registerRoom, unregisterRoom } from "../../sagas/chat/utils";

import * as actions from "../../actions";
import { handleSagaError } from "../sagas.utils";

import { tryOrLog } from "../../../utils";
import { eventChannel } from "redux-saga";
import { delay, put, select, take, call, fork, race } from "redux-saga/effects";

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
function roomChannel(room) {
  const cleanedSubscription = (emitter) => {
    const subscription = api.subscribeToRoom(room, {
      next: emitter,
      error: handleSagaError,
    });
    // This weird trick removes a this is undefined error:
    const unsubscribe = subscription.unsubscribe;
    return unsubscribe.bind(subscription);
  };
  return eventChannel(cleanedSubscription);
}

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
function* processSignal(payload) {
  const { room, signal } = payload;
  try {
    switch (signal.signalType) {
      case "roomUpdate": {
        const { title, admin } = signal.room;
        yield put(
          actions.setRoomProperties({
            room,
            properties: {
              title,
              admin,
            },
          })
        );
        yield put(actions.updateRooms());
        break;
      }
      case "roomMembersUpdate": {
        yield put(
          actions.setRoomProperties({
            room,
            properties: {
              users: signal.room.users,
              admin: signal.room.admin,
            },
          })
        );
        break;
      }
      case "roomAdded": {
        const rooms = yield select((state) => state.chat.rooms);
        const notifications = yield select((state) => state.notifications);
        //In case the user had a pending invitation to the group that was just added.
        const roomNotification = notifications.filter((notification) => {
          return (
            notification.notificationType === "roomInvitation" &&
            notification.room.id === signal.room.id
          );
        });

        if (roomNotification.length) {
          yield put(actions.rejectNotification(roomNotification[0]));
        }
        const roomsIds = rooms.map((room) => room.id);
        if (!roomsIds.includes(signal.room.id)) {
          yield registerRoom(signal.room);
          const snackbar = {
            severity: "info",
            message: `You were added to the group ${signal.room.title}`,
          };
          yield put(actions.addSnackbar(snackbar));
        } else {
          yield put(actions.updateRoomUsers(signal.room.id, signal.room.users));
        }
        break;
      }
      case "removeRoom": {
        if (signal.message) {
          const snackbar = {
            severity: "info",
            message: `${signal.message.text} from room ${room.title}`,
          };
          yield put(actions.addSnackbar(snackbar));
        }
        const activeChannel = yield select(
          (state) => state.video.activeChannel
        );
        const conference = yield select((state) => state.conference);
        if (activeChannel === room && conference) {
          conference.leave();
        }
        yield call(unregisterRoom, room);
        break;
      }
      case "message": {
        const activeChannel = yield select(
          (state) => state.video.activeChannel
        );
        const mainRoom = yield select((state) => state.chat.room);
        const { id: ownId } = yield select((state) => state.chat.user);

        if (activeChannel === room) {
          yield put(
            actions.setActiveChannel({ ...activeChannel, pending: true })
          );
          yield delay(2000);
          yield put(
            actions.setRoomProperties({
              room: activeChannel,
              properties: {
                pending: false,
              },
            })
          );
          yield put(actions.setActiveChannel(activeChannel));
        }

        if (
          mainRoom?.id === room.id &&
          (ownId !== signal.sender || signal.message.messageType === "scribble")
        ) {
          yield put(actions.addMessage(signal.message));
        } else {
          yield put(
            actions.setRoomProperties({
              room,
              pending: true,
            })
          );
        }

        yield put(
          actions.setRoomProperties({
            room,
            properties: {
              unreadMessages: signal.room.unreadMessages,
              dateOldestUnreadMessage: signal.room.dateOldestUnreadMessage,
              messagesWithReplies: signal.room.messagesWithReplies,
            },
          })
        );

        let propertiesFromMessType;
        if (signal.message.messageType === "text") {
          propertiesFromMessType = {
            lastMessage: signal.message.text,
          };
        } else if (signal.message.messageType === "file") {
          propertiesFromMessType = {
            lastMessage: JSON.parse(signal.message.text).name,
          };
        } else {
          propertiesFromMessType = {
            lastMessage: "",
          };
        }

        yield put(
          actions.setRoomProperties({
            room,
            properties: propertiesFromMessType,
          })
        );
        yield put(
          actions.setRoomProperties({
            room,
            properties: {
              lastUpdate: signal.message.createdAt,
              dateOldestUnreadMessage: signal.room.dateOldestUnreadMessage,
              hidden: false,
            },
          })
        );

        yield put(actions.updateRooms());
        yield put(actions.updateFriends());

        if (electron.isSupported) {
          const { message } = signal;
          const notification = {
            room: room.id,
            title: room.title,
          };
          switch (message.messageType) {
            case "text":
              notification.body = message.text;
              break;
            default:
              notification.body += String.fromCharCode(9829);
          }
          tryOrLog(() => {
            electron.showNotification(notification);
          });
        }
        break;
      }
      case "messageDeleted": {
        const message = signal.message;
        yield put(actions.removeMessage(message));
        yield put(
          actions.setRoomProperties({
            room,
            properties: {
              lastMessage: signal.room.lastMessage,
            },
          })
        );
        yield put(actions.updateRooms());
        yield put(actions.updateFriends());
        return;
      }
      case "messageUpdated": {
        const message = signal.message;
        yield put(actions.updateMessage({ message, properties: message }));
        const currentMessage = yield select(
          (state) => state.chat.currentMessage
        );
        const messages = yield select((state) => state.messages);

        if (
          currentMessage &&
          currentMessage.id === message.id &&
          !messages.some((m) => m.id === message.id)
        ) {
          yield put(actions.setCurrentMessage(message));
        }
        if (signal.room) {
          yield put(
            actions.setRoomProperties({
              room,
              properties: {
                lastMessage: signal.room.lastMessage,
                unreadMessages: signal.room.unreadMessages,
                dateOldestUnreadMessage: signal.room.dateOldestUnreadMessage,
                messagesWithReplies: signal.room.messagesWithReplies,
              },
            })
          );
          const activeChatRoom = yield select((state) => state.chat.room);
        }
        yield put(actions.updateRooms());
        yield put(actions.updateFriends());
        return;
      }
      case "callEnded": {
        const incomingCall = yield select((state) => state.video.incomingCall);
        if (incomingCall && incomingCall.meetingCode === room.meetingCode) {
          yield put(actions.setIncomingCall(null));
        }
        break;
      }
      case "typing": {
        if (signal.isTyping) {
          yield put(
            actions.setRoomProperties({
              room,
              properties: {
                typing: true,
                usernameTyping: signal.message?.text,
              },
            })
          );
        } else {
          yield put(
            actions.setRoomProperties({
              room,
              properties: {
                typing: false,
                usernameTyping: signal.message?.text,
              },
            })
          );
        }
        yield put(actions.updateRooms());
        // yield delay(3000);
        // yield put(
        //     actions.setRoomProperties({
        //         room,
        //         properties: {
        //             typing: false,
        //             usernameTyping: undefined,
        //         },
        //     })
        // );
        // yield put(actions.updateRooms());
        break;
      }
      default: {
        return console.log("unhandled case", signal);
      }
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
}

/**
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 */
export const startRoomSubscription = function* ({ payload }) {
  const room = payload;
  const channel = yield call(roomChannel, room);
  try {
    while (true) {
      const { signal, cancel } = yield race({
        signal: take(channel),
        cancel: take(actions.cancelRoomSubscription),
      });
      if (cancel) {
        if (cancel.payload && cancel.payload.id === room.id) {
          channel.close();
        }
      } else {
        yield fork(processSignal, { room, signal });
      }
    }
  } catch (error) {
    yield call(handleSagaError, error);
  }
};
