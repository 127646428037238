import client from "../../../client";
import query from "./getNotifications.query";

/**
 * @returns {Promise<Array<{
 *  id: string,
 *  invitationMessage: string,
 *  notificationType: string,
 *  sender: {
 *      id: string,
 *      username: string,
 *      avatar: string,
 *  },
 *  room: {
 *      id: string,
 *      group: boolean,
 *      title: string,
 *      meetingCode: string,
 *  },
 * }>>}
 * @deprecated _rooms_ are no longer valid entities, they must be replaced by _channels_.
 * Furthermore, it is not always clear if code that refers to _rooms_ is obsolete or is
 * still useful. If this function is still relevant, rename its identifiers using the
 * proper entities and remove this deprecation warning
 * Make sure the corresponding changes has been made on the backend
 */
export const getNotifications = async function () {
  const { data } = await client.query({ query });
  return data.getUser.notifications;
};

export default getNotifications;
