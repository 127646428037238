import { gql } from "../../../utils/apollo";

export const changeOrganizationChats = gql`
  mutation ChangeOrganizationChats($isOrganized: Boolean) {
    changeOrganizationChats(input: { isOrganized: $isOrganized }) {
      null
    }
  }
`;

export default changeOrganizationChats;
