import React, { useState } from "react";
import { Grid } from "@mui/material";
import * as Mui from "@mui/material";
import { Alert } from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import {
  TooltipIconButton,
  LinearSubmitButton,
} from "../../prestyledComponents";
import { useMounted } from "../../../hooks/useMounted";
import { formSubmitHandler } from "../../../utils/formSubmitHandler/index";
import { getBoundActionPromise } from "../../../utils/getBoundActionPromise";
import { logError } from "../../../utils/errors/logError";

export const DeleteAccountForm = function ({ deleteUser, onSubmit }) {
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const mounted = useMounted();

  const handleClose = () => setOpen(false);
  const handleClick = () => setOpen(true);

  const handleSubmit = formSubmitHandler(async (_, event) => {
    try {
      setProcessing(true);
      await getBoundActionPromise(deleteUser);
      if (onSubmit) onSubmit(event);
    } catch (error) {
      logError(error);
    } finally {
      if (mounted.current) {
        setProcessing(false);
      }
    }
  });

  return (
    <Grid container direction="row">
      <Grid item>
        <Alert severity="warning" variant="outlined">
          This action cannot be undone
        </Alert>
      </Grid>
      <Grid item>
        <Mui.IconButton onClick={handleClick}>
          <Icons.Check color="primary" />
        </Mui.IconButton>
      </Grid>
      <Mui.Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Mui.DialogTitle id="form-dialog-title">
          Are your sure you want to delete your account?
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Alert severity="warning" variant="filled">
                This action cannot be undone!
              </Alert>
            </Grid>
            <Grid item>
              <form onSubmit={handleSubmit}>
                <LinearSubmitButton
                  loading={processing}
                  children="Delete account"
                  color="secondary"
                  endIcon={<Icons.Trash2 />}
                />
              </form>
            </Grid>
          </Grid>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <TooltipIconButton
            title="Cancel"
            color="primary"
            onClick={handleClose}
          >
            <Icons.X />
          </TooltipIconButton>
        </Mui.DialogActions>
      </Mui.Dialog>
    </Grid>
  );
};

export default DeleteAccountForm;

DeleteAccountForm.propTypes = {
  deleteUser: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};
