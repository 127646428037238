import recordingState from "../../../JitsiMeetJS/recording/recordingState";

const mockSession = {
  getID() {
    return "sessionID";
  },
};

const getRndTime = (time = 1, delay = 0) =>
  Math.floor(time * Math.random() * 1000 + delay * 1000);

export const conference = {
  addEventListener() {},
  removeEventListener() {},
  async startRecording() {
    return new Promise((resolve) => {
      setTimeout(resolve, getRndTime(), mockSession);
      setTimeout(() => {
        recordingState.resolver();
      }, getRndTime(2, 1));
    });
  },
  async stopRecording() {
    return new Promise((res) => setTimeout(res, getRndTime(1, 1), null));
  },
};

export default conference;
