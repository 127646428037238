import * as Mui from "@mui/material";
import FoundItemLoading from "../../collectionItems/FoundItemLoading";
import useStyles from "./FoundItemsLoading.styles";

export const FoundItemsLoading = function ({ style }) {
  const classes = useStyles();
  const foundItems = [1, 2, 3, 4, 5, 6];

  return (
    <Mui.Box className={classes.container} style={style}>
      <Mui.List className={classes.contactsContainer}>
        {foundItems.map((item) => (
          <FoundItemLoading key={`loading-item-${item}`} />
        ))}
      </Mui.List>
    </Mui.Box>
  );
};

export default FoundItemsLoading;
