import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import HandtrackButton from "./HandtrackButton.component";

import { addSnackbar, updateVideoEffects } from "../../../store/actions/index";

const mapStateToProps = ({ localParticipant, videoEffects }) => ({
  localParticipant,
  videoEffects,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addSnackbar,
      updateVideoEffects,
    },
    dispatch
  );

const HandtrackButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HandtrackButton);

export default HandtrackButtonContainer;
