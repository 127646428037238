import * as Mui from "@mui/material";
import PropTypes from "prop-types";

import useStyles from "./Tooltip.styles";

export const Tooltip = function ({ classes: TooltipClasses, ...props }) {
  const classes = useStyles();

  return (
    <Mui.Tooltip
      classes={{
        tooltipPlacementLeft: classes.noMargin,
        tooltipPlacementRight: classes.noMargin,
        tooltipPlacementTop: classes.noMargin,
        tooltipPlacementBottom: classes.noMargin,
        tooltip: classes.tip,

        ...TooltipClasses,
      }}
      {...props}
    />
  );
};

export default Tooltip;

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

Tooltip.defaultProps = {
  arrow: true,
  classes: {},
};
