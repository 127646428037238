import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  height100: {
    height: "100%",
  },
  editInput: {
    marginTop: "18px",
    marginLeft: "17px",
    width: "95%",
  },
  chatButton: {
    backgroundColor: theme.palette.buttons.light,
    border: `1px solid ${theme.palette.buttons.light}`,
    color: theme.palette.buttons.dark,
    marginLeft: "0rem",
    borderRadius: "0.25rem",
    marginTop: "calc( 1.1rem - 1px )",
    marginRight: "17px",
    transition:
      "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out",

    "&:hover": {
      backgroundColor: theme.palette.buttons.medium,
      borderColor: theme.palette.buttons.medium,
    },
  },
  emojiButton: {
    color: "rgba(0, 0, 0, 0.54)",
    marginBottom: "2px",
  },

  sendButton: {
    //backgroundColor: theme.palette.primary.main,
    background:
      "linear-gradient(to left, #1FA2FF 30%, #12D8FA 91%, #1FA2FF 170%)",
    border: `1px solid ${theme.palette.primary.main}`,
    color: "#fff",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  cancelButton: {},
}));
