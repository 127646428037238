import { gql } from "../../../utils/apollo";

export const changePassword = gql`
  mutation ChangePassword($password: String, $newPassword: String) {
    updateUser(input: { password: $password, newPassword: $newPassword }) {
      id
    }
  }
`;

export default changePassword;
