import searchInitialState from "./initialState";

export const resetSearchState = (state) => {
  state = searchInitialState;
};

export const setFoundMessages = function (state, { payload }) {
  state.foundMessages = payload;
};

export const setFoundGroups = function (state, { payload }) {
  state.foundGroups = payload;
};

export const setFoundContacts = function (state, { payload }) {
  state.foundContacts = payload;
};

export const setFoundFiles = function (state, { payload }) {
  state.foundFiles = payload;
};

export const setTotalResults = function (state, { payload }) {
  state.totalResults = payload;
};

export const setSearchedValue = function (state, { payload }) {
  state.searchedValue = payload;
};

export const setAreResultsLoaded = function (state, { payload }) {
  state.areResultsLoaded = payload;
};

export const setDefaultResults = function (state, { payload }) {
  state.defaultResults = payload;
};

export const setIsResultsPanelOpen = function (state, { payload }) {
  state.isResultsPanelOpen = payload;
};

export const setSearchPanelOpen = function (state, { payload }) {
  state.searchPanelOpen = payload;
};

export const setSearchWithFilters = function (state, { payload }) {
  state.searchWithFilters = payload;
};
export const setOnSelectorOpen = function (state, { payload }) {
  state.onSelectorOpen = payload;
};
export const setDate = function (state, { payload }) {
  state.date = payload;
};
export const setFilters = function (state, { payload }) {
  state.filters = { ...state.filters, ...payload };
};
export const resetFilters = function (state, { payload }) {
  state.filters = {};
};
