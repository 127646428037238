/**
 * @param {string} url
 * @param {Object} options
 * @param {string} [options.method="get"]
 * @param {Document | BodyInit} options.body
 * @param {(this: XMLHttpRequest, ev: ProgressEvent<EventTarget>) => any} options.onUploadProgress
 * @returns {Promise<Response>}
 */
export const customFetch = function (
  url,
  { method = "get", body, onUploadProgress }
) {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    if (onUploadProgress) xhr.upload.onprogress = onUploadProgress;
    xhr.onload = function () {
      const { status } = xhr;
      if (status < 200 || status >= 300) {
        const reason = new Error(xhr.responseText);
        return rej(reason);
      }
      res(xhr.response);
    };
    xhr.open(method, url);
    xhr.send(body);
  });
};

export default customFetch;
