import Box from "@mui/material/Box";
import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { RemoteParticipant } from "../../collectionItems";

import useStyles from "./ParticipantsQueue.styles";

// eslint-disable-next-line
import { Participant } from "../../../typeDefs";

/**
 * @param {Participant} participant
 */
const SortableParticipant = function ({ participant } = {}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: participant.id, data: { type: "QUEUE" } });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Box
      marginLeft="0.5em"
      height="100%"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      sx={{ display: "inline-block" }}
    >
      <RemoteParticipant small participant={participant} />
    </Box>
  );
};

/**
 * @param {Object} props
 * @param {Array<Participant>} props.participants
 */
export const ParticipantsQueue = function ({ participants }) {
  const classes = useStyles();

  const renderParticipants = function () {
    if (!participants.length)
      return (
        <Box
          height="100%"
          marginRight="1em"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Mui.ListItem>
            <Mui.ListItemIcon>
              <Mui.Icon>
                <Icons.Users />
              </Mui.Icon>
            </Mui.ListItemIcon>
            <Mui.ListItemText primary="..." />
          </Mui.ListItem>
        </Box>
      );

    return participants.map((participant) => (
      <SortableParticipant key={participant.id} participant={participant} />
    ));
  };

  return (
    <SortableContext items={participants} id="QUEUE">
      <Box
        height="100%"
        sx={{ overflow: "auto", whiteSpace: "nowrap" }}
        className={classes.queue}
      >
        {renderParticipants()}
      </Box>
    </SortableContext>
  );
};

export default ParticipantsQueue;

ParticipantsQueue.propTypes = {
  participants: PropTypes.array.isRequired,
};
