import { gql } from "../../../utils/apollo";

export const login = gql`
  mutation Login($email: String, $password: String) {
    login(input: { email: $email, password: $password }) {
      id
    }
  }
`;

export default login;
