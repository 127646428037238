import { ListItemIcon } from "@mui/material";
import * as Icons from "react-feather";
import PropTypes from "prop-types";

import { TooltipIconButton } from "../../prestyledComponents";
import useStyles from "./SendVideoCallButton.styles";

export const SendVideoCallButton = function ({
  channel,
  channelCall,
  updateCallStatus,
  setOnlyAudio,
  ListItemIconProps,
  currentWorkspace,
  ...props
}) {
  const classes = useStyles();

  const handleClick = function (audio) {
    setOnlyAudio(audio);
    updateCallStatus({ dialing: true });
    channelCall({ channel, workspace: currentWorkspace });
  };
  return (
    <>
      <ListItemIcon {...ListItemIconProps}>
        <TooltipIconButton
          {...props}
          title={
            channel.isPublic ? "Cannot call a public channel" : "Voice Call"
          }
          onClick={() => handleClick(true)}
          name={"VoiceCall"}
          className={classes.iconButton}
          children={<Icons.Phone />}
          disabled={channel.isPublic}
        />
      </ListItemIcon>
      <ListItemIcon {...ListItemIconProps}>
        <TooltipIconButton
          {...props}
          title={
            channel.isPublic ? "Cannot call a public channel" : "Video Call"
          }
          name={"VideoCall"}
          onClick={() => handleClick(false)}
          className={classes.iconButton}
          children={<Icons.Video />}
          disabled={channel.isPublic}
        />
      </ListItemIcon>
    </>
  );
};

export default SendVideoCallButton;
