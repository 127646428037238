import { connect } from "react-redux";
import { LocalAudio } from "./LocalAudio.component";

const mapStateToProps = (state) => {
  const { localAudioVolume } = state.jitsi.localTracksData;
  return { localAudioVolume };
};

const mapDispatchToProps = null;

const LocalAudioContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalAudio);

export default LocalAudioContainer;
