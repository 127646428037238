import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getRecordings } from "../../../store/actions/index";

import RecordingsButton from "./RecordingsButton.component";

const mapStateToProps = (state) => {
  const { user } = state.chat;
  return { user };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRecordings,
    },
    dispatch
  );

export const RecordingsButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordingsButton);

export default RecordingsButtonContainer;
