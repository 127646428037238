import client from "../../../client";
import mutation from "./deleteReply.mutation";
import { addBearer } from "../../../utils";

/**
 * @param {string} replyId
 * @param {string} repliedMessageId
 */
export const deleteReply = async function (replyId, repliedMessageId) {
  replyId = addBearer(replyId);
  repliedMessageId = addBearer(repliedMessageId);
  await client.mutate({
    mutation,
    variables: {
      replyId,
      repliedMessageId,
    },
  });
};

export default deleteReply;
