import ctxDrawPath from "./ctxDrawPath";
import { getRectanglePath } from "./path2D";

/**
 * @callback configCallback
 * @param {CanvasRenderingContext2D} ctx
 */

/**
 * @param {CanvasRenderingContext2D} ctx
 * @param {Object} [options]
 * @param {number} [options.left]
 * @param {number} [options.top]
 * @param {number} [options.width]
 * @param {number} [options.height]
 * @param {boolean} [options.full]
 * @param {boolean} [options.strike]
 * @param {configCallback} [options.configCallback]
 */
export const ctxDrawRectangle = function (
  ctx,
  {
    left = 0,
    top = 0,
    width = 10,
    height = 10,
    full,
    strike,
    configCallback,
  } = {}
) {
  const path = getRectanglePath({ left, top, width, height });
  ctxDrawPath(ctx, path, { full, strike, configCallback });
};

export default ctxDrawRectangle;
