import * as Mui from "@mui/material";
import * as Icons from "react-feather";
import useStyles from "./JoinVideoCallButton.styles";

export const JoinVideoCallButton = function ({
  setPage,
  setMeetingCode,
  setActiveChannel,
  setOnlyAudio,
  ongoingCall,
}) {
  const classes = useStyles();

  const handleClick = function (audio) {
    let { meetingCode, channel } = ongoingCall;
    setActiveChannel(channel);
    setMeetingCode(meetingCode);
    setPage("videoCall");
    setOnlyAudio(audio);
  };

  return (
    <>
      <Mui.Button
        onClick={() => handleClick(false)}
        endIcon={<Icons.Video />}
        color="success"
        variant="outlined"
        className={classes.marginLeftBottom}
      >
        Join Call
      </Mui.Button>
    </>
  );
};

export default JoinVideoCallButton;
