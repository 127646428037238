import React, { useRef, useEffect, useState, useMemo } from "react";
import * as Mui from "@mui/material";

import {
  getCurrentDate,
  organizeMessages,
  sameTimeInterval,
} from "../Messages/utils/index";
import { VerticalScrollbars } from "../../prestyledComponents";

import { Reply } from "../../collectionItems";
import TextDivider from "../../TextDivider";
import useStyles from "./Replies.styles";

import { useAutoScroll, useDependency } from "../../../hooks/index";

/**
 * List of replies.
 * @component
 * @returns {React.ReactElement} List with the replies of the current message.
 */
function Replies({ message, inputHeight }) {
  const classes = useStyles();
  const ref = useRef(null);

  const handleTopScroll = useDependency()[0];
  const { scrollToBottom, autoScroll } = useAutoScroll(ref, {
    onTopScroll: handleTopScroll,
  });

  useEffect(() => {
    const messages = message.replies || [];
    const lastMessage = messages[messages.length - 1];
    const ownLastMessage =
      lastMessage &&
      lastMessage.owner &&
      lastMessage.owner.username === "You" &&
      lastMessage.createdAt > Date.now() - 1000;
    if (ownLastMessage) {
      scrollToBottom();
    } else {
      autoScroll();
    }

    // eslint-disable-next-line
  }, [message?.replies]);

  function renderSameDayReplies(sameDayMessages) {
    const byMinuteAndUser = [];

    sameDayMessages.forEach((message) => {
      if (!byMinuteAndUser.length) {
        byMinuteAndUser.push([message]);
      } else {
        let groupMessage = byMinuteAndUser[byMinuteAndUser.length - 1][0];
        let sameUserMinute =
          sameTimeInterval(groupMessage.createdAt, message.createdAt) &&
          groupMessage.owner?.id === message.owner.id;

        if (sameUserMinute) {
          byMinuteAndUser[byMinuteAndUser.length - 1].push(message);
        } else {
          byMinuteAndUser.push([message]);
        }
      }
    });

    return byMinuteAndUser.map((sameMUReplies) => (
      <Mui.Box key={sameMUReplies[0].createdAt}>
        {sameMUReplies.map((reply, index) => {
          let position;
          if (sameMUReplies.length === 1) {
            position = "unique";
          } else if (index === 0) {
            position = "start";
          } else if (index === sameMUReplies.length - 1) {
            position = "end";
          } else {
            position = "middle";
          }

          return (
            <Reply
              key={index}
              message={reply}
              repliedMessage={{ id: message.id }}
              position={position}
              // containerRef={ref}
            />
          );
        })}
      </Mui.Box>
    ));
  }

  let organizedMessages = useMemo(() => {
    return organizeMessages(message?.replies);
  }, [message?.replies]);

  return (
    <VerticalScrollbars
      viewStyle={{
        padding: "30px 17px 30px 25px",
        marginBottom: "5px",
        //height: "calc(100vh - 87px - 65px)",
        height: `calc(100vh - ${66 + inputHeight}px - 65px)`,
      }}
      ref={(s) => (ref.current = s && s.view)}
    >
      <Mui.List className={classes.replies} ref={ref}>
        <TextDivider
          text={getCurrentDate(message.createdAt)}
          className={classes.topDivider}
        />
        <Reply message={message} position="unique" header={true} />
        {
          <TextDivider
            text={`${message?.replies?.length || 0} repl${
              message?.replies?.length === 1 ? "y" : "ies"
            }`}
            className={classes.numRepliesDivider}
          />
        }
        {organizedMessages &&
          organizedMessages.map((sameDayMessages) => {
            return (
              <Mui.Box key={sameDayMessages[0].createdAt}>
                <TextDivider
                  text={getCurrentDate(sameDayMessages[0].createdAt)}
                  className={classes.dateDivider}
                />
                {renderSameDayReplies(sameDayMessages)}
              </Mui.Box>
            );
          })}
      </Mui.List>
    </VerticalScrollbars>
  );
}

export default Replies;
