import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SearchedUser from "./SearchedUser.component";

import { sendFriendRequest } from "../../../store/actions/index";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendFriendRequest,
    },
    dispatch
  );

const SearchedUserContainer = connect(null, mapDispatchToProps)(SearchedUser);

export default SearchedUserContainer;
