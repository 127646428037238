export const setConnection = function (state, { payload }) {
  if (payload !== null) {
    throw new Error("Something is trying to set a connection");
  }
  state.connection = payload;
};

export const setConnectionStatus = function (state, { payload }) {
  state.connection = payload;
};
