import { gql } from "../../../utils/apollo";

export const getRecordings = gql`
  query GetRecordings {
    getUser {
      recordings {
        sessionID
        thumbnail
        createdAt
        size
      }
    }
  }
`;

export default getRecordings;
