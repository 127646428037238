import React, { useState, useEffect, useRef, forwardRef } from "react";
import DrawableCanvasTools from "../DrawableCanvasTools";

export const DrawableCanvas = forwardRef(function (props, outerRef) {
  const ref = useRef(null);
  const textareaRef = useRef(null);

  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);
  const [drawingType, setDrawingType] = useState("pencil");
  const [color, setColor] = useState("#000000");
  const [drawing, setDrawing] = useState(false);
  const [writing, setWriting] = useState(false);
  const [pos, setPos] = useState({
    x: 0,
    y: 0,
  });
  const [start, setStart] = useState({
    x: 0,
    y: 0,
  });
  const [prevHistory, setPrevHistory] = useState([]);
  const [fwdHistory, setFwdHistory] = useState([]);
  const [coordinatesHistory, setCoordinatesHistory] = useState([]);

  useEffect(() => {
    setWidth(window.innerWidth - 50);
    setHeight(window.innerHeight - 225);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updateRef = function () {
      if (outerRef) outerRef.current = ref.current;
    };
    updateRef();
    return () => updateRef();
  }, [ref, outerRef]);

  useEffect(() => {
    if (writing) {
      const textArea = textareaRef.current;
      textArea.value = "";
      textArea.focus();
    }
  }, [writing]);

  /* ------------ UPDATE POSITION ------------*/
  const getPosition = function (event) {
    const rect = event.target.getBoundingClientRect();
    let x, y;
    if (event.changedTouches) {
      const touch = event.changedTouches[0];
      x = touch.clientX - rect.x;
      y = touch.clientY - rect.y;
    } else {
      x = event.clientX - rect.x;
      y = event.clientY - rect.y;
    }
    return {
      x,
      y,
    };
  };

  /* ------------ START ------------*/
  const handleStart = (event) => {
    if (event.buttons !== 1 && event.type !== "touchstart") return;
    const currentPosition = getPosition(event);
    if (drawingType === "text") {
      if (!writing) {
        setWriting(true);
        setPos(currentPosition);
      }
    } else {
      setDrawing(true);
      if (drawingType === "pencil" || drawingType === "eraser") {
        setPos(currentPosition);
        setCoordinatesHistory([currentPosition]);
      } else setStart(currentPosition);
    }
  };

  /* ------------ MOVE ------------*/
  const handleMove = function (event) {
    if (!drawing) return;
    if (drawingType === "pencil" || drawingType === "eraser") {
      draw(() => getPosition(event));
    }
  };

  /* ------------ END ------------*/
  const handleEnd = (event) => {
    if (!drawing) return;
    const end = getPosition(event);
    // pencil or two points, text is handled onBlur of textarea
    if (drawingType !== "pencil" && drawingType !== "eraser") {
      drawTwoPoints(drawingType, start, end, color);
      setPrevHistory([
        ...prevHistory,
        {
          drawingType,
          color,
          start,
          end,
        },
      ]);
    } else {
      if (coordinatesHistory.length > 1) {
        const lastCoordinates = [...coordinatesHistory, end];
        setPrevHistory([
          ...prevHistory,
          {
            drawingType,
            color: drawingType === "eraser" ? "#FFFFFF" : color,
            coordinatesHistory: lastCoordinates,
          },
        ]);
        setCoordinatesHistory([]);
      }
    }
    setFwdHistory([]);
    setDrawing(false);
  };

  /* ------------ PENCIL ------------*/
  /**
   * @param {()=>void} updatePosition
   */
  const draw = function (updatePosition) {
    const canvas = ref.current;
    const ctx = canvas.getContext("2d");
    ctx.lineCap = "round";
    ctx.lineWidth = drawingType === "eraser" ? 15 : 3;
    ctx.strokeStyle = drawingType === "eraser" ? "#FFFFFF" : color;
    ctx.beginPath();
    ctx.moveTo(pos.x, pos.y);
    setCoordinatesHistory([...coordinatesHistory, pos]);
    const updated = updatePosition();
    setPos(updated);
    ctx.lineTo(updated.x, updated.y);
    ctx.stroke();
  };

  const drawTwoPoints = (drawingType, start, end, color) => {
    const canvas = ref.current;
    const ctx = canvas.getContext("2d");
    ctx.lineCap = "round";
    ctx.lineWidth = 3;
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.beginPath();
    switch (drawingType) {
      case "line":
        ctx.moveTo(start.x, start.y);
        ctx.lineTo(end.x, end.y);
        break;
      case "circle":
        const centerX = (start.x + end.x) / 2,
          centerY = (start.y + end.y) / 2;
        ctx.ellipse(
          centerX,
          centerY,
          Math.abs(end.x - centerX),
          Math.abs(end.y - centerY),
          0,
          0,
          2 * Math.PI
        );
        break;
      case "rectangle":
        ctx.rect(start.x, start.y, end.x - start.x, end.y - start.y);
        break;
      default:
        break;
    }
    ctx.stroke();
  };

  const write = (text, position, color) => {
    const canvas = ref.current;
    const ctx = canvas.getContext("2d");
    ctx.font = "20px sans-serif";
    ctx.fillStyle = color;
    ctx.fillText(text, position.x, position.y);
  };

  /* ------------ TEXTAREA FUNCTIONS ------------*/
  const handleBlur = () => {
    const text = textareaRef.current.value;
    if (text.length) {
      write(text, pos, color);
      setPrevHistory([
        ...prevHistory,
        {
          drawingType,
          color,
          text,
          pos,
        },
      ]);

      setFwdHistory([]);
    }
    setWriting(false);
  };

  const autoResize = (event) => {
    const textArea = event.target;
    textArea.cols = textArea.value.length;
  };

  /* ------------ UNDO ------------*/
  const undo = () => {
    // update prevHistory and fwdHistory
    const previous = [...prevHistory];
    const last = previous.pop();
    setFwdHistory([...fwdHistory, last]);
    setPrevHistory([...previous]);
    //redo everything from prevHistory
    const canvas = ref.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    for (const action of previous) {
      executeLast(action);
    }
  };

  /* ------------ REDO ------------*/
  const redo = () => {
    const forward = [...fwdHistory];
    const last = forward.pop();
    setPrevHistory([...prevHistory, last]);
    setFwdHistory([...forward]);
    executeLast(last);
  };

  /* ------------ EXECUTE FROM HISTORY ------------*/
  const executeLast = (last) => {
    const { drawingType, color, coordinatesHistory, start, end, text, pos } =
      last;
    if (drawingType === "text") write(text, pos, color);
    else if (drawingType === "pencil" || drawingType === "eraser") {
      drawFromHistory(drawingType, coordinatesHistory, color);
    } else drawTwoPoints(drawingType, start, end, color);
  };

  /* ------------ PENCIL FROM HISTORY ------------*/
  const drawFromHistory = function (drawingType, coordinatesHistory, color) {
    const canvas = ref.current;
    const ctx = canvas.getContext("2d");
    ctx.lineCap = "round";
    ctx.lineWidth = drawingType === "eraser" ? 15 : 3;
    ctx.strokeStyle = color;
    ctx.beginPath();
    for (let idx = 0; idx < coordinatesHistory.length - 1; idx++) {
      ctx.moveTo(coordinatesHistory[idx].x, coordinatesHistory[idx].y);
      ctx.lineTo(coordinatesHistory[idx + 1].x, coordinatesHistory[idx + 1].y);
    }
    ctx.stroke();
  };

  return (
    <>
      <DrawableCanvasTools
        drawingType={drawingType}
        setDrawingType={setDrawingType}
        color={color}
        setColor={setColor}
        prevHistory={!prevHistory.length}
        fwdHistory={!fwdHistory.length}
        undo={undo}
        redo={redo}
      />
      {writing && (
        <textarea
          ref={textareaRef}
          cols="1"
          style={{
            position: "fixed",
            top: pos.y + 110,
            left: pos.x + 25,
            color,
            border: 0,
            outline: 0,
            margin: 0,
            padding: 0,
            font: "20px sans-serif",
            background: "transparent",
            resize: "none",
          }}
          onBlur={handleBlur}
          onChange={autoResize}
        />
      )}
      <canvas
        id="canvas"
        width={width}
        height={height}
        onMouseDown={handleStart}
        onMouseMove={handleMove}
        onMouseUp={handleEnd}
        onTouchStart={handleStart}
        onTouchMove={handleMove}
        onTouchEnd={handleEnd}
        ref={ref}
        style={{
          border: "solid 1px lightGray",
        }}
      >
        Your browser does not support canvas element.
      </canvas>
    </>
  );
});

export default DrawableCanvas;

/* 
NOTES:
- Touch must be moved to start typing on text mode.
- If you click and release without moving, the forward history is cleared.
*/
