import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";
import { getUid } from "XmarsUtils";
import useStyles from "./SearchTextField.styles";

export const SearchTextField = function ({
  InputProps,
  setFilters,
  reset,
  setReset,
  ...props
}) {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (reset === true) {
      setSearchValue("");
    }
    setReset(false);
  }, [reset, setReset]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    setFilters({ keywords: newValue });
  };

  return (
    <Mui.Box>
      <Mui.Typography>Keywords</Mui.Typography>
      <Mui.TextField
        id={getUid()}
        {...props}
        type="text"
        autoFocus
        InputProps={{
          className: classes.input,
          notched: false,
          classes: {
            root: classes.inputRoot,
            focused: classes.inputFocused,
            notchedOutline: classes.inputNotchedOutline,
          },
          ...InputProps,
        }}
        value={searchValue}
        onChange={handleChange}
        inputProps={{ className: classes.inputBase }}
      />
    </Mui.Box>
  );
};

SearchTextField.propTypes = {
  /**
   * The name to be used in the form
   */
  name: PropTypes.string,
  /**
   * The label to be displayed
   */
  label: PropTypes.string,
  /**
   * The placeholder to be displayed
   */
  placeholder: PropTypes.string,
  /**
   * Input variant
   */
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
};

SearchTextField.defaultProps = {
  name: "keywords",
  placeholder: "ex. house",
  variant: "outlined",
  size: "small",
  fullWidth: true,
  autoComplete: "off",
  InputProps: {},
};

export default SearchTextField;
