import { useEffect, useState } from "react";

import useMounted from "./useMounted";

import env from "../env";
import { logError } from "../utils/errors/logError";
import * as electron from "../electron";

// eslint-disable-next-line
import { JitsiEffect } from "../JitsiMeetJS/typeDefs";

/** @typedef {"loading"|"ready"|"error"} StatusType */

/**
 * @param {JitsiEffect} effect
 * @param {Object<string,any>} initOptions
 * @returns {[StatusType,Error]}
 */
export const useJitsiEffect = function (effect, initOptions) {
  const mounted = useMounted();

  /** @type {[StatusType, React.Dispatch<StatusType>]} */
  const [status, setStatus] = useState("loading");
  /** @type {[Error, React.Dispatch<Error>]} */
  const [error, setError] = useState(null);

  useEffect(() => {
    const dispose = function () {
      if (effect.dispose) effect.dispose();
    };
    const handle = async function () {
      try {
        if (env.DEVELOPMENT && electron.isSupported)
          throw new Error("Unavailable in electron development");
        if (effect.init) await effect.init(initOptions);
        if (!mounted.current) {
          dispose();
        } else {
          setStatus("ready");
        }
      } catch (error) {
        logError(error);
        setError(error);
        setStatus("error");
      }
    };
    handle();
    return dispose;
    // eslint-disable-next-line
  }, []);

  return [status, error];
};

export default useJitsiEffect;
