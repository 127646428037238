/**
 * @param {number} cents
 * @param {Intl.NumberFormatOptions} IntlNumberFormatOpts
 * @param {string} IntlNumberFormatOpts.locale
 */
export const formatUsdCents = function (
  cents,
  { locale = "en-US", ...IntlNumberFormatOpts } = {}
) {
  const dollars = cents / 100;
  const formatter = new Intl.NumberFormat(locale, {
    ...IntlNumberFormatOpts,
    style: "currency",
    currency: "usd",
  });
  const formatted = formatter.format(dollars);
  return formatted;
};

export default formatUsdCents;
