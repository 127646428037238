export const classes = {
  pageWidth: {
    width: "100vw",
    overflowX: "hidden",
  },
  pageHeight: {
    height: "100vh",
    overflowY: "hidden",
  },
  fullPage: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
  },
  fullFormPage: {
    padding: "1rem",
    width: "100%",
    height: "auto",
    overflow: "hidden",
  },
  width100: {
    width: "100%",
  },
  height100: {
    height: "100%",
  },
  widthOverflow: {
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  heightOverflow: {
    //height: "800px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  fullOverflow: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  avatarThumbnail: {
    // MUI Avatar should have variant = "square" or "rounded"
    height: "auto",
    width: "auto",
    maxHeight: "100px",
    maxWidth: "100px",
  },
};

export default classes;
