import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import EditReplyForm from "./EditReplyForm.component";

import {
  editReply,
  setIsEditingReply,
  setEditingReply,
} from "../../../store/actions";

const mapStateToProps = (state) => {
  const { editingReply, currentMessage } = state.chat;
  return {
    message: editingReply,
    threadingMessageId: currentMessage?.id,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editReply,
      setIsEditingReply,
      setEditingReply,
    },
    dispatch
  );

const EditReplyFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditReplyForm);

export default EditReplyFormContainer;
