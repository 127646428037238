import React from "react";
import * as Mui from "@mui/material";

import useStyles from "./LocalAudio.styles";

export const LocalAudio = function (props) {
  const { localAudioVolume } = props;

  const classes = useStyles();

  return (
    <Mui.Slider
      disabled
      orientation="vertical"
      value={localAudioVolume}
      classes={{
        root: classes.root,
        rail: classes.slider,
        track: classes.slider,
        vertical: classes.root,
      }}
    />
  );
};

export default LocalAudio;
