import { loadStripe } from "@stripe/stripe-js";
import { Elements as StripeProvider } from "@stripe/react-stripe-js";

import env from "../env";

// eslint-disable-next-line
import { Component as ReactComponent } from "react";

const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY);

/**
 * Wrap a React form component that uses ONE Stripe input element
 * @param {ReactComponent} Component
 * @returns {ReactComponent}
 */
export const WithStripe = function (Component) {
  return function (props) {
    return (
      <StripeProvider stripe={stripePromise}>
        <Component {...props} />
      </StripeProvider>
    );
  };
};

export default WithStripe;
