/**
 * JSON messages for event handling in conference
 */
export class JitsiMessage {
  /**
   * Type of message to handle
   * @type {"text"|"file"|"scribble"|"speaker"}
   */
  type;

  /**
   * Date of the message creation
   * @type {string}
   */
  date;

  /** @type {string} */
  text;

  /**
   * @param {Object<string,any>} props
   */
  constructor(props) {
    for (const [key, value] of Object.entries(props)) {
      this[key] = value;
    }
    this.date = Date.now();
  }
}

export default JitsiMessage;
